export const queryAllAutomations = `
query queryAllAutomations{
  automations {
    id
    shop
    audience
    trigger
    condition
    actions
    schedule
    isDeleted
    priority
    status
    templateType
    settings
    productMetadata
  }
}
`;

export const queryAutomationsById = `
query queryAutomationsById($id: Int!) {
  automations_by_pk(id: $id) {
    id
    shop
    audience
    trigger
    condition
    actions
    schedule
    isDeleted
    priority
    status
    templateType
    settings
    productMetadata
  }
}
`;

/* --------------- Mutations ------------------- */

export const insertAutomations = `
  mutation insertAutomations ($automationsInput: automations_insert_input!){
    insert_automations_one(object: $automationsInput) {
        id   
    }
  }
`;

export const bulkUpdateAutomations = `
mutation bulkUpdateAutomations($idArray: [Int!],$automationsInput:automations_set_input) {
  update_automations(_set:$automationsInput, where:{_and:{id:{_in:$idArray}}}) {
     affected_rows
    }
}
`;

export const updateAutomations = `
  mutation updateAutomations ($automationsId:automations_pk_columns_input!, $automationsInput:automations_set_input){
    update_automations_by_pk(pk_columns: $automationsId, _set:$automationsInput) {
      id
    }
  }
`;