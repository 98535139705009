import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import CustomiserUnit from "../../../components/CustomiserUnit";
import TabRadioButton from "../../../components/TabRadioButton";
import Textfield from "@atlaskit/textfield";
import Button from "../../../components/Button";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import Popup from "@atlaskit/popup";
import {
  RiAddCircleFill,
  RiEditFill,
  RiCheckboxCircleFill,
  RiDeleteBin2Line,
  RiMenuLine,
  RiArrowDropDownLine,
  RiLoader2Line,
} from "react-icons/ri";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import {
  updateSourceDetails,
  queryWorkspaceProps,
} from "../../../api/workspace";
import { Formik } from "formik";
import { suportedCurrencies } from "../../../utilities/variables";
import { RiAlertFill } from "react-icons/ri";
import fetchRequestWrapper from "../../../utilities/fetchRequestWrapper";
import { nanoid } from "nanoid";
import ErrorModal from "../../../components/ErrorModal";
import contactCrisponApiError from "../../../utilities/contactCrisponApiError";
import useClearCache from "../../../utilities/useClearCache";
import { deepCloneObject } from "../../../utilities/basicUtilityFunctions";

/**
 * Store settings Page.
 * @component
 */
export default function Account() {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const clearCache = useClearCache();
  const [isCurrencySelectVisible, setIsCurrencySelectVisible] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [isFormatsVisible, setIsFormatsVisible] = useState(false);
  const [errorKeyRef, setErrorKeyRef] = useState("");
  const [isExpFeaturesLoading, setIsExpFetauresLoading] = useState(false);
  const [openSyncErrorModal, setOpenSyncErrorModal] = useState(false);

  const queryCache = useQueryCache();

  const { data, refetch } = useQuery("queryWorkspaceProps", () =>
    graphQL(queryWorkspaceProps)
  );

  const updateStoreDetails = async (input) => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: input,
    });
  };

  const [updateStoreDetailsMutation] = useMutation(updateStoreDetails, {
    onSuccess: () => {
      clearCache();
      queryCache.invalidateQueries("queryWorkspaceProps");
      toaster.notify("Store Settings Updated", {
        duration: 2000,
      });
    },
  });

  const sumbitExperimentalFeatuers = async (values) => {
    setIsExpFetauresLoading(true);
    let apiRequest = {
      shop_url: workspace.data.storeMyShopifyUrl,
      key: "release_channel",
      value: values.releaseChannel,
    };
    const isApiTriggered = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/settings/update`,
      apiRequest
    );
    if (isApiTriggered.status !== "OK") {
      const apiErrorKey = nanoid(10);
      setErrorKeyRef(apiErrorKey);
      setOpenSyncErrorModal(true);
      window.Rollbar.error(`Cornercart api failed in release channel update`, {
        ...isApiTriggered,
        shopifyStoreURL: workspace.data.storeMyShopifyUrl,
        API_ERROR_KEY: apiErrorKey,
      });
    } else {
      refetch();
      setIsExpFetauresLoading(false);
    }
  };

  const visibilityOptions = [
    { label: "Disable", value: false },
    { label: "Enable", value: true },
  ];
  const experimentalOptions = [
    { label: "No", value: "stable" },
    { label: "Yes", value: "latest" },
  ];

  const getCurrentAppReleaseChannel = (installations) => {
    const currentAppDetails = installations.find(
      (installVal) => installVal.appid === workspace.data.currentAppID
    );
    if (currentAppDetails && currentAppDetails.release_channel)
      return currentAppDetails.release_channel;
    else return "stable";
  };

  return (
    <div className=" h-full overflow-y-auto">
      {data && (
        <Formik
          enableReinitialize
          initialValues={{
            sources: data.workspaces[0].source,
            releaseChannel: getCurrentAppReleaseChannel(data.installations),
          }}
          onSubmit={(values) => {
            let updatedValue = {
              currency_format: values.sources.currency_format,
              tracking_pixels: values.sources.tracking_pixels,
            };

            sumbitExperimentalFeatuers(values);
            updateStoreDetailsMutation(updatedValue);
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <div className="">
                {dirty && (
                  <div className="z-50 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                <div className="px-8 py-4 ">
                  {/* Store Currencies */}
                  <CustomiserUnit
                    title="Store Currencies"
                    description="Customize how currency values are displayed in Corner widgets. Multi-currency feature is supported only on stores using Shopify Markets. "
                  >
                    {workspace.data.currentAppID === 3 ? (
                      <>
                        <div className="boreder rounded-md bg-gray-100 text-sm p-3 mb-3">
                          <button
                            className="w-full flex justify-between"
                            onClick={() => {
                              setIsFormatsVisible((prevState) => !prevState);
                            }}
                          >
                            <p className="my-2 font-bold">Supported Formats</p>
                            <p className="my-2 font-bold flex items-center">
                              View{" "}
                              <RiArrowDropDownLine
                                size="18"
                                className={`ml-1 transition-all rotate-180 ${
                                  isFormatsVisible && "rotate-0"
                                }`}
                              />{" "}
                            </p>
                          </button>
                          {isFormatsVisible && (
                            <div>
                              <div className="mb-2">
                                <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                  {`{{ amount }}`}
                                </span>{" "}
                                : Example : 1,999.99
                              </div>

                              <div className="mb-2">
                                <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                  {`{{ amount_no_decimals }}`}
                                </span>{" "}
                                : Example : 2,000
                              </div>

                              <div className="mb-2">
                                <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                  {`{{ amount_with_comma_separator }}`}
                                </span>{" "}
                                : Example : 1.999,99
                              </div>

                              <div className="mb-2">
                                <span className="font-mono font-bold bg-gray-300 px-2 py-1 rounded-md">
                                  {`{{ amount_no_decimals_with_comma_separator }}`}
                                </span>{" "}
                                : Example : 2.000
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="mt-3 border rounded-lg max-w-4xl">
                          {/* Object.entries() returns an array of key value pairs in a js object */}
                          {values.sources.currency_format &&
                            Object.entries(values.sources.currency_format).map(
                              (currencyKeyValuePair) => {
                                // markign which one is the default currency
                                if (currencyKeyValuePair[1].isDefault)
                                  setDefaultCurrency(currencyKeyValuePair[0]);

                                return (
                                  <CurrencyItem
                                    format={currencyKeyValuePair[1].format}
                                    currency={currencyKeyValuePair[0]}
                                    onChange={(currency, format) => {
                                      setFieldValue(
                                        `sources.currency_format.${currency}.format`,
                                        format
                                      );
                                    }}
                                    onDelete={(currency) => {
                                      // deep cloning the object so that formik detects the change
                                      let updatedValue = deepCloneObject(
                                        values.sources.currency_format
                                      );
                                      delete updatedValue[currency];
                                      setFieldValue(
                                        `sources.currency_format`,
                                        updatedValue
                                      );
                                    }}
                                    isDefaultCurrency={
                                      currencyKeyValuePair[1].isDefault
                                    }
                                    showDecimalsIfWholeNumber={
                                      !!currencyKeyValuePair[1]
                                        .showDecimalsIfWholeNumber
                                    }
                                    onShowDecimalsIfWholeNumberChange={(
                                      value
                                    ) => {
                                      setFieldValue(
                                        `sources.currency_format.${currencyKeyValuePair[0]}.showDecimalsIfWholeNumber`,
                                        value
                                      );
                                    }}
                                    onDefaultCurrencyChange={(currency) => {
                                      // At a tie there will be only one default value. Thats why we alter two values here
                                      setFieldValue(
                                        `sources.currency_format.${currency}.isDefault`,
                                        true
                                      );
                                      setFieldValue(
                                        `sources.currency_format.${defaultCurrency}.isDefault`,
                                        false
                                      );
                                    }}
                                  />
                                );
                              }
                            )}
                          <div className="py-3 w-full font-bold text-violet-700">
                            {!isCurrencySelectVisible && (
                              <button
                                onClick={() => setIsCurrencySelectVisible(true)}
                                className="py-3 w-full font-bold text-violet-700 flex justify-center items-center"
                              >
                                <RiAddCircleFill />
                                <span className="ml-2">Add Currency</span>
                              </button>
                            )}
                            {isCurrencySelectVisible && (
                              <div className="flex justify-center items-center px-3">
                                <Select
                                  onChange={(selectedLanguage) => {
                                    let updatedValue = {
                                      ...values.sources.currency_format,
                                      [`${selectedLanguage.code}`]: {
                                        format: selectedLanguage.value,
                                        showDecimalsIfWholeNumber: false,
                                        // if its the first cuurency then isDefalt is set asn true by default
                                        isDefault: values.sources
                                          .currency_format
                                          ? false
                                          : true,
                                      },
                                    };
                                    setFieldValue(
                                      `sources.currency_format`,
                                      updatedValue
                                    );
                                    setIsCurrencySelectVisible(false);
                                  }}
                                  className="w-48 mr-2 grow"
                                  options={suportedCurrencies}
                                  placeholder="Click to select a currency"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-sm p-3 mt-3 border rounded-lg bg-gray-100">
                          <span className="font-bold"> Warning:</span> If you
                          don't add all currencies in your store to this list,
                          it's possible that a user may see an incorrect price
                          for a product.
                        </div>{" "}
                      </>
                    ) : (
                      <div className="text-gray-500 p-4 bg-gray-100 rounded-lg border">
                        <p className="font-bold"> Limited Feature</p>
                        <p className="">
                          {" "}
                          This feature is only available if you've installed our
                          CornerCart App
                        </p>
                      </div>
                    )}
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Experimental Features"
                    description="Turn this on to explore our latest features and help our team improve the product by sharing your feedback."
                  >
                    <div className="flex items-center mt-5">
                      <div className="mb-5">
                        {workspace.data.currentAppID === 3 ? (
                          <div className="flex gap-4">
                            <TabRadioButton
                              defaultValue={values.releaseChannel}
                              options={experimentalOptions}
                              onChange={(selectedValue) => {
                                setFieldValue("releaseChannel", selectedValue);
                              }}
                            />
                            {isExpFeaturesLoading && (
                              <div className="flex items-center justify-center">
                                <div className=" animate-spin text-xl text-violet-700">
                                  <RiLoader2Line />
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-gray-500 p-4 bg-gray-100 rounded-lg border">
                            <p className="font-bold"> Limited Feature</p>
                            <p className="">
                              This feature is only available if you've installed
                              our CornerCart App
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Tracking Pixels"
                    description="Tracking pixels will be used to report relevant store events to Google"
                  >
                    <div className="flex items-center mt-5">
                      <div className="mb-5">
                        <p className="font-bold text-sm mb-4">
                          Google Analytics
                        </p>
                        {workspace.data.currentAppID === 3 ? (
                          <TabRadioButton
                            defaultValue={
                              values.sources.tracking_pixels.gaPixel
                            }
                            options={visibilityOptions}
                            onChange={(selectedValue) => {
                              setFieldValue(
                                "sources.tracking_pixels.gaPixel",
                                selectedValue
                              );
                            }}
                          />
                        ) : (
                          <div className="text-gray-500 p-4 bg-gray-100 rounded-lg border">
                            <p className="font-bold"> Limited Feature</p>
                            <p className="">
                              {" "}
                              This feature is only available if you've installed
                              our CornerCart App
                            </p>
                          </div>
                        )}
                        {data.installations.find((item) => item.appid === 3) &&
                          !data?.feature_access[0].feature_flag?.apps.cornercart
                            ?.facebook_ga_tracking &&
                          values.sources.tracking_pixels.gaPixel && (
                            <div className="bg-amber-100 p-3 mt-5 text-amber-700 rounded-lg flex items-center">
                              <RiAlertFill className="mr-3" />
                              <span>
                                You need a higher plan in CornerCart to enable
                                Tracking Pixels
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </CustomiserUnit>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
      <ErrorModal
        isOpen={openSyncErrorModal}
        onClose={() => setOpenSyncErrorModal(false)}
        title="Sorry!! Something went wrong"
        description="Please contact our support team to help you out with this issue."
        handleApiError={() =>
          contactCrisponApiError(
            errorKeyRef,
            `Could you please help with this issue: "Syncing release channel failed" ?`
          )
        }
      />
    </div>
  );
}

/**
 * An UI element designed to show currecncies in a store and a text field to edit those.
 * @component
 * @param {String} format - The format of thee currency
 * @param {String} Currency - The currency code of the format
 * @param {Function} onChange - What happens on change
 * @param {Function} onDelete - What happens on delete
 * @param {Function} onDefaultCurrencyChange - call this fuction to make the currency default
 * @param {Function} onShowDecimalsIfWholeNumberChange - call this fuction tochange the showDecimalsIfWholeNumber
 * @param {Boolean} isDefaultCurrency - denotes if its default currency or not
 * @param {Boolean} showDecimalsIfWholeNumber - denotes if decimal values are shown in whole number or not
 */
const CurrencyItem = ({
  format,
  currency,
  onChange,
  onDelete,
  isDefaultCurrency,
  onDefaultCurrencyChange,
  onShowDecimalsIfWholeNumberChange,
  showDecimalsIfWholeNumber,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFormat, setCurrentFormat] = useState(format);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const yesNoSelect = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  return (
    <div className="flex items-center border-b  p-3">
      <div className="grow-0 px-4 mr-2">
        <p className="text-xs font-bold text-gray-400 leading-none">Currency</p>
        <h4 className="text-base font-bold leading-0">{currency}</h4>
      </div>
      {editMode ? (
        <div className="grow flex items-center">
          <Textfield
            defaultValue={format}
            onChange={(e) => {
              setCurrentFormat(e.target.value);
            }}
            name="basic"
          />
          <Button
            className="ml-2"
            onClick={() => {
              onChange(currency, currentFormat, isDefaultCurrency);
              setEditMode(false);
            }}
            type="primary"
            icon={<RiCheckboxCircleFill />}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="grow flex items-center">
          <div className=" grow px-2 mr-2">
            <p className="text-xs font-bold text-gray-400 leading-none">
              Format
            </p>
            <h4 className="text-base font-bold leading-0">{format}</h4>
          </div>

          <Popup
            isOpen={isMoreOptionsOpen}
            placement="bottom-end"
            onClose={() => setIsMoreOptionsOpen(false)}
            content={() => (
              <div className="p-4">
                {!isDefaultCurrency && (
                  <div className="text-left max-w-md mb-3">
                    <p className="font-bold">Default currency</p>
                    <p className="font-light text-gray-600 text-sm">
                      We will use this default currency setting as a fallback if
                      a currency value listed in your store is not in this list.
                    </p>
                    <Button
                      type="ghost"
                      className="mt-3 text-sm"
                      onClick={() => {
                        onDefaultCurrencyChange(currency);
                      }}
                    >
                      <p className="text-sm">
                        {" "}
                        Make {currency} default currency
                      </p>
                    </Button>
                  </div>
                )}
                <div className="text-left max-w-md ">
                  <p className="font-bold">Show Decimals in Whole Numbers</p>
                  <p className="font-light text-gray-600 text-sm">
                    Select whether to show decimal places when rendering whole
                    numbers. For example 15 USD will be shown as 15.00 USD
                  </p>
                  <div className="mt-3">
                    <TabRadioButton
                      defaultValue={showDecimalsIfWholeNumber}
                      options={yesNoSelect}
                      onChange={(selectedValue) => {
                        onShowDecimalsIfWholeNumberChange(selectedValue);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            trigger={(triggerProps) => (
              <button
                {...triggerProps}
                className="flex text-center items-center font-bold text-gray-600 p-3 rounded-lg"
                type="link"
                // Here the state is an object because this state has to handle open/close state of multiple items in the list
                onClick={() => setIsMoreOptionsOpen(true)}
              >
                <RiMenuLine />
              </button>
            )}
          />

          {!isDefaultCurrency ? (
            <Button
              className="mr-2"
              onClick={() => {
                onDelete(currency);
              }}
              type="danger-link"
              icon={<RiDeleteBin2Line />}
            />
          ) : (
            <div className="text-xs mr-3 px-3 py-1 bg-gray-100 text-gray-600 rounded-sm border font-bold">
              Default Currency
            </div>
          )}
          <Button
            onClick={() => {
              setEditMode(true);
            }}
            type="outline"
            icon={<RiEditFill />}
          >
            Edit Format
          </Button>
        </div>
      )}
    </div>
  );
};
