import React from "react";
import { RiShoppingCart2Fill, RiEditFill, RiAlertFill } from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import toaster from "toasted-notes";
import Button from "../../../../../components/Button";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import NumberInput from "../../../../../components/NumberInput";
import MultiResourceSelector from "../../../../../components/MultiResourceSelector";
import { FieldArray } from "formik";
import { parseShopifyProductId } from "../../../../../utilities/basicUtilityFunctions";

const ConditonItem = ({
  title,
  description,
  btnText = "Select",
  onBtnClick,
  showBorder = true,
  showEditIcon = false,
}) => {
  return (
    <div
      className={`flex flex-row justify-between p-6 ${
        showBorder && "border-b border-solid border-gray-200"
      } items-center`}
    >
      <div className="flex flex-col mr-8">
        <p className="text-base font-bold text-gray-800">{title}</p>
        <p className="text-tiny text-gray-600">{description}</p>
      </div>
      <div>
        <Button
          type={"ghost"}
          onClick={() => onBtnClick()}
          icon={showEditIcon && <RiEditFill />}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};

const getRewardConditionText = (type) => {
  switch (type) {
    case "BUYX":
      return {
        title: "Buy Product X",
        description: "Reward visitors for buying a specific product",
      };
    case "SPENDXINY":
      return {
        title: "Spend X in Collection Y",
        description:
          "Reward visitors for spending a specific amount buying products from a certain collection",
      };
    case "BUYXFROMY":
      return {
        title: "Buy Any Product from Collection Y",
        description:
          "Reward visitors for buying a specific number of products from a certain collection",
      };
  }
};

const RewardConditonSelector = ({
  values,
  setFieldValue,
  validationFailed,
}) => {
  const onRewardConditionSelect = (type) => {
    setFieldValue(`campaign_bxgy_frees[0].config.type`, type);
    if (type === "BUYX" || type === "BUYXFROMY") {
      setFieldValue(`campaign_bxgy_frees[0].config.customerBuys.value`, {
        amount: "",
        quantity: 1,
      });
    }
  };

  const onRewardConditionChange = () => {
    setFieldValue(`campaign_bxgy_frees[0].config.type`, "");
    // reset form values on changing the selected condition
    setFieldValue(`campaign_bxgy_frees[0].config.customerBuys`, {
      items: {
        all: false,
        collections: {
          add: [],
          remove: [],
        },
        products: {
          productVariantsToAdd: [],
          productVariantsToRemove: [],
          productsToAdd: [],
          productsToRemove: [],
        },
      },
      value: {
        amount: "",
        quantity: "",
      },
    });
  };

  /**
   * This function checks the shopify config when selecting a collection
   * and decides whether or not it should be added shopify_config remove list
   * It checks for remove item list in collection
   * and removes current selected collection and saves it to shopify_config
   * @param {String} collectionId
   */
  const handleCollectionSelectShopifyConfig = (collectionId) => {
    const bxgyDiscount = values.shopify_config.automaticBxgyDiscount;
    if (
      bxgyDiscount &&
      bxgyDiscount.customerBuys.items.collections &&
      bxgyDiscount.customerBuys.items.collections.remove
    ) {
      const removeItems = bxgyDiscount.customerBuys.items.collections.remove;
      // filter out remove items if product added is in the remove item list
      if (removeItems.includes(collectionId)) {
        const restOfRemoveItems = removeItems.filter(
          (removeItem) => removeItem !== collectionId
        );
        setFieldValue(
          "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerBuys.items.collections.remove",
          [...restOfRemoveItems]
        );
      }
    }
  };

  /**
   * This function checks the shopify config when deleting a collection
   * and decides whether or not it should be added shopify_config remove list
   * It checks for remove item list in collection
   * and adds current selected collection and saves it to shopify_config
   * @param {String} collectionId
   */
  const handleCollectionDeleteShopifyConfig = (collectionId) => {
    const bxgyDiscount = values.shopify_config.automaticBxgyDiscount;
    if (
      bxgyDiscount &&
      bxgyDiscount.customerBuys.items.collections &&
      bxgyDiscount.customerBuys.items.collections.remove
    ) {
      const { add, remove } =
        values.shopify_config.automaticBxgyDiscount.customerBuys.items
          .collections;
      // check if product is in previous shopify_config productsToAdd
      // check if product id is not already added to new product id remove list
      if (add.includes(collectionId) && !remove.includes(collectionId)) {
        setFieldValue(
          "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerBuys.items.collections.remove",
          [...remove, collectionId]
        );
      }
    }
  };

  const renderConditionDetailsSelect = () => {
    switch (values.config.type) {
      case "BUYX":
        return (
          <>
            <CustomiserUnit
              title="Select the Product Visitor Must Buy"
              description="Select when does the customer gets the reward"
            >
              <FieldArray
                name={`campaign_bxgy_frees[0].config.customerBuys.items.products.productsToAdd`}
                render={(arrayHelpers) => {
                  return (
                    <MultiResourceSelector
                      className={"max-w-lg"}
                      initialValues={
                        values.config.customerBuys.items.products.productsToAdd
                      }
                      btnText={"Add more products"}
                      emptyWarning={
                        validationFailed && (
                          <div className="flex items-center justify-center mt-1">
                            <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                              <RiAlertFill
                                size={12}
                                className="grow-0 animate-bounce"
                              />
                              <span className="ml-3">
                                Make sure a product is added
                              </span>
                            </div>
                          </div>
                        )
                      }
                      onAdd={(selectedProduct) => {
                        const selectedProductId = parseShopifyProductId(
                          selectedProduct[0].id
                        );
                        const rewardProduct = {
                          title: selectedProduct[0].title,
                          id: selectedProduct[0].id,
                          product_id: selectedProductId,
                          handle: selectedProduct[0].handle,
                          images: selectedProduct[0].images,
                        };
                        const currentRewardProducts =
                          values.config.customerBuys.items.products
                            .productsToAdd;

                        setFieldValue(
                          `campaign_bxgy_frees[0].config.customerBuys.items.products.productsToAdd`,
                          [...currentRewardProducts, rewardProduct]
                        );
                        const previouslySavedConfig =
                          values.shopify_config.automaticBxgyDiscount;
                        if (
                          previouslySavedConfig &&
                          previouslySavedConfig.customerBuys.items.products
                        ) {
                          const removeItems =
                            values.shopify_config.automaticBxgyDiscount
                              .customerBuys.items.products.productsToRemove;
                          // filter out remove items if product added is in the remove item list
                          if (removeItems.includes(selectedProduct[0].id)) {
                            const restOfRemoveItems = removeItems.filter(
                              (removeItem) =>
                                removeItem !== selectedProduct[0].id
                            );
                            setFieldValue(
                              "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerBuys.items.products.productsToRemove",
                              [...restOfRemoveItems]
                            );
                          }
                        }
                      }}
                      onDelete={(deletedIndex, deletedProduct) => {
                        // on Delete the updatedrewardStack length will be length - 1
                        const updatedRewardStackLength =
                          values.config.customerBuys.items.products
                            .productsToAdd - 1;

                        if (updatedRewardStackLength <= 1) {
                          // reset the stackSelect count
                          setFieldValue(
                            `campaign_bxgy_frees[0].config.customerBuys.value.quantity`,
                            1
                          );
                        }
                        arrayHelpers.remove(deletedIndex);
                        if (values.shopify_config.automaticBxgyDiscount) {
                          const { productsToAdd, productsToRemove } =
                            values.shopify_config.automaticBxgyDiscount
                              .customerBuys.items.products;
                          const currentProductList =
                            values.config.customerBuys.items.products
                              .productsToAdd;

                          const deleteOccurrences = currentProductList.filter(
                            (product) => product.id === deletedProduct.id
                          );

                          // check if product is in previous shopify_config productsToAdd
                          // check if product id is not already added to new product id remove list
                          // if product is already synced to shopify add the items to remove list
                          if (
                            productsToAdd.includes(deletedProduct.id) &&
                            !productsToRemove.includes(deletedProduct.id) &&
                            deleteOccurrences.length < 2
                          ) {
                            setFieldValue(
                              "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerBuys.items.products.productsToRemove",
                              [...productsToRemove, deletedProduct.id]
                            );
                          }
                        }
                      }}
                      CustomInitialSelectBtn={(btnProps) => (
                        <Button
                          icon={<RiShoppingCart2Fill />}
                          type="outline"
                          {...btnProps}
                        >
                          Select Product
                        </Button>
                      )}
                    />
                  );
                }}
              />
            </CustomiserUnit>
            <CustomiserUnit
              title="Minimum Quantity"
              description="Select the minimum quantity of items visitor must buy to unlock the free gift"
            >
              <NumberInput
                value={values.config.customerBuys.value.quantity}
                min={1}
                max={null}
                onChange={(value) => {
                  setFieldValue(
                    `campaign_bxgy_frees[0].config.customerBuys.value.quantity`,
                    value
                  );
                }}
              />
            </CustomiserUnit>
          </>
        );

      case "SPENDXINY":
        return (
          <>
            <CustomiserUnit
              title="Select the Collection"
              description="Select the collection from which visitor must buy to get the free gift"
            >
              <FieldArray
                name={`campaign_bxgy_frees[0].config.customerBuys.items.collections.add`}
                render={(arrayHelpers) => {
                  return (
                    <MultiResourceSelector
                      className={"max-w-lg"}
                      resourceType="collection"
                      initialValues={
                        values.config.customerBuys.items.collections.add
                      }
                      btnText="Add More Collections"
                      emptyWarning={
                        validationFailed && (
                          <div className="flex items-center justify-center mt-1">
                            <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                              <RiAlertFill
                                size={12}
                                className="grow-0 animate-bounce"
                              />
                              <span className="ml-3">
                                Make sure a collection is added
                              </span>
                            </div>
                          </div>
                        )
                      }
                      onAdd={(selectedCollection) => {
                        const collectionId = parseShopifyProductId(
                          selectedCollection[0].id
                        );
                        const currentRewardProducts =
                          values.config.customerBuys.items.collections.add;
                        if (
                          !currentRewardProducts.some(
                            (product) => product.id === selectedCollection[0].id
                          )
                        ) {
                          const collectionDetails = {
                            title: selectedCollection[0].title,
                            id: selectedCollection[0].id,
                            collectionId: collectionId,
                            handle: selectedCollection[0].handle,
                            images: [selectedCollection[0].image],
                          };

                          setFieldValue(
                            `campaign_bxgy_frees[0].config.customerBuys.items.collections.add`,
                            [...currentRewardProducts, collectionDetails]
                          );
                          handleCollectionSelectShopifyConfig(
                            selectedCollection[0].id
                          );
                        } else {
                          toaster.notify("Please add another collection ", {
                            duration: 2000,
                          });
                        }
                      }}
                      onDelete={(deletedIndex, deletedProduct) => {
                        arrayHelpers.remove(deletedIndex);
                        handleCollectionDeleteShopifyConfig(deletedProduct.id);
                      }}
                      CustomInitialSelectBtn={(btnProps) => (
                        <Button
                          icon={<RiShoppingCart2Fill />}
                          type="outline"
                          {...btnProps}
                        >
                          <span>Select Collection</span>
                        </Button>
                      )}
                    />
                  );
                }}
              />
            </CustomiserUnit>
            <CustomiserUnit
              title="Minimum Spend"
              description="Select the minimum amount visitor must buy to unlock the free gift"
            >
              <div className="flex items-center">
                <div className="w-24">
                  <Textfield
                    value={values.config.customerBuys.value.amount}
                    min={1}
                    onChange={(onChangeEvent) => {
                      setFieldValue(
                        `campaign_bxgy_frees[0].config.customerBuys.value.amount`,
                        onChangeEvent.target.value
                      );
                    }}
                    name="basic"
                    type="number"
                  />
                </div>
                {/* Error messages */}
                {validationFailed && !values.config.customerBuys.value.amount && (
                  <div
                    className={`ml-4 py-3 px-2 rounded-lg flex text-sm items-center text-red-700 bg-red-100`}
                  >
                    <RiAlertFill
                      size={12}
                      className={`grow-0 mr-3 animate-bounce`}
                    />
                    <span>Please select the minimun spend amount</span>
                  </div>
                )}
              </div>
            </CustomiserUnit>
          </>
        );
      case "BUYXFROMY":
        return (
          <>
            <CustomiserUnit
              title="Select the Collection"
              description="Select the collection from which visitor must buy to get the free gift"
            >
              <FieldArray
                name={`campaign_bxgy_frees[0].config.customerBuys.items.collections.add`}
                render={(arrayHelpers) => {
                  return (
                    <MultiResourceSelector
                      className={"max-w-lg"}
                      resourceType="collection"
                      initialValues={
                        values.config.customerBuys.items.collections.add
                      }
                      btnText="Add More Collections"
                      emptyWarning={
                        validationFailed && (
                          <div className="flex items-center justify-center mt-1">
                            <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                              <RiAlertFill
                                size={12}
                                className="grow-0 animate-bounce"
                              />
                              <span className="ml-3">
                                Make sure a collection is added
                              </span>
                            </div>
                          </div>
                        )
                      }
                      onAdd={(selectedCollection) => {
                        const collectionId = parseShopifyProductId(
                          selectedCollection[0].id
                        );
                        const collectionDetails = {
                          title: selectedCollection[0].title,
                          id: selectedCollection[0].id,
                          collectionId: collectionId,
                          handle: selectedCollection[0].handle,
                          images: [selectedCollection[0].image],
                        };
                        const currentRewardProducts =
                          values.config.customerBuys.items.collections.add;
                        setFieldValue(
                          `campaign_bxgy_frees[0].config.customerBuys.items.collections.add`,
                          [...currentRewardProducts, collectionDetails]
                        );
                      }}
                      onDelete={(deletedIndex, deletedProduct) => {
                        arrayHelpers.remove(deletedIndex);
                      }}
                      CustomInitialSelectBtn={(btnProps) => (
                        <Button
                          icon={<RiShoppingCart2Fill />}
                          type="outline"
                          {...btnProps}
                        >
                          <span>Select Collection</span>
                        </Button>
                      )}
                    />
                  );
                }}
              />
            </CustomiserUnit>
            <CustomiserUnit
              title="Minimum Quantity"
              description="Select the minimum quantity of items visitor must buy to unlock the free gift"
            >
              <NumberInput
                value={values.config.customerBuys.value.quantity}
                min={1}
                max={null}
                onChange={(value) => {
                  setFieldValue(
                    `campaign_bxgy_frees[0].config.customerBuys.value.quantity`,
                    value
                  );
                }}
              />
            </CustomiserUnit>
          </>
        );
    }
  };

  return (
    <>
      {/* show reward condition is not previously selected */}
      {!values.config.type ? (
        <div className="max-w-3xl">
          {validationFailed && !values.config.type && (
            <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1 mb-2">
              <RiAlertFill size={12} className="grow-0 animate-bounce" />
              <span className="ml-3">
                Make sure a reward condition is selected
              </span>
            </div>
          )}
          <div
            className={`border border-gray-200 flex flex-col  rounded-md  ${
              validationFailed && "border border-red-400"
            }`}
          >
            <ConditonItem
              title={getRewardConditionText("BUYX").title}
              description={getRewardConditionText("BUYX").description}
              onBtnClick={() => onRewardConditionSelect("BUYX")}
            />
            <ConditonItem
              title={getRewardConditionText("SPENDXINY").title}
              description={getRewardConditionText("SPENDXINY").description}
              onBtnClick={() => onRewardConditionSelect("SPENDXINY")}
            />
            <ConditonItem
              title={getRewardConditionText("BUYXFROMY").title}
              description={getRewardConditionText("BUYXFROMY").description}
              onBtnClick={() => onRewardConditionSelect("BUYXFROMY")}
              showBorder={false}
            />
          </div>
        </div>
      ) : (
        <div className="border border-solid border-gray-200 max-w-3xl rounded-md">
          <ConditonItem
            title={getRewardConditionText(values.config.type).title}
            description={getRewardConditionText(values.config.type).description}
            btnText="Change"
            onBtnClick={() => onRewardConditionChange()}
          />
          <div className="p-6">{renderConditionDetailsSelect()}</div>
        </div>
      )}
    </>
  );
};

export default RewardConditonSelector;
