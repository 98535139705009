const getJsonLogic = (configValues) => {
  const bxgyType = configValues.type;
  const rewardConditionType =
    bxgyType === "BUYX" ? "productArray" : "collectionArray";
  const rewardConditions =
    bxgyType === "BUYX"
      ? configValues.customerBuys.items.products.productsToAdd
      : configValues.customerBuys.items.collections.add;
  const rewardArray = rewardConditions.map((condtion) => {
    return {
      "==": [
        {
          var: `${
            rewardConditionType === "productArray"
              ? "productId"
              : "collectionId"
          }`,
        },
        `${
          rewardConditionType === "productArray"
            ? condtion.product_id
            : condtion.collectionId
        }`,
      ],
    };
  });
  const conditionType = bxgyType === "SPENDXINY" ? "amount" : "quantity";
  const conditionTypeValue =
    bxgyType === "SPENDXINY"
      ? configValues.customerBuys.value.amount * 100
      : configValues.customerBuys.value.quantity;

  return {
    ">=": [
      {
        reduce: [
          {
            filter: [
              { var: `${rewardConditionType}` },
              {
                or: rewardArray,
              },
            ],
          },
          {
            "+": [{ var: `current.${conditionType}` }, { var: "accumulator" }],
          },
          0,
        ],
      },
      conditionTypeValue,
    ],
  };
};

export default getJsonLogic;
