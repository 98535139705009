import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import {
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiGift2Fill,
  RiPencilFill,
  RiListSettingsFill,
  RiLoader3Line,
  RiArrowGoForwardFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import toaster from "toasted-notes";
import Joyride from "react-joyride";
import { useBlock } from "@dopt/react";
import {
  queryCampaignCartGoalById,
  updateCartGoalCampaignDetails,
  queryHighestCampaignKey,
} from "../../../../api/campaign";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import fetchRequestWrapper from "../../../../utilities/fetchRequestWrapper";
import generateNanoId from "../../../../utilities/generateNanoId";
import ErrorModal from "../../../../components/ErrorModal";
import { nanoid } from "nanoid";
import contactCrisponApiError from "../../../../utilities/contactCrisponApiError";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import Campaign from "./Campiagn";
import Content from "./Content";
import Settings from "./Settings";
import CampaignSyncFailWarning from "../../../../components/CampaignSyncFailWarning";
import useCompleteDoptFlow from "../../../../utilities/useCompleteDoptFlow";
import { visibilityOptions } from "../../../../defaultValues/campaign";
import useClearCache from "../../../../utilities/useClearCache";

export default function EditCartGoals() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();

  const { finishFlow } = useCompleteDoptFlow();

  const [openSyncErrorModal, setOpenSyncErrorModal] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(true);
  const [errorKeyRef, setErrorKeyRef] = useState("");
  const [isValidationFailed, setIsValidationFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCampaignKey, setCurrentCampaignKey] = useState(0);
  const [currentTab, setCurrentTab] = useState("campaign");

  const [cartGoalOnboarding, cartGoalOnboardTransition] = useBlock(
    "cart-goal-onboard.campaign-edit"
  );

  const cartGoalsteps = [
    {
      target: "#cart-goal-type",
      content:
        "Goals can be in terms of cart value (order amount) or number of items in cart.",
      offset: 20,
      disableBeacon: true,
    },
    {
      target: "#spend-goal-value-0",
      content: "Set a goal value",
      offset: 20,
    },
    {
      target: "#select-reward-type-0",
      content: "Choose a reward type: Free Gift, Free Shipping or Discount.",
      offset: 20,
    },
    {
      target: "#add-new-goal",
      disableScrolling: true,
      content: `You can have multiple goals with different rewards for each goal. If you wish to add more goals, click on "Add a new goal" button. But let's skip to the next step for now.`,
      offset: 20,
      placement: "center",
    },
    {
      target: "#cart-goal-tab-settings",
      disableScrolling: true,
      content:
        "If you wish, you can edit content & translations here. Let's skip this for now.",
      offset: 20,
    },
    {
      target: "#cart-goal-status",
      content: `Set this campaign as active and click on "Save Changes"`,
      offset: 20,
      placementBeacon: "bottom-end",
      styles: {
        options: {
          primaryColor: "#3d2266",
        },
      },
    },
  ];

  const tabItems = [
    { label: "Goals & Rewards", id: "campaign", icon: <RiGift2Fill /> },
    { label: "Edit Texts", id: "content", icon: <RiPencilFill /> },
    {
      label: "Other Settings",
      id: "settings",
      icon: <RiListSettingsFill />,
    },
  ];

  const { data, refetch } = useQuery("queryCampaignCartGoalById", () =>
    graphQL(queryCampaignCartGoalById, { id: params.id })
  );
  // get the campaign with largest key value
  const { data: campaignWithHighestKey } = useQuery(
    "queryHighestCampaignKey",
    () => graphQL(queryHighestCampaignKey)
  );

  // this useEffect manages the campaign key
  // checks if the campaign key is in the table, then save it to the state
  // else if the campaign are old ones, the campaign key will be "0", generate new campaign key
  // and save it on update
  useEffect(() => {
    if (data) {
      const campaignKey = data.campaign_by_pk.key;
      if (campaignKey === 0) {
        if (campaignWithHighestKey) {
          if (campaignWithHighestKey.campaign.length === 0) {
            // ideally this should not happen.
            // either there is a campaign with properkeys
            // or it should have intial value as 0
            setCurrentCampaignKey(0);
          } else {
            const highestCampaignKey = campaignWithHighestKey.campaign[0]?.key;
            const newCampaignKey = parseInt(highestCampaignKey) + 1;
            setCurrentCampaignKey(newCampaignKey);
          }
        }
      } else {
        setCurrentCampaignKey(campaignKey);
      }
    }
    return () => {
      setCurrentCampaignKey("");
    };
  }, [data, campaignWithHighestKey]);

  const updateCartGoalCampaign = async (values) => {
    const updatedCampaignDetails = await graphQL(
      updateCartGoalCampaignDetails,
      {
        campaignId: values.id,
        campaignInput: values.campaignDetails,
        cartGoalId: values.cartGoalId,
        cartGoalInput: values.cartGoalDetails,
      }
    );
    refetch();
    return updatedCampaignDetails;
  };

  const triggerCampaignAPI = async (type, campaignTriggerRqst) => {
    const isCampaignTriggered = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/campaign/${type}`,
      campaignTriggerRqst
    );
    if (isCampaignTriggered.status !== "OK") {
      const apiErrorKey = nanoid(10);
      setErrorKeyRef(apiErrorKey);
      setOpenSyncErrorModal(true);
      window.Rollbar.error(`Cornercart ${type} api failed in CartGoal`, {
        ...isCampaignTriggered,
        shopifyStoreURL: workspace.data.storeMyShopify,
        API_ERROR_KEY: apiErrorKey,
      });
    } else {
      refetch();
    }
    setIsLoading(false);
  };

  const [updateCartGoalCampaignMutation] = useMutation(updateCartGoalCampaign, {
    onSuccess: (data) => {
      clearCache();
      queryCache.invalidateQueries("queryCampaignCartGoalById");
      toaster.notify("Cart Goals updated 🎉", {
        duration: 2000,
      });
      if (cartGoalOnboardTransition) cartGoalOnboardTransition("next");
      if (data && data.update_campaign_by_pk) {
        const { id, status } = data.update_campaign_by_pk;
        const campaignTriggerRequest = {
          campaign_id: parseInt(id),
          shop_url: workspace.data.storeMyShopifyUrl,
        };
        if (status === "not_published") {
          setIsLoading(true);
          triggerCampaignAPI("publish", campaignTriggerRequest);
        } else {
          triggerCampaignAPI("unpublish", campaignTriggerRequest);
        }
      }
    },
  });

  /**
   * Return reward table entries for each reward type.
   * please refer notion doc for details
   * @link https://www.notion.so/cornerteam/RFC-128-Functions-84c70dd894274c7696dfa34e44d37c27
   * @param {String} reward reward object
   * @returns Object with reward discount type and message
   */
  const getRewardTypeDetails = (reward) => {
    switch (reward.type) {
      case "freeProduct":
        return {
          f: "fg",
          m: "Gift",
        };
      case "shippingDiscount":
        return {
          f: "s",
          m: "Shipping",
        };
      case "orderDiscount":
        return {
          f: "cd",
          m: "Offer",
        };
      default:
        return {
          f: "fg",
          m: "Gift",
        };
    }
  };

  function findHighestProductOccurrences(rewardStack) {
    const occurrences = {};

    // If the product_id is occurrences, increase its count
    // else set the value to 1
    rewardStack.forEach((reward) => {
      const productId = reward.target.product_id;
      if (occurrences[productId]) {
        occurrences[productId]++;
      } else {
        occurrences[productId] = 1;
      }
    });

    // Find the highest occurrence count
    let maxOccurrences = 0;
    for (const productId in occurrences) {
      if (occurrences[productId] > maxOccurrences) {
        maxOccurrences = occurrences[productId];
      }
    }
    return maxOccurrences;
  }

  /**
   * The following function generates a jsonLogic based on the cart goal for each milestone
   * This json Logic will be saved as prerequisite for each promocode
   * @param {Number} currentIndex current index of milestone
   * @param {Array} milestones array of the milestones in the cart goal
   * @param {Boolean} isStackable should the rewards be stacked or not
   * @param {String} goalType goal type of cart goal
   * @returns {JSON} JSON Logic object
   */
  const generateRewardRuleJSON = (
    currentIndex,
    milestones,
    isStackable,
    goalType
  ) => {
    let rewardRule;
    if (currentIndex === milestones.length - 1 || isStackable) {
      rewardRule = {
        ">=": [
          {
            var: goalType,
          },
          milestones[currentIndex].goal,
        ],
      };
    } else {
      rewardRule = {
        and: [
          {
            ">=": [
              {
                var: goalType,
              },
              milestones[currentIndex].goal,
            ],
          },
          {
            "<": [
              {
                var: goalType,
              },
              milestones[currentIndex + 1].goal,
            ],
          },
        ],
      };
    }
    return rewardRule;
  };

  /**
   *This function takes in the formik values on submit and validates based on whether free product is added and also if the json logic is valid
   * @param {Object} formikValues values from formik
   * @returns {Boolean} true or false
   */
  const isCampaignValid = (formikValues) => {
    let validityFlag = true;
    const { milestones } = formikValues.campaign_cart_goals[0];
    // make sure that initial mileStone has reward selected
    if (milestones[0].rewards.rewardStack.length > 0) {
      // Following makes sure there are no cart Discount failed Items
      const cartDiscountFailItems = milestones.filter((milestone) => {
        if (
          milestone.rewards.rewardStack[0].type === "orderDiscount" &&
          milestone.rewards.rewardStack[0].unit === "percent" &&
          !(
            milestone.rewards.rewardStack[0].value > 0 &&
            milestone.rewards.rewardStack[0].value < 100
          )
        ) {
          return milestone;
        }
      });
      if (cartDiscountFailItems.length !== 0) validityFlag = false;

      // Following makes sure there are no empty free products
      const noFreeProductRewardArray = milestones.filter((milestone) => {
        if (
          milestone.rewards.rewardStack[0].type === "freeProduct" &&
          milestone.rewards.rewardStack[0].target?.product_id == undefined
        ) {
          return milestone;
        }
      });

      // the following map function makes sure the spend goals are in assending order
      milestones.map((milestone, index) => {
        // Checking if any spend goal is 0
        if (milestone.goal === null || milestone.goal === 0) {
          validityFlag = false;
        }
        // Checking if spend goal doesnt go lower than the previous milestone
        if (index !== 0 && milestone.goal <= milestones[index - 1].goal) {
          validityFlag = false;
        }
        // Checking if spend goal doesnt go higher than the next milestone
        if (
          index !== milestones.length - 1 &&
          milestone.goal > milestones[index + 1].goal
        ) {
          validityFlag = false;
        }
      });

      if (noFreeProductRewardArray.length !== 0) validityFlag = false;
    } else {
      validityFlag = false;
    }

    if (validityFlag) {
      setIsValidationFailed(false);
      return true;
    } else {
      setIsValidationFailed(true);
      return false;
    }
  };

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "campaign":
        return (
          <Campaign
            values={values}
            setFieldValue={setFieldValue}
            isValidationFailed={isValidationFailed}
            currentCampaignKey={currentCampaignKey}
          />
        );
      case "content":
        return (
          <Content
            values={values}
            setFieldValue={setFieldValue}
            isValidationFailed={isValidationFailed}
          />
        );
      case "settings":
        return (
          <Settings
            values={values}
            setFieldValue={setFieldValue}
            isValidationFailed={isValidationFailed}
          />
        );
    }
  };

  if (data && data.campaign_by_pk)
    return (
      <Formik
        enableReinitialize
        initialValues={data.campaign_by_pk}
        onSubmit={async (values) => {
          if (isCampaignValid(values)) {
            // reward array is created for sending to shopify functions
            let rewardArray = [];
            const { milestones, goalType, stackable } =
              values.campaign_cart_goals[0];

            const updatedMilestones = await Promise.all(
              milestones.map(async (milestone, milestoneIndex) => {
                const rewardStack = milestone.rewards.rewardStack;

                const rewardRule = generateRewardRuleJSON(
                  milestoneIndex,
                  milestones,
                  stackable,
                  goalType
                );
                const nanoId = await generateNanoId();
                const updatedRewardStack = rewardStack.map(
                  (rewardStackItem) => {
                    // check if current reward promoCode is included in the rewardArray
                    // push to array if it is not there
                    let rewardItemPromoCode;
                    if (rewardStackItem.promoCode) {
                      rewardItemPromoCode = rewardStackItem.promoCode;
                    } else {
                      rewardItemPromoCode =
                        rewardStackItem.type === "freeProduct"
                          ? `GIFT${currentCampaignKey}${nanoId}`
                          : `SHIP${currentCampaignKey}${nanoId}`;
                    }

                    // is reward code is not found in reward array
                    const rewardCodeNotFoundInRewardArray = !rewardArray.find(
                      (rewardArrayItem) =>
                        rewardArrayItem.c === rewardItemPromoCode
                    );

                    if (rewardCodeNotFoundInRewardArray) {
                      // reward array values are shortened for making request length smaller
                      // https://www.notion.so/cornerteam/RFC-128-Functions-84c70dd894274c7696dfa34e44d37c27

                      const shortenedGoalType =
                        goalType === "totalOrderValue" ? "st" : "n";
                      const rewardArrayPrequisite = generateRewardRuleJSON(
                        milestoneIndex,
                        milestones,
                        stackable,
                        shortenedGoalType
                      );

                      const prodHighestOccurence =
                        findHighestProductOccurrences(rewardStack);
                      rewardArray.push({
                        k: parseInt(currentCampaignKey),
                        c: rewardItemPromoCode,
                        v: rewardStackItem.value.toFixed(1),
                        u: rewardStackItem.unit === "percent" ? "%" : "$",
                        q: prodHighestOccurence,
                        ...getRewardTypeDetails(rewardStackItem),
                        r: rewardArrayPrequisite,
                        l: milestone.rewards.stackSelectCount
                          ? milestone.rewards.stackSelectCount
                          : 1,
                      });
                    }
                    return {
                      ...rewardStackItem,
                      promoCode: rewardItemPromoCode,
                      limit: {
                        quantity: 1,
                      },
                    };
                  }
                );

                // return updated milestone Items
                return {
                  ...milestone,
                  rewards: {
                    ...milestone.rewards,
                    rewardStack: updatedRewardStack,
                    prerequisites: rewardRule,
                  },
                };
              })
            );

            const { id: cartGoalId, ...cartGoalDetails } =
              values.campaign_cart_goals[0];
            updateCartGoalCampaignMutation({
              cartGoalId: { id: cartGoalId },
              cartGoalDetails: {
                ...cartGoalDetails,
                milestones: updatedMilestones,
                rewards: rewardArray,
                shop_id: workspace.data.sourceid,
                shop_url: workspace.data.storeMyShopifyUrl,
              },
              id: { id: values.id },
              campaignDetails: {
                status:
                  values.status === "active" ? "not_published" : values.status,
                settings: values.settings,
                audience: values.audience,
                key: currentCampaignKey,
              },
            });
          } else {
            toaster.notify(
              "Please fix the highlighted issues before you continue.",
              {
                duration: 6000,
              }
            );
          }
        }}
      >
        {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
          return (
            <PageViewContainer
              title={
                <InlineEdit
                  defaultValue={values.settings.title}
                  editView={({ ...fieldProps }) => (
                    <Textfield {...fieldProps} name="basic" />
                  )}
                  readView={() => (
                    <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                      <b>{values.settings.title}</b>
                      <div
                        className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2 "
                        id="my-first-step"
                      >
                        <RiEdit2Fill size={15} className="mr-3 text-gray-500" />
                        Edit name
                      </div>
                    </h2>
                  )}
                  onConfirm={(e) => {
                    setFieldValue("settings.title", e);
                  }}
                />
              }
              action={
                workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <div className="flex items-center">
                    <span className="font-bold text-gray-700 mr-3">
                      Campaign Status:
                    </span>
                    {isLoading === true ? (
                      <span className="text-primary flex flex-row items-center">
                        <RiLoader3Line className="animate-spin h-16 w-8" />
                        <p className="ml-2 mr-6">Syncing Details</p>
                      </span>
                    ) : (
                      <TabRadioButton
                        btnId="cart-goal-status"
                        defaultValue={values.status}
                        options={visibilityOptions}
                        onChange={(selectedType) => {
                          setFieldValue("status", selectedType);
                        }}
                      />
                    )}
                  </div>
                )
              }
            >
              <HorizontalTabs
                horizontalTabsId={"cart-goal-tab-settings"}
                items={tabItems}
                showSeperatorIcons
                onChange={(selectedTab) => {
                  setCurrentTab(selectedTab);
                }}
              />
              {dirty && (
                <div className="z-30  sticky top-0 flex justify-between border-b bg-amber-100 items-center py-2 desktop:px-8 px-4 ">
                  <p>You've unsaved changes</p>
                  <div className="flex items-center">
                    <Button
                      onClick={() => {
                        setIsValidationFailed(false);
                        resetForm();
                      }}
                      type="link"
                    >
                      Discard Changes
                    </Button>
                    <Button
                      btnId="cart-goal-save"
                      onClick={() => {
                        finishFlow();
                        submitForm();
                      }}
                      type="primary"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
              {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                <CampaignPaywall />
              )}
              {values.status === "not_published" &&
                workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignSyncFailWarning />
                )}
              {!dirty &&
                values.status === "draft" &&
                workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPauseWarning />
                )}
              {renderCurrentTab(values, setFieldValue)}
              <ErrorModal
                isOpen={openSyncErrorModal}
                onClose={() => setOpenSyncErrorModal(false)}
                secondaryAction={
                  <Button
                    icon={<RiArrowGoForwardFill />}
                    disabled={!showRetryButton}
                    type="outline"
                    loading={isLoading}
                    onClick={() => {
                      setShowRetryButton(false);
                      setOpenSyncErrorModal(false);
                      setIsLoading(true);
                      triggerCampaignAPI("publish", {
                        campaign_id: parseInt(params.id),
                        shop_url: workspace.data.storeMyShopifyUrl,
                      });
                    }}
                  >
                    Retry Syncing
                  </Button>
                }
                title="Sync Incomplete !"
                description="There was an error in syncing reward details with Shopify. It's probably due to rate limits imposed by Shopify. 
          Please try once again and if this happens again, please contact our support team."
                handleApiError={() =>
                  contactCrisponApiError(
                    errorKeyRef,
                    `Could you please help with this issue: "Syncing campaign details to Shopify failed" ?`
                  )
                }
              />
              {cartGoalOnboarding.state.active && (
                <Joyride
                  steps={cartGoalsteps}
                  continuous
                  showProgress
                  styles={{
                    options: {
                      primaryColor: "#6d28d9",
                      textColor: "#000000",
                      zIndex: 1000,
                    },
                  }}
                  locale={{
                    last: "Finish",
                  }}
                  scrollOffset={250}
                  spotlightClicks
                />
              )}
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
}
