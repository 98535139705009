import React, { useState } from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import MergeTags from "../../../../../components/MergeTags";
import PaywallElement from "../../../../../components/PaywallElement";
import Select from "@atlaskit/select";
import { langOptions } from "../../../../../utilities/variables";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import { Link } from "react-router-dom";
import Textfield from "@atlaskit/textfield";
import Category from "./Category";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";
/**
 * Customise the language settings of the widget
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - method to set values in each field
 * @param {string} currentDefaultLang - value of the current default language
 */
export default function MiniCartLanguageSettings({
  values,
  setFieldValue,
  currentDefaultLang,
}) {
  const workspace = useWorkspace();
  const langArray = Object.keys(values.corner_cart_lang);
  // to remove "version" from language array
  const filteredLangArray = langArray.filter(
    (arrayElement) => arrayElement !== "version"
  );

  const [selectedLanguage, setSelectedLanguage] = useState(currentDefaultLang);
  const [expandedCategory, setExpandedCategory] = useState();
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const handleDefaultValueChange = (e) => {
    setSelectedLanguage(e.value);
  };

  return (
    <>
      <div className="-mx-8 p-2 desktop:px-8 desktop:py-3 border-b">
        <p className="font-bold mb-1 desktop:mb-4 text-center ">
          Select Language to Edit
        </p>
        <div className="flex justify-center">
          <Select
            isSearchable={false}
            value={langOptions.find(
              (option) => option.value === selectedLanguage
            )}
            onChange={handleDefaultValueChange}
            className="w-48 mr-2"
            classNamePrefix="react-select"
            options={langOptions.filter((item) =>
              filteredLangArray.includes(item.value)
            )}
          />
        </div>
        <p className="text-sm  text-center mt-2 desktop:mt-4">
          <Link to={`/settings/language`} className="text-violet-700">
            {" "}
            Click here
          </Link>{" "}
          to add/edit the language list{" "}
        </p>
        <div className="flex justify-end mt-3 mr-3">
          <button
            className={
              "text-sm flex items-center font-bold text-gray-600 hover:text-violet-700"
            }
            onClick={() => setIsAllExpanded((prev) => !prev)}
          >
            {isAllExpanded ? <RiArrowUpFill /> : <RiArrowDownFill />}
            <span>{isAllExpanded ? "Collapse" : "Expand"} all</span>{" "}
          </button>
        </div>
      </div>
      {!workspace.data?.feature_flag.apps?.cornercart
        ?.multi_language_support ? (
        <PaywallElement
          paywallPlan={13}
          title="Upgrade Now"
          description="Subscribe to a higher plan to edit various texts inside the widget"
        />
      ) : (
        <div>
          {/* Header Elements */}
          <Category
            title="Widget Header Elements"
            onExpand={() => {
              if (expandedCategory !== "banner") {
                setExpandedCategory("banner");
              } else {
                setExpandedCategory(null);
              }
            }}
            isExpanded={expandedCategory === "banner" || isAllExpanded}
            description="Text inside the elements in header/cart banner"
          >
            {/* cart title */}
            <CustomiserUnit
              title="Cart Title"
              description="The text displayed as the title of the cart"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.cart_context_title
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].cart_context_title`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Offer title */}
            <CustomiserUnit
              title="Offers Page Title"
              description="The text displayed as the title of the offer page"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offers_context_title
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offers_context_title`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Cart Close text */}
            <CustomiserUnit
              title="Cart Close Text"
              description="The text displayed for closing the cart"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.widget_close_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].widget_close_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
          </Category>

          {/* Cart summary Elements */}
          <Category
            title="Checkout Button And Cart Summary"
            onExpand={() => {
              if (expandedCategory !== "cartSummary") {
                setExpandedCategory("cartSummary");
              } else {
                setExpandedCategory(null);
              }
            }}
            isExpanded={expandedCategory === "cartSummary" || isAllExpanded}
            description="Checkout button and the text shown near to it"
          >
            {/* Cart subtotal text */}
            <CustomiserUnit
              title="Cart Subtotal Text"
              description="The text displayed beside the total value of the cart"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.subtotal_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].subtotal_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Cart Cta text */}
            <CustomiserUnit
              title="Cart CTA Text"
              description="The text displayed inside the CTA Button"
            >
              <MergeTags
                tags={[
                  {
                    tag: "{{cart_total}}",
                    description: "Will be replaced with the cart total",
                  },
                ]}
              />
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.cart_list_cta
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].cart_list_cta`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Continue Shopping  */}
            {values.cart_config.general.showContinueShoppingBtn && (
              <CustomiserUnit
                title="Continue Shopping Button Text"
                description="The text displayed on the button displayed below cta button which closes the cart widget"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.cart_list_continue_shopping
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].cart_list_continue_shopping`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
            )}

            {/* Shipping */}
            <CustomiserUnit
              title="Shipping"
              description="The word shipping used in various places"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.shipping_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].shipping_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Estimated Tax */}
            <CustomiserUnit
              title="Estimated Tax"
              description="The label used to show estimated tax"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.estimated_tax || "Estimated Tax"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].estimated_tax`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Discount Text */}
            <CustomiserUnit
              title="Discount Text"
              description="The text indicating the word discount"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.discount_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].discount_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
            {values.cart_config.general?.isDiscountBoxActive && (
              <>
                {/* Discount CTA Text */}
                <CustomiserUnit
                  title="Discount CTA Text"
                  description="The text on Discount CTA button"
                >
                  <Textfield
                    value={
                      values.corner_cart_lang[`${selectedLanguage}`]
                        ?.discount_box_cta
                    }
                    onChange={(e) => {
                      setFieldValue(
                        `corner_cart_lang["${selectedLanguage}"].discount_box_cta`,
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                {/* Discount Input Placeholder Text */}
                <CustomiserUnit
                  title="Discount Input Placeholder Text"
                  description="Placeholder text for the discount input"
                >
                  <Textfield
                    value={
                      values.corner_cart_lang[`${selectedLanguage}`]
                        ?.discount_box_place_holder
                    }
                    onChange={(e) => {
                      setFieldValue(
                        `corner_cart_lang["${selectedLanguage}"].discount_box_place_holder`,
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>
              </>
            )}
          </Category>

          {/* Upsell Section */}
          {values.cart_config.general.showRelatedProducts && (
            <Category
              title="Upsell Section"
              onExpand={() => {
                if (expandedCategory !== "upsell") {
                  setExpandedCategory("upsell");
                } else {
                  setExpandedCategory(null);
                }
              }}
              isExpanded={expandedCategory === "upsell" || isAllExpanded}
              description="Text shown inside the upsell section shown below the cart items"
            >
              {/* Related Items Title */}
              <CustomiserUnit
                title="Related Items Title"
                description="The text displayed as the title of 'Related Items' section"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.related_items_title
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].related_items_title`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* Related Items CTA Text */}
              <CustomiserUnit
                title="Related Items CTA Text"
                description="Call to action for adding a related item to cart"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.related_items_cta
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].related_items_cta`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
            </Category>
          )}

          {/* Rewards Selector Popup */}
          <Category
            title="Reward Selector popup"
            onExpand={() => {
              if (expandedCategory !== "rewardsSelector") {
                setExpandedCategory("rewardsSelector");
              } else {
                setExpandedCategory(null);
              }
            }}
            isExpanded={expandedCategory === "rewardsSelector" || isAllExpanded}
            description="Text shown in the rewards selector popup"
          >
            {/* Rewards Sleector Popup Titlke */}
            <CustomiserUnit
              title="Rewards Selector Popup title"
              description="The title of the rewards selection popup"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.reward_overlay_title
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].reward_overlay_title`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Rewards Selectr Popup Subtitle */}
            <CustomiserUnit
              title="Rewards Selector Popup Sub-title"
              description="The text below the title in the rewards selection popup"
            >
              <MergeTags
                tags={[
                  {
                    tag: "{{gift_count}}",
                    description:
                      "Will be replaced with the number of gifts allowed to be selected",
                  },
                ]}
              />
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.reward_overlay_sub_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].reward_overlay_sub_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Rewards Selectr Popup Cta Btn */}
            <CustomiserUnit
              title="Rewards Selector Popup CTA Button"
              description="The text on the CTA Button of the rewards selection popup"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.reward_overlay_cta_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].reward_overlay_cta_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Rewards Selectr Popup Cancel Btn */}
            <CustomiserUnit
              title="Rewards Selector Popup Cancel Button"
              description="The text on the Cancel Button of the rewards selection popup"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.reward_overlay_cancel_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].reward_overlay_cancel_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* View product in store */}
            <CustomiserUnit
              title="View Product in store"
              description="View product in store button"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.view_product_in_store
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].view_product_in_store`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

          </Category>

          {/* Order note */}
          {values.cart_config.general.showOptionalNotes && (
            <Category
              title="Order Note"
              onExpand={() => {
                if (expandedCategory !== "orderNote") {
                  setExpandedCategory("orderNote");
                } else {
                  setExpandedCategory(null);
                }
              }}
              isExpanded={expandedCategory === "orderNote" || isAllExpanded}
              description="Texts related to the order note feature"
            >
              {/* Order Note btn text */}
              <CustomiserUnit
                title="Order Note Button Text"
                description="The text displayed on the button to open the Order Note Popup"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.optional_note_btn_text
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].optional_note_btn_text`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* Note popuptitle */}
              <CustomiserUnit
                title="Order Note Popup Title"
                description="The text displayed as the title of the 'Order Note' popup"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.optional_note_title
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].optional_note_title`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* Note popup cta */}
              <CustomiserUnit
                title="Order Note Popup CTA"
                description="The text displayed as the CTA on the 'Order Note' popup"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.optional_note_cta
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].optional_note_cta`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>

              {/* note popup cancel */}
              <CustomiserUnit
                title="Order Note Popup Cancel"
                description="The text displayed in the 'Order Note' Cancel button"
              >
                <Textfield
                  value={
                    values.corner_cart_lang[`${selectedLanguage}`]
                      ?.optional_note_cancel
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `corner_cart_lang["${selectedLanguage}"].optional_note_cancel`,
                      e.target.value
                    );
                  }}
                  name="basic"
                />
              </CustomiserUnit>
            </Category>
          )}

          {/* Offers Section */}
          <Category
            title="Offers Section"
            onExpand={() => {
              if (expandedCategory !== "errors") {
                setExpandedCategory("errors");
              } else {
                setExpandedCategory(null);
              }
            }}
            errors
            isExpanded={expandedCategory === "errors" || isAllExpanded}
            description="Texts related to the offers section"
          >

            {/* Slelect Gift button */}
            <CustomiserUnit
              title="Select Gift Button"
              description="Once a reward is unlocked the text on the cta button"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_cta_achieved
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_cta_achieved`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Show Gift button */}
            <CustomiserUnit
              title="Show Gift Button"
              description="Before reward is unlocked the text on the cta button"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_cta_unachieved
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_cta_unachieved`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/*Offerpage unlocked reward tag */}
            <CustomiserUnit
              title="Gift Unlocked Tag"
              description="After unlocking a gift a small tag will appear below the respective gift. This is the text shown on that tag"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_achieved_tag
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_achieved_tag`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
            
            {/* Mutli gift expand */}
            <CustomiserUnit
              title="Button to expand multiple gifts"
              description="Once a reward is unlocked and if there are multiple gifts under same campaign this button will exapnd to show each"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_cta_multiple_gift_expand || "Show unlocked gifts"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_cta_multiple_gift_expand`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
            
            {/* Mutli gift collapse */}
            <CustomiserUnit
              title="Button to collapse multiple gifts"
              description="Once a reward is unlocked and if there are multiple gifts under same campaign this button will collapse to show each"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_cta_multiple_gift_collapse || "Hide"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_cta_multiple_gift_collapse`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
            
            {/* Multi gift title */}
            <CustomiserUnit
              title="Title for repeadted gifts"
              description="if same reward is unlocked multiple times this text will be shown as title for each instance"
            >
               <MergeTags
                tags={[
                  {
                    tag: "{{number}}",
                    description: "Will be replaced with the index of teh respective reward",
                  },
                ]}
              />
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.offer_card_cta_multiple_gift_title || "Gift {{number}}"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].offer_card_cta_multiple_gift_title`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>


          </Category>

          {/* Errors & warnings */}
          <Category
            title="Errors & Warnings"
            onExpand={() => {
              if (expandedCategory !== "errors") {
                setExpandedCategory("errors");
              } else {
                setExpandedCategory(null);
              }
            }}
            errors
            isExpanded={expandedCategory === "errors" || isAllExpanded}
            description="Error messages and warning messages that appear in the cart"
          >
            {/* Non Applicable discount */}
            <CustomiserUnit
              title="Non Applicable discount error message"
              description="Error message inside the popup when a discount code is not applicable"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.non_applicable_discount_code_warning
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].non_applicable_discount_code_warning`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Unclaimed reward warning title */}
            <CustomiserUnit
              title="Unclaimed Reward Warning Title"
              description="The title of the warning popup that appears before checkout when a reward is unlocked but not claimed"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.rewards_checkout_reminder_title || "Don't forget your Free Gifts!"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].rewards_checkout_reminder_title`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Unclaimed reward warning text */}
            <CustomiserUnit
              title="Unclaimed Reward Warning Text"
              description="The text of the warning popup that appears before checkout when a reward is unlocked but not claimed"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.rewards_checkout_reminder_description || "You're eligible for one or more free gifts with this purchase. Please add them to your cart before checkout."
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].rewards_checkout_reminder_description`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Unclaimed reward warning cta */}
            <CustomiserUnit
              title="Unclaimed Reward Warning CTA"
              description="The text on the cta button of the warning popup that appears before checkout when a reward is unlocked but not claimed"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.rewards_checkout_reminder_primary_cta_btn || "Claim Your Free Gift"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].rewards_checkout_reminder_primary_cta_btn`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Unclaimed reward warning continue without claiming rewards btn */}
            <CustomiserUnit
              title="Unclaimed Reward Warning Continue Without Rewards Button"
              description="The text on the button that allows the user to continue without claiming the rewards"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.rewards_checkout_reminder_secondary_cta_btn || "No Thanks, I’ll Pass"
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].rewards_checkout_reminder_secondary_cta_btn`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

          </Category>

          {/* Misc text */}
          <Category
            title="Miscellaneous"
            onExpand={() => {
              if (expandedCategory !== "misc") {
                setExpandedCategory("misc");
              } else {
                setExpandedCategory(null);
              }
            }}
            isExpanded={expandedCategory === "misc" || isAllExpanded}
            description="Common words used throughout the cart widget"
          >
            {/* Empty Cart text */}
            <CustomiserUnit
              title="Empty Cart Text"
              description="The text to display when the cart is empty"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]
                    ?.cart_empty_state
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].cart_empty_state`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Free text */}
            <CustomiserUnit
              title="Free"
              description="The word Free used in various places"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.free_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].free_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Sold out */}
            <CustomiserUnit
              title="Sold Out"
              description="The phrase sold out used in various places"
            >
              <Textfield
                value={values.corner_cart_lang[`${selectedLanguage}`]?.sold_out}
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].sold_out`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* Add item text */}
            <CustomiserUnit
              title="Add item text"
              description="The text on button used to add items to the cart"
            >
              <Textfield
                value={values.corner_cart_lang[`${selectedLanguage}`]?.add_item}
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].add_item`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* total */}
            <CustomiserUnit
              title="Total Text"
              description="The text total used in various places in the cart"
            >
              <Textfield
                value={
                  values.corner_cart_lang[`${selectedLanguage}`]?.total_text
                }
                onChange={(e) => {
                  setFieldValue(
                    `corner_cart_lang["${selectedLanguage}"].total_text`,
                    e.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
          </Category>
        </div>
      )}
    </>
  );
}
