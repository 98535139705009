import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useBlock } from "@dopt/react";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import {
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiAlertFill,
  RiPencilFill,
  RiListSettingsFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  queryCampaignOneClickById,
  updateOneClickCampaignDetails,
} from "../../../../api/campaign";
import toaster from "toasted-notes";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import Settings from "./Settings";
import Upsell from "./Upsell";
import Joyride from "react-joyride";
import useCompleteDoptFlow from "../../../../utilities/useCompleteDoptFlow";
import useClearCache from "../../../../utilities/useClearCache";

export default function OneClickUpsell() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();
  const { finishFlow } = useCompleteDoptFlow();

  const [isValidationFailed, setIsValidationFailed] = useState(false);
  const [currentTab, setCurrentTab] = useState("upsell");
  const [oneClickFlowEdit, oneClickFlowEditTransition] = useBlock(
    "one-click-upsell.campaign-edit"
  );

  const oneClickSteps = [
    {
      target: "#upsell-product-select",
      disableBeacon: true,
      content: "Select the add on product",
    },
    {
      target: "#upsell-product-text",
      content: "Enter the text for upsell",
    },
    {
      target: "#upsell-product-save-campaign",
      content: `Set this campaign as active and click on "Save Changes"`,
      placementBeacon: "bottom-end",
      styles: {
        options: {
          primaryColor: "#3d2266",
        },
      },
    },
  ];

  const { data, refetch } = useQuery("queryCampaignOneClickById", () =>
    graphQL(queryCampaignOneClickById, { id: params.id })
  );

  const updateOneClickCampaign = async (values) => {
    await graphQL(updateOneClickCampaignDetails, {
      campaignId: values.campaignId,
      campaignInput: values.campaignDetails,
      oneClickId: values.oneClickId,
      oneClickInput: values.oneClickDetails,
    });
    refetch();
  };

  const tabItems = [
    { label: "Upsell", id: "upsell", icon: <RiPencilFill /> },
    {
      label: "Advanced Settings",
      id: "settings",
      icon: <RiListSettingsFill />,
    },
  ];

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "upsell":
        return (
          <Upsell
            values={values}
            setFieldValue={setFieldValue}
            isValidationFailed={isValidationFailed}
          />
        );
      case "settings":
        return <Settings values={values} setFieldValue={setFieldValue} />;
    }
  };

  const [updateOneClickCampaignMutation] = useMutation(updateOneClickCampaign, {
    onSuccess: () => {
      clearCache();
      queryCache.invalidateQueries("queryCampaignOneClickById");
      if (oneClickFlowEditTransition) oneClickFlowEditTransition("next");
      toaster.notify("One Click Campaign updated 🎉", {
        duration: 2000,
      });
    },
  });

  const visibilityOptions = [
    {
      label: (
        <div className="flex items-center">
          <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused{" "}
        </div>
      ),
      value: "draft",
    },
  ];

  const isOneClickCampaignValid = (campaginValues) => {
    const upsellProduct = campaginValues?.campaign_one_clicks[0]?.upsellProduct;
    const campaignProduct = upsellProduct ? upsellProduct.target : null;

    if (
      !campaignProduct ||
      (upsellProduct.preferenceType === "merchantSelect" &&
        !upsellProduct.preferenceValue)
    ) {
      setIsValidationFailed(true);
      return false;
    } else {
      setIsValidationFailed(false);
      return true;
    }
  };

  if (data && data.campaign_by_pk)
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data.campaign_by_pk}
          onSubmit={async (values) => {
            if (isOneClickCampaignValid(values)) {
              const { audience, settings, status } = values;
              const { upsellConfig, upsellProduct } =
                values.campaign_one_clicks[0];
              updateOneClickCampaignMutation({
                campaignId: { id: values.id },
                campaignDetails: {
                  audience: audience,
                  settings: settings,
                  status: status,
                },
                oneClickId: { id: values.campaign_one_clicks[0].id },
                oneClickDetails: {
                  upsellConfig: upsellConfig,
                  upsellProduct: upsellProduct,
                },
              });
            } else {
              toaster.notify("Please fix the issues highlighted in red", {
                duration: 6000,
              });
            }
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <PageViewContainer
                title={
                  <InlineEdit
                    defaultValue={values.settings.title}
                    editView={({ ...fieldProps }) => (
                      <Textfield {...fieldProps} name="basic" />
                    )}
                    readView={() => (
                      <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                        <b>{values.settings.title}</b>
                        <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                          <RiEdit2Fill
                            size={15}
                            className="mr-3 text-gray-500"
                          />
                          Edit name
                        </div>
                      </h2>
                    )}
                    onConfirm={(e) => {
                      setFieldValue("settings.title", e);
                    }}
                  />
                }
                action={
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <div className="flex items-center">
                      <span className="font-bold text-gray-700 mr-3">
                        Campaign Status:
                      </span>
                      <TabRadioButton
                        btnId={"upsell-product-save-campaign"}
                        defaultValue={values.status}
                        options={visibilityOptions}
                        onChange={(selectedType) => {
                          setFieldValue("status", selectedType);
                        }}
                      />
                    </div>
                  )
                }
              >
                <HorizontalTabs
                  items={tabItems}
                  showSeperatorIcons
                  onChange={(selectedTab) => {
                    setCurrentTab(selectedTab);
                  }}
                />
                {dirty && (
                  <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          finishFlow();
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPaywall />
                )}
                {!dirty &&
                  values.status === "draft" &&
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <CampaignPauseWarning />
                  )}
                {renderCurrentTab(values, setFieldValue)}
              </PageViewContainer>
            );
          }}
        </Formik>
        {oneClickFlowEdit.state.active && (
          <Joyride
            steps={oneClickSteps}
            styles={{
              options: {
                primaryColor: "#6d28d9",
                textColor: "#000000",
                zIndex: 1000,
              },
            }}
            locale={{
              last: "Finish",
            }}
            showProgress
            continuous
            spotlightClicks
            scrollOffset={250}
          />
        )}
      </>
    );
  else return <LoadingSpinner />;
}
