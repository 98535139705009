import React, { useState } from "react";
import PaywallBadge from "./PaywallBadge";
import SucoPricingModal from "./SucoPricingModal";
import { useWorkspace } from "../contexts/WorkspaceContext";

const BannerImageSelector = (props) => {
  const workspace = useWorkspace();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  
  const imageOptions = [
    {
      name: "abstract-pastels",
      displayName: "Abstract Pastels",
      imageCount: 7,
      paywall : !workspace.data?.feature_flag.apps?.supportcorner?.faq_bg_packs.abstract_pastels
    },
    {
      name: "geometric-elements",
      displayName: "Geometric Elements",
      imageCount: 7,
      paywall : !workspace.data?.feature_flag.apps?.supportcorner?.faq_bg_packs.geometric_elements
    },
  ];

  const ImageButon = (btnProps) => {
    let styleObject;
    if (btnProps.bgImage) {
      styleObject = {
        backgroundImage: `url(${btnProps.bgImage})`,
        backgroundSize: "cover",
      };
    } else
      styleObject = {
        background: `linear-gradient(90deg, ${props.bgStartColor} 0%, ${props.bgEndColor}  100%)`,
      };
    return (
      <button
        style={styleObject}
        onClick={() => {
          btnProps.paywall
            ? setIsBillingModalOpen(true)
            : props.onSelect(btnProps.bgImage ? btnProps.bgImage : "");
        }}
        className="w-40 h-14 m-3 p-3 border hover:border-violet-700 rounded-md relative"
      >
        <small>{btnProps.text && btnProps.text }</small>
         {btnProps.paywall && <PaywallBadge absolute type="lite"/>}
      </button>
    );
  };

  return (
    <div className="">
      <div className="py-4">
        <h3 className="mb-4">Basic</h3>
        <div className="flex">
          <ImageButon text="Default Theme"/>
        </div>
      </div>
      {imageOptions.map((item) => {
        return (
          <div className="py-4">
            <h3 className="mb-4">{item.displayName}</h3>
            <div className="flex flex-wrap">
              {Array(item.imageCount)
                .fill("")
                .map((_, i) => {
                  return (
                    <ImageButon
                      paywall={item.paywall}
                      key={i}
                      bgImage={`https://assets.cornercart.io/coca-v2/images/faq-bg/${
                        item.name
                      }/${i + 1}.png`}
                    />
                  );
                })}
            </div>
          </div>
        );
      })}
      <SucoPricingModal
      hightlightPlan={5}
        title="You need a standard plan to access this feature"
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </div>
  );
};

export default BannerImageSelector;
