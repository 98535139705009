import React from "react";
import Button from "../Button";

/**
 * Component for rendering usage pricing details
 * @param {Function} onSelectUsagePlan  what happens when user selects usage plan
 * @param {Boolean} isLoading triggers the loading state in Select Button when selecting a plan
 */
const UsagePricingTable = ({
  onSelectUsagePlan,
  isLoading,
  currentPlanType,
}) => {
  const cocaUsagePricing = [
    {
      order: "ORDER PER MONTH",
      price: "PRICE",
    },
    {
      order: "0-50",
      price: "$0",
    },
    {
      order: "51-200",
      price: "$19",
    },
    {
      order: "201-500",
      price: "$29",
    },
    {
      order: "501-800",
      price: "$59",
    },
    {
      order: "801-1000",
      price: "$79",
    },
    {
      order: "1000+",
      price: "$99",
    },
  ];

  const renderSelectPlan = () => {
    return (
      <div className="flex flex-row items-center p-6">
        <div className="flex flex-col items-start">
          <p className="text-lg text-violet-700 font-bold mb-1">
            Usage based Billing
          </p>
          <p className="text-gray-800 font-normal text-left">
            In this Plan you will get access to every feature that we have to
            offer. And you will be charged based on your total orders per month
          </p>
        </div>
        <div className="flex shrink-0 px-14">
          {currentPlanType === "usage_charge" ? (
            <div className="text-center text-violet-600 rounded-lg py-2 px-4">
              Current plan
            </div>
          ) : (
            <Button
              type="primary desktop:px-6 laptop:px-3"
              className=""
              loading={isLoading}
              onClick={() => onSelectUsagePlan()}
            >
              Select this plan
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderFixedPricingRow = (order, price, index) => {
    return (
      <tr
        className={`${
          index % 2 === 0 ? "bg-gray-100" : "bg-white"
        }  text-black`}
        key={index}
      >
        <td
          className={`${
            index === 0 ? "text-xs font-bold" : "text-base font-normal"
          } text-center py-3`}
        >
          {order}
        </td>
        <td
          className={`${
            index === 0 ? "text-xs font-bold" : "text-base font-normal"
          } text-center py-3`}
        >
          {price}
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full border rounded-lg">
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th colSpan="2">{renderSelectPlan()}</th>
          </tr>
        </thead>
        <tbody>
          {cocaUsagePricing.map((cocaUsageItem, index) => {
            return renderFixedPricingRow(
              cocaUsageItem.order,
              cocaUsageItem.price,
              index
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsagePricingTable;
