/* --------------- Queries ------------------- */
export const queryAllCampaigns = `
  query queryAllCampaigns{
    campaigns {
      id
      priority
      type}
  }
`;

export const queryAllReferrals = `
  query queryAllReferrals ($fromDate: timestamptz!,$toDate: timestamptz!){
    viral_referrals {
      created_at
      expires_at
      starts_at
      status
      gift_config
      name
      reward_stage
      settings_config
      campaignid
      campaign {
        id
        priority
        type
        coupons_aggregate(where: {type: {_eq: "GIFT"}, created_at:{
          _gte:$fromDate,
          _lte:$toDate
        }}) {
          aggregate {
            count
          }
        }
      }
      viral_referrals_results_aggregate(
        where:{
          created_at:{
            _gte:$fromDate,
            _lte:$toDate
          }
        }
      ) {
        aggregate {
          sum {
            revenue_from_participant
          }
        }
      }
    }
    simple_referrals {
      expires_at
      starts_at
      name
      status
      reward_type
      campaignid
      campaign {
        id
        priority
        type
        coupons_aggregate(where: {type: {_eq: "GIFT"}, created_at:{
          _gte:$fromDate,
          _lte:$toDate
        }})  {
          aggregate {
            count
          }
        }
      }
      simple_referrals_results_aggregate(
        where:{
          created_at:{
           _gte:$fromDate,
            _lte:$toDate
          }
        }
      ) {
        aggregate {
          sum {
            revenue_from_participant
          }
        }
      }
    }
  }
`;

export const queryViralReferral = `
query queryViralReferral($viralReferralId: bigint!) {
  viral_referrals(
    where: { campaignid: { _eq: $viralReferralId } }
  ) {
    created_at
      expires_at
      starts_at
      status
      gift_config
      name
      blocked_collections
      reward_stage
      settings_config
      campaignid
      campaign {
        id
        priority
      }
      
  }
}
`;
export const querySimpleReferral = `
query querySimpleReferral($simpleReferralId: bigint!) {
  simple_referrals(
    where: { campaignid: { _eq: $simpleReferralId } }
  ) {
    created_at
      expires_at
      starts_at
      name
      blocked_collections
      status
      is_auto_optin
      max_discount_value
      min_order_value
      reward_type
      reward_config
      gift_config
      email_config
      terms_config
      postpurchase_config
      campaignid
      campaign {
        id
        priority
      }
      
  }
}
`;

export const querySimpelReferralRequests = `
  query querySimpelReferralRequests{
    simple_referral_cashback_requests {
      id
      status
      cashback_amount
      created_at
      customer_info
      effective_cashback_amount
      reason
      simple_referrals_result {
        order_id
        simple_referral {
          name
        }
        audience {
          name
          orders {
            first_name
            last_name
          }
        }
      }
      risk_score
      referee_order_id
    }
  }
`;

/* --------------- Mutations ------------------- */

export const insertCampaign = `
  mutation insertCampaign ($campaignInput: campaigns_insert_input!){
    insert_campaigns_one(object: $campaignInput) {
      id
      type
    }
  }
`;

export const updateCampaign = `
  mutation updateCampaign ($campaignId:campaigns_pk_columns_input!, $campaignInput:campaigns_set_input){
    update_campaigns_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
    }
  }
`;

export const deleteCampaign = `
  mutation deleteViralReferral ($campaignId:bigint!){
    delete_campaigns_by_pk(id: $campaignId) {
      id
    }
  }
`;

export const insertViralReferrals = `
  mutation insertViralReferrals ($viralReferralsInput: viral_referrals_insert_input!){
    insert_viral_referrals_one(object: $viralReferralsInput) {
      campaignid
    }
  }
`;

export const updateViralReferral = `
  mutation updateViralReferral ($campaignId:viral_referrals_pk_columns_input!, $viralReferralInput:viral_referrals_set_input){
    update_viral_referrals_by_pk(pk_columns: $campaignId, _set:$viralReferralInput) {
      campaignid
    }
  }
`;

export const insertSimpleReferrals = `
  mutation insertSimpleReferrals ($simpleReferralsInput: simple_referrals_insert_input!){
    insert_simple_referrals_one(object: $simpleReferralsInput) {
      campaignid
    }
  }
`;

export const updateSimpleReferral = `
  mutation updateSimpleReferral ($campaignId:simple_referrals_pk_columns_input!, $simpleReferralInput:simple_referrals_set_input){
    update_simple_referrals_by_pk(pk_columns: $campaignId, _set:$simpleReferralInput) {
      campaignid
    }
  }
`;

export const deleteSimpleReferral = `
  mutation deleteSimpleReferral ($simpleReferralId:bigint!){
    delete_simple_referrals_by_pk(id: $simpleReferralId) {
      campaignid
    }
  }
`;

export const updateSimpleReferralCashbackRequest = `
  mutation updateSimpleReferralCashbackRequest ($id:simple_referral_cashback_requests_pk_columns_input!, $simpleReferralCashbackRequestInput:simple_referral_cashback_requests_set_input){
    update_simple_referral_cashback_requests_by_pk(pk_columns: $id, _set:$simpleReferralCashbackRequestInput) {
      id
    }
  }
`;
