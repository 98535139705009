import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "@atlaskit/select";
import Button from "../../../components/Button";
import {
  queryWidgetSettings,
  updateWidgetSettings,
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
} from "../../../api/widgets";
import { updateSourceDetails } from "../../../api/workspace";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { langOptions } from "../../../utilities/variables";
import { getDefaultLangJson } from "../../../utilities/basicUtilityFunctions";
import { useHistory } from "react-router-dom";

export default function LanguageSelector() {
  let history = useHistory();
  const workspace = useWorkspace();
  const [languageCode, setlanguageCode] = useState("en");
  const [isLoading, setIsLoading] = useState(false);
  const graphQL = useGraphQl();
  const { data: cowiData } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const { data: stickybarData, refetch } = useQuery(
    "queryCornerConvertWidgets",
    () => graphQL(queryCornerConvertWidgets)
  );

  const updateDefaultLanguage = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        default_language: languageCode,
        translated_languages: [languageCode],
      },
    });
  };
  const [updateDefaultLanguageMutation] = useMutation(updateDefaultLanguage, {
    onSuccess: () => {
      history.push("/get-started/2/");
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      updateDefaultLanguageMutation();
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: async () => {
      let stickybarLang = await getDefaultLangJson("stickybar", languageCode);
      const latestLangVersions = await (
        await window.fetch(`https://alt.usecorner.io/versions.json`)
      ).json();
      updatCornerStickybarMutation({
        id: { id: stickybarData && stickybarData.widget_stickybar[0].id },
        config: {
          sticky_atc_lang: {
            [`${languageCode}`]: stickybarLang,
            version: latestLangVersions.stickybarLang,
          },
        },
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    let cartCowiLang = await getDefaultLangJson("cart-cowi", languageCode);
    const latestLangVersions = await (
      await window.fetch(`https://alt.usecorner.io/versions.json`)
    ).json();

    updateWidgetMutation({
      id: { id: cowiData && cowiData.widgets_cornerwidget[0].id },
      config: {
        corner_cart_lang: {
          [`${languageCode}`]: cartCowiLang,
          version: latestLangVersions.cartCowiLang,
        },
      },
    });
  };

  return (
    <div className=" shrink-0  px-7 py-8 flex  justify-center ">
      <div className="laptop:flex mobile:block items-center h-full w-full mt-8 bg-white rounded-lg shadow-lg">
        <div className="p-8 shrink grow max-w-xl">
          <div className="mb-8  text-center ">
            <h1 className="font-bold text-gray-700 mb-3">
              Select default store language
            </h1>
            <p>
              You can always change this later. You can also add more language
              translations from the settings page
            </p>
          </div>
          {cowiData && stickybarData && (
            <div className="max-w-s flex justify-center mb-8">
              <Select
                isSearchable={false}
                value={langOptions.find(
                  (option) => option.value === languageCode
                )}
                onChange={(e) => {
                  setlanguageCode(e.value);
                }}
                className="w-48 mr-2"
                classNamePrefix="react-select"
                options={langOptions}
              />
            </div>
          )}
          <div className="flex justify-center px-8">
            <Button
              loading={isLoading}
              type="primary"
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
