import { customAlphabet } from "nanoid";

const cornerIdAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const generateNanoId = async () => {
  const nanoIdGenerator = await customAlphabet(cornerIdAlphabets, 4);
  const nanoid = nanoIdGenerator();
  return nanoid;
};

export default generateNanoId;
