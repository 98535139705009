import React from "react";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Button from "../../../../components/Button";
import TextArea from "@atlaskit/textarea";
import Textfield from "@atlaskit/textfield";
import ColorPicker from "../../../../components/ColorPicker";
import toaster from "toasted-notes";
import { Formik } from "formik";
const AccordionSettings = (props) => {
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  return (
    <Formik
      enableReinitialize
      initialValues={props.accordion}
      onSubmit={(values) => {
        props.onChange({
          custom_css: values.custom_css,
          config: {
            questionTextColor: values.config.questionTextColor,
            questionBgColor: values.config.questionBgColor,
            answerBgColor: values.config.answerBgColor,
            blockAutoRender: values.config.blockAutoRender,
            title: values.config.title,
          },
        });
      }}
    >
      {(formikProps) => {
        return (
          <div className="relative">
            {formikProps.dirty && (
              <div className="sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 px-8 ">
                <p>You've unsaved changes</p>
                <div className="flex items-center">
                  <Button onClick={() => formikProps.resetForm()} type="link">
                    Discard Changes
                  </Button>
                  <Button
                    onClick={() => formikProps.submitForm()}
                    type="primary"
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
            <div className="laptop:w-1/2 mobile:w-full  px-8 ">
              <CustomiserUnit
                title="Embed Code"
                description="Use this code to embed the widget wherever you want"
              >
                <div className="mt-3">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<div class="coca-accordion" data-accordion-type="${props.accordion.type}" data-accordion-id="${props.accordion.id}"/>`
                      );
                      toaster.notify("Embed code copied to clipboard ", {
                        duration: 1000,
                      });
                    }}
                    className="border rounded-lg focus:outline-none  bg-gray-100 hover:bg-white hover:border-violet-700  p-3 laptop:block mobile:hidden text-left transition-all"
                  >
                    <p className="text-sm shrink-0 mr-3 text-gray-700 ">
                      <b>Click to copy: </b>
                    </p>
                    <div className="font-mono text-sm text-violet-700">{`<div class="coca-accordion" data-accordion-type="${props.accordion.type}" data-accordion-id="${props.accordion.id}"/>`}</div>
                  </button>
                  <small className="text-amber-600 text-xs mt-3">Ping us in chat if you need help in custom placement of the accodion</small>
                </div>
              </CustomiserUnit>
              <CustomiserUnit
                title="Accordion Title"
                description="Customize the title that appears above the accordion"
              >
                <Textfield
                  defaultValue={formikProps.values.config.title}
                  onChange={(e) => {
                    formikProps.setFieldValue("config.title", e.target.value);
                  }}
                  name="basic"
                />
              </CustomiserUnit>
              <CustomiserUnit
                title="Question Text Color"
                description="Customise the color of questions of each FAQ"
              >
                <div className=" flex items-center">
                  <ColorPicker
                    color={formikProps.values.config.questionTextColor}
                    onChange={(color) => {
                      formikProps.setFieldValue(
                        "config.questionTextColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                  <small className="ml-2">Select Color</small>
                </div>
              </CustomiserUnit>

              <CustomiserUnit
                title="Question Background Color"
                description="Customise the background color of questions "
              >
                <div className=" flex items-center">
                  <ColorPicker
                    color={formikProps.values.config.questionBgColor}
                    onChange={(color) => {
                      formikProps.setFieldValue(
                        "config.questionBgColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                  <small className="ml-2">Select Color</small>
                </div>
              </CustomiserUnit>

              <CustomiserUnit
                title="Answer Background Color"
                description="Customise the background color of answers of each FAQ"
              >
                <div className=" flex items-center">
                  <ColorPicker
                    color={formikProps.values.config.answerBgColor}
                    onChange={(color) => {
                      formikProps.setFieldValue(
                        "config.answerBgColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                  <small className="ml-2">Select Color</small>
                </div>
              </CustomiserUnit>

              <CustomiserUnit
                title="Custom CSS"
                description="Use this option to customize the accordion to any style. If you're not familiar with CSS, ping us in chat and we can help you out "
              >
                <div className="flex">
                  <small className="border rounded-lg bg-gray-100 p-3 mb-3">
                    Use the class{" "}
                    <span className="font-mono rounded-lg bg-gray-700 text-blue-200 px-2 py-1">
                      .accordion-id-{formikProps.values.id}
                    </span>{" "}
                    in css to select this particular accordion
                  </small>
                </div>
                <TextArea
                  defaultValue={formikProps.values.custom_css}
                  onChange={(e) => {
                    formikProps.setFieldValue("custom_css", e.target.value);
                  }}
                  name="basic"
                  minimumRows={7}
                />
                <small className="text-amber-600 text-xs mt-4">Ping us in chat if you need help to write CSS to match your theme</small>
              </CustomiserUnit>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AccordionSettings;
