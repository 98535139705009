import React, { useState } from "react";
import { ResourcePicker } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import Button from "./Button";

/**
 * Shopify Product Picker Component
 * @type {function({btnText,products,selectMultiple,showVariants,onChange,selectMultiple,initialQuery,resoucePickerType}): JSX}
 * @component
 * @param {Array} products list of already selected products
 * @param {Boolean} selectMultiple select multiple products or not
 * @param {Boolean} showVariants show variants or not
 * @param {Node} trigger react elemnt onWhich if clicked the selector will come. If left empty a button will be rendered as default
 * @param {Function} onChange function passed to handle the onChange of product picker
 * @returns Shopify Product Picker
 */
const ShopifyResourcePicker = ({
  btnText = "Select Products",
  products = [],
  onChange,
  selectMultiple = true,
  showVariants = false,
  initialQuery = "",
  resoucePickerType = "product",
  trigger = (triggerProps) => {
    return (
      <div>
        <Button {...triggerProps}>{btnText}</Button>
      </div>
    );
  },
}) => {
  const app = useAppBridge();

  const getResourceType = (type) => {
    switch (type) {
      case "product":
        return ResourcePicker.ResourceType.Product;
      case "variant":
        return ResourcePicker.ResourceType.ProductVariant;
      case "collection":
        return ResourcePicker.ResourceType.Collection;

      default:
        return ResourcePicker.ResourceType.Product;
    }
  };

  const productPicker = ResourcePicker.create(app, {
    resourceType: getResourceType(resoucePickerType),
    options: {
      selectMultiple: selectMultiple,
      showHidden: false,
      showVariants: showVariants,
      initialSelectionIds: products,
      initialQuery: initialQuery,
      actionVerb: ResourcePicker.ActionVerb.Select,
    },
  });

  const openProductPicker = async () => {
    await productPicker.dispatch(ResourcePicker.Action.OPEN);
  };

  const closePicker = async () => {
    await productPicker.dispatch(ResourcePicker.Action.CANCEL);
  };

  productPicker.subscribe(ResourcePicker.Action.SELECT, ({ selection }) => {
    if (onChange) {
      onChange(selection);
      closePicker();
    }
  });

  return trigger({ onClick: openProductPicker });
};

export default ShopifyResourcePicker;
