import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import { RiFlagFill, RiAlertFill } from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../../components/Button";
import toaster from "toasted-notes";
import MergeTags from "../../../../../components/MergeTags";

/**
 * A UI component for render a milestone item
 * @param {Function} isExpanded - Boolean showing if the goal item is expanded or not
 */
export default function Category({
  children,
  onExpand,
  isExpanded,
  title,
  description,
}) {
  return (
    <div className={`border-b `}>
      <div
        className={`flex items-center transition-all justify-between w-full px-4 py-4 desktop:py-6 ${
          isExpanded && "bg-gray-100"
        }`}
      >
        <button onClick={onExpand} className=" flex-grow items-center ">
         
          <h2 className={`font-bold text-base shrink-0 text-left`}>{title}</h2>
          <p className=" text-sm text-left text-gray-500">{description}</p>
        </button>
        <div className="flex items-center">
          <Button onClick={onExpand} type="link">
            {isExpanded ? "Collapse" : "Expand"}
          </Button>
        </div>
      </div>
      {isExpanded && <div className="px-6">{children}</div>}
    </div>
  );
}
