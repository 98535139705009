import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import ReferralCampaignList from "./ReferralCampaignList";
import ReferralRequests from "./ReferralRequests";
import ViralReferralEdit from "./ViralReferralEdit";
import SimpleReferralEdit from "./SimpleReferralEdit";
import ReferralsCreate from "./ReferralsCreate";

export default function CampaignsRouter(props) {
  let { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];

  return (
    <Switch>
      <Route
        exact
        path={`${path}/campaigns/`}
        component={ReferralCampaignList}
        />
      <Route
        exact
        path={`${path}/requests/`}
        component={ReferralRequests}
        />
      <Route
        exact
        path={`${path}/campaigns/create`}
        component={ReferralsCreate}
        />
      <Route
        path={`${path}/campaigns/viral-referral/edit/:id`}
        component={ViralReferralEdit}
      />
      <Route
        path={`${path}/campaigns/simple-referral/edit/:id`}
        component={SimpleReferralEdit}
      />
      <Route exact path={`${path}`}>
        <Redirect to="/offers/referral/camapigns" />
      </Route>
    </Switch>

  );
}
