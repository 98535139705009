import React from "react";
import { useWorkspace } from "../../../../../../contexts/WorkspaceContext";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../../../components/TabRadioButton";
import { Checkbox } from "@atlaskit/checkbox";
import { RiEyeOffFill } from "react-icons/ri";
import CustomiserUnit from "../../../../../../components/CustomiserUnit";
import toaster from "toasted-notes";
import { RiAlarmWarningFill } from "react-icons/ri";

/**
 * Customise the launcher of the widget
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function CartButtonSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();

  const widgetShape = [
    { label: "Circle", value: "round" },
    { label: "Square", value: "square" },
    { label: "Rounded square", value: "rounded-square" },
  ];
  const widgetSizes = [
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
  ];

  const widgetSelector = [
    {
      label: "Side Tab",
      value: "sideTab",
    },
    {
      label: "Corner Button",
      value: "cornerButton",
    },
  ];

  const OnClickOptions = [
    { label: "Open Cart", value: "OPEN_CART"},
    { label: "Go to Checkout Page", value: "/checkout" },
  ];

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const alignment = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
  ];

  const HiddenLauncherEmptyState = () => {
    return (
      <div className="flex justify-center mt-12 text-gray-300">
        <div>
          <h2 className="flex justify-center mb-2 text-4xl">
            <RiEyeOffFill />
          </h2>
          <h2 className="text-center ">Launcher Hidden</h2>
        </div>
      </div>
    );
  };

  return (
    <div className="flex grow flex-col">
      {/* type selector */}
      <CustomiserUnit
        title="Button Style"
        description="Choose how the floating cart button looks "
      >
        <TabRadioButton
          defaultValue={values.cart_launcher_config.type}
          options={widgetSelector}
          onChange={(selectedType) => {
            if (selectedType === "hide") {
              setFieldValue("cart_launcher_config.isActive", false);
            } else {
              setFieldValue("cart_launcher_config.isActive", true);
            }
            setFieldValue("cart_launcher_config.type", selectedType);
          }}
        />
      </CustomiserUnit>

      {values.cart_launcher_config.type === "hide" ? (
        <HiddenLauncherEmptyState />
      ) : (
        <>
          {values.cart_launcher_config.type === "cornerButton" && (
            <>
              {/* button shape */}
              <CustomiserUnit
                title="Button Shape"
                description="Select the shape of button to match your theme"
              >
                <Select
                  isSearchable={false}
                  value={widgetShape.find(
                    (option) =>
                      option.value === values.cart_launcher_config.shape
                  )}
                  onChange={(e) =>
                    setFieldValue("cart_launcher_config.shape", e.value)
                  }
                  className="w-64"
                  classNamePrefix="react-select"
                  options={widgetShape}
                  placeholder="Select widget position"
                />
              </CustomiserUnit>

              {/* size */}
              <CustomiserUnit
                title="Size"
                description="Select the button's size in both mobile and desktop "
              >
                <div className="flex items-center">
                  <div>
                    <label className="text-xs text-gray-700">Desktop</label>
                    <Select
                      isSearchable={false}
                      value={widgetSizes.find(
                        (option) =>
                          option.value ===
                          values.cart_launcher_config.size.desktop
                      )}
                      onChange={(e) =>
                        setFieldValue(
                          "cart_launcher_config.size.desktop",
                          e.value
                        )
                      }
                      className="w-32"
                      classNamePrefix="react-select"
                      options={widgetSizes}
                      placeholder="Size"
                    />
                  </div>
                  <div className="ml-4">
                    <label className="text-xs text-gray-700">Mobile</label>
                    <Select
                      isSearchable={false}
                      value={widgetSizes.find(
                        (option) =>
                          option.value ===
                          values.cart_launcher_config.size.mobile
                      )}
                      onChange={(e) =>
                        setFieldValue(
                          "cart_launcher_config.size.mobile",
                          e.value
                        )
                      }
                      className="w-32"
                      classNamePrefix="react-select"
                      options={widgetSizes}
                      placeholder="Size"
                    />
                  </div>
                </div>
              </CustomiserUnit>
            </>
          )}

          {/* Launcher text */}
          <CustomiserUnit
            title="Launcher Text"
            description="Text displayed inside the button. You may also leave it blank."
          >
            <Textfield
              defaultValue={
                values.cart_launcher_config.text
                  ? values.cart_launcher_config.text
                  : ""
              }
              onChange={(e) => {
                setFieldValue("cart_launcher_config.text", e.target.value);
              }}
              name="basic"
            />
            {values.cart_launcher_config.text.length > 20 && (
              <small className="text-red-500 flex items-center">
                <RiAlarmWarningFill />
                <span className="ml-2">
                  Exceeded the recommended character length
                </span>
              </small>
            )}
          </CustomiserUnit>

          {/* widget alignment */}
          <CustomiserUnit
            title="Alignment"
            description="Choose which side of the website to render the button"
          >
            <TabRadioButton
              defaultValue={values.cart_launcher_config.horizontalPositioning}
              options={alignment}
              onChange={(selectedType) => {
                setFieldValue(
                  "cart_launcher_config.horizontalPositioning",
                  selectedType
                );
              }}
            />
          </CustomiserUnit>

          {/* vertical Position */}
          <CustomiserUnit
            title="Vertical Position"
            description="Choose the height at which to position the button"
          >
            <Textfield
              defaultValue={values.cart_launcher_config.verticalPositioning}
              onChange={(e) => {
                setFieldValue(
                  "cart_launcher_config.verticalPositioning",
                  e.target.value
                );
              }}
              name="basic"
              type="number"
            />
          </CustomiserUnit>

          {/* on Click */}
          <CustomiserUnit
            title="On Click Behaviour"
            description="Select what happens when the button is clicked"
          >
            <Select
              isSearchable={false}
              value={OnClickOptions.find(
                (option) => option.value === values.cart_launcher_config.onClick
              )}
              onChange={(e) => {
                if (
                  !workspace.data?.feature_flag.apps?.cornercart?.dev_options &&
                  e.value === "custom"
                ) {
                  toaster.notify(
                    "You need a higher plan to add custom actions",
                    {
                      duration: 2000,
                    }
                  );
                } else {
                  setFieldValue("cart_launcher_config.onClick", e.value);
                }
              }}
              className="w-64"
              classNamePrefix="react-select"
              options={OnClickOptions}
              placeholder="On Click action"
            />
            {values.cart_launcher_config.onClick === "OPEN_CART" && !values.cart_config.general.isActive && (
              <div className=" p-3  rounded-lg mt-2 text-sm bg-amber-100">
                <span className="text-amber-700 font-bold">Warning:</span> Since Cart drawer is disabled, visitor will be redirected to the theme's cart page
              </div>
            )}
          </CustomiserUnit>

          {/* visibility */}
          <CustomiserUnit
            title="Visibility"
            description="Choose to show/hide the button in specific situations"
          >
            <div className="flex items-center">
              <div>
                <label className="text-xs text-gray-700">Desktop</label>
                <Select
                  isSearchable={false}
                  value={visibilityOptions.find(
                    (option) =>
                      option.value ===
                      values.cart_launcher_config.visibility.desktop
                  )}
                  onChange={(e) =>
                    setFieldValue(
                      "cart_launcher_config.visibility.desktop",
                      e.value
                    )
                  }
                  className="w-32"
                  classNamePrefix="react-select"
                  options={visibilityOptions}
                  placeholder="desktop-visibility"
                />
              </div>
              <div className="ml-4">
                <label className="text-xs text-gray-700">Mobile</label>
                <Select
                  isSearchable={false}
                  value={visibilityOptions.find(
                    (option) =>
                      option.value ===
                      values.cart_launcher_config.visibility.mobile
                  )}
                  onChange={(e) =>
                    setFieldValue(
                      "cart_launcher_config.visibility.mobile",
                      e.value
                    )
                  }
                  className="w-32"
                  classNamePrefix="react-select"
                  options={visibilityOptions}
                  placeholder="Size"
                />
              </div>
            </div>
            <div className="border rounded-lg mt-3 p-4 flex items-center justify-between">
              <Checkbox
                onChange={(e) => {
                  setFieldValue(
                    "cart_launcher_config.visibility.showOnProductPage",
                    e.target.checked
                  );
                }}
                label="Show on product pages"
                name="checkbox-basic"
                defaultChecked={
                  values.cart_launcher_config.visibility.showOnProductPage
                }
              />
              <div className="border-r h-6 w-1" />
              <Checkbox
                onChange={(e) => {
                  setFieldValue(
                    "cart_launcher_config.visibility.showOnCollectionPage",
                    e.target.checked
                  );
                }}
                label="Show on collection pages"
                name="checkbox-basic"
                defaultChecked={
                  values.cart_launcher_config.visibility.showOnCollectionPage
                }
              />
            </div>
          </CustomiserUnit>
        </>
      )}
    </div>
  );
}
