import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import Widgets from "./Widget/WidgetRouter";
import Cro from "./Cro/CroRouter";
import { Home } from "./Home";

export default function CartRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 3)
      workspace.data.changeCurrentAppID(3);
  }, [workspace]);

  return (
    <>
      <Switch>
        <Route path={`${path}/widgets`} component={Widgets} />
        <Route path={`${path}/cro`} component={Cro} />
        <Route path={`${path}/home`} component={Home} />
        <Route exact path={path}>
          <Redirect to={`/cart/home`} />
        </Route>
      </Switch>
    </>
  );
}
