import { useState } from "react";
import { Provider } from "@shopify/app-bridge-react";

/**
 * A component to configure App Bridge.
 * @desc A thin wrapper around AppBridgeProvider that provides the following capabilities:
 *
 * 1. Ensures that navigating inside the app updates the host URL.
 * 2. Configures the App Bridge Provider, which unlocks functionality provided by the host.
 *
 * See: https://shopify.dev/apps/tools/app-bridge/react-components
 */
const ShopifyAppBridgeProvider = ({ children }) => {
  /**
   * a function for checking the current app and get the api key accorfingly
   * @returns API key for the current App
   */
  const getCurrentAppShopifyAPIKey = () => {
    if (window.location.pathname.includes("supportcorner") || window.location.pathname.includes("support")) {
      return process.env.REACT_APP_SUCO_API_KEY;
    } else if (window.location.pathname.includes("offerscorner") || window.location.pathname.includes("offers")) {
      return process.env.REACT_APP_OFCO_API_KEY;
    } else if (window.location.pathname.includes("cornercart") || window.location.pathname.includes("cart")) {
      return process.env.REACT_APP_COCA_API_KEY;
    } 
    else {
      /**
       * Try loading the current app id from session variable and use that to set the
       * app public key
       */
      let currentAppId = sessionStorage.getItem("currentAppId");
      switch(currentAppId) {
        case "1": return process.env.REACT_APP_SUCO_API_KEY; break;
        case "2": return process.env.REACT_APP_OFCO_API_KEY; break;
        case "3": return process.env.REACT_APP_COCA_API_KEY; break;
        default: {
          console.log("Trying to setup App Bridge, but App is unknown");
          console.log("location was", window.location.pathname);
          window.Rollbar.error("Trying to setup App Bridge, but App is unknown. Location was:",
            window.location.pathname)
        }
      }
    }
  };

  // The host & appId may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const [appBridgeConfig] = useState(() => {
    const hostVal = new URLSearchParams(window.location.search).get("host");
    const host = hostVal || window.__SHOPIFY_DEV_HOST || sessionStorage.getItem("shopifyDevHost");
    window.__SHOPIFY_DEV_HOST = host;
    sessionStorage.setItem("shopifyDevHost", host);
    return {
      host,
      apiKey: getCurrentAppShopifyAPIKey(),
      forceRedirect: true,
    };
  });

  return <Provider config={appBridgeConfig}>{children}</Provider>;
};

export { ShopifyAppBridgeProvider };
