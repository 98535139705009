import React, { useState } from "react";
import { FieldArray } from "formik";
import GoalItem from "./GoalItem";
import CartGoalPreview from "../CartGoalPreview";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Content({
  values,
  setFieldValue,
  isValidationFailed,
  currentCampaignKey,
}) {
  const {
    data: { default_language },
  } = useWorkspace();

  const [previewCartValue, setPreviewCartValue] = useState(0);
  const [expandedGoals, setExpandedGoals] = useState();

  return (
    <div className=" h-full flex flex-row items-stretch min-h-0">
      <div className="desktop:max-w-2xl laptop:max-w-lg mobile:w-full  overflow-y-auto overflow-x-visible pb-12">
        {/* Cart Goals */}
        <FieldArray
          name="campaign_cart_goals[0].milestones"
          render={(arrayHelpers) => {
            return (
              <>
                <div className=" p-6 border-b">
                  <p className="font-bold text-lg mb-2">
                    Curstomize texts shown in each goal
                  </p>
                  <p className="text-gray-700">
                    Each goal in your campaign will have seperate sets of text
                    that you can customise and you can customise those here
                  </p>

                  {/* <div className="mt-3 ">
                    <p className="text-sm mr-2 font-bold">Select a language</p>
                    <div className="flex items-center">
                      <Select
                        isSearchable={false}
                        value={langOptions.find(
                          (option) => option.value === selectedLanguage
                        )}
                        onChange={(e) => {
                          setSelectedLanguage(e.value);
                        }}
                        className="w-48"
                        classNamePrefix="react-select"
                        options={langOptions.filter((item) =>
                          filteredLangArray.includes(item.value)
                        )}
                      />
                      <p className="text-sm ml-2 border max-w-max px-2 py-1 rounded-lg">
                        <Link
                          to={`/settings/language`}
                          className="text-violet-700"
                        >
                          {" "}
                          Click here
                        </Link>{" "}
                        to add/edit the language list{" "}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="max-w-4xl">
                  {values.campaign_cart_goals[0].milestones.length > 0 &&
                    values.campaign_cart_goals[0].milestones.map(
                      (milestone, index) => {
                        return (
                          <GoalItem
                            key={`goal-item-${milestone.id}`}
                            isValidationFailed={isValidationFailed}
                            currentMilestone={milestone}
                            values={values}
                            onDelete={() => {
                              if (expandedGoals === index) {
                                setExpandedGoals(index - 1);
                              }
                              arrayHelpers.remove(index);
                            }}
                            isExpanded={expandedGoals === index}
                            onExpand={() => {
                              if (expandedGoals !== index) {
                                setExpandedGoals(index);
                                if (index === 0) {
                                  setPreviewCartValue(0);
                                } else {
                                  setPreviewCartValue(
                                    values.campaign_cart_goals[0].milestones[
                                      index - 1
                                    ].goal + 1
                                  );
                                }
                              } else {
                                setExpandedGoals(null);
                              }
                            }}
                            currentCampaignKey={currentCampaignKey}
                            index={index}
                            setFieldValue={setFieldValue}
                          />
                        );
                      }
                    )}
                </div>
              </>
            );
          }}
        />
      </div>
      <div className=" mobile:hidden laptop:flex flex-col items-center grow shrink-0 bg-gray-100 border-l py-8 px-3">
        <p className="mb-3 text-sm font-bold text-gray-400">
          Progress Bar Preview
        </p>
        <CartGoalPreview
          values={values.campaign_cart_goals[0]}
          currValue={previewCartValue}
        />
        <div className="mt-4 border px-4 py-2 rounded-lg ">
          <p className="text-sm font-bold text-gray-600">
            Use this slider to adjust the status of the progress bar
          </p>
          <input
            type="range"
            class="appearance-none w-full h-2 accent-violet-700 focus:outline-none focus:ring-0 focus:shadow-none bg-gray-200 rounded-full "
            id="customRange1"
            max={
              values.campaign_cart_goals[0].milestones[
                values.campaign_cart_goals[0].milestones.length - 1
              ].goal
            }
            value={previewCartValue}
            onChange={(e) => {
              setPreviewCartValue(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
