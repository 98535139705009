/* -------------- Queries ---------- */

export const queryAnalyticsInfo = `
query queryBillingInfo($fromDate: timestamptz!,$toDate: timestamptz!) {
    sources {
        id
        orders_aggregate(
          where:{
            created_at:{
              _gte:$fromDate,
              _lte:$toDate
            }
          }
        ){
          aggregate {
            count
            sum{
              order_value
            }
          }
        }
      }
}
`;
