import React, { useState } from "react";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  RiCloseFill,
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiCheckLine,
  RiGiftFill,
} from "react-icons/ri";

/**
 * Formating money to a given currency format
 * @component
 * @param {object} milestone - milestone object of the milestone which is needde to be rendered
 * @param {number} index - Index of this particular milestone
 */
const GoalMilestones = ({
  milestone,
  index,
  currentMilestoneIndex,
  goalType,
  currValue,
  text,
}) => {
  let progress;

  if (index > currentMilestoneIndex) progress = 0;
  else if (index < currentMilestoneIndex) progress = 100;
  else {
    let goal = goalType === "totalOrderValue" ? milestone.goal : milestone.goal;
    progress = (currValue / goal) * 100;
  }

  return (
    <div
      id={`corner-cowi-goal-milestones-${index + 1}`}
      className="relative flex grow justify-between items-center h-full rounded-full"
    >
      <div
        id={`corner-cowi-goal-milestones-progress-${index + 1}`}
        className="block bg-black h-full rounded-full transition-all"
        style={{ width: `${progress}%` }}
      />
      <div
        id={`corner-cowi-goal-milestone-icon-${index + 1}`}
        className={`absolute flex items-center justify-center rounded-full -right-1 w-5 h-5 p-[.27em] ${
          progress === 100 ? "bg-black text-white" : "bg-gray-200 text-black"
        }`}
      >
        {progress === 100 ? (
          <RiCheckLine size={12} />
        ) : (
          <RiGiftFill size={12} />
        )}
      </div>
      <div
        id={`corner-cowi-goal-milestone-label-${index + 1}`}
        className="text-black absolute text-xs top-0 right-0 mt-4"
      >
        {text}
      </div>
    </div>
  );
};

const Carousel = ({ data, item }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <div className="flex items-center">
      {data.length > 1 && currentSlide > 0 && (
        <button
          onClick={() => setCurrentSlide((prev) => prev - 1)}
          className="mr-1 p-1 border rounded-md"
        >
          <RiArrowLeftSFill />
        </button>
      )}
      <div className="mx-3 grow">{item(data[currentSlide], currentSlide)}</div>
      {data.length > 1 && currentSlide < data.length - 1 && (
        <button
          onClick={() => setCurrentSlide((prev) => prev + 1)}
          className="ml-1 p-1 border rounded-md"
        >
          <RiArrowRightSFill />
        </button>
      )}
    </div>
  );
};

/**
 * cart goal example
 */
const CartGoalExample = ({ values, currValue }) => {
  const workspace = useWorkspace();

  let currency = workspace.data.storeCurrency;
  let currentMilestoneIndex = 0;
  currentMilestoneIndex =
    currValue < values.milestones[values.milestones.length - 1].goal
      ? values.milestones.findIndex((item) => {
          return currValue < item.goal;
        })
      : values.milestones.length;

  /**
   * Function to trucate longer strings
   * @param {string} sourceText - original string to be replaced with
   */
  const wordReplacer = (sourceText, milestone) => {
    let currentMilestoneValue = milestone.goal;

    const formatMoney = (cents) => {
      var value = Number.isInteger(cents) ? cents : cents.toFixed(2);
      var patt = /\{\{\s*(\w+)\s*\}\}/;
      let formatString = `{{amount}} ${currency} `;

      return formatString.replace(patt, value);
    };

    let wordMap = [
      {
        placeholder: "{{goal}}",
        value:
          values.goalType === "totalOrderValue"
            ? `<span class="font-bold">${formatMoney(
                currentMilestoneValue - currValue
              )}</span>`
            : `<span class="font-bold">${
                currentMilestoneValue - currValue
              }</span>`,
      },
      {
        placeholder: "{{current_status}}",
        value: currValue,
      },
      {
        placeholder: "{{discount}}",
        value:
          milestone.rewards.rewardStack[0].unit === "percent"
            ? `${milestone.rewards.rewardStack[0].value}%`
            : formatMoney(
                milestone.rewards.rewardStack[0].value *
                  currencyConversionMultiplier
              ),
      },
    ];

    let text = sourceText;
    wordMap.map((item) => {
      text = text.replace(new RegExp(item.placeholder, "gi"), item.value);
    });
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  const renderTopText = () => {
    let currentMilestone =
      currentMilestoneIndex < values.milestones.length
        ? values.milestones[currentMilestoneIndex]
        : values.milestones[values.milestones.length - 1];

    let text = "Select a reward type";
    if (currentMilestone.rewards.rewardStack.length > 0) {
      if (currentMilestoneIndex === values.milestones.length) {
        text =
          values.milestones[values.milestones.length - 1].content.postGoalText;
      } else {
        text = values.milestones[currentMilestoneIndex].content.preGoalText;
      }
      return wordReplacer(text, currentMilestone);
    }
    return text;
  };

  const renderCartItemPlaceholder = () => {
    return (
      <div className="w-full flex px-3 py-6 border-b ">
        <div className="w-12 h-12 bg-gray-200 rounded-lg"></div>
        <div className="grow ml-3">
          <div className="bg-gray-200 h-2 w-1/2 rounded-full"></div>
          <div className="bg-gray-200 h-2 w-1/3 rounded-full mt-2"></div>
          <div className="flex justify-end">
            <div className="bg-gray-200 h-3 w-6 rounded-full mt-2"></div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * This function splits an array in to multiple seperate arrays with eaxh containing 4 items.
   * @component
   * @param {object} array -array  that needed to be spit
   */
  const chunkArray = (arr) =>
    arr.length > 4 ? [arr.slice(0, 4), ...chunkArray(arr.slice(4), 4)] : [arr];

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="flex w-full justify-center relative h-80 rounded-t-lg overflow-hidden max-w-md grow">
          <div className=" absolute top-0 bg-gradient-to-b from-white  w-full h-80 p-3 z-10"></div>
          <div className=" absolute top-0 bg-gradient-to-b from-gray-500 w-full h-40 p-3 z-10"></div>

          <div className="flex flex-col px-3 pt-3 z-20 w-full">
            {/* Header */}
            <div className=" flex justify-between items-center w-full">
              <p className="text-lg font-bold text-white">Cart</p>
              <p className="text-xs shadow-lg bg-white rounded-full text-gray-700 px-2 flex items-center">
                <RiCloseFill />
                <span> Close</span>
              </p>
            </div>
            {/* content wrapper */}
            <div className="grow pb-8 rounded-t-lg mt-2 bg-white shadow overflow-hidden">
              {/* Message Banner */}
              <div
                id="corner-cowi-cart-goal-meter"
                className="w-full text-center px-3 pb-8 pt-3 bg-white border-b"
              >
                <div
                  id="corner-cowi-cart-goal-meter-text"
                  className="text-black text-center text-sm"
                >
                  {renderTopText()}
                </div>

                {values.milestones[0].rewards.rewardStack.length > 0 && (
                  <Carousel
                    data={chunkArray(values.milestones)}
                    item={(itemData, outerIndex) => {
                      return (
                        <div className="flex items-center w-full h-2 rounded-full mt-4 bg-gray-100">
                          {itemData.map((milestone, index) => {
                            return (
                              <GoalMilestones
                                milestone={milestone}
                                index={outerIndex * 4 + index}
                                currentMilestoneIndex={currentMilestoneIndex}
                                goalType={values.goalType}
                                currValue={currValue}
                                text={wordReplacer(
                                  milestone.content.milestoneLabel,
                                  milestone
                                )}
                              />
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                )}
              </div>

              {renderCartItemPlaceholder()}
              {renderCartItemPlaceholder()}
            </div>
            <div className="bg-gradient-to-b from-white h-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartGoalExample;
