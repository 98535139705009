/* --------------- Queries ------------------- */
export const queryInstallationsById = `
    query queryInstallationsById($id: bigint!) {
        installations_by_pk(id: $id) {
        id
        created_at
        contact_name
        contact_email
        }
    }
`;
