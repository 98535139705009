import React from "react";
import { RiFileCopyLine, RiPriceTag3Fill } from "react-icons/ri";
import Button from "../../../../../components/Button";

/**
 * cart goal example
 */
const discountCodeExample = () => {
  return (
    <div className=" text-sm flex py-3 px-4 items-center bg-white shadow-lg rounded-lg w-4/5 justify-between">
      <div className="p-4 mr-2 rounded-lg text-violet-700 bg-violet-100">
        <RiPriceTag3Fill size={16} />
      </div>
      <div className="text-sm text-gray-700 text-left grow">
      <div className="font-bold"> End of Season Sale</div>
      <div> Use this code to get 30% off. Discount upto 25$</div>
      </div>
      <div className="border border-solid border-gray-300 rounded-lg flex flex-col overflow-hidden shrink-0">
        <div className="text-base font-bold px-3 py-1 text-center flex items-center justify-center">
          <div className="mr-1">EOS30</div>
          <RiFileCopyLine size={14} />
        </div>

        <div className="text-white bg-violet-700 text-center font-bold py-2">
          Apply Code
        </div>
      </div>
    </div>
  );
};

export default discountCodeExample;
