/**
 * Function to return the currency object
 * @param {string} currency - the currency value is used as the key for the object
 * @param {string} currencyText - Text / symbol to be shown next to the amount
 * @param {boolean} isDefault - flag that determines whether the currency format is default or not
 * @returns {object} currency object
 */
const returnCurrencyObject = (currency, currencyText, isDefault = false) => {
  return {
    [currency]: {
      format: `${currencyText} {{amount}}`,
      isDefault: isDefault,
    },
  };
};

export default returnCurrencyObject;
