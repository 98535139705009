import React, { useState } from "react";
import {
  RiGiftFill,
  RiTruckFill,
  RiPercentFill,
  RiAlertFill,
} from "react-icons/ri";
import generateNanoId from "../../../../../utilities/generateNanoId";

/**
 * A UI which lets user select a reward type intially
 */
const InitialRewardSelect = ({
  rewardId,
  currentCampaignKey,
  title,
  onSelect,
  isValidationFailed,
}) => {
  const handleRewardType = async (selectedType) => {
    const nanoId = await generateNanoId();
    let defaultRewardStack;
    let defaultContent;
    switch (selectedType) {
      case "shippingDiscount": {
        defaultRewardStack = [
          {
            type: "shippingDiscount",
            target: "shipping",
            value: 100,
            unit: "percent",
            promoCode: `SHIP${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText: "Add {{goal}} more to get Free Shipping on this order",
          postGoalText: "Congratulations! You have unlocked Free Shipping!",
          milestoneLabel: "Free Shipping!",
          preGoalOfferPageTitle: "Free Shipping",
          postGoalOfferPageTitle: "Free Shipping",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get free shipping on this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked free shipping!",
        };
        break;
      }
      case "freeProduct": {
        defaultRewardStack = [
          {
            type: "freeProduct",
            target: "",
            value: 100,
            unit: "percent",
            promoCode: `GIFT${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText: "Add {{goal}} more to get Free Gift with this order",
          postGoalText: "Congratulations! You have unlocked Free Gift!",
          milestoneLabel: "Free Gift!",
          preGoalOfferPageTitle: "Free Gift",
          postGoalOfferPageTitle: "Free Gift",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get Free Gift with this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked free Gift!",
        };
        break;
      }
      case "orderDiscount": {
        defaultRewardStack = [
          {
            type: "orderDiscount",
            target: "cart",
            value: 20,
            unit: "percent",
            promoCode: `OFF${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText:
            "Add {{goal}} more to get a {{discount}} discount on this order",
          postGoalText:
            "Congratulations! You have unlocked the {{discount}} discount!",
          milestoneLabel: "{{discount}} Off",
          preGoalOfferPageTitle: "{{discount}} Discount",
          postGoalOfferPageTitle: "{{discount}} Discount",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get a {{discount}} discount on this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked the {{discount}} discount!",
        };

        break;
      }
    }
    onSelect(
      {
        id: rewardId,
        stackModifier: selectedType === "freeProduct" ? "any" : "all",
        stackSelectCount: 1,
        prerequisites: {},
        rewardStack: defaultRewardStack,
      },
      defaultContent
    );
  };

  const GoalTypeButton = ({ icon, title, onClick, disabled, subTitle }) => {
    return (
      <button
        onClick={onClick}
        className={`${
          disabled && "pointer-events-none opacity-50"
        } flex justify-center flex-col items-center p-3 mx-2 rounded-lg border bg-white text-gray-700  hover:text-violet-700 transition-all hover:border-violet-50 hover:bg-violet-50`}
      >
        {icon}
        <span className="mt-2 font-bold">{title}</span>
        {subTitle && <span className="text-xs">{subTitle}</span>}
      </button>
    );
  };

  return (
    <div className="w-full text-center">
      <p className="font-bold">
        <span className="ml-1">{title}</span>
      </p>
      {isValidationFailed && (
        <div className="flex items-center justify-center mt-1">
          <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-full">
            <RiAlertFill size={12} className="grow-0 animate-bounce" />
            <span className="ml-3">Make sure a reward type is selected</span>
          </div>
        </div>
      )}

      <div className="mt-4 flex items-stretch justify-center">
        <GoalTypeButton
          icon={<RiGiftFill size={27} />}
          title="Give Free Products"
          onClick={() => {
            handleRewardType("freeProduct");
          }}
        />
        <GoalTypeButton
          icon={<RiTruckFill size={27} />}
          title="Give Free Shipping"
          onClick={() => {
            handleRewardType("shippingDiscount");
          }}
        />

        <GoalTypeButton
          icon={<RiPercentFill size={27} />}
          title="Give Cart Discount"
          onClick={() => {
            handleRewardType("orderDiscount");
          }}
        />
      </div>
    </div>
  );
};

export default InitialRewardSelect;
