import React, { useState, useCallback } from "react";
import { RiPencilFill, RiDeleteBin6Line, RiAddFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import Select from "@atlaskit/select";
import Button from "./Button";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { langOptions } from "../utilities/variables";
import getLanguageLabel from "../utilities/getLanguageLabel";

const EditLangContent = ({
  contentList,
  onLangEdit,
  onLangRemove,
  onLangAdd,
}) => {
  const history = useHistory();
  const workspace = useWorkspace();

  const [showLangSelector, setShowLangSelector] = useState(false);

  const getLangOptions = useCallback(() => {
    const translatedLangArray = workspace.data.translated_languages;
    const addedLangArray = contentList.map((contentItem) => contentItem.lang);
    const translatedLangOptions = translatedLangArray.filter(
      (lang) => !addedLangArray.includes(lang)
    );
    const langOptionDetails = langOptions.filter((langOption) =>
      translatedLangOptions.includes(langOption.value)
    );
    const addNewLangOption = {
      label: (
        <div className="w-full py-1 text-violet-700 hover:text-violet-800 cursor-pointer">
          To add Languages, Click here
        </div>
      ),
      value: "redirect",
    };
    return [...langOptionDetails, addNewLangOption];
  }, [contentList]);

  return (
    <div className="rounded-lg border border-gray-300 max-w-lg">
      {contentList.map((contentListItem) => {
        return (
          <div
            className={`p-3 flex items-center justify-between border-b border-gray-200`}
            key={contentListItem.lang}
          >
            <h2 className="text-base font-bold ml-2">
              {getLanguageLabel(contentListItem.lang)}
            </h2>
            <div className="flex flex-row">
              <Button
                onClick={() => onLangEdit(contentListItem)}
                icon={<RiPencilFill />}
                type="ghost"
              >
                Edit
              </Button>
              {contentListItem.lang !== workspace.data.default_language &&
                contentList.length > 1 && (
                  <Button
                    className="ml-4"
                    onClick={() => onLangRemove(contentListItem)}
                    icon={<RiDeleteBin6Line />}
                    type="danger-ghost"
                  >
                    Delete
                  </Button>
                )}
            </div>
          </div>
        );
      })}
      <div className="flex flex-row justify-center p-3">
        {showLangSelector ? (
          <Select
            onChange={(selectedItem) => {
              if (selectedItem.value !== "redirect") onLangAdd(selectedItem);
              else history.push("/settings/language");
              setShowLangSelector(false);
            }}
            className="w-full"
            options={getLangOptions()}
            placeholder="Click to select a language"
          />
        ) : (
          <Button
            type="link-primary"
            icon={<RiAddFill />}
            onClick={() => setShowLangSelector(true)}
          >
            Add Translations
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditLangContent;
