import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { queryWorkspaceProps } from "../api/workspace";
import { useGraphQl } from "./GraphqlClient";
import { useAuth } from "./AuthenticationContext";
import Cookies from "js-cookie";
import createDoptUser from "../api/dopUser";

/* WorkspaceContext's primary responsibility is to fetch the intial
 * data needed to render the workspce like feature flag, account, source and user ids.
 * And provide this global context to all the branches in the app tree
 * App will not even render without this data.
 */

const defaultWorkspaceProps = {
  isAuthorised: false,
  status: "loading",
  data: {},
};
const WorkspaceContext = React.createContext(defaultWorkspaceProps);

const WorkspaceProvider = (props) => {
  const graphQL = useGraphQl();
  const authState = useAuth();

  const [currentAppID, setCurrentAppID] = useState();
  const [currentInstallationId, setCurrentInstallationId] = useState("");
  const [installedApps, setInstalledApps] = useState([]);
  const [isExperimental, setIsExperimental] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const { isLoading, isError, data, error, refetch } = useQuery(
    "queryWorkspaceProps",
    () => {
      return graphQL(queryWorkspaceProps);
    },
    { enabled: authState.token ? true : false, refetchOnWindowFocus: false }
  );

  const changeCurrentApp = (appId) => {
    setCurrentAppID(appId);
    sessionStorage.setItem("currentAppId", appId);
    Cookies.set("currentAppId", appId, {
      sameSite: "lax",
      secure: true,
    });
  };

  // This use effect initialize which os the current app and also which all app use have installed
  useEffect(async () => {
    let appId;
    if (
      window.location.pathname.includes("supportcorner") ||
      window.location.pathname.includes("support")
    ) {
      appId = 1;
    } else if (
      window.location.pathname.includes("offerscorner") ||
      window.location.pathname.includes("offers")
    ) {
      appId = 2;
    } else if (
      window.location.pathname.includes("cornercart") ||
      window.location.pathname.includes("cart")
    ) {
      appId = 3;
    } else {
      /* This typically should not happen. If this happen, we need to know the edge
       * case URL for which this happens.
       */
      // console.log("detected iframe location is --> ", window.location.href);
      appId = parseInt(urlParams.get("app_id"), 10);
    }
    if (data && data.installations) {
      const installedApps = data.installations.filter(
        (app) => app.status === "installed" || app.status === "reinstalled"
      );
      setInstalledApps(installedApps);

      if (appId) {
        changeCurrentApp(appId);
      } else if (sessionStorage.getItem("currentAppId")) {
        // Try to get from session storage
        appId = parseInt(sessionStorage.getItem("currentAppId"), 10);
      } else if (Cookies.get("currentAppId")) {
        // Try to get from cookies.
        appId = parseInt(Cookies.get("currentAppId"), 10);
      } else {
        appId = installedApps[0].appid;
        Cookies.set("currentAppId", installedApps[0].appid, {
          sameSite: "lax",
          secure: true,
        });
      }
      setCurrentAppID(appId);
      const currentAppInstallDetails = installedApps.find(
        (installedApp) => installedApp.appid === appId
      );
      if (currentAppInstallDetails && currentAppInstallDetails.id) {
        setCurrentInstallationId(currentAppInstallDetails.id);
        if (currentAppInstallDetails.release_channel !== "stable") {
          setIsExperimental(true);
        }
      }
    }
  }, [data]);

  if (isError) {
    window.Rollbar.error("Error in WorkspaceContext query", error);
    console.log("error in workspace context query");
  }

  let wsdata = {};

  if (data) {
    const [workspace] = data.workspaces;
    wsdata = {
      data: {
        accountid: workspace.accountid,
        userId: workspace.userid,
        feature_flag: data.feature_access[0].feature_flag,
        sourceid: workspace.sourceid,
        storeUrl: workspace.source.source_meta.domain,
        suco_onboarding_progress: workspace.source.onboarding_progress,
        ofco_onboarding_progress: workspace.source.offer_onboarding,
        coca_onboarding_progress: workspace.source.cornercart_onboarding,
        user: workspace.user,
        currentAppID: currentAppID,
        changeCurrentAppID: changeCurrentApp,
        isExperimentalFeaturesTurnedOn: isExperimental,
        installedApps: installedApps,
        installationId: currentInstallationId,
        default_language: workspace.source.default_language,
        storeCurrency: workspace.source.source_meta.currency,
        currencyFormat: workspace.source.currency_format,
        storeLocale: workspace.source.source_meta.locale,
        storeName: workspace.source.source_meta.shopName,
        storeMyShopifyUrl: workspace.source.source_domain,
        allAvailablePlans: data.billing_plans,
        otherDetails: workspace.source.source_details,
        shopifyPlanDetails: {
          planName: workspace.source.source_details?.plan_name,
          planDisplayName: workspace.source.source_details?.plan_display_name,
        },
        translated_languages: workspace.source.translated_languages,
      },
      refreshWorkspace: refetch,
    };
  }
  return <WorkspaceContext.Provider value={wsdata} {...props} />;
};

const useWorkspace = () => React.useContext(WorkspaceContext);

export { WorkspaceProvider, useWorkspace };
