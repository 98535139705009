import React, { memo } from "react";
import { RiAlertFill, RiShoppingCart2Fill,RiAlarmWarningFill } from "react-icons/ri";
import { FieldArray } from "formik";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import RewardConditonSelector from "./RewardCondtionSelector";
import Button from "../../../../../components/Button";
import NumberInput from "../../../../../components/NumberInput";
import MultiResourceSelector from "../../../../../components/MultiResourceSelector";
import { parseShopifyProductId } from "../../../../../utilities/basicUtilityFunctions";
import generateNanoId from "../../../../../utilities/generateNanoId";

const Rewards = memo(
  ({ values, setFieldValue, validationFailed, currentCampaignKey }) => {
    return (
      <div className=" overflow-y-auto h-full px-8 py-4">
        <CustomiserUnit
          title="Reward Condition (Customer Buys)"
          description="Select when the customer gets the reward"
        >
          <RewardConditonSelector
            values={values.campaign_bxgy_frees[0]}
            setFieldValue={setFieldValue}
            validationFailed={validationFailed}
          />
        </CustomiserUnit>

        {/* what Customer Gets */}
        <CustomiserUnit
          title="Reward (what Customer Gets)"
          description="Select what the customer gets. Make sure these rewards are not offered as free gifts in any other campaigns"
        >
          <FieldArray
            name={`campaign_bxgy_frees[0].config.rewards.rewardStack`}
            render={(arrayHelpers) => {
              const rewardItemArray =
                values.campaign_bxgy_frees[0].config.rewards.rewardStack.map(
                  (item) => item.target
                );
              return (
                <MultiResourceSelector
                  className={"max-w-lg"}
                  initialValues={rewardItemArray}
                  btnText={
                    values.campaign_bxgy_frees[0].config.rewards.rewardStack
                      .length === 0
                      ? "Add a Product"
                      : "Add More Products"
                  }
                  emptyWarning={
                    validationFailed && (
                      <div className="flex items-center justify-center mt-1">
                        <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                          <RiAlertFill
                            size={12}
                            className="grow-0 animate-bounce"
                          />
                          <span className="ml-3">
                            Make sure a product is added
                          </span>
                        </div>
                      </div>
                    )
                  }
                  onAdd={async (selectedProduct) => {
                    const selectedProductId = parseShopifyProductId(
                      selectedProduct[0].id
                    );
                    const currentRewardProducts =
                      values.campaign_bxgy_frees[0].config.rewards.rewardStack;
                    let promoCode = "";
                    if (currentRewardProducts.length > 0) {
                      promoCode = currentRewardProducts[0].promoCode;
                    } else {
                      const nanoId = await generateNanoId();
                      promoCode = `BXGY${currentCampaignKey}${nanoId}`;
                    }
                    const rewardDetails = {
                      target: {
                        title: selectedProduct[0].title,
                        id: selectedProduct[0].id,
                        product_id: selectedProductId,
                        handle: selectedProduct[0].handle,
                        images: selectedProduct[0].images,
                      },
                      type: "freeProduct",
                      unit: "percent",
                      value: 100,
                      promoCode: promoCode,
                    };
                    setFieldValue(
                      `campaign_bxgy_frees[0].config.rewards.rewardStack`,
                      [...currentRewardProducts, rewardDetails]
                    );
                    if (
                      values.campaign_bxgy_frees[0].shopify_config
                        .automaticBxgyDiscount
                    ) {
                      const removeItems =
                        values.campaign_bxgy_frees[0].shopify_config
                          .automaticBxgyDiscount.customerGets.items.products
                          .productsToRemove;

                      // filter out remove items if product added is in the remove item list
                      if (removeItems.includes(selectedProduct[0].id)) {
                        const restOfRemoveItems = removeItems.filter(
                          (removeItem) => removeItem !== selectedProduct[0].id
                        );
                        setFieldValue(
                          "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerGets.items.products.productsToRemove",
                          [...restOfRemoveItems]
                        );
                      }
                    }
                  }}
                  onDelete={(deletedIndex, deletedProduct) => {
                    // on Delete the updatedrewardStack length will be length - 1
                    const updatedRewardStackLength =
                      values.campaign_bxgy_frees[0].config.rewards.rewardStack
                        .length - 1;

                    if (updatedRewardStackLength <= 1) {
                      // reset the stackSelect count
                      setFieldValue(
                        `campaign_bxgy_frees[0].config.rewards.stackSelectCount`,
                        1
                      );
                    }
                    arrayHelpers.remove(deletedIndex);
                    if (
                      values.campaign_bxgy_frees[0].shopify_config
                        .automaticBxgyDiscount
                    ) {
                      const { productsToAdd, productsToRemove } =
                        values.campaign_bxgy_frees[0].shopify_config
                          .automaticBxgyDiscount.customerGets.items.products;
                      const currentProductList =
                        values.campaign_bxgy_frees[0].config.rewards
                          .rewardStack;
                      const deleteOccurrences = currentProductList.filter(
                        (product) => product.id === deletedProduct.id
                      );
                      if (
                        productsToAdd.includes(deletedProduct.id) &&
                        !productsToRemove.includes(deletedProduct.id) &&
                        deleteOccurrences.length < 2
                      ) {
                        setFieldValue(
                          "campaign_bxgy_frees[0].shopify_config.automaticBxgyDiscount.customerGets.items.products.productsToRemove",
                          [...productsToRemove, deletedProduct.id]
                        );
                      }
                    }
                  }}
                  CustomInitialSelectBtn={(btnProps) => (
                    <Button
                      icon={<RiShoppingCart2Fill />}
                      type="outline"
                      {...btnProps}
                    >
                      <span>Select Product</span>
                    </Button>
                  )}
                />
              );
            }}
          />
        </CustomiserUnit>

        {/* Customer gift selection count */}
        {values.campaign_bxgy_frees[0].config.rewards.rewardStack.length >
          1 && (
          <CustomiserUnit
            title="How many rewards user can select"
            description="Select how many gifts user can select from the above product list"
          >
            <NumberInput
              value={
                values.campaign_bxgy_frees[0].config.rewards.stackSelectCount
              }
              min={1}
              max={
                values.campaign_bxgy_frees[0].config.rewards.rewardStack.length
              }
              onChange={(value) => {
                setFieldValue(
                  `campaign_bxgy_frees[0].config.rewards.stackSelectCount`,
                  value
                );
              }}
            />
          </CustomiserUnit>
          
        )}
         <div className="border rounded-lg px-4 py-2 text-sm">
          <p className=" text-gray-700 font-bold flex items-center">
            <span className="p-2 bg-red-100 rounded-full">
              <RiAlarmWarningFill color="red" size={12} />
            </span>
            <span className="ml-2 text-red-700">
              Current limitations to keep in mind
            </span>
          </p>
          <ul className="list-disc ml-4 ">
            <li className="mt-2">
              Multiple campaigns (For e.g 2 BXGY campaigns, or 1 BXGY and 1 Cart Goal campaign) giving the same product as free gift is currently not supported
            </li>
            <li className="mt-2">
            Running a BXGY campaign together with a Cart Goal campaign with order discount as a reward is not supported. We recommend offering free gifts or free shipping as Cart Goal rewards if you are running BXGY campaigns at the same time.
            </li>
            <li className="mt-2">
            Having 2 BXGY campaigns with overlapping conditions is not supported. For instance, if a campaign offers a free gift for buying any product from collection A and another requires purchasing product X, only one gift will be marked FREE by Shopify if product X belongs to collection A.
           </li>
          </ul>
        </div>
      </div>
    );
  }
);

export default Rewards;
