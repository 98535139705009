import React, { useState } from "react";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../components/TabRadioButton";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ProfitCalculator from "./ProfitCalculator";
import ColorPicker from "../../../../components/ColorPicker";

const GiftOffer = (props) => {
  const workspace = useWorkspace();
  const [isProfitCalcOpened, setProfitCalcOpened] = useState(false);
  const dimensionOptions = [
    { label: "%", value: "percentage" },
    { label: `${workspace.data.storeCurrency}`, value: "fixedAmount" },
  ];
  const discountSharePageTypeOptions = [
    {
      value: "popup",
      label: "Show gift pop-up on home page",
    },
    { value: "page", label: "Send to a dedicated gift page" },
  ];
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  return (
    <>
      <div className="w-full laptop:flex desktop:py-3 desktop:px-8  py-0 px-4">
        <div className=" laptop:w-1/3">
          <CustomiserUnit
            title="Gift Discount Value"
            description="Value of the discount coupon that the friend gets when the referrer invites them to your store"
          >
            <div className="flex items-center ">
              <div className="w-20">
                <Textfield
                  value={props.values.gift_config.discountValue * -1}
                  onChange={(onChangeEvent) => {
                    if (onChangeEvent.target.value < 1) {
                      toaster.notify("The value must be greater than 0", {
                        duration: 2000,
                      });
                    } else if (
                      onChangeEvent.target.value > 100 &&
                      props.values.gift_config.discountType === "percentage"
                    ) {
                      toaster.notify("You cant give a dicount more than 100%", {
                        duration: 2000,
                      });
                    } else {
                      props.setFieldValue(
                        "gift_config.discountValue",
                        parseInt(onChangeEvent.target.value, 10) * -1
                      );
                    }
                  }}
                  min={1}
                  type="number"
                  name="basic"
                />
              </div>
              <Select
                isSearchable={false}
                value={dimensionOptions.find(
                  (option) =>
                    option.value === props.values.gift_config.discountType
                )}
                onChange={(onChangeEvent) => {
                  // The following condition makes sure the percentage doesnt exceeds 100 when switching from fixedAmount. If it exceded discount value is set to 20% by default
                  if (
                    props.values.gift_config.discountValue * -1 > 100 &&
                    onChangeEvent.value === "percentage"
                  ) {
                    props.setFieldValue("gift_config.discountValue", -20);
                  }
                  props.setFieldValue(
                    "gift_config.discountType",
                    onChangeEvent.value
                  );
                }}
                className="w-24 "
                options={dimensionOptions}
              />
            </div>
          </CustomiserUnit>

          <CustomiserUnit
            title="Minimum Requirements"
            description="Reward coupons created by this campaign will only be valid if these minimum requirements are met "
          >
            <TabRadioButton
              defaultValue={props.values.gift_config.minimumRequirementType}
              options={[
                { value: "none", label: "None" },
                { value: "amount", label: "Minimum Purchase Amount" },
                { value: "quantity", label: "Minimum quantity of items" },
              ]}
              onChange={(onChangeEvent) => {
                props.setFieldValue(
                  "gift_config.minimumRequirementType",
                  onChangeEvent
                );
              }}
            />
            {props.values.gift_config.minimumRequirementType !== "none" && (
              <div className="flex items-center mt-4">
                <div className="w-40">
                  <Textfield
                    value={props.values.gift_config.minimumRequirementValue}
                    onChange={(onChangeEvent) => {
                      if (onChangeEvent.target.value < 1) {
                        toaster.notify("The value must be greater than 0", {
                          duration: 2000,
                        });
                      } else {
                        props.setFieldValue(
                          "gift_config.minimumRequirementValue",
                          parseInt(onChangeEvent.target.value, 10)
                        );
                      }
                    }}
                    min={1}
                    type="number"
                    name="basic"
                  />
                </div>
                <div className="border-2 border-gray-200 bg-gray-200 rounded-r-md p-2 font-bold">
                  {props.values.gift_config.minimumRequirementType === "amount"
                    ? workspace.data.storeCurrency
                    : "Items"}
                </div>
              </div>
            )}
          </CustomiserUnit>

          <CustomiserUnit
            title="Gift URL Option"
            description="Select how the gift offer is shown to a visitor when they click the gift url shared by the referrer"
          >
            <Select
              isSearchable={false}
              value={
                props.values.gift_config.discountSharePageType
                  ? discountSharePageTypeOptions.find(
                      (option) =>
                        option.value ===
                        props.values.gift_config.discountSharePageType
                    )
                  : discountSharePageTypeOptions.find(
                      (option) => option.value === "page"
                    )
              }
              onChange={(e) =>
                props.setFieldValue(
                  "gift_config.discountSharePageType",
                  e.value
                )
              }
              className="max-w-sm mr-2"
              options={discountSharePageTypeOptions}
            />
          </CustomiserUnit>

          {props.values.postpurchase_config.giftPageColor && (
            <CustomiserUnit
              title="Share Page Theme Color"
              description="Theme color for the page/popup referrers share with their friends "
            >
              <div className="max-w-sm">
                <ColorPicker
                  color={props.values.postpurchase_config.giftPageColor}
                  onChange={(color) => {
                    props.setFieldValue(
                      "postpurchase_config.giftPageColor",
                      convertRGB(color.rgb)
                    );
                  }}
                />
              </div>
            </CustomiserUnit>
          )}
        </div>
        <div className="m-4 flex  justify-center laptop:w-2/3">
          <div>
            {isProfitCalcOpened ? (
              <div>
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setProfitCalcOpened(false);
                    }}
                    className="px-1 py-1 rounded-lg border border-gray-600 hover:border-violet-700 text-xs "
                  >
                    Back to FAQ
                  </button>
                </div>
                <ProfitCalculator {...props.values} selectedStage={1} />
              </div>
            ) : (
              <div>
                <div className="border rounded-lg py-1 px-3 max-w-3xl text-sm desktop:text-base ">
                  <div className="py-3 border-b">
                    <p className="font-bold">What is a Gift URL?</p>
                    <p className="text-gray-700 mt-2 ">
                      It is a URL to a discount code given to a customer on
                      thank you page. This customer is supposed to share this
                      URL with their friends.
                    </p>
                  </div>
                  <div className="py-3 border-b">
                    <p className="font-bold">
                      Can the customer itself get the discount code from this
                      URL?
                    </p>
                    <p className="text-gray-700 mt-2 ">
                      By default our app doesnt lets the customer to open the
                      URL from the same device they used to get this URL
                      (blocked using cookies)
                    </p>
                  </div>
                  <div className="py-3 border-bottom">
                    <p className="font-bold">
                      How much ROI can I expect from this campaign?
                    </p>
                    <button
                      onClick={() => {
                        setProfitCalcOpened(true);
                      }}
                      className="px-3 py-1 mt-2 rounded-lg border border-gray-600 hover:border-violet-700 "
                    >
                      Open Profit Calculator
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftOffer;
