import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import TabRadioButton from "../../TabRadioButton";

/**
 * This renders rule group
 * @component
 * @param {String} logicOperator - The current value
 * @param {Function} onChange - What happens on Change
 * @param {String} type - Deefine whether you are editing grop-level or top level logic operator
 */
const LogicSelector = ({ logicOperator, onChange, type }) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  const logicOptions = [
    {
      label: type === "groupLevel" ? "And" : "All",
      value: "and",
    },
    {
      label: type === "groupLevel" ? "Or" : "Any",
      value: "or",
    },
  ];
  return (
    <Popup
      isOpen={isEditPopupOpen}
      placement="top-start"
      onClose={() => {
        setIsEditPopupOpen(false);
      }}
      content={() => (
        <div className=" flex items-center">
          <TabRadioButton
            defaultValue={logicOperator}
            options={logicOptions}
            onChange={(selectedType) => {
              onChange(selectedType);
              setIsEditPopupOpen(false);
            }}
          />
        </div>
      )}
      trigger={(triggerProps) => {
        if (type === "groupLevel")
          return (
            <button
              {...triggerProps}
              onClick={() => setIsEditPopupOpen((prevState) => !prevState)}
              className={`p-2 cursor-pointer font-bold transition-all  ${
                isEditPopupOpen
                  ? "border text-violet-700 border-violet-700 shadow-lg bg-white"
                  : "bg-gray-100 hover:bg-white border border-x-gray-100 hover:border-violet-700 hover:shadow-lg hover:text-violet-700 "
              }`}
            >
              <div className="flex items-center text-tiny">{logicOperator}</div>
            </button>
          );
        else
          return (
            <button
              {...triggerProps}
              onClick={() => setIsEditPopupOpen((prevState) => !prevState)}
              className={`mx-1 font-bold transition-all px-3 rounded-full ${
                isEditPopupOpen
                  ? "bg-violet-700 text-white"
                  : "bg-violet-100 text-violet-700 hover:bg-violet-700 hover:text-white "
              }  `}
            >
              {logicOperator === "and" ? "All" : "Any"}
            </button>
          );
      }}
    />
  );
};

export default LogicSelector;
