import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import Button from "../../../../components/Button";
import TabRadioButton from "../../../../components/TabRadioButton";
import InlineTooltip from "../../../../components/InlineTooltip";
import Textfield from "@atlaskit/textfield";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import ProfitCalculator from "../../../offers/Referral/SimpleReferralEdit/ProfitCalculator";

export default function Reward({
  simpleRewardConfig,
  setSimpleRewardConfig,
  rewardType,
  setRewardType,
  maxDiscountValue,
  setMaxDiscountValue,
  giftConfig,
  goFront,
  goBack,
}) {
  const workspace = useWorkspace();
  let history = useHistory();
  const [isProfitCalcOpened, setProfitCalcOpened] = useState(false);

  const dimensionOptions = [
    { label: "%", value: "percentage" },
    { label: `${workspace.data.storeCurrency}`, value: "fixedAmount" },
  ];

  return (
    <div className="">
      <div className="max-w-5xl mb-8">
        <div className="flex ">
          <img
            className="grow-0"
            width="120px"
            src="https://assets.cornercart.io/coca-v2/images/onboard/ofco/onboard-4.png"
          />
        </div>
        <h1 className="text-5xl font-light text-gray-700">Rewards</h1>
        <p>
          The amount of cashback given to the customer as a reward for referring
          their friends. You can set this either as a percentage of the order
          value or a fixed amount.
        </p>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          reward_config: simpleRewardConfig,
          reward_type: rewardType,
          max_discount_value: maxDiscountValue,
        }}
        onSubmit={(values) => {
          setSimpleRewardConfig(values.reward_config);
          setRewardType(values.reward_type);
          setMaxDiscountValue(values.max_discount_value);
          goFront();
        }}
      >
        {(props) => {
          return (
            <>
              <div className="bg-white rounded-lg shadow-lg  w-full min-h-full p-4 laptop:flex items-center justify-between">
                <div className="w-1/3">
                  {/* <CustomiserUnit
                  title="Reward type"
                  description="Select the type of discount for the gift coupon"
                >
                  <TabRadioButton
                    defaultValue={props.values.reward_type}
                    options={[
                      { value: "cashback", label: "Cashback" },
                      { value: "coupon", label: "Coupon" },
                    ]}
                    onChange={(onChangeEvent) => {
                      // The following condition makes sure the percentage doesnt exceeds 100 when switching from fixedAmount. If it exceded discount value is set to 20% by default
                      if (
                        props.values.reward_config.rewardValue * -1 > 100 &&
                        onChangeEvent === "percentage"
                      ) {
                        props.setFieldValue("reward_config.rewardValue", -20);
                      }
                      props.setFieldValue("reward_type", onChangeEvent);
                    }}
                  />
                </CustomiserUnit> */}

                  <CustomiserUnit
                    title={
                      props.values.reward_type === "cashback" ? (
                        <span>
                          Reward{" "}
                          <InlineTooltip message="Processed as partial refunds">
                            Cashback
                          </InlineTooltip>{" "}
                          Amount{" "}
                        </span>
                      ) : (
                        "Reward Coupon Amounnt"
                      )
                    }
                    description={`For every successful referral the customer will be rewarded with a ${
                      props.values.reward_type === "cashback"
                        ? "cashback"
                        : "coupon"
                    } worth the following amount `}
                  >
                    <div className="flex items-center ">
                      <div className="w-20">
                        <Textfield
                          value={props.values.reward_config.rewardValue * -1}
                          onChange={(onChangeEvent) => {
                            if (onChangeEvent.target.value < 1) {
                              toaster.notify(
                                "The value must be greater than 0",
                                {
                                  duration: 2000,
                                }
                              );
                            } else if (
                              onChangeEvent.target.value > 100 &&
                              props.values.reward_config.discountType ===
                                "percentage"
                            ) {
                              toaster.notify(
                                "You cant give a dicount more than 100%",
                                {
                                  duration: 2000,
                                }
                              );
                            } else {
                              props.setFieldValue(
                                "reward_config.rewardValue",
                                parseInt(onChangeEvent.target.value, 10) * -1
                              );
                            }
                          }}
                          min={1}
                          type="number"
                          name="basic"
                        />
                      </div>
                      {}
                      <Select
                        isSearchable={false}
                        value={dimensionOptions.find(
                          (option) =>
                            option.value ===
                            props.values.reward_config.rewardDimension
                        )}
                        onChange={(onChangeEvent) => {
                          // The following condition makes sure the percentage doesnt exceeds 100 when switching from fixedAmount. If it exceded discount value is set to 20% by default
                          if (
                            props.values.reward_config.rewardValue * -1 > 100 &&
                            onChangeEvent.value === "percentage"
                          ) {
                            props.setFieldValue(
                              "reward_config.rewardValue",
                              -20
                            );
                          }
                          props.setFieldValue(
                            "reward_config.rewardDimension",
                            onChangeEvent.value
                          );
                        }}
                        className="w-24 "
                        options={dimensionOptions}
                      />
                    </div>
                  </CustomiserUnit>
                  {props.values.reward_type === "cashback" && (
                    <CustomiserUnit
                      title={
                        <span>
                          Maximum Allowed{" "}
                          <InlineTooltip message="Processed as partial refunds">
                            Cashback
                          </InlineTooltip>{" "}
                        </span>
                      }
                      description="Set a maximum cashback allowed for a customer"
                    >
                      {/* If user chooses unlimited we just set the max_discount_value to a very high number */}
                      <TabRadioButton
                        defaultValue={
                          props.values.max_discount_value === 999999999
                            ? "unlimited"
                            : "custom"
                        }
                        options={[
                          { value: "unlimited", label: "Total order value" },
                          { value: "custom", label: "Custom value" },
                        ]}
                        onChange={(onChangeEvent) => {
                          if (onChangeEvent === "unlimited")
                            props.setFieldValue(
                              "max_discount_value",
                              999999999
                            );
                          else
                            props.setFieldValue(
                              "max_discount_value",
                              props.values.min_order_value + 200
                            );
                        }}
                      />
                      {props.values.max_discount_value !== 999999999 && (
                        <div className="flex items-center mt-5">
                          <div className="w-32 flex-shrink-0">
                            <Textfield
                              value={props.values.max_discount_value}
                              onChange={(onChangeEvent) => {
                                if (onChangeEvent.target.value < 1) {
                                  toaster.notify(
                                    "The value must be greater than 0",
                                    {
                                      duration: 2000,
                                    }
                                  );
                                } else if (
                                  onChangeEvent.target.value > 100 &&
                                  props.values.reward_config.discountType ===
                                    "percentage"
                                ) {
                                  toaster.notify(
                                    "You cant give a dicount more than 100%",
                                    {
                                      duration: 2000,
                                    }
                                  );
                                } else {
                                  props.setFieldValue(
                                    "max_discount_value",
                                    parseInt(onChangeEvent.target.value, 10)
                                  );
                                }
                              }}
                              min={1}
                              type="number"
                              name="basic"
                            />
                          </div>
                          <div className="border-2 border-gray-100 bg-gray-100 rounded-r-md p-2 font-bold text-gray-500">
                            {workspace.data.storeCurrency}
                          </div>
                          <div className="ml-3 py-1 px-3 rounded-lg border flex bg-gray-100 text-gray-500 items-center">
                            <p className="text-sm">
                              If custom limit is more than order value, cashback
                              is paid out only upto the order value
                            </p>
                          </div>
                        </div>
                      )}
                    </CustomiserUnit>
                  )}
                  {props.values.reward_type === "coupon" && (
                    <CustomiserUnit
                      title="Minimum Requirements"
                      description="Reward coupons created by this campaign will only be valid if these minimum requirements are met "
                    >
                      <TabRadioButton
                        defaultValue={
                          props.values.reward_config.minimumRequirementType
                        }
                        options={[
                          { value: "none", label: "None" },
                          { value: "amount", label: "Minimum Purchase Amount" },
                          {
                            value: "quantity",
                            label: "Minimum quantity of items",
                          },
                        ]}
                        onChange={(onChangeEvent) => {
                          props.values.reward_stage.map((value, index) => {
                            props.setFieldValue(
                              `reward_stage[${index}].minimumRequirementType`,
                              onChangeEvent
                            );
                          });
                        }}
                      />
                      {props.values.reward_config.minimumRequirementType !==
                        "none" && (
                        <div className="flex items-center mt-4">
                          <div className="w-40">
                            <Textfield
                              value={
                                props.values.reward_config
                                  .minimumRequirementValue
                              }
                              onChange={(onChangeEvent) => {
                                if (onChangeEvent.target.value < 1) {
                                  toaster.notify(
                                    "The value must be greater than 0",
                                    {
                                      duration: 2000,
                                    }
                                  );
                                } else {
                                  props.values.reward_stage.map(
                                    (value, index) => {
                                      props.setFieldValue(
                                        `reward_stage[${index}].minimumRequirementValue`,
                                        parseInt(onChangeEvent.target.value, 10)
                                      );
                                    }
                                  );
                                }
                              }}
                              min={1}
                              type="number"
                              name="basic"
                            />
                          </div>
                          <div className="border-2 border-gray-200 bg-gray-200 rounded-r-md p-2 font-bold">
                            {props.values.reward_config
                              .minimumRequirementType === "amount"
                              ? workspace.data.storeCurrency
                              : "Items"}
                          </div>
                        </div>
                      )}
                    </CustomiserUnit>
                  )}
                </div>
                <div className="ml-8">
                  <div className="max-h-72 overflow-y-auto">
                    {isProfitCalcOpened ? (
                      <div>
                        <div className="flex justify-end">
                          <button
                            onClick={() => {
                              setProfitCalcOpened(false);
                            }}
                            className="px-1 py-1 rounded-lg border border-gray-600 hover:border-violet-700 text-xs "
                          >
                            Back to FAQ
                          </button>
                        </div>
                        <ProfitCalculator
                          reward_config={props.values.reward_config}
                          min_order_value={0}
                          reward_type={props.values.reward_type}
                          gift_config={giftConfig}
                          max_discount_value={maxDiscountValue}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="border rounded-lg py-1 px-3 max-w-3xl text-sm desktop:text-base ">
                          <div className="py-3 border-b">
                            <p className="font-bold">What is a cashback?</p>
                            <p className="text-gray-700 mt-2 ">
                              A cashback is a partial refund paid out to the
                              customer on their current order.
                            </p>
                          </div>
                          <div className="py-3 border-b">
                            <p className="font-bold">
                              How does a referrer gets a cashback reward?
                            </p>
                            <p className="text-gray-700 mt-2 ">
                              When a customer refers their friends, a cashback
                              request is created in the <b>Requests</b> section
                              of your Corner dashboard. Once the merchant
                              approves them, the amount is paid out.
                            </p>
                          </div>
                          <div className="py-3 border-b">
                            <p className="font-bold">
                              How will I know if a referral is genuine?
                            </p>
                            <p className="text-gray-700 mt-2 ">
                              On each cashback request we calculate a trust
                              score to evaluvate the genuinity of each order.
                              Click on the trust score to view more details.
                            </p>
                          </div>
                          <div className="py-3 border-b">
                            <p className="font-bold">
                              How is cashback paid out?
                            </p>
                            <p className="text-gray-700 mt-2 ">
                              Since cashbacks are essentially a partial refund,
                              they are paid out to the original payment method
                              that customer used to make the order
                            </p>
                          </div>
                          <div className="py-3 border-bottom">
                            <p className="font-bold">
                              How much ROI can I expect from this campaign?
                            </p>
                            <button
                              onClick={() => {
                                setProfitCalcOpened(true);
                              }}
                              className="px-3 py-1 mt-2 rounded-lg border border-gray-600 hover:border-violet-700 "
                            >
                              Open Profit Calculator
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center mt-12">
                <Button
                  className="mr-8"
                  type="link"
                  onClick={() => {
                    goBack();
                  }}
                >
                  Go back
                </Button>
                <button
                  onClick={() => props.submitForm()}
                  className="text-center py-6 px-24 bg-violet-700 text-white font-bold rounded-lg hover:bg-gray-700 transition-all"
                >
                  Continue
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
