import { langOptions } from "./variables";

/**
 * This functions returns the label of a given language value
 * @param {String} langKey language key eg:- "en"
 * @returns {String} return the language label eg:- "English"
 */
const getLanguageLabel = (langKey) => {
  const currentLang = langOptions.filter(
    (language) => language.value === langKey
  );
  return currentLang[0].label;
};

export default getLanguageLabel;
