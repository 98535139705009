import React from "react";
import { RiDeleteBinFill, RiAddCircleLine } from "react-icons/ri";
import ShopifyResourcePicker from "./ShopifyResourcePicker";

/**
 * This component renders the product details as a list
 * @type {function({initialValues,btnText,onDelete,onAdd,className}): JSX}
 * @param {[Object]} initialValues array of products / collections
 * @param {Function} onDelete this funtion passes delted index and deleted items to the callback fn
 * @param {Function} onAdd  this funtion passes selected product
 * @param {Object} className extra classes to be added if needed
 */
const MultiResourceSelector = ({
  initialValues,
  btnText,
  onDelete,
  className,
  onAdd,
  emptyWarning,
  resourceType = "product",
  CustomInitialSelectBtn,
}) => {
  const getProductImage = (initialValues) => {
    if (initialValues.images && initialValues.images[0]) {
      return initialValues.images[0].originalSrc;
    }
  };

  return (
    <div
      className={`${
        initialValues.length > 0 && "border rounded-lg"
      } ${className}`}
    >
      {initialValues.map((item, index) => (
        <div
          key={`${item.id}-${index}`}
          className={`flex flex-row justify-between items-center p-4 border-b`}
        >
          <div className="flex flex-row items-center">
            <img
              alt=""
              src={getProductImage(item)}
              className="w-8 h-8 rounded-md"
            />
            <div className="ml-4">
              <p className="text-tiny desktop:text-base font-bold">
                {item.title}
              </p>
            </div>
          </div>
          {onDelete && (
            <button
              className="text-gray-400 hover:text-red-600 hover:border-red-600 transition-all text-base mr-4 border rounded-full p-2"
              onClick={() => {
                onDelete(index, item);
              }}
            >
              <RiDeleteBinFill />
            </button>
          )}
        </div>
      ))}
      <ShopifyResourcePicker
        selectMultiple={false}
        resoucePickerType={resourceType}
        trigger={(triggerProps) =>
          CustomInitialSelectBtn && initialValues.length === 0 ? (
            <>
              <CustomInitialSelectBtn {...triggerProps} />
              <span className="flex mt-2 shrink-0 text-xs text-red-700 text-left">
                {emptyWarning}
              </span>
            </>
          ) : (
            <button
              {...triggerProps}
              className="p-4 flex flex-wrap items-center justify-center w-full font-bold transition-all text-violet-700 hover:bg-violet-50"
            >
              <RiAddCircleLine />
              <span className="ml-2">{btnText}</span>
              {emptyWarning && initialValues.length === 0 && (
                <span className="block shrink-0 w-full text-xs text-red-700">
                  {emptyWarning}
                </span>
              )}
            </button>
          )
        }
        onChange={onAdd}
      />
    </div>
  );
};

export default MultiResourceSelector;
