import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";

import {
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiPencilFill,
  RiListSettingsFill,
  RiTimerFill,
  RiTimerFlashFill,
  RiSettings3Fill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  queryCampaignCartTimerById,
  updateCartTimerCampaignDetails,
} from "../../../../api/campaign";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import toaster from "toasted-notes";
import TimerSettings from "./TimerSettings";
import BeforeTimerExpires from "./BeforeTimerExpires";
import AfterTimerExpires from "./AfterTimerExpires";
import OtherSettings from "./OtherSettings";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import useCompleteDoptFlow from "../../../../utilities/useCompleteDoptFlow";
import useClearCache from "../../../../utilities/useClearCache";

export default function CartTimer() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();
  const { finishFlow } = useCompleteDoptFlow();

  const [currentTab, setCurrentTab] = useState("timer settings");
  const { data, refetch } = useQuery(
    "queryCampaignCartTimerById",
    () => graphQL(queryCampaignCartTimerById, { id: params.id }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateCartTimerCampaign = async (values) => {
    await graphQL(updateCartTimerCampaignDetails, {
      campaignId: values.campaignId,
      campaignInput: values.campaignDetails,
      cartTimerId: values.cartTimerId,
      cartTimerInput: values.cartTimerInput,
    });
    refetch();
  };

  const [updateCartTimerCampaignMutation] = useMutation(
    updateCartTimerCampaign,
    {
      onSuccess: () => {
        clearCache();
        toaster.notify("Cart Timer updated 🎉", {
          duration: 2000,
        });
      },
    }
  );

  const visibilityOptions = [
    {
      label: (
        <div className="flex items-center">
          <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused
        </div>
      ),
      value: "draft",
    },
  ];

  const tabItems = [
    {
      label: "Timer Settings",
      id: "timer settings",
      icon: <RiListSettingsFill />,
    },
    {
      label: "Before Timer Expires",
      id: "before timer expires",
      icon: <RiTimerFill />,
    },
    {
      label: "After Timer Expires",
      id: "after timer expires",
      icon: <RiTimerFlashFill />,
    },

    {
      label: "Other Settings",
      id: "other settings",
      icon: <RiSettings3Fill />,
    },
  ];

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "timer settings":
        return <TimerSettings values={values} setFieldValue={setFieldValue} />;
      case "before timer expires":
        return (
          <BeforeTimerExpires values={values} setFieldValue={setFieldValue} />
        );
      case "after timer expires":
        return (
          <AfterTimerExpires values={values} setFieldValue={setFieldValue} />
        );
      case "other settings":
        return <OtherSettings values={values} setFieldValue={setFieldValue} />;
    }
  };
  if (data && data.campaign_by_pk)
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data.campaign_by_pk}
          onSubmit={async (values) => {
            const { audience, settings, status } = values;
            updateCartTimerCampaignMutation({
              campaignId: { id: values.id },
              campaignDetails: {
                audience: audience,
                settings: settings,
                status: status,
              },
              cartTimerId: {
                id: values.campaign_campaign_cart_timers[0].id,
              },
              cartTimerInput: {
                content: values.campaign_campaign_cart_timers[0].content,
                config: values.campaign_campaign_cart_timers[0].config,
              },
            });
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <PageViewContainer
                title={
                  <InlineEdit
                    defaultValue={values.settings.title}
                    editView={({ ...fieldProps }) => (
                      <Textfield {...fieldProps} name="basic" />
                    )}
                    readView={() => (
                      <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                        <b>{values.settings.title}</b>
                        <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                          <RiEdit2Fill
                            size={15}
                            className="mr-3 text-gray-500"
                          />
                          Edit name
                        </div>
                      </h2>
                    )}
                    onConfirm={(e) => {
                      setFieldValue("settings.title", e);
                    }}
                  />
                }
                queryToBeInvalidated="queryCampaignCartTimerById"
                action={
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <div className="flex items-center">
                      <span className="font-bold text-gray-700 mr-3">
                        Campaign Status:
                      </span>
                      <TabRadioButton
                        btnId="cart-annouce-message-save"
                        defaultValue={values.status}
                        options={visibilityOptions}
                        onChange={(selectedType) => {
                          setFieldValue("status", selectedType);
                        }}
                      />
                    </div>
                  )
                }
              >
                <HorizontalTabs
                  items={tabItems}
                  showSeperatorIcons
                  onChange={(selectedTab) => {
                    setCurrentTab(selectedTab);
                  }}
                />
                {dirty && (
                  <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          finishFlow();
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPaywall />
                )}
                {!dirty &&
                  values.status === "draft" &&
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <CampaignPauseWarning />
                  )}

                {renderCurrentTab(values, setFieldValue)}
              </PageViewContainer>
            );
          }}
        </Formik>
      </>
    );
  else return <LoadingSpinner />;
}
