import React from "react";
import { RiDeleteBinFill } from "react-icons/ri";

/**
 * This component renders the product details as row
 * @type {function({product,onDelete, className}): JSX}
 * @param {Object} product product
 * @param {Function} onDelete function
 * @param {Object} className extra styles to be added if needed
 */
const VariantDetails = ({ title, onDelete, className }) => {
  return (
    <div
      className={`flex flex-row justify-between border rounded-lg items-center p-4 ${className}`}
    >
      <div className="flex flex-row items-center">
        <div className="text-tiny desktop:text-base ">
          <p className="font-bold text-gray-600 text-xs">
            Selected Variant 
          </p>
          <p className="">
            {title}
          </p>
        </div>
      </div>
      <button
        className="text-red-600 text-xl mr-4"
        onClick={() => {
          if (onDelete) {
            onDelete();
          }
        }}
      >
        <RiDeleteBinFill />
      </button>
    </div>
  );
};

export default VariantDetails;
