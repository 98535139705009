import { Crisp } from "crisp-sdk-web";

const crispInitialiser = () => {
  const WEBSITE_ID = "78d9ab83-696e-4ed8-aee0-665704e5dc8e";

  Crisp.configure(WEBSITE_ID, {
    sessionMerge: false,
    safeMode: true,
  });
};

export default crispInitialiser;
