import { Crisp } from "crisp-sdk-web";
/**
 * This function sends a message to crisp support chat on behalf of user
 * when api fails and details of the error will be available in rollbar
 * with the current error ref id
 * @param {String} errorKeyRef error key reference id
 * @param {String} crispMessage message to be sent to the crisp support
 */
const contactCrisponApiError = (errorKeyRef, crispMessage) => {
  let $crisp = window.$crisp;
  // returns true is crisp is injected else returns false
  if (Crisp.isCrispInjected()) {
    // managing the edge case when $crisp is array
    // sometimes $crisp wont have crisp methods available
    // so we will fallback to $__CRISP_INSTANCE
    if (Array.isArray($crisp)) {
      if (window.$__CRISP_INSTANCE) {
        $crisp = window.$__CRISP_INSTANCE;
        $crisp.push([
          "set",
          "session:data",
          [[["API_ERROR_KEY", `${errorKeyRef}`]]],
        ]);
        $crisp.push(["do", "message:send", ["text", crispMessage]]);
        $crisp.push(["do", "chat:open"]);
      } else {
        window.Rollbar.error("Crisp fallback method failed", {
          API_ERROR_KEY: errorKeyRef,
          API_ERROR_MSG: crispMessage,
        });
      }
    } else {
      Crisp.session.setData({
        API_ERROR_KEY: errorKeyRef,
      });
      Crisp.message.send("text", crispMessage);
      Crisp.chat.open();
    }
  } else {
    window.Rollbar.error("Crisp is not injected, so contact support failed", {
      API_ERROR_KEY: errorKeyRef,
      API_ERROR_MSG: crispMessage,
    });
  }
};

export default contactCrisponApiError;
