import React, { useState } from "react";
import TextArea from "@atlaskit/textarea";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../../components/TabRadioButton";
import ColorPicker from "../../../../../components/ColorPicker";
import Textfield from "@atlaskit/textfield";
import EmailPreview from "./EmailPreview";

const EmailSettings = (props) => {
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  const [emailType, setEmailType] = useState("giftEmailConfig");

  return (
    <div>
      <div className="w-full flex">
        <div className="">
          <div className="flex justify-center border-b ">
            <button
              onClick={() => setEmailType("giftEmailConfig")}
              className={`flex-1 text-center py-4 focus:outline-none ${
                emailType === "giftEmailConfig" &&
                "border-b-2 border-violet-700 text-violet-700 font-bold"
              } `}
            >
              Gift Email
            </button>
            {props.values.reward_type === "coupon" && (
              <button
                onClick={() => setEmailType("rewardEmailConfig")}
                className={`flex-1 text-center py-4 focus:outline-none ${
                  emailType === "rewardEmailConfig" &&
                  "border-b-2 border-violet-700 text-violet-700 font-bold"
                } `}
              >
                Reward Email
              </button>
            )}
          </div>
          {emailType === "rewardEmailConfig" ? (
            <>
              <div
                key="1"
                className="max-w-xl py-4 border-b py-5 desktop:px-8 px-4"
              >
                <h3 className="font-bold desktop:mb-3 laptop:mb-1">
                  Reward Emails
                </h3>
                <p>
                  A reward email is sent to a referrer once a reward target is
                  unlocked. This email will contain the reward coupon which he
                  can redeem.
                </p>
              </div>
              <div className=" desktop:py-3 desktop:px-8 px-4">
                <CustomiserUnit
                  key="emailSubject"
                  title="Email Subject"
                  description="Subject line for the email"
                >
                  <TextArea
                    value={props.values.email_config.rewardEmailConfig.subject}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.subject",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Theme color"
                  description="Select the theme color of the email"
                >
                  <ColorPicker
                    color={
                      props.values.email_config.rewardEmailConfig.themeColor
                    }
                    onChange={(color) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.themeColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Foreground color"
                  description="Select the foreground color"
                >
                  <TabRadioButton
                    defaultValue={
                      props.values.email_config.rewardEmailConfig.fgColor
                    }
                    options={[
                      { value: "white", label: "Light" },
                      { value: "black", label: "Dark" },
                    ]}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.fgColor",
                        e
                      );
                    }}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Logo URL"
                  description="Image URL of your logo. This image will be showcased above the email"
                >
                  <Textfield
                    value={props.values.email_config.rewardEmailConfig.logoUrl}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.logoUrl",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                {/* <CustomiserUnit title="Shop Name">
                  <Textfield
                    value={
                      props.values.email_config.rewardEmailConfig.shopName
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.shopName",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit> */}

                <CustomiserUnit title="Title">
                  <Textfield
                    value={
                      props.values.email_config.rewardEmailConfig.primaryTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.primaryTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Greeting">
                  <Textfield
                    value={props.values.email_config.rewardEmailConfig.greeting}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.greeting",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Main Content">
                  <TextArea
                    value={props.values.email_config.rewardEmailConfig.content}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.content",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={7}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Coupon code label"
                  description="This text will be shown above the coupon code followed by the coupon value"
                >
                  <Textfield
                    value={
                      props.values.email_config.rewardEmailConfig.couponTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.couponTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="CTA Button Text"
                  description="This button will take the user to your website (with coupon applied)"
                >
                  <Textfield
                    value={props.values.email_config.rewardEmailConfig.cta}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.cta",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Text Below CTA Button">
                  <TextArea
                    value={props.values.email_config.rewardEmailConfig.subCta}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.rewardEmailConfig.subCta",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>
              </div>
            </>
          ) : (
            <>
              <div
                key="2"
                className="max-w-xl py-4 border-b py-5 desktop:px-8 px-4 "
              >
                <h3 className="font-bold mb-3">Gift Emails</h3>
                <p>
                  A gift email is sent to a referrer once they signs up to a
                  referral program. This email will contain the gift
                  coupon which they are supposed to share with their friends
                </p>
              </div>
              <div className=" desktop:py-3 desktop:px-8 px-4">
                <CustomiserUnit
                  key="giftEmailConfigSubject"
                  title="Email Subject"
                  description="Subject line for the email"
                >
                  <TextArea
                    value={props.values.email_config.giftEmailConfig.subject}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.subject",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Theme color"
                  description="Select the theme color of the email"
                >
                  <ColorPicker
                    color={props.values.email_config.giftEmailConfig.themeColor}
                    onChange={(color) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.themeColor",
                        convertRGB(color.rgb)
                      );
                    }}
                  />
                </CustomiserUnit>
                <CustomiserUnit
                  title="Foreground color"
                  description="Select the foreground color"
                >
                  <TabRadioButton
                    defaultValue={
                      props.values.email_config.giftEmailConfig.fgColor
                    }
                    options={[
                      { value: "white", label: "Light" },
                      { value: "black", label: "Dark" },
                    ]}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.fgColor",
                        e
                      );
                    }}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Logo URL"
                  description="Image URL of your logo. This image will be showcased above the email"
                >
                  <Textfield
                    value={props.values.email_config.giftEmailConfig.logoUrl}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.logoUrl",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Shop Name">
                  <Textfield
                    value={props.values.email_config.giftEmailConfig.shopName}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.shopName",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Title">
                  <Textfield
                    value={
                      props.values.email_config.giftEmailConfig.primaryTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.primaryTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Greeting">
                  <Textfield
                    value={props.values.email_config.giftEmailConfig.greeting}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.greeting",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                <CustomiserUnit title="Main Content">
                  <TextArea
                    value={props.values.email_config.giftEmailConfig.content}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.content",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={7}
                  />
                </CustomiserUnit>

                <CustomiserUnit
                  title="Coupon code label"
                  description="This text will be shown above the coupon code followed by the coupon value"
                >
                  <Textfield
                    value={
                      props.values.email_config.giftEmailConfig.couponTitle
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.couponTitle",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit>

                {/* <CustomiserUnit
                  title="CTA Button Text"
                  description="This button will take the user to your website (with coupon applied)"
                >
                  <Textfield
                    value={
                      props.values.email_config.giftEmailConfig.cta
                    }
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.cta",
                        e.target.value
                      );
                    }}
                    name="basic"
                  />
                </CustomiserUnit> */}

                <CustomiserUnit title="Text Below CTA Button">
                  <TextArea
                    value={props.values.email_config.giftEmailConfig.subCta}
                    onChange={(e) => {
                      props.setFieldValue(
                        "email_config.giftEmailConfig.subCta",
                        e.target.value
                      );
                    }}
                    name="basic"
                    minimumRows={1}
                  />
                </CustomiserUnit>
              </div>
            </>
          )}
        </div>
        <div className="flex relative justify-center grow bg-gray-100 items-start">
          {emailType === "rewardEmailConfig" ? (
            <EmailPreview
              themeColor={
                props.values.email_config.rewardEmailConfig.themeColor
              }
              primaryTitle={
                props.values.email_config.rewardEmailConfig.primaryTitle
              }
              greeting={props.values.email_config.rewardEmailConfig.greeting}
              content={props.values.email_config.rewardEmailConfig.content}
              couponTitle={
                props.values.email_config.rewardEmailConfig.couponTitle
              }
              // shopName={props.values.email_config.rewardEmailConfig.shopName}
              cta={props.values.email_config.rewardEmailConfig.cta}
              subCta={props.values.email_config.rewardEmailConfig.subCta}
              logoUrl={props.values.email_config.rewardEmailConfig.logoUrl}
              fgColor={props.values.email_config.rewardEmailConfig.fgColor}
              subject={props.values.email_config.rewardEmailConfig.subject}
            />
          ) : (
            <EmailPreview
              themeColor={props.values.email_config.giftEmailConfig.themeColor}
              primaryTitle={
                props.values.email_config.giftEmailConfig.primaryTitle
              }
              greeting={props.values.email_config.giftEmailConfig.greeting}
              content={props.values.email_config.giftEmailConfig.content}
              couponTitle={
                props.values.email_config.giftEmailConfig.couponTitle
              }
              shopName={props.values.email_config.giftEmailConfig.shopName}
              // cta={props.values.email_config.giftEmailConfig.cta}
              subCta={props.values.email_config.giftEmailConfig.subCta}
              logoUrl={props.values.email_config.giftEmailConfig.logoUrl}
              fgColor={props.values.email_config.giftEmailConfig.fgColor}
              subject={props.values.email_config.giftEmailConfig.subject}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
