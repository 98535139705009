import React, { useState } from "react";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import Button from "../../../components/Button";
import FAQAccordion from "../../../components/FAQAccordion";
import { useBlock } from "@dopt/react";
import { useFlow } from "@dopt/react";
import { useHistory } from "react-router-dom";
import useForceUpdate from "../../../utilities/useForceUpdate";
import OnboardingStep from "./OnboardingStep";
import OnboardingOptionalStep from "./OnboardingOptionalStep";

export const Home = () => {
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  const [stickyBarFlow] = useFlow("sticky-add-to-cart-bar");
  const [stickyBarFlowStart, stickyBarFlowStartTransition] = useBlock(
    "sticky-add-to-cart-bar.onboarding-cta"
  );
  const [connectFlow] = useFlow("connect-with-team");
  const [connectFlowStep, connectFlowStepTransition] = useBlock(
    "connect-with-team.connect"
  );
  const [widgetFlow] = useFlow("turn-on-app-widget");
  const [widgetFlowStep, widgetFlowStepTransition] = useBlock(
    "turn-on-app-widget.home-page-cta"
  );

  const [cartDrawerFlow, cartDrawerFlowIntent] = useFlow(
    "cart-drawer-settings"
  );
  const [cartDrawerStep, cartDrawerStepTransition] = useBlock(
    "cart-drawer-settings.onboarding-cart-cta"
  );
  const [createCampaignFlow] = useFlow("create-campaign-flow");

  const handleCreateNewCampaign = () => {
    history.push(`/cart/cro/campaigns/create`);
  };

  const handleEnableStickyAtc = () => {
    if (stickyBarFlowStartTransition) {
      stickyBarFlowStartTransition("next");
    }
    history.push(`/cart/widgets/atc-bar`);
  };

  const handleConnectWithTeam = () => {
    connectFlowStepTransition("complete");
    if (window.$crisp) {
      $crisp.push(["do", "chat:open"]);
    }
  };

  const handleWidgetCheckCTA = () => {
    widgetFlowStepTransition("complete");
    forceUpdate();
  };

  const handleCartDrawerFlow = () => {
    if (cartDrawerStepTransition) cartDrawerStepTransition("next");
    history.push(`/cart/widgets/corner-cart`);
  };

  return (
    <PageViewContainer title="Home">
      <div className="overflow-y-auto">
        {/* ALert Banner */}
        {/* <div className="bg-red-200 m-8 p-4 rounded-lg">
          <h2 className="font-bold text-red-700">ALERT</h2>
          <div>
            <p>
              Shopify is currently facing an ongoing issue with checkouts
              affecting stores across Shopify. Shopify team is currently
              investigating this to resolve it ASAP. In the meantime, please
              keep an eye on Shopify status page here for updates
            </p>
            <Button
              className={"mt-4"}
              type="danger"
              onClick={() => {
                window.open("https://www.shopifystatus.com/", "_blank");
              }}
            >
              Go to Shopify Status page
            </Button>
          </div>
        </div> */}
        {/* getting started */}
        <div className="p-6">
          <div className="flex flex-col mb-6">
            <p className="text-lg font-bold mb-4">Getting Started</p>
            <div className="flex flex-row gap-3">
              <OnboardingStep
                stepNo="1"
                title="Enable the App"
                description="You can enable or disable the app inside theme customization"
                imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/cacebd52-7d42-4103-5c09-ff3b3a917d00/w=300"
                ctaButton={
                  <Button
                    type="outline"
                    className="w-full"
                    onClick={() => handleWidgetCheckCTA()}
                  >
                    Mark this as done
                  </Button>
                }
                isCompleted={widgetFlow.state.finished}
                videoList={[
                  {
                    id: "publish",
                    URL: "https://www.youtube.com/embed/ZqcClHW3Yiw",
                    btnText: "How to enable it on a live store",
                    modalTitle: "How to Enable Cart Widget on a live theme",
                  },
                  {
                    id: "duplicate",
                    URL: "https://www.youtube.com/embed/6RTnNv6DGCw?autoplay=1&mute=1",
                    btnText: "How to enable it on a test theme",
                    modalTitle: "How to Enable Cart Widget on a test theme",
                  },
                ]}
              />
              <OnboardingStep
                stepNo="2"
                imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/5f97fb46-94b3-4f1c-3b89-cae9ebfd6a00/w=300"
                title="Customize the Widget"
                description="You can enable or disable the app inside theme customization"
                ctaButton={
                  <Button
                    type="outline"
                    className="w-full"
                    onClick={() => handleCartDrawerFlow()}
                  >
                    Customize Now
                  </Button>
                }
                isCompleted={cartDrawerFlow.state.finished}
              />
              <OnboardingStep
                stepNo="3"
                imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/18a0627d-a2dd-4dc6-412c-c73839f65b00/w=300"
                title="Create a Campaign"
                description="Campaigns helps you increase your average order value. Click on the button Below to explore various campaigns we have to offer"
                ctaButton={
                  <Button
                    type="outline"
                    className="w-full"
                    onClick={() => handleCreateNewCampaign()}
                  >
                    Create a Campaign
                  </Button>
                }
                isCompleted={createCampaignFlow.state.finished}
              />
            </div>
          </div>
          {stickyBarFlow.state.finished === false && (
            <OnboardingOptionalStep
              ctaBtnText="Enable Sticky Bar"
              title="Enable Sticky Add to Cart Bar"
              description="Add a sticky add to cart bar to the product pages in your store"
              imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/cb5b2fa8-a7c0-4c2f-86c8-e0a34f556000/w=200"
              imgAltText="sticky bar"
              handleCTA={() => handleEnableStickyAtc()}
            />
          )}
          <OnboardingOptionalStep
            ctaBtnText="Connect with our Team"
            title="Connect with Us"
            description="Please say hi to the team! We'll be glad to help you set up or answer any questions"
            imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/78a06228-cf10-4264-4517-0d6fc733b400/w=200"
            imgAltText="team connect"
            handleCTA={() => handleConnectWithTeam()}
          />
        </div>

        {/* faq section */}
        {/* <div className="p-6 border-b border-solid border-gray-200">
          <div className="flex flex-col">
            <p className="text-lg font-bold">Frequently Asked Questions </p>
            <p className="text-base mt-2">
              Few Questions you might have setting up our app
            </p>
          </div>
          <div className="mt-6">
            <FAQAccordion
              title="How to test my cart without turning it on in a live store"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
            />
            <FAQAccordion
              title="How to test my cart without turning it on in a live store"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
            />
          </div>
        </div> */}
      </div>
    </PageViewContainer>
  );
};
