import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import PersonalInfo from "./PersonalInfo";
import FinalStep from "./FinalStep";
import useInitialCurrencyManager from "../../../utilities/initialCurrencyManager";
import WidgetSetup from "./WidgetSetup";

export default function CocaOnboarding() {
  let { path } = useRouteMatch();
  useInitialCurrencyManager();

  return (
    <div className="flex flex-wrap bg-gray-100 h-screen items-center justify-center">
      <Switch>
        <Route exact path={`${path}/`}>
          <Redirect to={`${path}/1/`} />
        </Route>
        <Route exact path={`${path}/1/`} component={LanguageSelector} />
        <Route exact path={`${path}/2/`} component={PersonalInfo} />
        <Route exact path={`${path}/3/`} component={WidgetSetup} />
        <Route exact path={`${path}/4/:type`} component={FinalStep} />
      </Switch>
    </div>
  );
}
