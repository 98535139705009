import React, { useState } from "react";
import CategoryHeader from "./CategoryHeader";
import DesktopCategoryNav from "./DesktopCategoryNav";
import SectionNav from "./SectionNav";
import Section from "./Section";
import MobileSideBar from "./MobileSideBar";
import ReviewAsker from "./ReviewAsker";

const Layout = (props) => {
  return (
    <>
      {/* <div className="grid bg-gradient-to-b from-white to-bg-gradient w-full h-screen grid-rows-shrinked-primary-row desktop:grid-rows-primary-row  mobile:grid-cols-primary-mob-col tab:grid-cols-primary-desk-col ">
        <HeaderIcon sidebarTrigger={() => setMobileSideBarVisibility(true)} />
        <CategoryHeader />
        <DesktopCategoryNav /> */}
      <div className="h-screen">
        <Section navigationBar={<SectionNav />} pageView={props.children} />
      </div>
      {/* </div> */}
      

      {/* <ReviewAsker/> */}
    </>
  );
};

export default Layout;
