import React from "react";
import {
  RiShoppingCart2Fill,
  RiGiftFill,
  RiCornerRightDownFill,
  RiCornerLeftUpFill,
} from "react-icons/ri";
import Button from "../../../../../components/Button";

/**
 * cart goal example
 */
const bXgYExample = () => {
  return (
    <div className=" t w-4/5 justify-center">
      <div className="flex items-center">
        <div className="flex  juistify-center text-sm p-2 items-center bg-white shadow-lg rounded-lg mr-3">
          <div className="p-2 mr-2 rounded-lg text-violet-700 bg-violet-100">
            <RiShoppingCart2Fill size={16} />
          </div>
          <p>Buys Shoes for 250 USD</p>
        </div>
        <div className="text-violet-200">
          <RiCornerRightDownFill size="20" className="" />
        </div>
      </div>
      <div className="flex items-center justify-end">
      <div className="text-violet-200">
          <RiCornerLeftUpFill size="20" className="" />
        </div>
        <div className="flex ml-3  juistify-center text-sm p-2 items-center bg-white shadow-lg rounded-lg mr-3">
          <div className="p-2 mr-2 rounded-lg text-violet-700 bg-violet-100">
            <RiGiftFill size={16} />
          </div>
          <p>Get a Free Pair of Socks</p>
        </div>
      </div>
    </div>
  );
};

export default bXgYExample;
