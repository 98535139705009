import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import toaster from "toasted-notes";
import { useMutation } from "react-query";
import {
  insertAutomations,
  insertCampaignCartGoals,
} from "../../../../api/automations";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";

/**
 * This component renders a modal with option to create various campaigns
 * @param {Boolean} isModalVisible determinses the modal is open or not
 * @param {Function} closeModal function to close modal
 * @param {Number} automationCount the total number of campaign user curently have
 * @returns modal component
 */
const CreateAutomationsModal = ({
  isModalVisible,
  closeModal,
  automationCount,
}) => {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const history = useHistory();

  // default value passed to table while creating a campaign
  const defaultAutomations = {
    accountid: workspace.data && workspace.data.accountid,
    shop: workspace.data && workspace.data.storeMyShopifyUrl,
    audience: { everyone: true },
    trigger: "cartUpdate",
    condition: { everyone: true },
    actions: [],
    priority: automationCount,
    schedule: {
      scheduleType: "indefinite",
      startDate: "",
      endDate: "",
    },
    status: "draft",
    templateType: "custom",
    isDeleted: false,
    settings: {
      title: `Automation ${automationCount + 1}`,
    },
  };

  const insertAutomation = async () => {
    return await graphQL(insertAutomations, {
      automationsInput: defaultAutomations,
    });
  };

  const [insertAutomationMutation] = useMutation(insertAutomation, {
    onError: (err) => {
      window.Rollbar.error("Error in insertAutomationsMutation", err);
    },
    onSuccess: (data) => {
      closeModal();
      toaster.notify("New Automation Created", {
        duration: 2000,
      });
      history.push(
        `/cart/cro/automations/edit/${data.insert_automations_one.id}`
      );
    },
  });

  return (
    <ModalTransition>
      {isModalVisible && (
        <Modal width="large" onClose={() => closeModal()}>
          <div className="p-14 overflow-y-auto">
            <p className="text-center font-bold text-xl desktop:text-xl text-violet-800 ">
              Automation
            </p>
            <p className="text-center text-base  text-gray-800 mb-8">
              Automatically do X when Y happens. For example, add a product to
              the cart when an order value reaches a specific amount.
            </p>
            <div className="border rounded-lg p-4 mb-4 text-sm">
              <p className=" text-gray-700 font-bold">EXAMPLE USE-CASES</p>
              <ul className="list-disc ml-4 mt-4">
                <li className="mt-2">
                  Add a cart attribute for orders created by signed in users
                </li>
                <li className="mt-2">
                  If customer add a specific product, automatically redirect to
                  a URL which upsells other similar products
                </li>
              </ul>
            </div>
            <div className="flex flex-row justify-center">
              <Button onClick={() => insertAutomationMutation()} type="primary">
                Create an Automation
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateAutomationsModal;
