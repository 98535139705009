import React, { useState } from "react";
import { RiQuestionFill, RiCheckboxCircleFill } from "react-icons/ri";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "../../../components/Button";
import { useWorkspace } from "../../../contexts/WorkspaceContext";

const OnboardingStep = ({
  stepNo = "",
  title,
  description,
  videoList = [],
  isCompleted,
  ctaButton,
  imgSrc,
}) => {
  const workspace = useWorkspace();

  const [videoDetails, setVideoDetails] = useState({
    isModalOpen: false,
    video: "",
    showVideo: false,
  });

  const handleModalClose = () => {
    setVideoDetails({
      isModalOpen: false,
      video: "",
      showVideo: false,
    });
  };

  const toggleModalVideo = (flag) => {
    setVideoDetails((prevState) => ({ ...prevState, showVideo: flag }));
  };

  return (
    <>
      <div className="flex flex-col basis-0 grow p-3 rounded-lg border border-solid border-gray-200 ">
        <div className="flex flex-col grow">
          <div className="w-56">
            <img
              src={imgSrc}
              className=""
              alt={`onbaording-step-image-${stepNo}`}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-xs font-bold text-violet-400 ">STEP {stepNo}</p>
            <h4 className="text-base desktop:text-lg text-black font-bold mb-3">
              {title}
            </h4>
            <p className="text-base text-gray-700">{description}</p>

            <div className="mt-2">
              {videoList.length > 0 &&
                videoList.map((videoItem) => {
                  return (
                    <button
                      key={videoItem.id}
                      className="text-gray-600 hover:text-violet-600 text-left flex flex-row items-center mt-2"
                      onClick={() =>
                        setVideoDetails({
                          isModalOpen: true,
                          video: videoItem,
                          showVideo: false,
                        })
                      }
                    >
                      <RiQuestionFill />
                      <span className="ml-2">{videoItem.btnText}</span>
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="mt-8">
          {isCompleted ? (
            <div className="flex flex-row items-center justify-center text-black">
              <RiCheckboxCircleFill />
              <p className="font-bold text-sm ml-1">Done</p>
            </div>
          ) : (
            ctaButton
          )}
        </div>
      </div>
      <ModalTransition>
        {videoDetails.isModalOpen && (
          <Modal
            onClose={() => handleModalClose()}
            width={"x-large"}
            // heading={videoDetails.video.modalTitle}
          >
            {videoDetails.video && (
              <div className="p-6 overflow-y-auto">
                <div className="flex">
                  <p className="text-lg text-center w-full text-black font-bold mb-4">
                    {videoDetails.video.modalTitle}
                  </p>
                </div>
                {videoDetails.video.id === "publish" ? (
                  <div>
                    {videoDetails.showVideo === false ? (
                      <div>
                        <div className="flex justify-center">
                          <img
                            className="w-4/5"
                            src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/f3d722a9-3f12-4b09-4e98-a482b203c800/public"
                            alt=""
                          />
                        </div>
                        <div className="p-4">
                          <div className="my-2 text-left">
                            <p className="text-base text-gray-800">
                              The App Embeds need to be enabled from your Theme
                              settings. Click the button below to get there or
                              Go to Shopify Admin → Online Store → Themes →
                              Click on Customize.{" "}
                              <a
                                onClick={() => toggleModalVideo(true)}
                                className="cursor-pointer underline text-blue-600 hover:text-violet-800 visited:text-purple-600"
                              >
                                Click here to watch a step by step video guide
                              </a>
                            </p>
                            {/* <div className="mt-4 desktop:mt-6 flex justify-center">
                              <div className="flex items-center mr-2 border px-3 py-1 rounded-lg">
                                <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                                  1
                                </div>
                                <div className="ml-2">
                                  Click on app embed icon
                                </div>
                              </div>
                              <div className="flex items-center mr-2 border px-3 py-1 rounded-lg">
                                <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                                  2
                                </div>
                                <div className="ml-2">
                                  Enable Cornercart Widgets
                                </div>
                              </div>
                              <div className="flex items-center border px-3 py-1 rounded-lg">
                                <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                                  3
                                </div>
                                <div className="ml-2">Click save</div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center mb-8">
                        <iframe
                          width="560"
                          height="340"
                          src={videoDetails.video.URL}
                          loading="lazy"
                          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    )}

                    <div className="flex justify-center px-8">
                      {videoDetails.showVideo && (
                        <Button
                          className="mx-2"
                          type="link"
                          onClick={() => toggleModalVideo(false)}
                        >
                          Go back
                        </Button>
                      )}

                      <Button
                        className="mx-2"
                        type={"primary"}
                        onClick={() => {
                          window.open(
                            `https://${workspace.data.storeMyShopifyUrl}/admin/themes/current/editor?context=apps&&activateAppId=${process.env.REACT_APP_VIKRAM_UUID}/corner-widget`,
                            "_blank"
                          );
                        }}
                      >
                        Take me to Theme Customization Page
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex justify-center">
                    <iframe
                      width="590"
                      height="380"
                      src={videoDetails.video.URL}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      loading="lazy"
                    ></iframe>
                  </div>
                )}
              </div>
            )}
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default OnboardingStep;
