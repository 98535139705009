import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ReferralRouter from "./Referral/ReferralRouter";
import { useWorkspace } from "../../contexts/WorkspaceContext";

export default function CampaignsRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 2)
      workspace.data.changeCurrentAppID(2);
  }, [workspace]);

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to="/offers/referral/campaigns" />
        </Route>
        <Route path={`${path}/referral`} component={ReferralRouter} />
      </Switch>
    </>
  );
}
