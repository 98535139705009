import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";
import { RiDeleteBin6Line } from "react-icons/ri";

/**
 * @typedef {object} DeleteModalProps

 */

/**
 * A modal for Deleting Items
 * @type {function({isOpen ,isLoading, title, description, cancelText, deleteText, handleClose, handleDelete }): JSX}
 * @param {Boolean} isOpen Boolean which control whether the modal is open or not
 * @param {Boolean} isLoading Boolean flag for deleting
 * @param {String} title heading of the modal
 * @param {String} description description in the
 * @param {String} cancelText cancel button text
 * @param {String} deleteText delete button text
 * @param {Function} handleClose What happens on Close
 * @param {Function} handleDelete What happens on Delete
 */
const DeleteModal = ({
  isOpen,
  isLoading,
  title = "",
  description = "",
  cancelText = "Cancel",
  deleteText = "Delete",
  handleClose,
  handleDelete,
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={() => handleClose()}>
          <div className="p-8 ">
            <h2 className="text-base font-bold mb-4">{title}</h2>
            <p className="text-base mb-4">{description}</p>
            <div className="flex flex-row justify-end">
              <Button onClick={() => handleClose()} type="link">
                {cancelText}
              </Button>
              <Button
                loading={isLoading}
                icon={<RiDeleteBin6Line />}
                className="ml-4"
                onClick={() => handleDelete()}
                type="danger"
              >
                {deleteText}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default DeleteModal;
