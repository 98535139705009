import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import Button from "../../../components/Button";
import {
  queryWidgetSettings,
  queryDefaultWidgetConfigs,
} from "../../../api/widgets";
import { updateOnboardingDetails } from "../../../api/workspace";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { useParams, useHistory } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import fetchRequestWrapper from "../../../utilities/fetchRequestWrapper";

export default function FinalStep() {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const { type } = useParams();

  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const updateOnboarding = async (values) => {
    await graphQL(updateOnboardingDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        cornercart_onboarding: 101,
      },
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateOnboardingMutation] = useMutation(updateOnboarding, {
    onSuccess: () => {
      window.location.href = "/cart";
    },
  });

  const { data: cowiData } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const { data: defaultValues } = useQuery("queryDefaultWidgetConfigs", () =>
    graphQL(queryDefaultWidgetConfigs)
  );

  let defaultCartConfig =
    defaultValues &&
    defaultValues.admin_config_templates.find(
      (items) => items.name === "cartConfig"
    );
  let defaultCartLauncherConfig =
    defaultValues &&
    defaultValues.admin_config_templates.find(
      (items) => items.name === "cartLauncherConfig"
    );

  const handleSubmit = async () => {
    setIsLoading(true);
    const finaliseApiRqst = {
      shop_url: workspace.data.storeMyShopifyUrl,
    };
    const finaliseApiResponse = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/install/finalise`,
      finaliseApiRqst
    );
    setIsLoading(false);
    if (finaliseApiResponse.status !== "OK") {
      window.Rollbar.error(
        "Cornercart onboarding finalise api error",
        finaliseApiResponse
      );
    }
    let updatedCartConfig = {
      ...defaultCartConfig.value,
      ...cowiData.widgets_cornerwidget[0].cart_config,
    };
    let updatedCartLauncherConfig = {
      ...defaultCartLauncherConfig.value,
      ...cowiData.widgets_cornerwidget[0].cart_launcher_config,
    };
    updateOnboardingMutation({
      id: { id: cowiData.widgets_cornerwidget[0].id },
      config: {
        cart_config: updatedCartConfig,
        cart_launcher_config: updatedCartLauncherConfig,
      },
    });
  };

  return (
    <div className="   py-8 flex items-center w-full h-full justify-center overflow-y-auto">
      <div>
        {type === "publish" ? (
          <div className="  w-full mt-2 bg-white rounded-lg shadow-lg max-w-4xl">
            <div>
              <div className="flex justify-center">
                <img
                  className="w-4/6 desktop:w-full"
                  src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/f3d722a9-3f12-4b09-4e98-a482b203c800/public"
                  alt=""
                />
              </div>
            </div>
            <div className="p-4 desktop:p-8  grow  ">
              <div className="mb-4 desktop:mb-8 text-center">
                <h2 className="font-bold text-gray-700 mb-1">
                  Turn on Cornercart widgets from your Shopify Admin settings
                </h2>
                <p>
                  The App Embeds need to be enabled from your Theme settings.
                  Click the button below to get there or Go to Shopify Admin →
                  Online Store → Themes → Click on Customize
                </p>
                <div className="mt-3  flex justify-center">
                  <div className="flex items-center mr-2 border px-3 py-1 rounded-lg">
                    <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                      1
                    </div>
                    <div className="ml-2">Click on app embed icon</div>
                  </div>
                  <div className="flex items-center mr-2 border px-3 py-1 rounded-lg">
                    <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                      2
                    </div>
                    <div className="ml-2">Enable Cornercart Widgets</div>
                  </div>
                  <div className="flex items-center border px-3 py-1 rounded-lg">
                    <div className="bg-indigo-100 flex items-center justify-center font-bold text-violet-700 w-8 h-8 rounded-full">
                      3
                    </div>
                    <div className="ml-2">Click save</div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center px-8">
                <Button
                  className="mx-2"
                  type={"primary"}
                  onClick={() => {
                    window.open(
                      `https://${workspace.data.storeMyShopifyUrl}/admin/themes/current/editor?context=apps&&activateAppId=${process.env.REACT_APP_VIKRAM_UUID}/corner-widget`,
                      "_blank"
                    );
                  }}
                >
                  Take me there
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <iframe
              width="640"
              height="415"
              loading="lazy"
              src="https://www.youtube-nocookie.com/embed/6RTnNv6DGCw"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        )}

        <div className="flex mt-4 justify-between px-8">
          <button
            className=" text-base text-gray-600 hover:text-violet-700 f hover:font-bold"
            onClick={() => history.goBack()}
          >
            Go back
          </button>

          <div className="flex items-center">
            <button
              className=" text-base text-gray-600 hover:text-violet-700 f hover:font-bold"
              onClick={() => {
                $crisp.push(["do", "chat:open"]);
              }}
            >
              Need help? Chat with us
            </button>
            {cowiData && defaultValues && (
              <Button
                type="outline"
                className="ml-3 "
                onClick={() => handleSubmit()}
                loading={isLoading}
              >
                I've Completed this Step →
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
