import React, { useState } from "react";
import GenericRuleItem from "./GenericRuleItem";
import ProductRuleItem from "./ProductRuleItem";

/**
 * This renders base level rules in the json Logic
 * @component
 * @param {object} rule - The rule that is needded to be rendered
 * @param {object} productsMetadata - The product details is needded to be rendered
 * @param {Boolean} firstItem - Flag to define if this is the first rule in the rule group (if yes there are certain style diferences)
 * @param {Function} onChange - What happens onChange
 * @param {Function} onDelete - What happens on Delete
 * @param {Object} variableDetails - Details for the variable type. There are different variable types and they are all defined in the variables folder in utilities
 * @param {Number} index - a unique number for keying each element
 */
const RuleItem = (props) => {
  switch (props.variableDetails.type) {
    case "productArray":
      return <ProductRuleItem {...props} />;
    default:
      return <GenericRuleItem {...props} />;
  }
};
export default RuleItem;
