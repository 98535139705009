import React from "react";
import Select from "react-select";

/**
 * This component renders a floating select with search when clicked on a button
 * @component
 * @param {ReactNode} trigger - The react element which is supposed to be clicked by the user to open the floating popup
 * @param {Function} onChange - What happens on Change
 * @param {String} value - current Value
 * @param {Boolean} searchable - Is the selct box searchable
 * @param {Boolean} isOpen - Is the selct box open
 * @param {Function} onClose - What happens on close
 * @param {Object} options - Options to render inside the select box
 * @param {"top"|"bottom"} alignment - Where to render the select box
 */
const PopupSelect = ({
  trigger,
  onChange,
  value,
  searchable,
  isOpen,
  onClose,
  options,
  alignment,
}) => {

  // This variable holds the customisations that we have done to the select box rendeerd by react-select
  // To learn more refer: https://react-select.com/styles
  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 250,
    }),
    control: (provided, state) => ({
      ...provided,
      margin: "8px 10px",
      borderColor: "transparent",
      borderStyle: "none",
      borderWidth: 0,
      boxShadow: "none",
      display: searchable ? "block" :"none"
    }),
    option: (
      provided,
      { data, isDisabled, isFocused, isSelected, isActive }
    ) => ({
      ...provided,
      padding: "8px 18px",
      textAlign: "left",
      cursor: "pointer",
      color: isDisabled
        ? undefined
        : isSelected
        ? "white"
        : isFocused
        ? "#6e29d9"
        : undefined,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#7c3aee"
        : isFocused
        ? "#f9f5ff"
        : undefined,
      ":active": {
        backgroundColor: !isDisabled ? "#eadeff" : undefined,
      },
    }),
    menu: () => ({ boxShadow: "inset 0 0 0 rgba(0, 0, 0, 0.1)" }),
  };
  return (
    <div className="relative inline-block">
      {trigger}
      <div
        className={`${isOpen ? "block" : "hidden"} absolute z-20 left-0 ${
          alignment === "top" ? "bottom-full" : "top-full"
        } w-72 bg-white rounded-lg shadow-lg border`}
      >
        <Select
          autoFocus
          components={{
            DropdownIndicator: null,
          }}
          isSearchable={searchable}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          blurInputOnSelect={true}
          isClearable={false}
          menuIsOpen
          onChange={onChange}
          closeMenuOnSelect={false}
          options={options}
          placeholder="Search..."
          tabSelectsValue={false}
          styles={selectStyles}
          value={value}
          getOptionLabel={(option) => {
            if (option.icon)
              return (
                <div className="flex items-center">
                  <span className="mr-2">{option.icon}</span>
                  {option.label}
                </div>
              );
            else return `${option.label}`;
          }}
        />
      </div>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } z-10 fixed top-0 left-0 w-full h-full`}
        onClick={onClose}
      ></div>
    </div>
  );
};

export default PopupSelect;
