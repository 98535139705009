import React, { useState, useEffect } from "react";
import { RiAlertFill } from "react-icons/ri";
import { Radio } from "@atlaskit/radio";
import Popup from "@atlaskit/popup";
import Textfield from "@atlaskit/textfield";
import ShopifyResourcePicker from "../../../ShopifyResourcePicker";
import { parseShopifyProductId } from "../../../../utilities/basicUtilityFunctions";

/**
 * This renders base level rules in the json Logic
 * @component
 * @param {object} rule - The rule that is needded to be rendered
 * @param {object} productsMetadata - The product details is needded to be rendered
 * @param {Boolean} firstItem - Flag to define if this is the first rule in the rule group (if yes there are certain style diferences)
 * @param {Function} onChange - What happens onChange
 * @param {Function} onDelete - What happens on Delete
 * @param {Object} variableDetails - Details for the variable type. There are different variable types and they are all defined in the variables folder in utilities
 */
const ProductRuleItem = ({
  rule,
  firstItem,
  onChange,
  onDelete,
  variableDetails,
  index,
  productsMetadata,
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [ruleElements, setRuleElements] = useState({
    productId: null,
    qtyRelation: null,
    qtyValue: null,
  });

  let isValid = ruleElements.productId !== null;

  /**
   * Converts the values we have in hand (ruleElements) to a JSONLogic format which could be passed on to the onChange function
   */
  const handleRuleChange = () => {
    let updatedRule = {
      productsMetadata: productsMetadata,
      jsonLogic: {
        ["some"]: [
          {
            var: "productArray",
          },
          {
            and: [
              {
                "==": [{ var: "productId" }, ruleElements.productId],
              },
              {
                [ruleElements.qtyRelation]: [
                  { var: "quantity" },
                  ruleElements.qtyValue,
                ],
              },
            ],
          },
        ],
      },
    };
    onChange(updatedRule);
  };

  const numberTranslation = (operator) => {
    switch (operator) {
      case "==":
        return "exactly";
      case ">=":
        return "atleast";
      case "<=":
        return "not more than";
    }
  };

  /**
   * Different types of rules need different types of edit popups. This fucntion contents of edit popup based on the rule type
   */
  const renderRuleEditor = () => {
    const numberOptions = [
      { value: ">=", label: "atleast" },
      { value: "<=", label: "not more than" },
      { value: "==", label: "exactly" },
    ];

    const renderOption = () => {
      // First item in the aproduct array rule (which says the product id)
      return (
        <div>
          <b className="mb-2">The quantity should be</b>
          {numberOptions.map((option, index) => {
            return (
              <div key={`number-options-${index}`}>
                <Radio
                  value={option.value}
                  label={option.label}
                  isChecked={ruleElements.qtyRelation === option.value}
                  onChange={(e) => {
                    setRuleElements((prevRule) => {
                      let updatedRuleValue = {
                        ...prevRule,
                        qtyRelation: e.target.value,
                      };
                      return updatedRuleValue;
                    });
                  }}
                />
                {option.value === ruleElements.qtyRelation && (
                  <Textfield
                    value={ruleElements.qtyValue}
                    type="number"
                    onChange={(e) => {
                      setRuleElements((prevRule) => {
                        let updatedRuleValue = {
                          ...prevRule,
                          qtyValue: parseFloat(e.target.value),
                        };
                        return updatedRuleValue;
                      });
                    }}
                    name="basic"
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <div id="popup-editer" className="edit-popper overflow-visible">
        <div className="px-4 pt-4 p">{renderOption()}</div>
        <div className="flex justify-end mt-3 border-t">
          <button
            onClick={() => {
              onDelete(ruleElements.productId);
              setIsEditPopupOpen(false);
            }}
            type="danger-link"
            className="w-1/2 flex text-tiny items-center justify-center px-5 py-2 hover:bg-red-100 text-red-700 font-bold border-r"
            noPadding
          >
            Remove{" "}
          </button>
          <button
            onClick={() => {
              handleRuleChange();
              setIsEditPopupOpen(false);
            }}
            type="danger-link"
            className="w-1/2 flex text-tiny items-center justify-center px-5 py-2 hover:bg-indigo-100 text-violet-700 font-bold"
            noPadding
          >
            Save{" "}
          </button>
        </div>
      </div>
    );
  };
  useEffect(() => {
    // when you delete a rule just behind this one the rulevalue doesnt updatez and this takes care of it
    let productRulesArray = rule["some"][1]["and"];
    let productId = productRulesArray[0]["=="][1];
    let qtyRelation = Object.keys(productRulesArray[1])[0];
    let initRuleElements = {
      productId: productId,
      qtyRelation: Object.keys(productRulesArray[1])[0],
      qtyValue: productRulesArray[1][qtyRelation][1],
    };
    if (productId && productsMetadata && productsMetadata[productId]) {
      setTitle(productsMetadata[productId].title);
    }
    setRuleElements(initRuleElements);
  }, [rule, productsMetadata]);

  return (
    <div key={`${index}-wrapper`}>
      {ruleElements.productId !== null ? (
        <Popup
          key={`${index}-with-product`}
          isOpen={isEditPopupOpen}
          placement="top-start"
          boundary={() => (
            <div
              id="popup-editer"
              className="edit-popper overflow-visible"
            ></div>
          )}
          onClose={() => {
            handleRuleChange();
            setIsEditPopupOpen(false);
          }}
          content={() => renderRuleEditor()}
          trigger={(triggerProps) => (
            <button
              {...triggerProps}
              onClick={() => setIsEditPopupOpen((prevState) => !prevState)}
              className={`shrink-0 my-1 transition-all cursor-pointer ${
                firstItem && "rounded-l-lg"
              }  ${
                isEditPopupOpen
                  ? "bg-white border text-violet-700 border-violet-700 shadow-lg"
                  : `bg-gray-100 border border-r-gray-100 ${
                      firstItem ? "border-l-gray-200" : "border-l-gray-100"
                    } hover:bg-white hover:text-violet-700  hover:border-violet-700 hover:shadow-lg`
              } ${!isValid && "text-red-700 animate-pulse"} `}
            >
              {ruleElements && (
                <div className="flex items-center p-2 text-tiny ">
                  <span className="mr-2 h-full flex items-center">
                    {isValid ? (
                      variableDetails.icon
                    ) : (
                      <RiAlertFill size={12} className="animate-bounce" />
                    )}
                  </span>

                  <span>
                    {isValid ? (
                      <>
                        <span className="font-bold">Cart contains</span>
                        <i key={`${index}-qty-details`} className="ml-1">
                          {numberTranslation(ruleElements.qtyRelation) +
                            " " +
                            ruleElements.qtyValue}
                        </i>
                        <span
                          key={`${index}-product-value`}
                          className="ml-1 font-bold"
                        >
                          {title.length > 20
                            ? `${title.slice(0, 20)}...`
                            : `${title}`}
                        </span>
                      </>
                    ) : (
                      <span className="ml-1">Click to select a product</span>
                    )}
                  </span>
                </div>
              )}
            </button>
          )}
        />
      ) : (
        <ShopifyResourcePicker
          key={`${index}-without-product`}
          selectMultiple={false}
          trigger={(triggerProps) => (
            <button
              key={`${index}-picker-button`}
              {...triggerProps}
              className={`shrink-0 my-1 transition-all cursor-pointer ${
                firstItem && "rounded-l-lg"
              }  ${
                isEditPopupOpen
                  ? "bg-white border text-violet-700 border-violet-700 shadow-lg"
                  : `bg-gray-100 border border-r-gray-100 ${
                      firstItem ? "border-l-gray-200" : "border-l-gray-100"
                    } hover:bg-white hover:text-violet-700  hover:border-violet-700 hover:shadow-lg`
              } text-red-700 animate-pulse `}
            >
              {ruleElements && (
                <div className="flex items-center p-2 text-tiny ">
                  <span className="mr-2 h-full flex items-center">
                    <RiAlertFill size={12} className="animate-bounce" />
                  </span>

                  <span className="ml-1">Click to select a product</span>
                </div>
              )}
            </button>
          )}
          onChange={(selectedProducts) => {
            const selectedProduct = selectedProducts[0];
            const productId = parseShopifyProductId(selectedProduct.id);
            let updatedValue = {
              productsMetadata: {
                ...productsMetadata,
                [productId]: {
                  title: selectedProduct.title,
                  image:
                    selectedProduct.images.length > 0
                      ? selectedProduct.images[0].originalSrc
                      : "",
                  handle: selectedProduct.handle,
                  variants: selectedProduct.variants,
                },
              },
              jsonLogic: {
                ["some"]: [
                  {
                    var: "productArray",
                  },
                  {
                    and: [
                      {
                        "==": [{ var: "productId" }, productId],
                      },
                      {
                        [ruleElements.qtyRelation]: [
                          { var: "quantity" },
                          ruleElements.qtyValue,
                        ],
                      },
                    ],
                  },
                ],
              },
            };
            onChange(updatedValue);
          }}
        />
      )}
    </div>
  );
};
export default ProductRuleItem;
