import React from "react";
import { RiCheckLine, RiGiftFill } from "react-icons/ri";
import Button from "../../../../../components/Button";

/**
 * cart goal example
 */
const CartGoalExample = () => {
  return (
    <div className="px-4 pt-4 pb-7 bg-white shadow-lg rounded-lg w-4/5">
      <div className="text-sm text-gray-700 mb-2 text-center">
        Add <span className="font-bold">70 USD</span> more to unlock a Free
        Gift !
      </div>
      <div className="w-full">
        <div className="relative bg-gray-200 h-2 w-full rounded-full ">
          <div className="absolute top-0 left-0 bg-violet-700 w-2/3 h-full rounded-full"></div>
          <div className="absolute -bottom-[4px] left-0 flex w-full">
            <div className="flex-1 flex justify-end text-xs mt-2">
              <div className="p-1 rounded-full bg-violet-700 text-white">
                <RiCheckLine />
              </div>
            </div>
            <div className="flex-1 flex justify-end text-xs mt-2">
              <div className="p-1 rounded-full bg-gray-200 text-gray-700">
                <RiGiftFill />
              </div>
            </div>
          </div>
          <div className="flex w-full pt-1">
            <div className="flex-1 flex justify-end text-xs mt-2">
              Free Shipping
            </div>
            <div className="flex-1 flex justify-end text-xs mt-2">
              Free Gift
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartGoalExample;
