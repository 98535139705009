import React, { useState } from "react";
import { FieldArray } from "formik";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import toaster from "toasted-notes";
import Button from "../../../../../components/Button";
import ColorPicker from "../../../../../components/ColorPicker";
import MessageItem from "./MessageItem";
import Select from "@atlaskit/select";
import { RiAddCircleFill, RiCloseCircleLine } from "react-icons/ri";
import { langOptions } from "../../../../../utilities/variables";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function BeforeTimerExpires({ values, setFieldValue }) {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  return (
    <div className=" h-full flex flex-row items-stretch min-h-0">
      <div className="desktop:max-w-lg laptop:max-w-sm mobile:w-full px-6 desktop:px-8  overflow-y-auto overflow-x-visible pb-12">
        {/* Colors */}
        <CustomiserUnit
          title="Banner Colors"
          description="Select the colors for the banner and the message"
          customiserUnitId="cart-annouce-color-selector"
        >
          <div className="flex flex-wrap border  rounded-lg py-3">
            <div className="max-w-sm flex border-r  px-4 items-center w-1/2 ">
              <ColorPicker
                color={
                  values.campaign_campaign_cart_timers[0].config.attributes
                    .preBgColor
                }
                onChange={(color) => {
                  setFieldValue(
                    "campaign_campaign_cart_timers[0].config.attributes.preBgColor",
                    convertRGB(color.rgb)
                  );
                }}
              />
              <p className="ml-3 text-sm desktop:text-base">Background Color</p>
            </div>
            <div className="max-w-sm flex px-4 items-center w-1/2">
              <ColorPicker
                color={
                  values.campaign_campaign_cart_timers[0].config.attributes
                    .preTextColor
                }
                onChange={(color) => {
                  setFieldValue(
                    "campaign_campaign_cart_timers[0].config.attributes.preTextColor",
                    convertRGB(color.rgb)
                  );
                }}
              />
              <p className="ml-3 text-sm desktop:text-base">Text Color</p>
            </div>
          </div>
        </CustomiserUnit>
        {/* Message */}
        <CustomiserUnit
          title="Banner Message"
          customiserUnitId="cart-timer-message-input"
          description={
            <p>
              Enter the message you wish to display before the timer expires.
            </p>
          }
        >
          <FieldArray
            name="campaign_campaign_cart_timers[0].content"
            render={(arrayHelpers) => {
              return (
                <div className="max-w-4xl">
                  {values.campaign_campaign_cart_timers[0].content.map(
                    (messageItem, index) => {
                      return (
                        <div className="border rounded-md mt-1">
                          <MessageItem
                            message={{
                              language: messageItem.language,
                              content: messageItem.value.preCartTimerMessage,
                            }}
                            showDelete={
                              values.campaign_campaign_cart_timers[0].content
                                .length > 1
                            }
                            onDelete={() => {
                              arrayHelpers.remove(index);
                            }}
                            onChange={(text) => {
                              setFieldValue(
                                `campaign_campaign_cart_timers[0].content[${index}].value.preCartTimerMessage`,
                                text
                              );
                            }}
                            status="pre"
                          />
                        </div>
                      );
                    }
                  )}
                  <div className="flex justify-end mt-2">
                    {isEditPopupOpen ? (
                      <div className="flex w-full justify-center items-center">
                        <Select
                          onChange={(selectedLanguage) => {
                            let existingLanguages =
                              values.campaign_campaign_cart_timers[0].content.map(
                                (item) => item.language
                              );
                            let newLanguage = {
                              language: selectedLanguage.value,
                              value: {
                                postCartTimerMessage: "",
                                preCartTimerMessage: "",
                              },
                            };
                            if (
                              !existingLanguages.includes(
                                selectedLanguage.value
                              )
                            ) {
                              arrayHelpers.push(newLanguage);
                            } else {
                              toaster.notify(
                                `${selectedLanguage.label} is already in the list`,
                                {
                                  duration: 2000,
                                }
                              );
                            }
                            setIsEditPopupOpen((prevState) => !prevState);
                          }}
                          className="w-full mr-2 grow"
                          options={langOptions}
                          placeholder="Select the language to add"
                        />
                        <Button
                          onClick={() => {
                            setIsEditPopupOpen((prevState) => !prevState);
                          }}
                          type="link"
                          icon={<RiCloseCircleLine />}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setIsEditPopupOpen((prevState) => !prevState);
                        }}
                        type="link"
                        icon={<RiAddCircleFill />}
                      >
                        <span>Add translations</span>
                      </Button>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </CustomiserUnit>
      </div>
    </div>
  );
}
