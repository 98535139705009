import React, { useState } from "react";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { Checkbox } from "@atlaskit/checkbox";
import { FieldArray } from "formik";
import ArrayInput from "../../../../../components/ArrayInput";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import Button from "../../../../../components/Button";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import ScriptEditor from "./ScriptEditor";
/**
 * advanced settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function MiniCartAdvancedSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();
  const [isCustomHtmlModalOpen, setIsCustomHtmlModalOpen] = useState(false);
  return (
    <div className="px-6 desktop:px-8">
      {/* launcher z-index */}
      <CustomiserUnit
        title="Z-Index"
        description="Controls z-index of the Cart Widget. A website element with a higher z-index is always shown in front of an element with lower z-index."
      >
        <div className="max-w-xs">
          <Textfield
            defaultValue={values.cart_launcher_config.zIndex}
            onChange={(e) => {
              setFieldValue("cart_launcher_config.zIndex", e.target.value);
            }}
            name="basic"
            type="number"
          />
        </div>
      </CustomiserUnit>

      {/* custom css */}
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            .cornerwidget_custom_css
        }
        paywallPlan={13}
        title="Custom CSS"
        description="Add custom CSS for further customization of your Cart Widget.
        Please use `!important` to make sure your changes get precedence over the default values.
        "
      >
        <TextArea
          value={values.cart_config.general.customCss}
          onChange={(e) => {
            setFieldValue("cart_config.general.customCss", e.target.value);
          }}
          name="basic"
          minimumRows={7}
        />
      </CustomiserUnit>

      {/* custom HTML */}
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.cornerwidget
            .cornerwidget_custom_css
        }
        paywallPlan={13}
        title="Add Custom HTML Elements"
        description="Add/change various parts of the cart widget with custom HTML."
      >
        <Button
          onClick={() => {
            setIsCustomHtmlModalOpen(true);
          }}
          type="ghost"
        >
          Add Custom HTML
        </Button>
        <ModalTransition>
          {isCustomHtmlModalOpen && (
            <Modal
              // actions={[
              //   {
              //     text: "OK",
              //     onClick: () => {
              //       setIsCustomHtmlModalOpen(false);
              //     },
              //   },
              // ]}
              onClose={() => {
                setIsCustomHtmlModalOpen(false);
              }}
            >
              <div className="h-full flex flex-col">
                <h2 className="text-base font-bold px-6 pt-6">Add Custom HTML</h2>
                <div className="shrink overflow-auto p-6">
                  {/* empty state */}
                  <CustomiserUnit
                    title="Cart Empty State"
                    description="Replace the default empty cart state with a custom HTML."
                  >
                    <TextArea
                      defaultValue={values.cart_config.customHtml.emptyCart}
                      onChange={(e) => {
                        setFieldValue(
                          "cart_config.customHtml.emptyCart",
                          e.target.value
                        );
                      }}
                      name="basic"
                      minimumRows={3}
                    />
                  </CustomiserUnit>

                  {/* above list */}
                  <CustomiserUnit
                    title="Above Item List"
                    description="Render a piece of custom HTML above the cart items list"
                  >
                    <TextArea
                      defaultValue={values.cart_config.customHtml.aboveItemList}
                      onChange={(e) => {
                        setFieldValue(
                          "cart_config.customHtml.aboveItemList",
                          e.target.value
                        );
                      }}
                      name="basic"
                      minimumRows={3}
                    />
                  </CustomiserUnit>

                  {/* below list */}
                  <CustomiserUnit
                    title="Below Item List"
                    description="Render a piece of custom HTML below the cart items list"
                  >
                    <TextArea
                      defaultValue={values.cart_config.customHtml.belowItemList}
                      onChange={(e) => {
                        setFieldValue(
                          "cart_config.customHtml.belowItemList",
                          e.target.value
                        );
                      }}
                      name="basic"
                      minimumRows={3}
                    />
                  </CustomiserUnit>

                  {/* above cta */}
                  <CustomiserUnit
                    title="Above Checkout Button"
                    description="Render a piece of custom HTML above the checkout button"
                  >
                    <TextArea
                      defaultValue={
                        values.cart_config.customHtml.aboveCheckoutCta
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "cart_config.customHtml.aboveCheckoutCta",
                          e.target.value
                        );
                      }}
                      name="basic"
                      minimumRows={3}
                    />
                  </CustomiserUnit>

                  {/* below cta */}
                  <CustomiserUnit
                    title="Below Checkout Button"
                    description="Render a piece of custom HTML below the checkout button"
                  >
                    <TextArea
                      defaultValue={
                        values.cart_config.customHtml.belowCheckoutCta
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "cart_config.customHtml.belowCheckoutCta",
                          e.target.value
                        );
                      }}
                      name="basic"
                      minimumRows={3}
                    />
                  </CustomiserUnit>
                </div>
                <div className="flex flex-row justify-end p-4 border-t">
                  <Button
                    onClick={() => setIsCustomHtmlModalOpen(false)}
                    type="primary"
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </CustomiserUnit>

      <ScriptEditor values={values} setFieldValue={setFieldValue} />

      {/* Custom Cart selectors */}
      <CustomiserUnit
        title="Custom Cart Selectors"
        description="Convert any element in your store into a cart button by pasting its CSS querySelector below."
      >
        <FieldArray
          name="cart_config.general.customCartButtonSelectors"
          render={(arrayHelper) => (
            <ArrayInput
              addButtonText="Add Selector"
              onAddition={(value) => {
                arrayHelper.push(value);
              }}
              onDeletion={(index) => arrayHelper.remove(index)}
              arrayItems={
                values.cart_config.general.customCartButtonSelectors
                  ? values.cart_config.general.customCartButtonSelectors
                  : []
              }
            />
          )}
        />
      </CustomiserUnit>

      {/* Header Elements to Refresh on Cart Update */}
      <CustomiserUnit
        title="Header Elements to Refresh on Cart Update"
        description="Some theme headers may display item count next to the cart icon, but sometimes the count may not update. If this happens, please provide the IDs of the elements that should update when the cart is updated. If you need help with this, please do contact us in support"
      >
        <FieldArray
          name="cart_config.general.headerElementsToRefreshOnCartUpdate"
          render={(arrayHelper) => (
            <ArrayInput
              addButtonText="Add ID"
              onAddition={(value) => {
                arrayHelper.push(value);
              }}
              onDeletion={(index) => arrayHelper.remove(index)}
              arrayItems={
                values.cart_config.general.headerElementsToRefreshOnCartUpdate
                  ? values.cart_config.general
                      .headerElementsToRefreshOnCartUpdate
                  : []
              }
            />
          )}
        />
      </CustomiserUnit>

      {/* Prevent native ATC Behavour */}
      <CustomiserUnit
        title="Prevent Add to Cart Override "
        description="By default, Corner Cart will override the theme's default cart behavior by overriding Add to Cart forms, and it is the recommended way. But in some rare cases, this might cause irregularities in certain theme features. In such cases, use this setting to prevent this override from happening on certain types of pages."
      >
        <FieldArray
          name="cart_config.general.nativeAtcBeaviourPreventedPages"
          render={(arrayHelper) => {
            let pageTypes = [
              {
                label: "Prevent in Collection Pages",
                value: "collection",
              },
              {
                label: "Prevent in Product Pages",
                value: "product",
              },
              {
                label: "Prevent in General Pages",
                value: "general",
              },
            ];
            let nativeAtcBeaviourPreventedPages =
              values.cart_config.general.nativeAtcBeaviourPreventedPages || [];
            return (
              <div className="flex border rounded-lg p-3 flex-wrap">
                {pageTypes.map((type) => (
                  <div className="shrink-0">
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelper.push(type.value);
                        } else {
                          let indexToRemove =
                            nativeAtcBeaviourPreventedPages.findIndex(
                              (item) => item === type.value
                            );
                          if (indexToRemove !== -1)
                            arrayHelper.remove(indexToRemove);
                        }
                      }}
                      label={type.label}
                      name="checkbox-basic"
                      isChecked={nativeAtcBeaviourPreventedPages.includes(
                        type.value
                      )}
                    />
                  </div>
                ))}
              </div>
            );
          }}
        />
      </CustomiserUnit>

      {/* overideCartPage */}
      <CustomiserUnit
        title="Takeover Clicks to Cart Page"
        description="Whenever a user clicks a link in your theme which redirects to your theme's cart page, it will be overidden and the cart widget will be opened instead."
      >
        <div className="border p-3 rounded-lg">
          <Checkbox
            onChange={(e) => {
              setFieldValue(
                "cart_config.general.overideCartPage",
                e.target.checked
              );
            }}
            label="Takeover clicks to cart page"
            name="checkbox-basic"
            defaultChecked={values.cart_config.general.overideCartPage}
          />
        </div>
      </CustomiserUnit>
    </div>
  );
}
