import React from "react";
import { useFlow } from "@dopt/react";
/**
 * Thisfunction is used for completing campaign flow
 * @returns {Function} a function that that completes dopt flow
 */
const useCompleteDoptFlow = () => {
  const [doptFlow, doptFlowIntent] = useFlow("create-campaign-flow");

  const handleDoptFinish = () => {
    if (!doptFlow.state.finished) {
      doptFlowIntent.finish();
    }
  };
  return {
    doptState: doptFlow.state,
    finishFlow: handleDoptFinish,
  };
};

export default useCompleteDoptFlow;
