import React from "react";
import toaster from "toasted-notes";
import Datetime from "react-datetime";
import moment from "moment";
import Textfield from "@atlaskit/textfield";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import CollectionProductExtractor from "../../../../components/CollectionProductExtractor";
import RichTextEditor from "../../../../components/RichTextEditor";
import TabRadioButton from "../../../../components/TabRadioButton";
import ArrayInput from "../../../../components/ArrayInput";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

/**
 * Settings Tab
 * @component
 * @param {Object} values - Contains all formik values
 * @param {Function} setFieldValue - setFieldValue funtion of formik
 */
const Settings = ({ values, setFieldValue }) => {
  const workspace = useWorkspace();
  return (
    <div>
      <div className="w-full py-3 px-8">
        <CustomiserUnit
          title="Campaign Name"
          description="Give this campaign a name (only you see this, not your customers)"
        >
          <div>
            <Textfield
              value={values.name}
              onChange={(onChangeEvent) => {
                setFieldValue("name", onChangeEvent.target.value);
              }}
              name="basic"
            />
          </div>
        </CustomiserUnit>

        <CustomiserUnit
          title="Blocked Collections"
          description="To prevent this campaign popup from rendering for products in certain collections add the collection handle here."
        >
          <CollectionProductExtractor
            value={values.blocked_collections}
            onChange={(changedValue) => {
              setFieldValue("blocked_collections", changedValue);
            }}
          />
        </CustomiserUnit>

        <CustomiserUnit
          title="Campaign Duration"
          description="How long will this campaign last"
        >
          <div className="flex items-center justify-between max-w-lg">
            <div className=" mr-5">
              <small className="text-xs ">START DATE</small>
              <div>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  value={new Date(values.starts_at)}
                  onChange={(onChangeEvent) => {
                    if (
                      moment(onChangeEvent).isBefore(moment(values.expires_at))
                    )
                      setFieldValue("starts_at", onChangeEvent.toISOString());
                    else
                      toaster.notify(
                        "Please choose a date which comes before the End Date",
                        {
                          duration: 5000,
                        }
                      );
                  }}
                  inputProps={{
                    className:
                      "desktop:p-3 laptop:p-2 mobile:p-1 bg-gray-200 border rounded-md text-sm z-50",
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className=" mr-5">
              <small className="text-xs ">END DATE</small>
              <div>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  value={new Date(values.expires_at)}
                  onChange={(onChangeEvent) => {
                    if (moment(onChangeEvent).isAfter(moment(values.starts_at)))
                      setFieldValue("expires_at", onChangeEvent.toISOString());
                    else
                      toaster.notify(
                        "Please choose a date which comes after the Start Date",
                        {
                          duration: 5000,
                        }
                      );
                  }}
                  inputProps={{
                    className:
                      "desktop:p-3 laptop:p-2 mobile:p-1 bg-gray-200 border rounded-md text-sm z-50",
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </div>
        </CustomiserUnit>

        <CustomiserUnit
          title="Minimum Requirements"
          description="Both gift and reward coupons created by this campaign will only be valid if these minimum requirements are met "
        >
          <TabRadioButton
            defaultValue={values.gift_config.minimumRequirementType}
            options={[
              { value: "none", label: "None" },
              { value: "amount", label: "Minimum Purchase Amount" },
              { value: "quantity", label: "Minimum quantity of items" },
            ]}
            onChange={(onChangeEvent) => {
              setFieldValue(
                "gift_config.minimumRequirementType",
                onChangeEvent
              );
              values.reward_stage.map((value, index) => {
                setFieldValue(
                  `reward_stage[${index}].minimumRequirementType`,
                  onChangeEvent
                );
              });
            }}
          />
          {values.gift_config.minimumRequirementType !== "none" && (
            <div className="flex items-center mt-4">
              <div className="w-40">
                <Textfield
                  value={values.gift_config.minimumRequirementValue}
                  onChange={(onChangeEvent) => {
                    if (onChangeEvent.target.value < 1) {
                      toaster.notify("The value must be greater than 0", {
                        duration: 2000,
                      });
                    } else {
                      setFieldValue(
                        "gift_config.minimumRequirementValue",
                        parseInt(onChangeEvent.target.value, 10)
                      );
                      values.reward_stage.map((value, index) => {
                        setFieldValue(
                          `reward_stage[${index}].minimumRequirementValue`,
                          parseInt(onChangeEvent.target.value, 10)
                        );
                      });
                    }
                  }}
                  min={1}
                  type="number"
                  name="basic"
                />
              </div>
              <div className="border-2 border-gray-200 bg-gray-200 rounded-r-md p-2 font-bold">
                {values.gift_config.minimumRequirementType === "amount"
                  ? workspace.data.storeCurrency
                  : "Items"}
              </div>
            </div>
          )}
        </CustomiserUnit>

        <CustomiserUnit
          title="Offer Terms"
          description="This is the place where you can write offer terms if you have any."
        >
          <div className="border p-3 rounded-lg">
            <RichTextEditor
              value={values.settings_config.offerTerms}
              type="minimal"
              onChange={(htmlValue) =>
                setFieldValue("settings_config.offerTerms", htmlValue)
              }
            />
          </div>
        </CustomiserUnit>

        {values.campaign && (
          <CustomiserUnit
            title="Campaign Priority"
            description="Campaign with the higher priority number will be shown as the active campaign in the website. This setting is used if you have multiple active viral referral campaigns"
          >
            <div className="w-24">
              <Textfield
                value={values.campaign.priority}
                onChange={(onChangeEvent) => {
                  if (parseInt(onChangeEvent.target.value, 10) > 0)
                    setFieldValue(
                      "campaign.priority",
                      parseInt(onChangeEvent.target.value, 10)
                    );
                }}
                type="number"
                name="basic"
              />
            </div>
          </CustomiserUnit>
        )}
      </div>
    </div>
  );
};

export default Settings;
