import React from "react";

const FaqCategoryButton = props => {
  return (
    <a
      role="presentation"
      className="coca-cowi-faq-category-btn"
    >
      <div className="coca-cowi-faq-category-btn-card coca-cowi-card coca-cowi-hover-card coca-text-center">
        <div className="coca-cowi-faq-category-btn-img">
          <img src={props.categoryItem.icon} alt="ss" />
        </div>
        <div className="coca-cowi-faq-category-btn-content">
          <small>{props.categoryItem.name}</small>
        </div>
      </div>
    </a>
  );
};

export default FaqCategoryButton;
