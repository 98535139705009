import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "../../../../components/Button";
import List from "../../../../components/List";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { bulkUpdateCampaigns, updateCampaign } from "../../../../api/campaign";
import CampaignListItem from "./CampaignListItem";

/**
 * An UI element designed to render camapaign lists
 * @component
 * @param {Object} data - Campaign Data
 * @param {String} onUpdate - Calback function for what happens on update. Usually refetch the data provided to this component on update
 * @param {Node} emptyState - Empty State
 * @param {Boolean} draggable - Dragabble or not
 * @param {Boolean} campaignLimitCount - Number of camapigns before triggering paywall
 */
export default function CampaignList({
  data,
  onUpdate,
  emptyState,
  draggable,
  campaignLimitCount,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [activeSortOption, setActiveSortOption] = useState("all");
  const graphQL = useGraphQl();

  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

  const updateCampaignDetails = async (input) => {
    await graphQL(updateCampaign, {
      campaignId: { id: input.id },
      campaignInput: input.values,
    });
  };

  const bulkUpdateCampaignsDetails = async (input) => {
    let valueToUpdate;
    switch (input.updateType) {
      case "archive":
        valueToUpdate = { status: "archived" };
        break;
      case "draft":
        valueToUpdate = { status: "draft" };
        break;
      case "active":
        valueToUpdate = { status: "active" };
        break;
      case "delete":
        valueToUpdate = { status: "delete" };
        break;
    }

    await graphQL(bulkUpdateCampaigns, {
      idArray: input.id,
      campaignInput: valueToUpdate,
    });
  };

  const [updateCampaignMutation] = useMutation(updateCampaignDetails, {
    onSuccess: () => {
      onUpdate();
    },
  });

  const [bulkUpdateCampaignMutation] = useMutation(bulkUpdateCampaignsDetails, {
    onSuccess: (e) => {
      setSelectedItems([]);
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      onUpdate();
    },
  });

  const handleBulkAction = (action, idArray) => {
    bulkUpdateCampaignMutation({
      id: idArray,
      updateType: action,
    });
  };

  const getSortedData = () => {
    switch (activeSortOption) {
      case "all":
        return campaignList.filter(
          (campaign) =>
            campaign.status !== "archived" && campaign.status !== "delete"
        );
      case "active":
        return campaignList.filter((campaign) => campaign.status === "active");
      case "draft":
        return campaignList.filter((campaign) => campaign.status === "draft");
      case "archived":
        return campaignList.filter(
          (campaign) => campaign.status === "archived"
        );
    }
  };
  const sortOptions = [
    { label: "All", value: "all" },
    { label: "Active campaigns", value: "active" },
    { label: "Paused campaigns", value: "draft" },
    { label: "Archived", value: "archived" },
  ];

  // When user drags an campaign, the whole new campaignList array which is aranged in the new order is passed on to this fuction. Here we update each campaign's priority with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateCampaignMutation({
        id: item.id,
        values: {
          priority: updatedList.length - 1 - index,
        },
      });
    });
  };

  // each time anything is updated this functio makes sure the updated campoaign list is sorted.
  const initialiseList = () => {
    const arrangedCampaignList = data.sort((a, b) => b.priority - a.priority);
    setCampaignList(arrangedCampaignList);
  };

  useEffect(() => {
    initialiseList();
  }, [data]);

  return (
    <>
      <div className="flex  py-1 px-2 desktop:py-2 desktop:px-4 flex-wrap">
        <div className="flex items-center">
          <p className="mr-3 desktop:block hidden  ">View</p>
          <Select
            value={sortOptions.find(
              (option) => option.value === activeSortOption
            )}
            isSearchable={false}
            onChange={(e) => setActiveSortOption(e.value)}
            className="w-48 mr-2"
            options={sortOptions}
          />
        </div>
        {/* <div className="flex items-center ">
          <Dropdown
            alignment="right"
            type="link"
            icon={<RiArrowDownSLine />}
            triggerText="Bulk Actions"
            disabled={selectedItems.length === 0}
          >
            <Button
              onClick={() => {
                handleBulkAction("archive", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiArchiveFill />}
            >
              Archive Campaigns
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("draft", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiPauseCircleFill />}
            >
              Pause Campaigns
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("active", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiPlayCircleFill />}
            >
              Activate Campaigns
            </Button>

           
          </Dropdown>
        </div> */}
      </div>

      <div className="desktop:px-8 mobile:px-3  pt-4 ">
        <List
          draggable={draggable && activeSortOption === "all"}
          onDrag={handleDrag}
          items={getSortedData()}
          // selectedItemsId={selectedItems}
          // onSelectionChange={setSelectedItems}
          emptyState={emptyState}
          header={
            <div className="grid grid-cols-12 items-center justify-center ">
              <div className="laptop:col-span-6 mobile:col-span-12 px-3 desktop:px-7">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">
                  Campaign
                </p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">
                  Status
                </p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden"></div>
            </div>
          }
          render={(item, index) => {
            return (
              <CampaignListItem
                key={`list-items-${index}`}
                handleDeleteUpdate={() => onUpdate()}
                campaignItem={item}
              />
            );
          }}
        />
      </div>
    </>
  );
}
