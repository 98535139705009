import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Textfield from "@atlaskit/textfield";
import Button from "../../../components/Button";
import { queryUsers, updateUserDetails } from "../../../api/workspace";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import toaster from "toasted-notes";
import { validateEmail } from "../../../utilities/basicUtilityFunctions";

export default function PersonalInfo() {
  let history = useHistory();
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const [invalidEmailErr, setInvalidEmailErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: userData } = useQuery("queryUsers", () => graphQL(queryUsers));

  const updateUserDetail = async (values) => {
    await graphQL(updateUserDetails, {
      userId: values.id,
      userInput: values.config,
      installationId: values.installationId,
      installationInput: values.installationInput,
    });
  };

  const [updateUserDetailMutation] = useMutation(updateUserDetail, {
    onSuccess: () => {
      history.push("/get-started/3/");
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  return (
    <div className=" shrink-0  px-7 py-8 flex  justify-center ">
      <div className="laptop:flex mobile:block items-center h-full w-full mt-8 bg-white rounded-lg shadow-lg">
        {userData && (
          <Formik
            enableReinitialize
            initialValues={userData.users[0]}
            onSubmit={(values) => {
              setIsLoading(true);
              updateUserDetailMutation({
                id: { id: values.id },
                config: {
                  first_name: values.first_name,
                  preferred_email: values.preferred_email,
                },
                installationId: {
                  id: workspace.data.installationId,
                },
                installationInput: {
                  contact_name: values.first_name,
                  contact_email: values.preferred_email,
                },
              });
            }}
          >
            {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
              return (
                <div className="p-8 shrink grow max-w-xl">
                  <div className="mb-8">
                    <h1 className="font-bold text-center text-gray-700 mb-3">
                      Tell us about yourself
                    </h1>
                    <p>
                      The following information helps us setup your workspace
                    </p>
                  </div>
                  <div className="my-5">
                    <div className="">
                      <label htmlFor="First name" className="text-xs font-bold">
                        Contact Name
                      </label>
                      <Textfield
                        defaultValue={values.first_name}
                        onChange={(e) =>
                          setFieldValue("first_name", e.target.value)
                        }
                        name="basic"
                      />

                      {/* <div className="flex-grow">
                        <label
                          htmlFor="Last name"
                          className="text-xs font-bold"
                        >
                          Last Name
                        </label>
                        <Textfield
                          defaultValue={values.last_name}
                          onChange={(e) =>
                            setFieldValue("last_name", e.target.value)
                          }
                          name="basic"
                        />
                      </div> */}
                    </div>
                    <div className=" mt-4">
                      <label htmlFor="Last name" className="text-xs font-bold">
                        Email ID
                      </label>
                      <Textfield
                        defaultValue={values.preferred_email}
                        onChange={(e) => {
                          const isEmailValid = validateEmail(e.target.value);
                          setInvalidEmailErr(!isEmailValid);
                          setFieldValue("preferred_email", e.target.value);
                        }}
                        isInvalid={invalidEmailErr}
                        name="basic"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center px-8">
                    <Button
                      isLoading={isLoading}
                      type="primary"
                      onClick={() => {
                        const isEmailValid = validateEmail(
                          values.preferred_email
                        );
                        if (isEmailValid) {
                          submitForm();
                        } else {
                          setInvalidEmailErr(true);
                          toaster.notify(
                            "Please do update your informations. We send mostly product updates. No spam, we promise!",
                            {
                              duration: 2000,
                            }
                          );
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}
