/**
 * This functions returns shopify pricing details
 * @param {String} planName Shopify plan display name
 * @returns
 */
const getCocaPricingDetails = (planName) => {
  switch (planName) {
    case "Basic Shopify":
      return {
        pricing: 15,
        planCode: "COCA_BASIC_2023_APR",
      };
    case "Shopify":
      return {
        pricing: 29,
        planCode: "COCA_STANDARD_2023_APR",
      };
    case "Advanced Shopify":
      return {
        pricing: 59,
        planCode: "COCA_ADVANCED_2023_APR",
      };
    case "Shopify Plus":
      return {
        pricing: 99,
        planCode: "COCA_PLUS_2023_APR",
      };
    case "Development":
      return {
        pricing: "Free",
        planCode: "COCA_FREE_2023_APR",
      };
    case "Developer Preview":
      return {
        pricing: "Free",
        planCode: "COCA_FREE_2023_APR",
      };
    case "trial":
      return {
        pricing: "Free",
        planCode: "COCA_FREE_2023_APR",
      };
    case "staff":
      return {
        pricing: 29,
        planCode: "COCA_STANDARD_2023_APR",
      };
    case "Pause and Build":
      return {
        pricing: 15,
        planCode: "COCA_BASIC_2023_APR",
      };

    default:
      return {
        pricing: 15,
        planCode: "COCA_BASIC_2023_APR",
      };
  }
};

export default getCocaPricingDetails;
