import React from "react";
import FaqCategoryButton from "./FaqCategoryButton";
import FaqItem from "./FaqItem";

const FaqCategories = (props) => {
  const categoryList = [
    {
      id: 1,
      name: "How to",
      icon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/51.svg",
    },
    {
      id: 2,
      name: "Return Policy",
      icon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/39.svg",
    },
    {
      id: 3,
      name: "Shipping",
      icon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/49.svg",
    },
    {
      id: 4,
      name: "T & C",
      icon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/14.svg",
    },
  ];
  const FaqList = [
    {
      id: 1,
      title: "How to often should I use this?",
      categoryTitle: "How to",
      categoryIcon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/51.svg",
    },

    {
      id: 2,
      title: "Can I cancel my subscription?",
      categoryTitle: "Shipping",
      categoryIcon:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/49.svg",
    },

   
  ];

  return (
    <div className="coca-cowi-faq-category-wrapper ">
      <h2 className="coca-inner-title">
        {props.langObject[props.defaultLang].assistant.category_cards_title
          ? props.langObject[props.defaultLang].assistant.category_cards_title
          : " FAQ Categories"}
      </h2>
      <div className="coca-d-flex coca-cowi-faq-category-btn-wrapper">
        {categoryList.map((item) => (
          <FaqCategoryButton key={item.name} categoryItem={item} />
        ))}
      </div>
      <h2 className="coca-inner-title">
        {props.langObject[props.defaultLang].assistant.featured_faq_title
          ? props.langObject[props.defaultLang].assistant.featured_faq_title
          : " Featured"}
      </h2>
      <div>
        {FaqList.map((item) => (
          <FaqItem key={item.categoryTitle} faqItem={item} bgEndColor={props.bgEndColor} />
        ))}
      </div>
    </div>
  );
};

export default FaqCategories;
