import React, { useEffect } from "react";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { useGraphQl } from "../contexts/GraphqlClient";
import { updateSourceDetails } from "../api/workspace";
import returnCurrencyObject from "./returnCurrencyObject";

/**
 * Use this with caution!!!
 * This fuction is called at the intial setup of each application to update the default currency
 * It checks if currency format is existing in the workspace context
 * Updates the default currency if the currency is not available
 * also it refreshes the workspace context
 */

const useInitialCurrencyManager = () => {
  const graphQL = useGraphQl();
  const {
    data: { currencyFormat, storeCurrency, sourceid },
    refreshWorkspace,
  } = useWorkspace();

  /**Function to update the currency format to backend db */
  const updateCurrencyFormat = async () => {
    const currencyObject = returnCurrencyObject(
      storeCurrency,
      storeCurrency,
      true
    );
    await graphQL(updateSourceDetails, {
      sourceId: { id: sourceid },
      sourceInput: {
        currency_format: currencyObject,
      },
    });
    refreshWorkspace();
  };

  useEffect(() => {
    if (!currencyFormat) {
      updateCurrencyFormat();
    }
  }, []);
};

export default useInitialCurrencyManager;
