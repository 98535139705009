import React, { useState } from "react";
import {
  RiArchiveFill,
  RiPauseCircleFill,
  RiPlayCircleFill,
  RiEdit2Line,
  RiCheckboxBlankCircleFill,
  RiAlertFill,
  RiDeleteBin6Line,
  RiArrowGoForwardFill,
} from "react-icons/ri";
import { useMutation } from "react-query";
import Button from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { updateCampaign } from "../../../../api/campaign";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { nanoid } from "nanoid";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ErrorModal from "../../../../components/ErrorModal";
import contactCrisponApiError from "../../../../utilities/contactCrisponApiError";
import fetchRequestWrapper from "../../../../utilities/fetchRequestWrapper";
import useClearCache from "../../../../utilities/useClearCache";

/**
 * This component renders the campaign list
 * @param {Object} campaignItem the entire campaign object
 * @returns React Element
 */
const CampaignListItem = ({ campaignItem, handleDeleteUpdate }) => {
  const history = useHistory();
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const clearCache = useClearCache();

  const [campaignDelete, setCampaignDelete] = useState({
    isModalOpen: false,
    isLoading: false,
  });
  const [errorDetails, setErrorDetails] = useState({
    isModalOpen: false,
    apiErrorKey: "",
    isBtnDisabled: false,
    isLoading: false,
  });

  const updateCampaignDetails = async (input) => {
    await graphQL(updateCampaign, {
      campaignId: { id: input.id },
      campaignInput: input.values,
    });
  };

  const [updateCampaignMutation] = useMutation(updateCampaignDetails, {
    onSuccess: () => {
      clearCache();
      handleDeleteUpdate();
      setCampaignDelete({
        isModalOpen: false,
        isLoading: false,
      });
    },
  });

  /**
   * This function returns the icon image url according to the type
   * @param {String} campaignType
   * @return {URL}  Image URL
   */
  const getCampaignTypeDetails = (campaignType) => {
    switch (campaignType) {
      case "goal_meter_in_cart":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a0ef1eaa-d3bf-4081-093d-1940ceb18a00/public",
          typeName: "Cart Goals",
        };

      case "one_click_upsell":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/4a504c23-2ded-4e39-9cd2-5cf7ee0ba400/public",
          typeName: "One Click Upsell",
        };
      case "cart_announcement":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/51031b76-5b7a-44dc-8bf2-bc1730279800/public",
          typeName: "Cart Announcement",
        };
      case "bxgy_free":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a20afe40-8ea1-4a80-57e3-210ed523c700/public",
          typeName: "Buy X Get Y",
        };
      case "discount_code":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e1e5d495-5af2-409e-6464-428f62bc6b00/public",
          typeName: "Discount Code Campaign",
        };
      case "cart_timer":
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/4406cd20-a2fc-4de0-cec7-58f2efb0dd00/public",
          typeName: "Cart Timer",
        };
      default:
        return {
          iconImageUrl:
            "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a0ef1eaa-d3bf-4081-093d-1940ceb18a00/public",
          typeName: "Cart Upsell",
        };
    }
  };

  const { iconImageUrl, typeName } = getCampaignTypeDetails(campaignItem.type);

  const getCampaignStatus = (status) => {
    switch (status) {
      case "active":
        return (
          <span className="flex items-center rounded-sm py-2">
            <RiPlayCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />
            Active
          </span>
        );
      case "draft":
        return (
          <span className="flex items-center  rounded-sm py-2 ">
            <RiPauseCircleFill className="flex-none text-amber-400 mr-2 text-lg" />
            Paused
          </span>
        );
      case "archived":
        return (
          <span className="flex items-center  rounded-sm py-2">
            <RiArchiveFill className="flex-none text-red-400 mr-2 text-lg" />
            Archived
          </span>
        );
      case "not_published":
        return (
          <span className="flex items-center  rounded-sm py-2 ">
            <RiAlertFill className="flex-none text-red-400 mr-2 text-lg" />
            Sync Failed
          </span>
        );
      default:
        return (
          <span className="flex items-center  rounded-sm py-2">
            <RiCheckboxBlankCircleFill className="flex-none text-gray-400 mr-2 text-lg" />
            Deleted
          </span>
        );
    }
  };

  const handleClick = () => {
    switch (campaignItem.type) {
      case "one_click_upsell": {
        history.push(
          `/cart/cro/campaigns/oneclickupsells/edit/${campaignItem.id}`
        );
        break;
      }
      case "goal_meter_in_cart": {
        history.push(`/cart/cro/campaigns/cartgoals/edit/${campaignItem.id}`);
        break;
      }
      case "cart_announcement": {
        history.push(
          `/cart/cro/campaigns/cartannouncement/edit/${campaignItem.id}`
        );
        break;
      }
      case "bxgy_free": {
        history.push(`/cart/cro/campaigns/buyxgety/edit/${campaignItem.id}`);
        break;
      }
      case "discount_code": {
        history.push(
          `/cart/cro/campaigns/discountcode/edit/${campaignItem.id}`
        );
        break;
      }
      case "cart_timer": {
        history.push(`/cart/cro/campaigns/carttimer/edit/${campaignItem.id}`);
        break;
      }
    }
  };

  const removeCampaignDetails = () => {
    updateCampaignMutation({
      id: campaignItem.id,
      values: {
        status: "draft",
        delete_after: new Date().toISOString(),
      },
    });
  };

  const removeFnDiscountFromShopify = async (campaignType) => {
    const campaignRemoveRqst = {
      campaign_id: parseInt(campaignItem.id),
      shop_url: workspace.data.storeMyShopifyUrl,
    };
    const isCampaignRemoved = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/campaign/unpublish`,
      campaignRemoveRqst
    );
    if (isCampaignRemoved.status !== "OK") {
      const apiErrorKey = nanoid(10);
      setCampaignDelete({
        isModalOpen: false,
        isLoading: false,
      });
      setErrorDetails((prevState) => ({
        ...prevState,
        isModalOpen: true,
        apiErrorKey: apiErrorKey,
        isLoading: false,
      }));
      window.Rollbar.error(
        `Cornercart unpublish api failed in ${campaignType}`,
        {
          ...isCampaignRemoved,
          shopifyStoreURL: workspace.data.storeMyShopify,
          API_ERROR_KEY: apiErrorKey,
        }
      );
    } else {
      removeCampaignDetails();
    }
  };

  const handleDelete = async () => {
    setCampaignDelete((prevState) => ({ ...prevState, isLoading: true }));
    setErrorDetails({
      isModalOpen: false,
      apiErrorKey: "",
      isBtnDisabled: false,
      isLoading: false,
    });
    const { type, status } = campaignItem;
    if (
      (type === "goal_meter_in_cart" || type === "bxgy_free") &&
      status === "active"
    ) {
      removeFnDiscountFromShopify(type);
    } else removeCampaignDetails();
  };
  return (
    <>
      <div className="grid grid-cols-12 items-center justify-center py-3 desktop:py-5">
        <div className="col-span-1 mobile:hidden laptop:flex justify-center ">
          <img className="w-9 desktop:w-12" src={iconImageUrl} alt="" />
        </div>
        <button
          onClick={handleClick}
          className="laptop:col-span-5 mobile:col-span-10 desktop:px-7 cursor-pointer mobile:px-3 text-left"
        >
          <p className="font-bold text-black-500 hover:text-violet-700  desktop:text-base text-tiny">
            {campaignItem?.settings?.title}
          </p>
          <div className="flex items-center">
            <p className="text-gray-500 flex items-center text-tiny desktop:text-base mr-3">
              {typeName}
            </p>
            <div className="ml-3 mobile:block laptop:hidden">
              {getCampaignStatus(campaignItem.status)}
            </div>
          </div>
        </button>
        <div className="col-span-2 laptop:flex mobile:hidden ">
          {getCampaignStatus(campaignItem.status)}
        </div>
        <div className="px-3 col-span-3 mobile:hidden laptop:flex justify-center">
          <Button onClick={handleClick} type="outline" icon={<RiEdit2Line />}>
            <span className="laptop:block mobile:hidden ml-2">Edit</span>
          </Button>
        </div>
        <div className="col-span-1 flex justify-center">
          <Button
            noPadding
            className="px-3 py-3 rounded-lg border border-solid border-red-300"
            type="danger-ghost"
            icon={<RiDeleteBin6Line />}
            onClick={() =>
              setCampaignDelete((prevState) => ({
                ...prevState,
                isModalOpen: true,
              }))
            }
          />
        </div>
      </div>
      <DeleteModal
        isOpen={campaignDelete.isModalOpen}
        isLoading={campaignDelete.isLoading}
        title="You are about to delete this campaign"
        description="This process cannot be reversed, are you sure you want to delete the campaign?"
        handleClose={() =>
          setCampaignDelete((prevState) => ({
            ...prevState,
            isModalOpen: false,
          }))
        }
        handleDelete={() => handleDelete()}
      />
      <ErrorModal
        isOpen={errorDetails.isModalOpen}
        onClose={() =>
          setErrorDetails((prevState) => ({
            ...prevState,
            isModalOpen: false,
            apiErrorKey: "",
          }))
        }
        secondaryAction={
          <Button
            icon={<RiArrowGoForwardFill />}
            disabled={errorDetails.isBtnDisabled}
            type="outline"
            loading={errorDetails.isLoading}
            onClick={() => {
              setErrorDetails((prevState) => ({
                ...prevState,
                isLoading: true,
                isBtnDisabled: true,
                isModalOpen: false,
              }));
              removeFnDiscountFromShopify();
            }}
          >
            Retry Syncing
          </Button>
        }
        title="Sorry. Unable to delete the campaign"
        description="There was an error in deleting the campaign reward details from Shopify. Please try once again and if this happens again, please contact our support team."
        handleApiError={() => {
          contactCrisponApiError(
            errorDetails.apiErrorKey,
            `Could you please help with this issue: "Syncing campaign details to Shopify failed" ?`
          );
        }}
      />
    </>
  );
};

export default CampaignListItem;
