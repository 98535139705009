import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import {
  RiFlagFill,
  RiDeleteBin6Line,
  RiAlertFill,
  RiEdit2Fill,
  RiInformationFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../../components/Button";
import { FieldArray } from "formik";
import PercentageAmountInput from "../../../../../components/PercentageAmountInput";
import toaster from "toasted-notes";
import { parseShopifyProductId } from "../../../../../utilities/basicUtilityFunctions";
import MultiResourceSelector from "../../../../../components/MultiResourceSelector";
import NumberInput from "../../../../../components/NumberInput";
import AddGoalButton from "./AddGoalButton";
import InitialRewardSelect from "./InitialRewardSelect";

/**
 * A UI component for render a milestone item
 * @param {Object} currentMilestone The milestone this specific component need to render
 * @param {Object} index index of this milestone in the milestone array
 * @param {Object} values values from the parent formik
 * @param {Function} setFieldValue formik funtion
 * @param {Array} onExpand what happens if you click expand/collapse button
 * @param {Function} isExpanded - Boolean showing if the goal item is expanded or not
 * @param {Function} onDelete - What happens on deletion of milestone
 */
export default function GoalItem({
  currentMilestone,
  index,
  values,
  setFieldValue,
  onExpand,
  isExpanded,
  onDelete,
  isValidationFailed,
  currentCampaignKey,
}) {
  const [isGoalValid, setIsGoalValid] = useState(true);
  const [isChangeGoalVisible, setIsChangeGoalVisible] = useState(false);
  const workspace = useWorkspace();

  const rewardOptions = [
    { label: "Give Free Shipping", value: "shippingDiscount" },
    { label: "Give Free Products", value: "freeProduct" },
    {
      label: "Give an Cart Discount",
      value: "orderDiscount",
      disabled: true,
    },
  ];

  const getRewardType = () => {
    let currentReward = rewardOptions.find(
      (item) => item.value === currentMilestone.rewards.rewardStack[0].type
    );
    return currentReward.label;
  };

  /**
   * This function return a warning toaster
   * @param {String} warningText text to be shown in toaster
   * @returns {JSX} warning toaster
   */
  const showWarningToaster = (warningText) => {
    toaster.notify(
      <div className="flex flex-row items-center">
        <RiAlertFill className="text-orange-400" />
        <p className="text-base ml-2">{warningText}</p>
      </div>,
      {
        duration: 5000,
      }
    );
  };

  /**
   *This function renders reward selector
   * @param {[Object]} rewards reward object
   * @param {Function} setFieldValue a function to change value in the formink
   * @param {Number} index index number of the goal
   * @returns {Node} the react node of the reward selector
   */
  const renderRewardSelector = (rewards, setFieldValue, index) => {
    const { rewardStack, stackSelectCount } = rewards;
    const rewardType = rewardStack[0].type;

    switch (rewardType) {
      case "shippingDiscount": {
        return (
          <div className="p-3 border rounded-lg flex items-center justify-between">
            <RiInformationFill size={22} />
            <span className="text-sm ml-2">
              Make sure that you setup free shipping options in Shopify Admin
              first.
              <a
                className="font-bold shrink-0 hover:text-violet-600"
                target="_blank"
                href="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/setting-up-shipping-rates#free-shipping-over-a-certain-value"
              >
                Click here to learn how
              </a>
            </span>
          </div>
        );
      }
      case "orderDiscount": {
        return (
          <div className="flex items-center border rounded-lg p-3">
            <p className="mr-4">Discount amount in percentage</p>
            <span className="w-24">
              <Textfield
                value={rewardStack[0].value}
                min={1}
                max={100}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack[0].value`,
                    parseInt(onChangeEvent.target.value)
                  );
                }}
                isInvalid={
                  !(rewardStack[0].value > 0 && rewardStack[0].value < 100)
                }
                name="basic"
                type="number"
              />
            </span>
            {/* <PercentageAmountInput
              amount={rewardStack[0].value}
              dimention={rewardStack[0].unit}
              onAmountChange={(value) => {
                setFieldValue(
                  `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack[0].value`,
                  value
                );
              }}
              onDimentionChange={(value) => {
                setFieldValue(
                  `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack[0].unit`,
                  value
                );
              }}
              currency={workspace.data.storeCurrency}
            /> */}
          </div>
        );
      }
      case "freeProduct": {
        return (
          <>
            <div className="-mt-1">
              <FieldArray
                name={`campaign_cart_goals[0].milestones[${index}].rewards.rewardStack`}
                render={(arrayHelpers) => {
                  const productArray = rewardStack.filter(
                    (reward) => reward.type === "freeProduct" && reward.target
                  );
                  return (
                    <MultiResourceSelector
                      initialValues={productArray.map((item) => item.target)}
                      btnText={
                        productArray.length === 0
                          ? "Add a Product"
                          : "Add More Products"
                      }
                      emptyWarning={
                        isValidationFailed && (
                          <div className="flex items-center justify-center mt-1">
                            <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-full">
                              <RiAlertFill
                                size={12}
                                className="grow-0 animate-bounce"
                              />
                              <span className="ml-3">
                                Make sure a product is added
                              </span>
                            </div>
                          </div>
                        )
                      }
                      onAdd={(selectedProduct) => {
                        const selectedProductId = parseShopifyProductId(
                          selectedProduct[0].id
                        );
                        const rewardProduct = {
                          title: selectedProduct[0].title,
                          id: selectedProduct[0].id,
                          product_id: selectedProductId,
                          handle: selectedProduct[0].handle,
                          productType: selectedProduct[0].productType,
                          images: selectedProduct[0].images,
                          status: selectedProduct[0].status,
                          totalInventory: selectedProduct[0].totalInventory,
                          tracksInventory: selectedProduct[0].tracksInventory,
                          tags: selectedProduct[0].tags,
                        };
                        // if reward stack have one item then change the target product of the first item
                        // else push items to reward stack
                        if (rewardStack[0] && !rewardStack[0].target) {
                          setFieldValue(
                            `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack`,
                            [
                              {
                                ...rewardStack[0],
                                target: rewardProduct,
                              },
                            ]
                          );
                        } else {
                          setFieldValue(
                            `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack`,
                            [
                              ...rewardStack,
                              {
                                type: "freeProduct",
                                target: rewardProduct,
                                value: 100,
                                unit: "percent",
                                promoCode: rewardStack[0].promoCode,
                              },
                            ]
                          );
                        }
                      }}
                      onDelete={(deletedIndex, deletedProduct) => {
                        if (rewardStack.length > 1) {
                          arrayHelpers.remove(deletedIndex);
                          if (stackSelectCount > rewardStack.length - 1) {
                            setFieldValue(
                              `campaign_cart_goals[0].milestones[${index}].rewards.stackSelectCount`,
                              rewardStack.length - 1
                            );
                          }
                        } else {
                          setFieldValue(
                            `campaign_cart_goals[0].milestones[${index}].rewards.rewardStack[0].target`,
                            null
                          );
                        }
                      }}
                    />
                  );
                }}
              />
            </div>

            {/* user selection */}
            {rewardStack.length > 1 && (
              <CustomiserUnit
                title="How many gifts user can select"
                description="Select how many gifts can they choose from the above product list"
              >
                <NumberInput
                  value={stackSelectCount}
                  max={rewardStack.length}
                  min={1}
                  onChange={(value) => {
                    const stackValue = parseInt(value);
                    if (stackValue > 0 && stackValue <= rewardStack.length) {
                      setFieldValue(
                        `campaign_cart_goals[0].milestones[${index}].rewards.stackSelectCount`,
                        parseInt(value)
                      );
                    }
                  }}
                />
              </CustomiserUnit>
            )}
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (currentMilestone.rewards.rewardStack.length > 0) {
      if (
        currentMilestone.rewards.rewardStack[0].type === "freeProduct" &&
        !currentMilestone.rewards.rewardStack[0]?.target?.id
      ) {
        setIsGoalValid(false);
      } else if (currentMilestone.goal < 1 || !currentMilestone.goal) {
        setIsGoalValid(false);
      }
      // The following condition makes sure the value doesnt increase than the next stage
      else if (
        values.campaign_cart_goals[0].milestones.length > index + 1 &&
        currentMilestone.goal >=
          values.campaign_cart_goals[0].milestones[index + 1].goal
      ) {
        setIsGoalValid(false);
      }
      // The following condition makes sure the value doesnt decrease than the previous stage
      else if (
        index > 0 &&
        currentMilestone.goal <=
          values.campaign_cart_goals[0].milestones[index - 1].goal
      ) {
        setIsGoalValid(false);
      } else {
        setIsGoalValid(true);
      }
    } else {
      setIsGoalValid(false);
    }
  }, [currentMilestone]);

  return (
    <div
      className={` border-b transition-all overflow-hidden ${
        !isGoalValid && isValidationFailed && "border-red-100"
      }`}
    >
      <div
        className={`flex  items-center justify-between w-full px-2 ${
          isExpanded && "border-b"
        }`}
      >
        <button
          onClick={onExpand}
          className="flex flex-grow items-center py-4 px-2"
        >
          {!isGoalValid && isValidationFailed ? (
            <div className="flex animate-bounce shrink-0 justify-center items-center mr-2  rounded-full h-8 w-8 bg-red-100 text-red-600">
              <RiAlertFill />
            </div>
          ) : (
            <div
              className={`flex justify-center shrink-0 items-center mr-2  rounded-full h-8 w-8 bg-violet-100 text-violet-600`}
            >
              <RiFlagFill />
            </div>
          )}
          <div className="text-left ml-2">
            <h2
              className={`font-bold text-lg shrink-0 ${
                !isGoalValid && isValidationFailed && "text-red-700"
              }`}
            >
              Goal{" "}
              {values.campaign_cart_goals[0].milestones.length > 1 && index + 1}
            </h2>
            <p className="text-sm text-left text-gray-500">
              {currentMilestone.rewards.rewardStack.length > 0 &&
                `${getRewardType()} when ${
                  values?.campaign_cart_goals[0]?.goalType === "totalOrderValue"
                    ? "total cart value"
                    : "total items in cart"
                } reaches ${currentMilestone.goal} ${
                  values.campaign_cart_goals[0].goalType === "totalOrderValue"
                    ? workspace.data.storeCurrency
                    : ""
                }`}
            </p>
          </div>
        </button>
        <div className="flex items-center">
          <Button onClick={onExpand} type="link">
            {isExpanded ? "Collapse" : "Expand"}
          </Button>

          {index !== 0 && (
            <Button
              onClick={onDelete}
              type="ghost"
              icon={<RiDeleteBin6Line />}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="p-4 ">
          {/* Spend Goal */}
          <CustomiserUnit
            title="Spend Goal"
            customiserUnitId={`spend-goal-value-${index}`}
            description={`Enter the ${
              values.campaign_cart_goals[0].goalType === "totalOrderValue"
                ? "amount"
                : "number of items"
            } needed to unlock the cart goal`}
          >
            <div className="flex items-center">
              <div className=" w-20">
                <Textfield
                  value={currentMilestone.goal}
                  min={1}
                  onChange={(onChangeEvent) => {
                    if (currentMilestone.goal < 1 || !currentMilestone.goal) {
                      setIsGoalValid(false);
                    }
                    // The following condition makes sure the value doesnt increase than the next stage
                    else if (
                      values.campaign_cart_goals[0].milestones.length >
                        index + 1 &&
                      currentMilestone.goal >=
                        values.campaign_cart_goals[0].milestones[index + 1].goal
                    ) {
                      setIsGoalValid(false);
                    }
                    // The following condition makes sure the value doesnt decrease than the previous stage
                    else if (
                      index > 0 &&
                      currentMilestone.goal <=
                        values.campaign_cart_goals[0].milestones[index - 1].goal
                    ) {
                      setIsGoalValid(false);
                    }

                    setFieldValue(
                      `campaign_cart_goals[0].milestones[${index}].goal`,
                      parseFloat(onChangeEvent.target.value)
                    );
                  }}
                  name="basic"
                  type="number"
                />
              </div>
              <span className="ml-1 font-bold">
                {values.campaign_cart_goals[0].goalType === "totalOrderValue"
                  ? workspace.data.storeCurrency
                  : "Items"}
              </span>
              {/* Error messages */}
              <div className="flex ml-3">
                {(currentMilestone.goal < 1 || !currentMilestone.goal) && (
                  <p
                    className={`py-2 px-2 my-3 rounded-lg flex text-sm items-center ${
                      !isGoalValid && isValidationFailed
                        ? "text-red-700 bg-red-100"
                        : " border"
                    }`}
                  >
                    <RiAlertFill
                      size={12}
                      className={`grow-0 mr-3 ${
                        !isGoalValid && isValidationFailed && "animate-bounce"
                      }`}
                    />

                    <span>The value must be greater than 0</span>
                  </p>
                )}
                {index < values.campaign_cart_goals[0].milestones.length - 1 &&
                  currentMilestone.goal >=
                    values.campaign_cart_goals[0].milestones[index + 1]
                      .goal && (
                    <p
                      className={`py-2 px-2 my-3 rounded-lg flex text-sm items-center ${
                        !isGoalValid && isValidationFailed
                          ? "text-red-700 bg-red-100"
                          : " bg-gray-100"
                      }`}
                    >
                      <RiAlertFill
                        size={12}
                        className={`grow-0 mr-3 ${
                          !isGoalValid && isValidationFailed && "animate-bounce"
                        }`}
                      />

                      <span>
                        The value must be lower than the spend goal of the next
                        goal
                      </span>
                    </p>
                  )}
                {index > 0 &&
                  currentMilestone.goal <=
                    values.campaign_cart_goals[0].milestones[index - 1]
                      .goal && (
                    <p
                      className={`py-2 px-2 my-3 rounded-lg flex text-sm items-center ${
                        !isGoalValid && isValidationFailed
                          ? "text-red-700 bg-red-100"
                          : " border"
                      }`}
                    >
                      {" "}
                      <RiAlertFill
                        size={12}
                        className={`grow-0 mr-3 ${
                          !isGoalValid && isValidationFailed && "animate-bounce"
                        }`}
                      />
                      <span>
                        The value must be greater than the spend goal of the
                        previous goal
                      </span>
                    </p>
                  )}
              </div>
            </div>
          </CustomiserUnit>

          {/* Reward */}
          <CustomiserUnit
            title="Reward"
            customiserUnitId={`select-reward-type-${index}`}
            description="The reward customer gets for achieving the cart goal"
          >
            {isChangeGoalVisible ? (
              <AddGoalButton
                title="Select reward type"
                values={values}
                currentCampaignKey={currentCampaignKey}
                onSelect={(value) => {
                  let updatedRewardStack = value.rewards;
                  let updatedContent = value.content;
                  //updating the reward id
                  updatedRewardStack.id = `${values.id}-${index}`;
                  setFieldValue(
                    `campaign_cart_goals[0].milestones[${index}].rewards`,
                    updatedRewardStack
                  );
                  setFieldValue(
                    `campaign_cart_goals[0].milestones[${index}].content`,
                    updatedContent
                  );
                }}
                onClose={() => {
                  setIsChangeGoalVisible(false);
                }}
              />
            ) : currentMilestone.rewards.rewardStack.length > 0 ? (
              <div className="mt-4 max-w-xl">
                <div className="border-x border-t px-4 py-3 rounded-t-lg flex justify-between items-center w-full">
                  <div className="">
                    <p className="text-xs text-gray-500 font-bold">
                      REWARD TYPE
                    </p>
                    <p className="font-bold"> {getRewardType()}</p>
                  </div>
                  <Button
                    className="px-3 py-2 ml-2 rounded-full"
                    type="ghost"
                    noPadding
                    onClick={() => setIsChangeGoalVisible(true)}
                    icon={<RiEdit2Fill size={14} />}
                  >
                    <span className="text-tiny goal-item-type-change">
                      Change
                    </span>
                  </Button>
                </div>
                <div className="-mt-1">
                  {renderRewardSelector(
                    currentMilestone.rewards,
                    setFieldValue,
                    index
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-4 max-w-xl">
                <InitialRewardSelect
                  rewardId={`${values.id}-${index}`}
                  isValidationFailed={isValidationFailed}
                  onSelect={(rewards, content) => {
                    setFieldValue(
                      `campaign_cart_goals[0].milestones[${index}].rewards`,
                      rewards
                    );
                    setFieldValue(
                      `campaign_cart_goals[0].milestones[${index}].content`,
                      content
                    );
                  }}
                  title="Select reward type"
                  currentCampaignKey={currentCampaignKey}
                />
              </div>
            )}
          </CustomiserUnit>
        </div>
      )}
    </div>
  );
}
