import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const RichTextEditor = ({ value, onChange, type, extraButtons }) => {
  const editor = useRef(null);
  let buttons;

  switch (type) {
    case "complete": {
      buttons = [
        "paragraph",
        "fontsize",
        "brush",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "center",
        "left",
        "right",
        "justify",
        "link",
        "image",
        "video",
        "table",
        "source",
        "find",
        "fullsize",
      ];
      break;
    }
    case "limited": {
      buttons = [
        "paragraph",
        "fontsize",
        "brush",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "center",
        "left",
        "right",
        "justify",
        "link",
        "fullsize",
      ];
      break;
    }
    case "minimal": {
      buttons = [
        "brush",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "link",
      ];
      break;
    }
  }
  if (extraButtons) {
    buttons = [...buttons, ...extraButtons];
  }
  const realConfig = useMemo(
    () => ({
      readonly: false,
      buttons: buttons,
      editHTMLDocumentMode: true,
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
    }),
    []
  );

  return (
    <div className="mt-3 mx-2">
      <JoditEditor
        ref={editor}
        value={value}
        config={realConfig}
        onChange={onChange}
      />
    </div>
  );
};

export default RichTextEditor;
