import React, { Children } from "react";
import { RiQuestionLine } from "react-icons/ri";
import Tooltip from "@atlaskit/tooltip";


const InlineTooltip = ({ message, children }) => {
  const customToolTipWrapper = React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    return (
      <div
        {...rest}
        ref={ref}
        style={{
          display: "inline-block",
        }}
      >
        {children}
      </div>
    );
  });
  return (
    <Tooltip content={message} tag={customToolTipWrapper}>
      <span className="cursor-pointer flex">
        {children}
        <span className="text-xs">
        <RiQuestionLine />
        </span>
      </span>
    </Tooltip>
  );
};

export default InlineTooltip;
