import React, { useState } from "react";
import TagGroup from "@atlaskit/tag-group";
import Tag from "@atlaskit/tag";
import Textfield from "@atlaskit/textfield";
import Button from "./Button";

/**
 * Settings Tab
 * @component
 * @param {[String]} arrayItems - Array of what ever the Array input should display
 * @param {Function} onAddition - Decide what happens on addition
 * @param {Function} onDeletion - Decide what happens on deletion
 * @param {String} addButtonText - Text on the add button
 * @param {Boolean} urlCheck - Whether to check the input is an url or not
 */
const List = ({
  arrayItems,
  onAddition,
  onDeletion,
  addButtonText,
  urlCheck,
}) => {
  const [inputValue, setInputValue] = useState();
  const handleAddition = () => {
    try {
      if (urlCheck) {
        const url = new URL(inputValue);
        onAddition(url.pathname);
      } else onAddition(inputValue);
    } catch (err) {
      console.log(err);
      window.alert("Invalid URL");
    }
    setInputValue("");
  };
  return (
    <>
      <TagGroup>
        {arrayItems.map((url, index) => (
          <Tag
            onAfterRemoveAction={() => onDeletion(index)}
            key={Math.random()}
            text={url}
          />
        ))}
      </TagGroup>
      <div className="flex items-center">
        <Textfield
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          name="basic"
        />
        <div className="flex-none ml-2">
          <Button onClick={() => handleAddition()} type="ghost">
            {addButtonText}
          </Button>
        </div>
      </div>
    </>
  );
};
export default List;
