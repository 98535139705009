import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import {
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiAlertFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  queryDiscountCodeCampaignById,
  updateDiscountCodeDetails,
} from "../../../../api/campaign";
import toaster from "toasted-notes";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import EditLangContent from "../../../../components/EditLangContent";
import getLanguageLabel from "../../../../utilities/getLanguageLabel";
import TakeOverDrawer from "../../../../components/TakeOverDrawer";
import {
  validateEmail,
  validateURL,
} from "../../../../utilities/basicUtilityFunctions";
import useClearCache from "../../../../utilities/useClearCache";

export default function DiscountCodeCampaign() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();

  const [isEditorVisibile, setIsEditorVisibile] = useState(false);
  const [validationFailed, setValidationFailed] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");

  const { data, refetch } = useQuery("queryDiscountCodeCampaignById", () =>
    graphQL(queryDiscountCodeCampaignById, { id: params.id })
  );

  const updateDiscountCodeCampaign = async (values) => {
    const updatedCampaignDetails = await graphQL(updateDiscountCodeDetails, {
      campaignId: values.campaignId,
      campaignInput: values.campaignDetails,
      discountCodeId: values.discountCodeId,
      discountCodeInput: values.discountCodeInput,
    });
    refetch();
    return updatedCampaignDetails;
  };

  const [updateDiscountCodeMutation] = useMutation(updateDiscountCodeCampaign, {
    onSuccess: () => {
      clearCache();
      queryCache.invalidateQueries("queryDiscountCodeCampaignById");
      toaster.notify("Discount Code Campaign updated 🎉", {
        duration: 2000,
      });
    },
  });

  const visibilityOptions = [
    {
      label: (
        <div className="flex items-center">
          <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused{" "}
        </div>
      ),
      value: "draft",
    },
  ];

  const getTextFieldValue = (field, formikValues) => {
    const selectedLangDetails =
      formikValues.campaign_discount_codes[0].content.filter(
        (contentItem) => contentItem.lang === selectedLang
      );
    const returnValue = selectedLangDetails[0].value[field];
    return returnValue;
  };

  const renderLanguageEditor = (formikValues, setFieldValue) => {
    const currentLangIndex =
      formikValues.campaign_discount_codes[0].content.findIndex(
        (contentItem) => contentItem.lang === selectedLang
      );
    return (
      <div className="h-full overflow-y-auto desktop:p-8 px-4 py-2">
        <CustomiserUnit
          title="Card Title text"
          description="Title text on the card shown"
        >
          <Textfield
            value={getTextFieldValue("cardTitle", formikValues)}
            onChange={(onChangeEvent) => {
              setFieldValue(
                `campaign_discount_codes[0].content[${currentLangIndex}].value.cardTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="Card Subtitle text"
          description="Subtitle text in the card"
        >
          <Textfield
            value={getTextFieldValue("cardSubTitle", formikValues)}
            onChange={(onChangeEvent) => {
              setFieldValue(
                `campaign_discount_codes[0].content[${currentLangIndex}].value.cardSubTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="CTA Button Text before"
          description="Call to action button text in the card before discount application"
        >
          <Textfield
            value={getTextFieldValue("preApplicationCta", formikValues)}
            onChange={(onChangeEvent) => {
              setFieldValue(
                `campaign_discount_codes[0].content[${currentLangIndex}].value.preApplicationCta`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="CTA Button Text after"
          description="Call to action button text in the card after discount is applied"
        >
          <Textfield
            value={getTextFieldValue("postApplicationCta", formikValues)}
            onChange={(onChangeEvent) => {
              setFieldValue(
                `campaign_discount_codes[0].content[${currentLangIndex}].value.postApplicationCta`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
      </div>
    );
  };

  const validateCampaign = (formikValues) => {
    let isCampaignValid = true;
    const { config, discount_code } = formikValues.campaign_discount_codes[0];
    if (!discount_code) isCampaignValid = false;
    if (config.imageUrl) {
      const isURLValid = validateURL(config.imageUrl);
      // if url is not valid set the flag to false
      if (!isURLValid) isCampaignValid = false;
    }

    if (isCampaignValid) setValidationFailed(false);
    else setValidationFailed(true);
    return isCampaignValid;
  };

  if (data && data.campaign_by_pk)
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data.campaign_by_pk}
          onSubmit={async (values) => {
            if (validateCampaign(values)) {
              const { settings, status } = values;
              const {
                id: discountCodeId,
                config,
                content,
                discount_code,
              } = values.campaign_discount_codes[0];
              updateDiscountCodeMutation({
                campaignId: { id: values.id },
                campaignDetails: {
                  settings: settings,
                  status: status,
                },
                discountCodeId: {
                  id: discountCodeId,
                },
                discountCodeInput: {
                  config: config,
                  content: content,
                  discount_code: discount_code,
                },
              });
            } else {
              toaster.notify(
                "Please fix the highlighted issues before you continue.",
                {
                  duration: 6000,
                }
              );
            }
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <PageViewContainer
                title={
                  <InlineEdit
                    defaultValue={values.settings.title}
                    editView={({ ...fieldProps }) => (
                      <Textfield {...fieldProps} name="basic" />
                    )}
                    readView={() => (
                      <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                        <b>{values.settings.title}</b>
                        <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                          <RiEdit2Fill
                            size={15}
                            className="mr-3 text-gray-500"
                          />
                          Edit name
                        </div>
                      </h2>
                    )}
                    onConfirm={(e) => {
                      setFieldValue("settings.title", e);
                    }}
                  />
                }
                action={
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <div className="flex items-center">
                      <span className="font-bold text-gray-700 mr-3">
                        Campaign Status:
                      </span>
                      <TabRadioButton
                        btnId="cart-annouce-message-save"
                        defaultValue={values.status}
                        options={visibilityOptions}
                        onChange={(selectedType) => {
                          setFieldValue("status", selectedType);
                        }}
                      />
                    </div>
                  )
                }
              >
                {dirty && (
                  <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPaywall />
                )}
                {!dirty &&
                  values.status === "draft" &&
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <CampaignPauseWarning />
                  )}

                <div className="h-full overflow-y-auto desktop:p-8 px-4 py-2 ">
                  <CustomiserUnit
                    title="Discount Code"
                    description="the discount code to be highlighted"
                  >
                    <Textfield
                      isInvalid={
                        validationFailed &&
                        values.campaign_discount_codes[0].discount_code === ""
                      }
                      value={values.campaign_discount_codes[0].discount_code}
                      onChange={(onChangeEvent) => {
                        setFieldValue(
                          `campaign_discount_codes[0].discount_code`,
                          onChangeEvent.target.value
                        );
                      }}
                      name="basic"
                    />
                    {validationFailed &&
                      values.campaign_discount_codes[0].discount_code ===
                        "" && (
                        <div className="flex items-center mt-2">
                          <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                            <RiAlertFill
                              size={12}
                              className="grow-0 animate-bounce"
                            />
                            <span className="ml-3">
                              Make sure you enter a valid Discount Code
                            </span>
                          </div>
                        </div>
                      )}
                  </CustomiserUnit>
                  <CustomiserUnit
                    title="Image URL"
                    description="Enter the image url"
                  >
                    <Textfield
                      isInvalid={
                        validationFailed &&
                        values.campaign_discount_codes[0].config.imageUrl !== ""
                      }
                      value={values.campaign_discount_codes[0].config.imageUrl}
                      onChange={(onChangeEvent) => {
                        setFieldValue(
                          `campaign_discount_codes[0].config.imageUrl`,
                          onChangeEvent.target.value
                        );
                      }}
                      name="basic"
                    />
                    {validationFailed &&
                      values.campaign_discount_codes[0].config.imageUrl !==
                        "" &&
                      !validateURL(
                        values.campaign_discount_codes[0].config.imageUrl
                      ) && (
                        <div className="flex items-center mt-2">
                          <div className="flex items-center justify-center text-red-700 bg-red-100 px-2 rounded-md p-1">
                            <RiAlertFill
                              size={12}
                              className="grow-0 animate-bounce"
                            />
                            <span className="ml-3">
                              Make sure you enter a valid URL
                            </span>
                          </div>
                        </div>
                      )}
                  </CustomiserUnit>
                  <CustomiserUnit
                    title="Edit Language Text"
                    description="Edit or Add translations for text"
                  >
                    <EditLangContent
                      contentList={values.campaign_discount_codes[0].content}
                      onLangAdd={(selectedLangValue) => {
                        setFieldValue("campaign_discount_codes[0].content", [
                          ...values.campaign_discount_codes[0].content,
                          {
                            lang: selectedLangValue.value,
                            value: {
                              cardTitle: "",
                              cardSubTitle: "",
                              preApplicationCta: "",
                              postApplicationCta: "",
                            },
                          },
                        ]);
                      }}
                      onLangEdit={(selectedLangValue) => {
                        setIsEditorVisibile(true);
                        setSelectedLang(selectedLangValue.lang);
                      }}
                      onLangRemove={(selectedLangValue) => {
                        const updatedContent =
                          values.campaign_discount_codes[0].content.filter(
                            (contentItem) =>
                              contentItem.lang !== selectedLangValue.lang
                          );
                        setFieldValue(
                          "campaign_discount_codes[0].content",
                          updatedContent
                        );
                      }}
                    />
                  </CustomiserUnit>
                </div>
                {/* Text Editor Section */}
                {isEditorVisibile && selectedLang && (
                  <TakeOverDrawer
                    title={
                      <div className="flex items-center">
                        <span className="ml-3 flex">
                          {getLanguageLabel(selectedLang)} Language Text Editor
                        </span>
                      </div>
                    }
                    actions={
                      <div className="flex items-center">
                        <Button
                          onClick={() => setIsEditorVisibile(false)}
                          className="mr-2"
                          type="ghost"
                        >
                          Close
                        </Button>
                      </div>
                    }
                    isOpen={isEditorVisibile}
                    onClose={() => setIsEditorVisibile(false)}
                  >
                    {renderLanguageEditor(values, setFieldValue)}
                  </TakeOverDrawer>
                )}
              </PageViewContainer>
            );
          }}
        </Formik>
      </>
    );
  else return <LoadingSpinner />;
}
