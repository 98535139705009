/**
 * This function is used for sending details to intercom
 */
const sendDetailsToIntercom = ({
  name,
  email,
  storeUrl,
  appInstalled,
  cornerPlanName,
  shopifyPlanName,
  unixFormatSignUpDate,
  shopifyPlanDisplayName,
}) => {
  const shopHash = sessionStorage.getItem("shopHash");
  if (window.Intercom) {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "ek496hq1",
      user_id: storeUrl,
      user_hash: shopHash,
      created_at: unixFormatSignUpDate,
      name: name,
      email: email,
      plan_name: cornerPlanName,
      "Shopify Plan Name": shopifyPlanName,
      "Corner Plan Name": cornerPlanName,
      "Shopify Plan Display Name": shopifyPlanDisplayName,
    });
  } else {
    window.Rollbar.error("Error in connecting with Intercom");
  }
};

export default sendDetailsToIntercom;
