import React, { useState } from "react";
import { Formik } from "formik";
import toaster from "toasted-notes";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import TextArea from "@atlaskit/textarea";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import SucoPricingModal from "../../../../components/SucoPricingModal";
import PaywallBadge from "../../../../components/PaywallBadge";
import Button from "../../../../components/Button";
import TabRadioButton from "../../../../components/TabRadioButton";
import FaqIntro from "./FaqIntro";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiEdit2Fill,
  RiEyeLine,
  RiLandscapeFill,
} from "react-icons/ri";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import FaqPreview from "./FaqPreview";
import BannerImageSelector from "../../../../components/BannerImageSelector";
import useClearCache from "../../../../utilities/useClearCache";

const FaqPage = () => {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache("supportcorner");

  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isBannerThemeModalOpen, setBannerThemeModalOpen] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const [urlChanger, setUrlChanger] = useState(false);
  const [isCustomThemeModalOpen, setIsCustomThemeModalOpen] = useState(false);
  const [customBannerImage, setCustomBannerImage] = useState("");
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };
  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Launcher settings saved successfully", {
        duration: 2000,
      });
      clearCache();
    },
  });

  const bannerTextColorOptions = [
    { label: "Light", value: "white" },
    { label: "Dark", value: "black" },
  ];

  const initialValues = {
    id: data && data.widgets_cornerwidget[0].id,
    appProxyUrl: data && data.widgets_cornerwidget[0].faq_config.appProxyUrl,
    bannerText: data && data.widgets_cornerwidget[0].faq_config.bannerText,
    bannerTextColor:
      data && data.widgets_cornerwidget[0].faq_config.bannerTextColor,
    customCss: data && data.widgets_cornerwidget[0].faq_config.customCss,
    bannerImage: data && data.widgets_cornerwidget[0].faq_config.bannerImage,
    viewType: data && data.widgets_cornerwidget[0].faq_config.viewType,
  };

  if (data)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const faqConfig = {
            faq_config: {
              bannerText: values.bannerText,
              bannerTextColor: values.bannerTextColor,
              bannerImage: values.bannerImage,
              appProxyUrl: values.appProxyUrl,
              customCss: values.customCss,
              viewType: values.viewType,
            },
          };

          updateWidgetMutation({
            id: { id: values.id },
            config: faqConfig,
          });
        }}
      >
        {(props) => (
          <PageViewContainer
            action={
              <div className="flex items-center text-violet-700">
                <p className="mobile:hidden laptop:block mr-3 ">
                  <b>URL:</b>
                  {`${workspace.data.storeUrl}/a/${data.widgets_cornerwidget[0].faq_config.appProxyUrl}`}
                </p>
                {props.dirty ? (
                  <Button
                    type="primary"
                    icon={<RiEyeLine />}
                    onClick={() => props.submitForm()}
                  >
                    Save Settings
                  </Button>
                ) : (
                  <Button
                    className="mr-2"
                    type="white-ghost"
                    onClick={() => {
                      if (workspace.data)
                        window.open(
                          `https://${workspace.data.storeUrl}/a/${data.widgets_cornerwidget[0].faq_config.appProxyUrl}`
                        );
                    }}
                    icon={<RiEyeLine />}
                  >
                    View it on my store
                  </Button>
                )}
              </div>
            }
            title="FAQ Page"
          >
            <div className="h-full overflow-y-auto ">
              {data && (
                <FaqIntro
                  url={`https://${workspace.data.storeUrl}/a/${data.widgets_cornerwidget[0].faq_config.appProxyUrl}`}
                />
              )}
              <div className="desktop:px-8 mobile:px-4 mt-8">
                <h2 className="font-semibold mb-4 text-gray-700">
                  Customise FAQ Page
                </h2>
                <div className="flex ">
                  <div className="max-w-xl shrink-0 pb-10">
                    <CustomiserUnit
                      title="FAQ Page type"
                      description="Choose whether you want to display your FAQs as accordion or as a set of category cards. Accordions are more ideal for short answers and choose cards layout if your FAQs are longer"
                    >
                      <TabRadioButton
                        defaultValue={props.values.viewType}
                        options={[
                          { value: "cards", label: "Cards" },
                          { value: "accordion", label: "Accordions" },
                        ]}
                        onChange={(e) => {
                          props.setFieldValue("viewType", e);
                        }}
                      />
                    </CustomiserUnit>
                    <CustomiserUnit
                      title="Banner Text"
                      description="Customise the text shown on top banner of the faq page"
                    >
                      <Textfield
                        defaultValue={props.values.bannerText}
                        onChange={(e) => {
                          props.setFieldValue("bannerText", e.target.value);
                        }}
                        name="basic"
                      />
                    </CustomiserUnit>

                    <CustomiserUnit
                      title="Text Color"
                      description="For dark backgrounds use light text and for light backgrounds use dark text "
                    >
                      <Select
                        isSearchable={false}
                        value={bannerTextColorOptions.find(
                          (option) =>
                            option.value === props.values.bannerTextColor
                        )}
                        onChange={(e) =>
                          props.setFieldValue("bannerTextColor", e.value)
                        }
                        className="w-48 mr-2 "
                        classNamePrefix="react-select"
                        options={bannerTextColorOptions}
                      />
                    </CustomiserUnit>
                    <CustomiserUnit
                      title="Banner Theme"
                      description="Select themes to customize the banner image in your faq page. Your widget's theme color is used as default"
                    >
                      <div className="flex items-cente">
                        <Button
                          icon={<RiLandscapeFill />}
                          onClick={() => setBannerThemeModalOpen(true)}
                          type="outline"
                        >
                          Select from theme library
                        </Button>
                        <Button
                          icon={<RiEdit2Fill />}
                          onClick={() => {
                            {
                              workspace.data?.feature_flag?.apps?.supportcorner
                                ?.custom_help_page_banner
                                ? setIsCustomThemeModalOpen(true)
                                : setIsBillingModalOpen(true);
                            }
                          }}
                          type="link-thin"
                          className="relative"
                        >
                          <span> Custom image</span>
                          {!workspace.data?.feature_flag?.apps?.supportcorner
                            ?.custom_help_page_banner && (
                            <PaywallBadge absolute type="pro" />
                          )}
                        </Button>
                      </div>
                    </CustomiserUnit>

                    <div className="flex justify-center">
                      <Button
                        type="link"
                        onClick={() => {
                          setAdvancedOptions(!advancedOptions);
                          setUrlChanger(false);
                        }}
                        icon={
                          advancedOptions ? (
                            <RiArrowDropUpLine />
                          ) : (
                            <RiArrowDropDownLine />
                          )
                        }
                      >
                        {advancedOptions
                          ? "Hide Advanced options"
                          : "Advanced Settings"}
                      </Button>
                    </div>
                    {advancedOptions && (
                      <>
                        <CustomiserUnit
                          paywall={
                            !workspace.data?.feature_flag?.apps?.supportcorner
                              ?.custom_help_page_css
                          }
                          paywallPlan={3}
                          title="Custom Css"
                          description="Add custom CSS to make changes to your FAQ page. Please use !important in your CSS to make sure it gets precedence over the default CSS"
                        >
                          <TextArea
                            defaultValue={props.values.customCss}
                            onChange={(e) => {
                              props.setFieldValue("customCss", e.target.value);
                            }}
                            name="basic"
                            minimumRows={7}
                          />
                        </CustomiserUnit>

                        <CustomiserUnit
                          title="Change FAQ Page URL"
                          description={
                            <span>
                              {" "}
                              <span
                                className={
                                  urlChanger ? `font-bold text-red-600` : ""
                                }
                              >
                                Important!
                              </span>{" "}
                              Normally, you won't need to change this setting.
                              If you wish to proceed anyway, ensure that before
                              changing this setting here, you have updated the
                              URL in Shopify Admin settings here{" "}
                              <a
                                className={`text-violet-700 relative`}
                                target="_blank"
                                href={`https://${workspace.data.storeUrl}/admin/app_installations/app/supportcorner`}
                              >
                                {urlChanger && (
                                  <>
                                    <span className=" absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600 opacity-75"></span>
                                    <span className=" absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600 opacity-75"></span>
                                  </>
                                )}
                                Shopify settings page{" "}
                              </a>
                              first
                            </span>
                          }
                        >
                          {!urlChanger && (
                            <Button
                              type="outline"
                              onClick={() => setUrlChanger(!urlChanger)}
                            >
                              Click here only after you've changed URL in
                              Shopify settings
                            </Button>
                          )}
                          {urlChanger && (
                            <div className="flex items-center">
                              <span className="text-gray-400 bg-gray-100 p-2 border-2 border-gray-100 rounded-l-md">
                                {workspace.data.storeUrl}/a/
                              </span>
                              <div className="w-2/4">
                                <Textfield
                                  defaultValue={props.values.appProxyUrl}
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "appProxyUrl",
                                      e.target.value
                                    );
                                  }}
                                  name="basic"
                                />
                              </div>
                            </div>
                          )}
                        </CustomiserUnit>
                      </>
                    )}
                  </div>
                  <div className="laptop:block mobile:hidden grow px-10 text-center ">
                    <small className="text-gray-400">FAQ Banner Preview</small>
                    <FaqPreview
                      bannerText={props.values.bannerText}
                      bannerImage={props.values.bannerImage}
                      bannerTextColor={props.values.bannerTextColor}
                      bgStartColor={
                        data.widgets_cornerwidget[0].common_config.bgStartColor
                      }
                      bgEndColor={
                        data.widgets_cornerwidget[0].common_config.bgEndColor
                      }
                      searchBox={
                        data.widgets_cornerwidget[0].common_config
                          .contextConfigs.assistant.search
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <SucoPricingModal
              hightlightPlan={3}
              title="You need a higher plan to access this feature"
              isOpen={isBillingModalOpen}
              onClose={() => setIsBillingModalOpen(false)}
            />
            <ModalTransition>
              {isBannerThemeModalOpen && (
                <Modal onClose={() => setBannerThemeModalOpen(false)}>
                  <div className="flex flex-col h-full">
                    <div className="p-4 shrink overflow-auto">
                      <h2 className="text-base font-bold mb-4">
                        Select a background image
                      </h2>
                      <div className="">
                        <BannerImageSelector
                          onSelect={(imageUrl) => {
                            props.setFieldValue("bannerImage", imageUrl);
                          }}
                          textColor={props.values.bannerTextColor}
                          bgStartColor={
                            data.widgets_cornerwidget[0].common_config
                              .bgStartColor
                          }
                          bgEndColor={
                            data.widgets_cornerwidget[0].common_config
                              .bgEndColor
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-row justify-end p-4 border-t">
                      <Button
                        onClick={() => setBannerThemeModalOpen(false)}
                        type="link"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ml-4"
                        onClick={() => setBannerThemeModalOpen(false)}
                        type="primary"
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                </Modal>
              )}
              {isCustomThemeModalOpen && (
                <Modal onClose={() => setIsCustomThemeModalOpen(false)}>
                  <div className="p-6">
                    <h2 className="text-base font-bold mb-4">
                      Select a custom background image
                    </h2>
                    <p>
                      Insert a image URL of the prefered image below. Uploading
                      an image in
                      <b> Files</b> section in shopify's setting will give you
                      an Image URL. Paste it here in the text box below.
                    </p>
                    <div className="mt-3">
                      <Textfield
                        name="basic"
                        placeholder="Paste Image URL here"
                        onChange={(e) => {
                          setCustomBannerImage(e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-row justify-end mt-4">
                      <Button
                        onClick={() => setIsCustomThemeModalOpen(false)}
                        type="link"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ml-4"
                        onClick={() => {
                          props.setFieldValue("bannerImage", customBannerImage);
                          setIsCustomThemeModalOpen(false);
                        }}
                        type="primary"
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                </Modal>
              )}
            </ModalTransition>
          </PageViewContainer>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default FaqPage;
