import React from "react";
import AlertBanner from "./AlertBanner";

/**
 * Warning component when campaigns are not synced ("not_published")
 * @returns {JSX} component
 */
const CampaignSyncFailWarning = () => {
  return (
    <AlertBanner
      type="warning"
      layout="banner"
      message={`This campaign is currently not synced to shopify. Make it "Active" using the toggle button in the top right or contact us.`}
    />
  );
};

export default CampaignSyncFailWarning;
