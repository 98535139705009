import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import AudienceRuleBuilder from "../../../../components/jsonLogicBuilders/AudienceRuleBuilder";
import CartRuleBuilder from "../../../../components/jsonLogicBuilders/CartRuleBuilder";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import {
  RiAddCircleLine,
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiShoppingCart2Fill,
  RiLinksFill,
  RiDraftFill,
  RiAlertFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import PopupSelect from "../../../../components/PopupSelect";
import Button from "../../../../components/Button";
import { Formik, FieldArray } from "formik";
import toaster from "toasted-notes";
import {
  queryAutomationsById,
  updateAutomations,
} from "../../../../api/automations";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  defaultAddItemAction,
  defaultAddCartAttributeAction,
  defaultGoToUrl,
} from "../../../../utilities/variables";
import ActionItem from "./ActionItem";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import useClearCache from "../../../../utilities/useClearCache";

export default function AutomationsEdit() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isValidationFailed, setIsValidationFailed] = useState(false);

  const [expandedActions, setExpandedActions] = useState(0);

  const { data, refetch } = useQuery("queryAutomationsById", () =>
    graphQL(queryAutomationsById, { id: params.id })
  );

  const updateAutomation = async (values) => {
    await graphQL(updateAutomations, {
      automationsId: values.id,
      automationsInput: values.automationsDetails,
    });
    refetch();
  };

  const [updateAutomationsMutation] = useMutation(updateAutomation, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryAutomationsById");
      clearCache();
      toaster.notify("Cart Goals updated 🎉", {
        duration: 2000,
      });
    },
  });

  const visibilityOptions = [
    {
      label: (
        <div className="flex items-center">
          <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused{" "}
        </div>
      ),
      value: "draft",
    },
  ];

  const actionOptions = [
    {
      label: "Add an item to cart",
      value: "add_item",
      icon: <RiShoppingCart2Fill size={14} />,
    },
    {
      label: "Add a cart attribute",
      value: "add_cart_attribute",
      icon: <RiDraftFill size={14} />,
    },
    {
      label: "Redirect to a URL",
      value: "goto_url",
      icon: <RiLinksFill size={14} />,
    },
  ];

  const checkIsAutomationValid = (values) => {
    const checkIfConditionHaveNoNullProducts = (rules) => {
      let result = true;
      const topLevelLogic = Object.keys(rules)[0];
      if (topLevelLogic === "everyone") return (result = false);
      rules[topLevelLogic].map((group) => {
        let groupLevelRule = Object.keys(group)[0];
        group[groupLevelRule].map((ruleItem) => {
          let ruleItemRule = Object.keys(ruleItem)[0];
          if (
            ruleItemRule === "some" &&
            ruleItem[ruleItemRule][1]["and"][0]["=="][1] === null
          ) {
            result = false;
          }
        });
      });
      return result;
    };
    const checkIfActionsAreValid = (actions) => {
      let result = true;

      if (actions.length === 0) result = false;

      actions.map((action) => {
        if (action.actionType === "add_item") {
          if (action.actionConfig.target === null) {
            result = false;
          }
          if (
            action.actionConfig.preferenceType === "merchantSelect" &&
            action.actionConfig.preferenceValue === null
          ) {
            result = false;
          }
        }
      });

      return result;
    };

    if (
      checkIfConditionHaveNoNullProducts(values.condition) &&
      checkIfActionsAreValid(values.actions)
    ) {
      setIsValidationFailed(false);
      return true;
    } else {
      setIsValidationFailed(true);
      return false;
    }
  };

  if (data && data.automations_by_pk)
    return (
      <Formik
        enableReinitialize
        initialValues={data.automations_by_pk}
        onSubmit={async ({ id, ...automationsDetails }) => {
          let isAutomationValid = checkIsAutomationValid(automationsDetails);
          if (isAutomationValid) {
            updateAutomationsMutation({
              id: { id: id },
              automationsDetails: {
                ...automationsDetails,
                // Making status draft if the automation is invalid
                status: isAutomationValid ? automationsDetails.status : "draft",
              },
            });
          } else {
            toaster.notify(
              "Please fix the issues highlighted in red before you continue",
              {
                duration: 12000,
              }
            );
          }
        }}
      >
        {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
          return (
            <PageViewContainer
              title={
                <InlineEdit
                  defaultValue={values.settings.title}
                  editView={({ ...fieldProps }) => (
                    <Textfield {...fieldProps} name="basic" />
                  )}
                  readView={() => (
                    <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                      <b>{values.settings.title}</b>
                      <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                        <RiEdit2Fill size={15} className="mr-3 text-gray-500" />
                        Edit name
                      </div>
                    </h2>
                  )}
                  onConfirm={(e) => {
                    setFieldValue("settings.title", e);
                  }}
                />
              }
              action={
                workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <div className="flex items-center">
                    <span className="font-bold text-gray-700 mr-3">
                      Automations Status:
                    </span>
                    <TabRadioButton
                      defaultValue={values.status}
                      options={visibilityOptions}
                      onChange={(selectedType) => {
                        setFieldValue("status", selectedType);
                      }}
                    />
                  </div>
                )
              }
            >
              {dirty && (
                <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                  <p>You've unsaved changes</p>
                  <div className="flex items-center">
                    <Button
                      onClick={() => {
                        resetForm();
                        setIsValidationFailed(false);
                      }}
                      type="link"
                    >
                      Discard Changes
                    </Button>
                    <Button
                      onClick={() => {
                        submitForm();
                      }}
                      type="primary"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
              {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                <CampaignPaywall />
              )}
              {!dirty &&
                values.status === "draft" &&
                workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPauseWarning />
                )}
              <div className=" overflow-y-auto px-8 py-4">
                {/* Cart rule */}
                <CustomiserUnit
                  title="Trigger (When this happens)"
                  description="This automation will begin when the rule defined here is satisfied"
                >
                  <CartRuleBuilder
                    productsMetadata={values.productMetadata}
                    value={values.condition}
                    warningIfEmpty
                    onRuleChange={(updatedJsonLogic) => {
                      setFieldValue("condition", { ...updatedJsonLogic });
                    }}
                    onProductsMetadataChange={(updatedValue) => {
                      setFieldValue("productMetadata", { ...updatedValue });
                    }}
                    emptyTitle="Add atleast one trigger"
                    emptySubtitle="For the automation to work you must have atleast one rule defined"
                  />
                </CustomiserUnit>

                {/* Actions */}
                <CustomiserUnit
                  title="Actions (Do these things)"
                  description="Specify the tasks to do if the above defined rule is satisfied"
                >
                  <FieldArray
                    name="actions"
                    render={(arrayHelpers) => {
                      return (
                        <div className="flex">
                          <div
                            className={`mr-2 ${
                              values.actions.length !== 0 && "grow"
                            }`}
                          >
                            {values.actions.map((action, index) => {
                              return (
                                <ActionItem
                                  action={action}
                                  values={values}
                                  isValidationFailed={isValidationFailed}
                                  onDelete={() => {
                                    if (expandedActions === index) {
                                      setExpandedActions(index - 1);
                                    }
                                    arrayHelpers.remove(index);
                                  }}
                                  isExpanded={expandedActions === index}
                                  onExpand={() => {
                                    if (expandedActions !== index)
                                      setExpandedActions(index);
                                    else setExpandedActions(null);
                                  }}
                                  index={index}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            })}
                            {/* Add New Action Button */}
                            <PopupSelect
                              isOpen={isEditPopupOpen}
                              alignment="bottom"
                              searchable={false}
                              options={actionOptions}
                              onClose={() =>
                                setIsEditPopupOpen((prevState) => !prevState)
                              }
                              onChange={(selectedObject) => {
                                let defaultValue;
                                switch (selectedObject.value) {
                                  case "add_item": {
                                    defaultValue = defaultAddItemAction;
                                    break;
                                  }
                                  case "add_cart_attribute": {
                                    defaultValue =
                                      defaultAddCartAttributeAction;
                                    break;
                                  }
                                  case "goto_url": {
                                    defaultValue = defaultGoToUrl;
                                    break;
                                  }
                                }

                                if (
                                  selectedObject.value === "goto_url" &&
                                  values.actions.findIndex(
                                    (actionObj) =>
                                      actionObj.actionType === "goto_url"
                                  ) != -1
                                ) {
                                  toaster.notify(
                                    "You can only have one redirect url in the actions",
                                    {
                                      duration: 2000,
                                    }
                                  );
                                } else {
                                  arrayHelpers.push(defaultValue);
                                  setExpandedActions(values.actions.length);
                                }
                                setIsEditPopupOpen((prevState) => !prevState);
                              }}
                              trigger={
                                <Button
                                  onClick={() => {
                                    setIsEditPopupOpen(
                                      (prevState) => !prevState
                                    );
                                  }}
                                  type={
                                    values.actions.length > 0
                                      ? "link-primary"
                                      : "outline"
                                  }
                                  icon={<RiAddCircleLine />}
                                >
                                  <span>Add an action</span>
                                </Button>
                              }
                            />
                          </div>
                          {values.actions.length === 0 && (
                            <div className="felx items-center text-tiny flex text-red-700 border border-red-500 p-2 rounded-lg max-w-xl">
                              <RiAlertFill />
                              <span className="ml-2">
                                Add atleast one action
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                </CustomiserUnit>

                {/* Audience targeting Rule Builder */}
                <CustomiserUnit
                  title="Audience (For these visitors)"
                  description="Create a rule to define the target visitors"
                >
                  <AudienceRuleBuilder
                    value={values.audience}
                    onChange={(updatedJsonLogic) => {
                      setFieldValue("audience", { ...updatedJsonLogic });
                    }}
                    emptyTitle="Targeting Everyone"
                    emptySubtitle="Right now, this automations is enabled to all customers. If you want this automation to work for a specific group of customers, add a new targeting rule."
                  />
                </CustomiserUnit>
              </div>
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
}
