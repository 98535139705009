import React, { useState } from "react";
import { RiAddCircleFill, RiTeamFill, RiDeleteBack2Fill } from "react-icons/ri";
import Button from "../../Button";
import PopupSelect from "../../PopupSelect";
import LogicSelector from "./LogicSelector";
import RuleItemGroup from "./RuleItemGroup";
import { visitorTargettingOptions } from "../defaultValues";

/**
 * This component lets you build jsonLogc objects
 * @component
 * @param {Function} onChange - Decide what happens on change
 * @param {object} value - initial jsonLogic
 * @param {String} emptyTitle - title when no rule is added
 * @param {String} emptySubtitle - title when no rule is added
 * @param {String} emptySubtitle - title when no rule is added
 * @param {'top'|'bottom'} popupAlignment - where shoud the popup align to
 */
const AudienceRuleBuilder = ({
  value,
  onChange,
  emptyTitle,
  emptySubtitle,
  popupAlignment = "top",
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const defaultTruthyRule = { everyone: true };
  const topLevelLogic = Object.keys(value)[0];

  /**
   * Adds a new Rule group
   * @param {String} selectedObject The selcted object contains the information about the first rule that needed to be added in this rule group
   */
  const handleGroupAddition = (selectedObject) => {
    const ruleToAdd = selectedObject.defaultValue;
    const groupToAdd = { and: [ruleToAdd] };
    let updatedValue;
    if (topLevelLogic === "everyone") {
      updatedValue = { and: [groupToAdd] };
    } else {
      updatedValue = { ...value };
      updatedValue[topLevelLogic] = [
        ...updatedValue[topLevelLogic],
        groupToAdd,
      ];
    }
    onChange(updatedValue);
  };

  /**
   * This function updates the top-level logic operator of teh JSON logic Object
   * @param {String} updatedOperator the operator that needed to be updated
   */
  const handleTopLevelLogicOperatorChange = (updatedOperator) => {
    let updatedValue = { ...value };
    updatedValue = {
      [updatedOperator]: updatedValue[topLevelLogic],
    };
    onChange(updatedValue);
  };

  if (topLevelLogic === "everyone")
    return (
      <div className="flex items-center border rounded-lg max-w-4xl p-4">
        <div className=" flex items-center">
          {" "}
          <div className="text-violet-700 bg-violet-100 p-4 rounded-full">
            <RiTeamFill size={26} />
          </div>
          <div className="mx-4 ">
            <p className="font-bold "> {emptyTitle}</p>
            <p className="text-gray-700 text-tiny mt-2"> {emptySubtitle}</p>
          </div>
        </div>
        <div className="flex shrink-0">
          <PopupSelect
            isOpen={isEditPopupOpen}
            alignment={popupAlignment}
            spacing="compact"
            maxMenuWidth={200}
            max
            options={visitorTargettingOptions}
            onClose={() => setIsEditPopupOpen((prevState) => !prevState)}
            onChange={(selectedObject) => {
              handleGroupAddition(selectedObject);
              setIsEditPopupOpen((prevState) => !prevState);
            }}
            trigger={
              <Button
                onClick={() => {
                  setIsEditPopupOpen((prevState) => !prevState);
                }}
                type="outline"
                icon={<RiAddCircleFill />}
              >
                <span>Add a targeting rule</span>
              </Button>
            }
          />
        </div>
      </div>
    );
  else {
    return (
      <div className="border rounded-lg max-w-5xl p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center text-tiny ">
            <span className="font-bold text-gray-500">When</span>
            <LogicSelector
              type="topLevel"
              logicOperator={topLevelLogic}
              onChange={(updatedOperator) =>
                handleTopLevelLogicOperatorChange(updatedOperator)
              }
            />{" "}
            <span className="font-bold text-gray-500">
              of the following is true
            </span>
          </div>
          <Button
            onClick={() => {
              onChange(defaultTruthyRule);
            }}
            className=" ml-3"
            type="danger-link"
            // icon={<RiDeleteBack2Fill />}
          >
            <span>Remove all rules</span>
          </Button>
        </div>
        <div>
          {value[topLevelLogic].map((rule, index) => {
            if (value[topLevelLogic].length > 1 && index > 0)
              return (
                <div key={`${index}-rule`} className=" mt-4 items-center">
                  <div className="font-bold shrink-0 text-sm text-gray-500 ">
                    RULE {index + 1}
                  </div>
                  <RuleItemGroup
                    groupIndex={index}
                    groupedOptions={visitorTargettingOptions}
                    ruleGroup={rule}
                    value={value}
                    topLevelLogic={topLevelLogic}
                    onChange={(updatedValue) => {
                      onChange(updatedValue);
                    }}
                  />
                </div>
              );
            else
              return (
                <div className="mt-4" key={`${index}-rule`}>
                  <div className="font-bold shrink-0 text-sm text-gray-500">
                    RULE {index + 1}
                  </div>
                  <RuleItemGroup
                    groupIndex={index}
                    groupedOptions={visitorTargettingOptions}
                    ruleGroup={rule}
                    value={value}
                    topLevelLogic={topLevelLogic}
                    onChange={(updatedValue) => {
                      onChange(updatedValue);
                    }}
                  />
                </div>
              );
          })}
        </div>

        <div className="mt-3">
          <PopupSelect
            isOpen={isEditPopupOpen}
            alignment={popupAlignment}
            spacing="compact"
            options={visitorTargettingOptions}
            onClose={() => setIsEditPopupOpen((prevState) => !prevState)}
            onChange={(selectedObject) => {
              handleGroupAddition(selectedObject);
              setIsEditPopupOpen((prevState) => !prevState);
            }}
            trigger={
              <Button
                onClick={() => {
                  setIsEditPopupOpen((prevState) => !prevState);
                }}
                type="outline"
                icon={<RiAddCircleFill />}
              >
                <span>Add a targeting rule</span>
              </Button>
            }
          />
        </div>
      </div>
    );
  }
};
export default AudienceRuleBuilder;
