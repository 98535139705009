import React, { useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// import 'react-clock/dist/Clock.css';
import "./index.css";

const CustomDatePicker = ({ isDisabled, value, onChange, minDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      onClick={() => setIsOpen(!open)}
      className="border border-solid border-gray-200 rounded-md p-2 text-left"
      disabled={isDisabled}
    >
      <DatePicker
        format="y-MM-dd"
        onChange={onChange}
        isOpen={isOpen}
        disabled={isDisabled}
        value={value ? new Date(value) : null}
        disableClock={true}
        calendarIcon={null}
        minDate={minDate ? new Date(minDate) : new Date()}
      />
    </button>
  );
};

export default CustomDatePicker;
