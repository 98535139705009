import React, { useState, useRef, useEffect } from "react";
import toaster from "toasted-notes";
import { Prompt } from "react-router";
import VisibilityOptions from "./VisibilityOptions";
import SEOOptions from "./SEOOptions";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import RichTextEditor from "../../../../components/RichTextEditor";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import List from "../../../../components/List";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import TextareaAutosize from "react-textarea-autosize";
import Popup from "@atlaskit/popup";
import {
  queryAssistantArticle,
  updateAssistantArticle,
  insertAssistantArticle,
  insertAssistantCategory,
  deleteAssistantArticle,
} from "../../../../api/support";
import { queryWidgetSettings } from "../../../../api/widgets";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiCheckboxBlankCircleFill,
  RiArrowDropDownLine,
  RiArrowDownSLine,
  RiFileAddLine,
  RiPencilFill,
  RiCheckboxCircleFill,
  RiDeleteBin2Line,
  RiLinksLine,
  RiStarLine,
  RiStarFill,
  RiFileWarningFill,
} from "react-icons/ri";
import InlineEdit from "@atlaskit/inline-edit";
import { FieldArray, Formik } from "formik";
import { langOptions } from "../../../../utilities/variables";
import LanguageEditPopup from "./LanguageEditPopup";
import useClearCache from "../../../../utilities/useClearCache";

const ArticleEdit = (historyProps) => {
  const editor = useRef(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isVisibilityOptionsOpen, setIsVisibilityOptionsOpen] = useState(false);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isSEOOptionsOpen, setIsSEOOptionsOpen] = useState(false);
  const [categoryCreator, setCategoryCreator] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [saveWarning, setSaveWarning] = useState(false);
  const [promptLocation, setPromptLocation] = useState("");
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();
  const clearCache = useClearCache("supportcorner");

  const [currentLang, setCurrentLang] = useState(null);

  const { data: widgetData, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const { data } = useQuery(
    `queryAssistantArticle${historyProps.match.params.id}`,
    () =>
      graphQL(queryAssistantArticle, {
        article_id: historyProps.match.params.id,
      })
  );

  useEffect(() => {
    if (data && data.assistant_articles && data.assistant_articles[0].content) {
      setCurrentLang(data.assistant_articles[0].content[0].lang);
    }
  }, [data]);

  const getTranslatedLanguages = (contentList) => {
    if (contentList) {
      const translatedLanguages = contentList.map((languageItem) => {
        return languageItem.lang;
      });
      return translatedLanguages;
    } else {
      return [];
    }
  };

  const updateArticle = async (input) => {
    await graphQL(updateAssistantArticle, {
      articleId: { id: input.id },
      articleInput: input.values,
    });
  };
  const insertArticleCategory = async (input) => {
    await graphQL(insertAssistantCategory, {
      categoryInput: { ...input },
    });
  };
  const deleteArticle = async (input) => {
    await graphQL(deleteAssistantArticle, {
      articleId: input.id,
    });
  };

  let duplicateArticleValue = {
    is_published: false,
    is_featured:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_featured,
    categoryid:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].categoryid,
    content:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].content.map((contentItem) => {
        return {
          ...contentItem,
          value: {
            ...contentItem.value,
            title: `[COPY OF] ${contentItem.value.title}`,
          },
        };
      }),
    visibility_rules:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].visibility_rules,
    metadata:
      data && data.assistant_articles[0] && data.assistant_articles[0].metadata,
    position_rank: 0,
    statistics: { likes: 0, views: 0 },
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
  };

  const [deleteArticleMutation] = useMutation(deleteArticle, {
    onSuccess: () => {
      toaster.notify("Article deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/support/faq/articles/`);
    },
  });

  const [updateArticleMutation] = useMutation(updateArticle, {
    onSuccess: () => {
      toaster.notify("Article updated", {
        duration: 2000,
      });
      queryCache.invalidateQueries(
        `queryAssistantArticle${historyProps.match.params.id}`
      );
      clearCache();

      // This handles redirection after the unsave prompt
      if (promptLocation.length > 0) window.location.href = promptLocation;
    },
  });
  const [insertArticleCategoryMutatiion] = useMutation(insertArticleCategory, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        `queryAssistantArticle${historyProps.match.params.id}`
      );
      clearCache();
    },
  });

  const insertArticle = async () => {
    return await graphQL(insertAssistantArticle, {
      articleInput: duplicateArticleValue,
    });
  };

  const [insertArticleMutation] = useMutation(insertArticle, {
    onSuccess: (data) => {
      historyProps.history.push(
        `/support/faq/articles/${data.insert_assistant_articles_one.id}/edit`
      );
      queryCache.invalidateQueries(
        `queryAssistantArticle${historyProps.match.params.id}`
      );
      clearCache();
    },
  });

  const handleCategoryCreation = () => {
    insertArticleCategoryMutatiion({
      sourceid: workspace.data && workspace.data.sourceid,
      content: [
        {
          lang: workspace.data.default_language,
          value: {
            name: categoryCreator ? categoryCreator : "Untitled",
          },
        },
      ],
      accountid: workspace.data && workspace.data.accountid,
      icon_url:
        "https://assets.cornercart.io/coca-v2/images/category-icons/color/1.svg",
    });
    setCategoryCreator("");
  };

  const widgetStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
          Publish{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Draft{" "}
        </div>
      ),
      value: false,
    },
  ];
  const FeatureStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiStarFill className="text-amber-500 text-xl" />
          Feature{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiStarLine className="text-gray-500 text-xl" />
          Not Featured{" "}
        </div>
      ),
      value: false,
    },
  ];

  const initialValues = {
    id: data && data.assistant_articles[0] && data.assistant_articles[0].id,
    is_published:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_published,
    is_featured:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_featured,
    categoryid:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].categoryid,
    content:
      data && data.assistant_articles[0] && data.assistant_articles[0].content,
    visibility_rules:
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].visibility_rules,
    metadata:
      data && data.assistant_articles[0] && data.assistant_articles[0].metadata,
  };

  const getDefaultLangCategoryName = (categoryContent) => {
    const defaultCategoryNameItem = categoryContent.content.filter(
      (categoryItem) => categoryItem.lang === workspace.data.default_language
    );
    if (defaultCategoryNameItem.length > 0) {
      return defaultCategoryNameItem[0].value.name;
    } else {
      return categoryContent.content[0].value.name;
    }
  };

  const getCategoryName = (categoryId) => {
    const categoryItem = data.assistant_categories.find(
      (category) => category.id === categoryId
    );
    return (
      <span className="flex mr-4  max-h-5 text-sm desktop:text-base">
        <p>{getDefaultLangCategoryName(categoryItem)}</p>
      </span>
    );
  };

  const getCurrentLangIndex = (contentValues) => {
    const currentLangIndex = contentValues.findIndex(
      (contentItem) => contentItem.lang === currentLang
    );
    // if currentlang is not found then return 0 for default item
    if (currentLangIndex === -1) return 0;
    else return currentLangIndex;
  };

  const handleVisitFAQURL = () => {
    if (
      data &&
      data.assistant_articles[0] &&
      data.assistant_articles[0].is_published
    ) {
      const faqURL = `https://${workspace.data.storeUrl}/a/${widgetData.widgets_cornerwidget[0].faq_config.appProxyUrl}/article/${historyProps.match.params.id}?lang=${currentLang}`;
      window.open(faqURL, "_blank");
    } else {
      toaster.notify("Please publish the article first", {
        duration: 2000,
      });
    }
  };

  if (
    parseInt(historyProps.match.params.id, 10) ===
    data?.assistant_articles[0]?.id
  )
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          updateArticleMutation({
            id: values.id,
            values: {
              is_published: values.is_published,
              is_featured: values.is_featured,
              content: values.content,
              metadata: values.metadata,
              categoryid: values.categoryid,
              visibility_rules: values.visibility_rules,
            },
          });
        }}
      >
        {({ values, dirty, submitForm, setFieldValue, resetForm, color }) => {
          return (
            <PageViewContainer
              title="Articles"
              action={
                <div className="flex">
                  <Button
                    onClick={() => {
                      setIsDeleteWarningOpen(true);
                    }}
                    type="danger-ghost"
                    className="mr-2"
                    icon={<RiDeleteBin2Line />}
                  >
                    <span className="mobile:hidden laptop:block">
                      Delete article
                    </span>
                  </Button>
                  <Button
                    className="relative"
                    type="primary"
                    onClick={async () => {
                      submitForm();
                    }}
                    icon={<RiFileAddLine />}
                    disabled={!dirty}
                  >
                    Save Article
                    {dirty && (
                      <>
                        <span className=" absolute inline-flex rounded-full bg-red-400 opacity-75 w-4 h-4 -top-1 -right-1"></span>
                        <span className=" absolute inline-flex rounded-full bg-red-600  w-4 h-4 -top-1 -right-1 "></span>
                      </>
                    )}
                  </Button>
                  <ModalTransition>
                    {isDeleteWarningOpen && (
                      <Modal
                        onClose={() => {
                          setIsDeleteWarningOpen(false);
                        }}
                      >
                        <div className="p-8">
                          <h2 className="text-base font-bold mb-4">
                            You’re about to delete this article
                          </h2>
                          <p className="text-base mb-4">
                            This process cannot be reversed, are you sure you
                            want to conitnue with the deletion?
                          </p>
                          <div className="flex flex-row justify-end">
                            <Button
                              onClick={() => setIsDeleteWarningOpen(false)}
                              type="link"
                            >
                              No, keep it
                            </Button>
                            <Button
                              className="ml-4"
                              onClick={() => {
                                deleteArticleMutation({
                                  id: historyProps.match.params.id,
                                });
                                setIsDeleteWarningOpen(false);
                              }}
                              type="danger"
                            >
                              Delete it
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </ModalTransition>
                </div>
              }
            >
              {data && data.assistant_articles[0] && currentLang ? (
                <FieldArray
                  name="content"
                  render={(arrayHelpers) => {
                    return (
                      <div className="overflow-y-auto h-full flex flex-col">
                        {!values.is_published && (
                          <div className="bg-amber-300 rounded-t-lg text-amber-800 text-center py-3 flex items-center justify-center">
                            <RiFileWarningFill className="text-xl mr-3" />
                            <p>
                              This Article is in draft mode. Publish it to make
                              it available in your store
                            </p>
                          </div>
                        )}
                        <Prompt
                          when={dirty}
                          message={(location) => {
                            setSaveWarning(true);
                            setPromptLocation(location.pathname);
                            return false;
                          }}
                        />
                        <div className="p-3 desktop:p-4 bg-violet-50 border rounded-t-lg flex justify-center">
                          <div className="max-w-4xl flex-1 text-left">
                            <div className="mb-1 desktop:mb-4 flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <div className="max-w-md ">
                                  <InlineEdit
                                    defaultValue={values.is_published}
                                    hideActionButtons
                                    editView={() => (
                                      <div className="w-40">
                                        <Select
                                          isSearchable={false}
                                          options={widgetStatus}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "is_published",
                                              e.value
                                            )
                                          }
                                          autoFocus
                                          openMenuOnFocus
                                        />
                                      </div>
                                    )}
                                    readView={() =>
                                      values.is_published ? (
                                        <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                          <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
                                          Published{" "}
                                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                        </div>
                                      ) : (
                                        <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                                          Draft{" "}
                                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                        </div>
                                      )
                                    }
                                    onConfirm={(e) =>
                                      console.log("is_published", e)
                                    }
                                  />
                                </div>
                                <div className="max-w-md ">
                                  <InlineEdit
                                    defaultValue={values.is_featured}
                                    hideActionButtons
                                    editView={() => (
                                      <div className="w-40">
                                        <Select
                                          isSearchable={false}
                                          options={FeatureStatus}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "is_featured",
                                              e.value
                                            )
                                          }
                                          autoFocus
                                          openMenuOnFocus
                                        />
                                      </div>
                                    )}
                                    readView={() =>
                                      values.is_featured ? (
                                        <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                          <RiStarFill className="text-amber-500 text-xl mr-2" />{" "}
                                          Featured{" "}
                                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                        </div>
                                      ) : (
                                        <div className="flex items-center py-3 px-4 rounded-md cursor-pointer ">
                                          <RiStarLine className="text-gray-500 text-xl mr-2" />{" "}
                                          Not Featured{" "}
                                          <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                                        </div>
                                      )
                                    }
                                    onConfirm={(e) =>
                                      console.log("is_published", e)
                                    }
                                  />
                                </div>
                              </div>

                              <Popup
                                isOpen={isMoreOptionsOpen}
                                placement="bottom-start"
                                onClose={() => setIsMoreOptionsOpen(false)}
                                content={() => (
                                  <div className="p-4">
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        setIsVisibilityOptionsOpen(true);
                                      }}
                                    >
                                      Link to a product
                                    </Button>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        setIsSEOOptionsOpen(true);
                                      }}
                                    >
                                      SEO Options
                                    </Button>
                                    <Button
                                      disabled={dirty}
                                      type="link"
                                      onClick={() => {
                                        insertArticleMutation();
                                      }}
                                    >
                                      <p className="text-left">
                                        Duplicate Article
                                      </p>
                                      {dirty && (
                                        <small className="text-xs text-gray-300 font-light">
                                          (Save changes before duplicating)
                                        </small>
                                      )}
                                    </Button>
                                  </div>
                                )}
                                trigger={(triggerProps) => (
                                  <button
                                    {...triggerProps}
                                    className="flex items-center font-bold text-gray-700"
                                    type="link"
                                    onClick={() =>
                                      setIsMoreOptionsOpen(!isMoreOptionsOpen)
                                    }
                                    style={{ background: color }}
                                  >
                                    <RiArrowDownSLine className="mr-3 text-xl" />
                                    <span>More Options</span>
                                  </button>
                                )}
                              />
                            </div>

                            <TextareaAutosize
                              value={
                                values.content[
                                  getCurrentLangIndex(values.content)
                                ]?.value?.title
                              }
                              onChange={(e) =>
                                setFieldValue(
                                  `content[${getCurrentLangIndex(
                                    values.content
                                  )}].value.title`,
                                  e.target.value
                                )
                              }
                              className="text-2xl desktop:text-3xl p-3 font-light text-gray-700 border-b border-dashed cursor-text border-gray-400 w-full  bg-violet-50"
                              placeholder="Article Title"
                            />
                            <div className="mt-3 flex flex-wrap">
                              <div className="px-8">
                                <div className="text-sm">Category</div>
                                <div className="flex justify-between items-center">
                                  <Button
                                    type="link"
                                    onClick={() => setIsCategoryModalOpen(true)}
                                    className="desktop:px-0  laptop:px-0  mobile:px-0"
                                  >
                                    <p className="text-gray-600 flex items-center font-semibold">
                                      {values.categoryid
                                        ? getCategoryName(values.categoryid)
                                        : "No category"}
                                      <RiPencilFill className="ml-2" />
                                    </p>
                                  </Button>
                                </div>
                              </div>
                              <div className="px-8 border-l">
                                <div className="text-sm">Language</div>
                                <div className="flex justify-between items-center py-2">
                                  <LanguageEditPopup
                                    translatedLanguages={getTranslatedLanguages(
                                      values.content
                                    )}
                                    currentLanguage={currentLang}
                                    onLangugageAdd={(selectedOption) => {
                                      if (
                                        workspace.data.feature_flag?.apps
                                          ?.supportcorner
                                          ?.multi_language_support
                                      ) {
                                        arrayHelpers.push({
                                          lang: selectedOption.value,
                                          value: {
                                            title: `Enter ${selectedOption.label} title`,
                                            body: `<p>Write ${selectedOption.label} article here</p>`,
                                          },
                                        });
                                        setFieldValue("metadata", [
                                          ...values.metadata,
                                          {
                                            lang: selectedOption.value,
                                            value: {
                                              seo_title: "",
                                              seo_description: "",
                                            },
                                          },
                                        ]);
                                        setCurrentLang(selectedOption.value);
                                      } else {
                                        toaster.notify(
                                          "This fetaure is only available in the higher-priced plans",
                                          {
                                            duration: 2000,
                                          }
                                        );
                                      }
                                    }}
                                    onLangugageEdit={(selectedOption) => {
                                      setCurrentLang(selectedOption.value);
                                    }}
                                    onLanguageDelete={(selectedOption) => {
                                      const removeItemIndex =
                                        values.content.findIndex(
                                          (languageItem) =>
                                            languageItem.lang ===
                                            selectedOption.value
                                        );

                                      arrayHelpers.remove(removeItemIndex);
                                      const updatedMetadata =
                                        values.metadata.filter(
                                          (metadataItem) =>
                                            metadataItem.lang !==
                                            selectedOption.value
                                        );
                                      setFieldValue(
                                        "metadata",
                                        updatedMetadata
                                      );
                                      if (
                                        currentLang === selectedOption.value
                                      ) {
                                        setCurrentLang(
                                          workspace.data.default_language
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="px-8 border-l">
                                <div className="text-sm text-center">
                                  Faq Page URL
                                </div>
                                <div className="flex justify-between items-center">
                                  {widgetData && workspace && (
                                    <a
                                      // href={`https://${workspace.data.storeUrl}/a/${widgetData.widgets_cornerwidget[0].faq_config.appProxyUrl}/article/${historyProps.match.params.id}?lang=${currentLang}`}
                                      onClick={() => handleVisitFAQURL()}
                                      target="_blank"
                                      className="flex flex-row items-center py-1 px-4 mt-1 font-semibold border border-gray-200 border-solid rounded-full text-gray-600 hover:shadow-lg"
                                    >
                                      <RiLinksLine />
                                      <p className="ml-2">Vist URL</p>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" flex grow justify-center">
                          <div className="max-w-4xl flex-1 flex-col leading-9">
                            {/* The formik keeps its initialValues of a previous formik instace for a fraction of a second before its reintialized 
                        with the values of new instance. So the following condition makes sure the articleId in the initialValue is equal to 
                        the articleId in the URL. And inturn makes sure the text editor is initialized with right Article */}
                            {values.id ===
                              parseInt(historyProps.match.params.id, 10) && (
                              <RichTextEditor
                                value={
                                  values.content[
                                    getCurrentLangIndex(values.content)
                                  ].value.body
                                }
                                type={
                                  workspace.data?.feature_flag.apps
                                    ?.supportcorner?.articles_allow_images_video
                                    ? "complete"
                                    : "limited"
                                }
                                onChange={(htmlValue) =>
                                  setFieldValue(
                                    `content[${getCurrentLangIndex(
                                      values.content
                                    )}].value.body`,
                                    htmlValue
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                        <ModalTransition>
                          {isVisibilityOptionsOpen && (
                            <Modal
                              width="large"
                              onClose={() => {
                                setIsVisibilityOptionsOpen(false);
                              }}
                            >
                              <div className="h-full flex flex-col">
                                <VisibilityOptions
                                  value={values.visibility_rules}
                                  onClose={(e) => {
                                    setIsVisibilityOptionsOpen(false);
                                  }}
                                  onSubmit={(e) => {
                                    setFieldValue("visibility_rules", e);
                                  }}
                                />
                              </div>
                            </Modal>
                          )}

                          {isSEOOptionsOpen && (
                            <Modal
                              width="large"
                              onClose={() => {
                                setIsSEOOptionsOpen(false);
                              }}
                            >
                              <SEOOptions
                                values={
                                  values.metadata[
                                    getCurrentLangIndex(values.metadata)
                                  ].value
                                }
                                onClose={(e) => {
                                  setIsSEOOptionsOpen(false);
                                }}
                                onSubmit={(seoValues) => {
                                  setFieldValue(
                                    `metadata[${getCurrentLangIndex(
                                      values.metadata
                                    )}].value`,
                                    seoValues
                                  );
                                }}
                              />
                            </Modal>
                          )}

                          {saveWarning && (
                            <Modal
                              actions={[
                                {
                                  text: "Save & continue",
                                  onClick: () => {
                                    setSaveWarning(false);
                                    submitForm();
                                  },
                                },
                                {
                                  text: "Discard Changes",
                                  onClick: () => {
                                    setSaveWarning(false);
                                    resetForm();
                                    window.location.href = promptLocation;
                                  },
                                },
                                {
                                  text: "Continue Editing",
                                  onClick: () => {
                                    setPromptLocation("");
                                    setSaveWarning(false);
                                  },
                                },
                              ]}
                              onClose={() => {
                                setSaveWarning(false);
                              }}
                              heading="You've unsaved changes"
                              appearance="warning"
                            >
                              <div className="p-8">
                                <h2 className="font-bold mb-4">
                                  You've unsaved changes
                                </h2>
                                <p>
                                  You haven't saved your changes. Are you sure
                                  you want to discard changes and go to another
                                  page?
                                </p>
                                <div className="flex flex-row justify-end mt-6">
                                  <Button
                                    className="ml-4"
                                    onClick={() => {
                                      setSaveWarning(false);
                                      resetForm();
                                      window.location.href = promptLocation;
                                    }}
                                    type="link"
                                  >
                                    Discard Changes
                                  </Button>
                                  <Button
                                    className="ml-4"
                                    onClick={() => {
                                      setPromptLocation("");
                                      setSaveWarning(false);
                                    }}
                                    type="link"
                                  >
                                    Continue Editing
                                  </Button>
                                  <Button
                                    className="ml-4"
                                    onClick={() => {
                                      setSaveWarning(false);
                                      submitForm();
                                    }}
                                    type="Primary"
                                  >
                                    Save and Continue
                                  </Button>
                                </div>
                              </div>
                            </Modal>
                          )}
                          {isCategoryModalOpen && (
                            <Modal
                              actions={[
                                {
                                  text: "Confirm",
                                  onClick: () => setIsCategoryModalOpen(false),
                                },
                              ]}
                              onClose={() => setIsCategoryModalOpen(false)}
                              heading="Select Categories"
                            >
                              <div className="flex flex-col h-full">
                                <div className="shrink overflow-auto px-6 py-4">
                                  <List
                                    disableSelectAll
                                    header={
                                      <div className="grid grid-cols-12 items-center justify-center ">
                                        <div className="px-3 col-span-12 flex justify-end" />
                                      </div>
                                    }
                                    items={data.assistant_categories}
                                    render={(item) => (
                                      <div className="grid grid-cols-12 items-center justify-center py-5">
                                        <div className="col-span-1 flex justify-center">
                                          <img
                                            width="46px"
                                            src={item.icon_url}
                                            alt=""
                                          />
                                        </div>
                                        <div className="col-span-8 px-3 ">
                                          <p className="font-bold text-gray-700">
                                            {getDefaultLangCategoryName(item)}
                                          </p>
                                        </div>
                                        <div className="col-span-3 px-3 text-center">
                                          {item.id === values.categoryid ? (
                                            <h2>
                                              <RiCheckboxCircleFill />
                                            </h2>
                                          ) : (
                                            <Button
                                              type="outline"
                                              onClick={() =>
                                                setFieldValue(
                                                  "categoryid",
                                                  item.id
                                                )
                                              }
                                            >
                                              Select
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  />
                                </div>

                                <div className="flex justify-center border-t py-4">
                                  <div className="flex items-center">
                                    <Textfield
                                      name="basic"
                                      defaultValue={categoryCreator}
                                      onChange={(e) =>
                                        setCategoryCreator(e.target.value)
                                      }
                                    />
                                    <div className="flex-none ml-2">
                                      <Button
                                        type="ghost"
                                        onClick={() => handleCategoryCreation()}
                                      >
                                        Add Category
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </ModalTransition>
                      </div>
                    );
                  }}
                />
              ) : (
                <div className="h-full flex flex-col justify-center items-center">
                  <div className="grow-0">
                    <LoadingSpinner />
                  </div>
                  {/* renders an error message after 3 seconds */}
                  {setTimeout(() => {
                    setErrorFlag(true);
                  }, 3000) &&
                    errorFlag && (
                      <p className="text-gray-500 text-center max-w-2xl">
                        Something went wrong and we couldn't fetch this article.
                        Please go back and select the article again, and if
                        error persists contact us
                      </p>
                    )}
                </div>
              )}
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default ArticleEdit;
