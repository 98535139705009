import { useWorkspace } from "../contexts/WorkspaceContext";
import fetchRequestWrapper from "./fetchRequestWrapper";

/**
 * This is a custom hook for clearing cloudflare cache
 * @param {string} cacheType Type of app cache to clear ('supportcorner' or 'cornercart')
 * @returns {Function} a function for clearing cache
 */
const useClearCache = (cacheType = "cornercart") => {
  const workspace = useWorkspace();

  const cachePurgeRequest = {
    shop_url: `${workspace.data.storeMyShopifyUrl}`,
  };

  const clearCache = async () => {
    const cachePurge = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/${cacheType}/cache/purge`,
      cachePurgeRequest
    );
    if (cachePurge.status !== "OK") {
      window.Rollbar.error(
        `${cacheType === "cornercart" ? "Corner Cart" : "Suppport Corner"
        } cache purging api failed`,
        {
          ...cachePurgeRequest,
          shopifyStoreURL: `${workspace.data.storeMyShopifyUrl}`,
        }
      );
    }
  };
  return clearCache;
};
export default useClearCache;
