import React from "react";

const OfferView = (props) => {

    const GiftIcon = props => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="52" fill="none">
            <path
              d="M33.585 3.133c-5.118-1.706-6.966 1.848-7.251 3.839.362-.853 2.062-2.303 5.971-1.28s3.903 4.407 3.412 5.971c1.422-2.133 2.986-6.824-2.133-8.53zM14.191 8.507c2.391-4.568 8.731-3.242 10.437 1.171-.852-.431-4.802-3.273-6.824 0s-.568 5.891.873 6.791c-2.492-.75-6.877-3.393-4.486-7.961zm11.648 31.732l-1.638-20.427c3.003-1.226 8.844-3.023 8.189-.409s1.092 14.435 2.047 20.019l-8.599.817z"
              fill={props.color}
            />
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path d="M11.336 21.851c.45 6.293 1.175 12.542 1.175 18.857 0 .34-.293 2.68.196 2.886.644.271 2.321-.503 2.969-.673 5.686-1.496 11.542-1.645 17.342-2.453 3.255-.454 6.404-.722 9.691-.722 1.774 0 .976-.799.653-2.068-1.325-5.207-1.751-10.768-1.778-16.115-.007-1.461.961-6.061-1.338-6.061-5.911 0-11.867 1.716-17.652 2.726-5.958 1.04-12.141 1.604-18.011 3.047" />
              <path d="M25.134 41.185a399.93 399.93 0 0 1-1.109-12.277l-.693-7.409v-2.495m9.496 20.385a399.93 399.93 0 0 1-1.109-12.277l-.693-7.409v-2.495m-7.278.687c-2.484-.41-4.872-.88-7.193-1.91-1.566-.695-3.286-1.53-3.735-3.327-.678-2.71.827-6.299 3.628-7.116 1.335-.389 3.104-.362 4.482-.231 1.608.153 2.672 1.233 3.443 2.557.88 1.512 1.425 2.992 1.648 4.729l.223 2.803" />
              <path d="M29.464 16.358c2.105-1.103 4.104-2.237 5.873-3.89 1.194-1.116 2.482-2.41 2.335-4.267-.221-2.801-2.669-5.826-5.433-5.813-1.317.006-2.897.537-4.094 1.057-1.396.606-2.021 1.949-2.308 3.443-.328 1.706-.364 3.286-.033 5.021l.657 2.761m2.45 1.864c1.588-.821 3.097-1.664 4.438-2.885.904-.824 1.881-1.779 1.784-3.136-.146-2.046-1.963-4.24-4.038-4.21-.989.014-2.179.413-3.082.801-1.053.453-1.532 1.439-1.758 2.533-.258 1.25-.296 2.404-.06 3.67l.474 2.014" />
              <path d="M26.823 15.62c-.536-1.706-1.107-3.337-2.08-4.867-.656-1.032-1.43-2.158-2.783-2.296-2.041-.208-4.514 1.205-4.842 3.255-.156.977.032 2.218.26 3.174.265 1.115 1.155 1.756 2.193 2.166 1.187.469 2.317.705 3.605.69l2.065-.121m18.307-5.328c.881-1.272 1.834-2.412 3.086-3.307m-.661 4.63h2.866m-2.205 3.086c.75 1.082 1.573 2.093 2.425 3.086M6.07 41.614L1 41.173m6.614 4.409c-1.177.915-2.041 2.041-3.086 3.086m6.878-1.604v3.748" />
            </g>
          </svg>
        );
      };

    const rewardStagesArray = [
        {
          id: Math.floor(Math.random() * 10000),
          rewardTarget: 2,
          discountConfig: {
            discountType: "percentage",
            discountValue: -12,
            targetType: "all",
            minimumRequirementType: "none",
            minimumRequirementValue: 0,
            targetItems: {
              productIds: [],
              collectionIds: [],
            },
          },
        },
        {
          id: Math.floor(Math.random() * 10000),
          rewardTarget: 5,
          discountConfig: {
            discountType: "percentage",
            discountValue: -20,
            targetType: "all",
            minimumRequirementType: "none",
            minimumRequirementValue: 0,
            targetItems: {
              productIds: [],
              collectionIds: [],
            },
          },
        },
        {
          id: Math.floor(Math.random() * 10000),
          rewardTarget: 10,
          discountConfig: {
            discountType: "percentage",
            discountValue: -25,
            targetType: "all",
            minimumRequirementType: "none",
            minimumRequirementValue: 0,
            targetItems: {
              productIds: [],
              collectionIds: [],
            },
          },
        },
        {
          id: Math.floor(Math.random() * 10000),
          rewardTarget: 25,
          discountConfig: {
            discountType: "percentage",
            discountValue: -40,
            targetType: "all",
            minimumRequirementType: "none",
            minimumRequirementValue: 0,
            targetItems: {
              productIds: [],
              collectionIds: [],
            },
          },
        },
      ];
  const RewardStage = (rewardProps) => (
    <div className="coca-reward-stage coca-d-flex ">
      <div className="coca-d-flex coca-flex-col coca-align-items-center">
        <div
          className={`coca-reward-tag ${
            rewardProps.isFinalStage
              ? "coca-final-reward-tag"
              : "coca-ordinary-reward-tag"
          }`}
        >
          {rewardProps.offer}
        </div>
        {!rewardProps.isFinalStage && (
          <div className="coca-reward-stage-line" />
        )}
      </div>
      <div className="coca-reward-text">
        {rewardProps.isInitialStage
          ? "Initial Offer"
          : "if <reward_requirement> or more people buy".replace(
              "<reward_requirement>",
              rewardProps.rewardRequirement
            )}
      </div>
    </div>
  );

  return (
    <div className="coca-d-flex coca-flex-col coca-cowi-main-container">
      <div className="coca-grow-1 ">
        <div className="coca-d-flex coca-justify-content-center coca-mt-2  ">
          <GiftIcon color={props.bgEndColor} />
        </div>
        <h1 className="coca-vire-hero-text coca-text-center coca-p-2">
          <span>
            Gift your friends{" "}
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "background",
                    `${props.bgEndColor}`,
                    "important"
                  );
                  node.style.setProperty(
                    "color",
                    `${
                      props.foregroundColor === "light"
                        ? "rgba(255,255,255,1)"
                        : "rgba(0,0,0,.6)"
                    }`,
                    "important"
                  );
                }
              }}
              className="coca-vire-hero-highlight coca-p-1"
            >
              10%
            </span>{" "}
            discount coupon and get upto{" "}
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "background",
                    `${props.bgEndColor}`,
                    "important"
                  );
                  node.style.setProperty(
                    "color",
                    `${
                      props.foregroundColor === "light"
                        ? "rgba(255,255,255,1)"
                        : "rgba(0,0,0,.6)"
                    }`,
                    "important"
                  );
                }
              }}
              className="coca-vire-hero-highlight coca-p-1 "
            >
              40%
            </span>{" "}
            OFF
          </span>
        </h1>
        <p className="coca-vire-hero-subtext coca-text-center coca-p-2">
        As more friends use the coupon you gift, higher the discount you get
        </p>
        <div className=" coca-reward-stage-wrapper">
          {rewardStagesArray.map((item, index) => {
            return (
              <RewardStage
                isInitialStage={index === 0}
                isFinalStage={index === rewardStagesArray.length - 1}
                offer={`${item.discountConfig.discountValue * -1}
              ${
                item.discountConfig.discountType === "percentage"
                  ? "%"
                  : window.Shopify.currency.active
              } OFF`}
                rewardRequirement={item.rewardTarget}
              />
            );
          })}
        </div>
      </div>
      <div className="coca-vire-initial-cta coca-grow-0 coca-d-flex coca-w-100">
        <button
          className="coca-vire-cowi-big-button coca-w-100 coca-grow-1"
          ref={(node) => {
            if (node)
              node.style.setProperty(
                "background",
                `${props.bgEndColor}`,
                "important"
              );
          }}
          type="submit"
        >
          <span
            ref={(node) => {
              if (node)
                node.style.setProperty(
                  "color",
                  `${
                    props.foregroundColor === "light"
                      ? "rgba(255,255,255,1)"
                      : "rgba(0,0,0,.6)"
                  }`,
                  "important"
                );
            }}
          >
            {" "}
            <b>
            Get this offer
            </b>
          </span>
        </button>
      </div>
    </div>
  );
};

export default OfferView;
