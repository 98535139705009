import React from "react";
import Select from "@atlaskit/select";
import TextArea from "@atlaskit/textarea";
import { useWorkspace } from "../../../../../../contexts/WorkspaceContext";
import CustomiserUnit from "../../../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../../../components/TabRadioButton";
import ScriptEditor from "./ScriptEditor";
import ShopifyResourcePicker from "../../../../../../components/ShopifyResourcePicker";
import ProductDetailsRow from "../../../../../../components/ProductDetailsRow";
import { parseShopifyProductId } from "../../../../../../utilities/basicUtilityFunctions";
/**
 * A component for rendering Convert Screen Sticky Bar General Settings
 * @param {object} values - values passed from formik
 * @param {function} setFieldValue - method to update the formik values object
 */
export default function StickyBarGeneralSettings({ values, setFieldValue }) {
  const workspace = useWorkspace();

  const ctaSelectOptions = [
    { label: "Open Cart Widget", value: "STAY_ON_PAGE" },
    { label: "Go to Cart Page", value: "CART_PAGE" },
  ];
  const ctaBehaviour = [
    { label: "Add to cart", value: "atc" },
    { label: "Buy Now / Go to Checkout", value: "checkout" },
  ];

  return (
    <>
      <CustomiserUnit
        title="Call to Action Button Behaviour"
        description="Select what happens when the CTA button is clicked"
      >
        <TabRadioButton
          defaultValue={
            values.stickybar_config.general.ctaOnClick === "CHECKOUT"
              ? "checkout"
              : "atc"
          }
          options={ctaBehaviour}
          onChange={(selectedValue) => {
            if (selectedValue === "checkout")
              setFieldValue("stickybar_config.general.ctaOnClick", "CHECKOUT");
            else
              setFieldValue(
                "stickybar_config.general.ctaOnClick",
                "STAY_ON_PAGE"
              );
          }}
        />
        {values.stickybar_config.general.ctaOnClick !== "CHECKOUT" && (
          <div className="flex items-center p-2 mt-3 border rounded-lg">
            <p className="mr-3 text-sm">After item is added to cart</p>
            <Select
              isSearchable={false}
              value={ctaSelectOptions.find(
                (option) =>
                  option.value === values.stickybar_config.general.ctaOnClick
              )}
              onChange={(e) => {
                setFieldValue("stickybar_config.general.ctaOnClick", e.value);
              }}
              className="grow"
              classNamePrefix="react-select"
              options={ctaSelectOptions}
            />
          </div>
        )}
      </CustomiserUnit>
      <CustomiserUnit
        title="Home Page Product"
        description="Copy & Paste the URL of the product that you wish to showcase on your homepage."
      >
        {values.stickybar_config.general.homepageProductObj ? (
          <ProductDetailsRow
            product={values.stickybar_config.general.homepageProductObj}
            showImage={false}
            onDelete={() =>
              setFieldValue("stickybar_config.general.homepageProductObj", "")
            }
            className={"p-3"}
          />
        ) : (
          <ShopifyResourcePicker
            key={"productSelector"}
            btnText="Select Product"
            selectMultiple={false}
            onChange={(selectedProduct) => {
              setFieldValue("stickybar_config.general.homepageProductObj", {
                id: parseShopifyProductId(selectedProduct[0].id),
                title: selectedProduct[0].title,
              });
            }}
          />
        )}
      </CustomiserUnit>
      <CustomiserUnit
        title="Custom CSS"
        description="Add custom CSS for further customization of your Add to Cart Widget. Please use `!important` to make sure your changes get precedence over the default values."
        paywall={
          !workspace.data?.feature_flag.apps?.cornercart?.stickybar
            ?.stickybar_custom_css
        }
        paywallPlan={13}
      >
        <TextArea
          value={values.stickybar_config.general.customCss}
          onChange={(e) => {
            setFieldValue("stickybar_config.general.customCss", e.target.value);
          }}
          name="basic"
          minimumRows={7}
        />
      </CustomiserUnit>
      <ScriptEditor values={values} setFieldValue={setFieldValue} />
    </>
  );
}
