import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
  RiTranslate,
  RiPaletteFill,
  RiListSettingsFill,
  RiUser4Fill,
  RiNotificationBadgeFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import Button from "../../../../components/Button";
import TabRadioButton from "../../../../components/TabRadioButton";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import toaster from "toasted-notes";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import MiniCartOpenSettings from "./OpenSettings";
import MiniCartAdvancedSettings from "./AdvancedSettings";
import MiniCartColorSettings from "./ColorSettings";
import { Formik } from "formik";
import WidgetPreview from "../WidgetPreview";
import MiniCartLanguageSettings from "./LanguageSettings";
import { useBlock } from "@dopt/react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useClearCache from "../../../../utilities/useClearCache";

//convert screen sticky bar
export default function MiniCart(props) {
  const graphQL = useGraphQl();
  const clearCache = useClearCache();
  const [currentTab, setCurrentTab] = useState("colors");
  // Manually change the following state to true in code to access the theme creatorMode. In them,e creator mode you'll get a botton to export the theme JSOn to console
  const [themeCreatorMode, setThemeCreatorMode] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [joyRide, setJoyRide] = useState({
    run: true,
    stepIndex: 0,
  });

  const [cartDrawerOnboarding, cartDrawerOnboardingTransition] = useBlock(
    "cart-drawer-settings.cart-drawer-settings"
  );

  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      clearCache();
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const WidgetStates = [
    {
      label: (
        <div className="flex items-center">
          <RiEyeFill className="flex-none  mr-2 text-lg" />
          Enabled{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiEyeOffFill className="flex-none mr-2 text-lg" /> Disabled{" "}
        </div>
      ),
      value: false,
    },
  ];

  const handleIsActiveChange = (value) => {
    updateWidgetMutation({
      id: { id: data.widgets_cornerwidget[0].id },
      config: {
        cart_config: {
          ...data.widgets_cornerwidget[0].cart_config,
          general: {
            ...data.widgets_cornerwidget[0].cart_config.general,
            isActive: value,
          },
        },
      },
    });
  };

  const cartDrawerSteps = [
    {
      target: "#cart-drawer-settings-btn",
      content: `You can customize the look and feel of the cart and other options here. Please chat with us, if you need help!`,
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#cart-drawer-status",
      content: `Click "Enable" and save to set the cart widget live on your store (or test theme).`,
      // disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setJoyRide((prevState) => ({
        ...prevState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      }));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyRide((prevState) => ({
        ...prevState,
        run: false,
      }));
      if (cartDrawerOnboardingTransition)
        cartDrawerOnboardingTransition("next");
    }
  };

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "colors":
        return (
          <MiniCartColorSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "open":
        return (
          <MiniCartOpenSettings values={values} setFieldValue={setFieldValue} />
        );
      case "advanced":
        return (
          <MiniCartAdvancedSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "language":
        return (
          <MiniCartLanguageSettings
            values={values}
            setFieldValue={setFieldValue}
            currentDefaultLang={data?.workspaces[0]?.source?.default_language}
          />
        );
    }
  };

  const tabItems = [
    { label: "Look & Feel", id: "colors", icon: <RiPaletteFill /> },
    { label: "Cart Features", id: "open", icon: <RiNotificationBadgeFill /> },
    {
      label: "Advanced Settings",
      id: "advanced",
      icon: <RiListSettingsFill />,
    },
    { label: "Language Settings", id: "language", icon: <RiTranslate /> },
  ];

  useEffect(() => {
    if (data) setInitialValues(data.widgets_cornerwidget[0]);
  }, [data]);

  return (
    <>
      <PageViewContainer
        title="Cart Widget"
        action={
          <div className="flex items-center">
            <p className="mobile:hidden laptop:block mr-3 font-bold text-sm desktop:text-base text-gray-700 ">
              Cart Drawer Status :{" "}
            </p>
            {data && (
              <TabRadioButton
                btnId="cart-drawer-status"
                defaultValue={
                  data.widgets_cornerwidget[0].cart_config.general.isActive
                }
                options={WidgetStates}
                onChange={(selectedValue) => {
                  handleIsActiveChange(selectedValue);
                }}
              />
            )}
          </div>
        }
      >
        <div className="flex flex-col h-full">
          {data &&
            !data.widgets_cornerwidget[0]?.cart_config?.general?.isActive && (
              <div className="bg-amber-300 text-amber-800 text-center py-2 flex items-center justify-center">
                <RiFileWarningFill className="text-xl mr-3" />
                <p className="text-sm">
                  The Cart Drawer is currently disabled. Enable it on your store
                  using the toggle button in the top right
                </p>
              </div>
            )}
          <HorizontalTabs
            horizontalTabsId="cart-drawer-settings-btn"
            items={tabItems}
            showSeperatorIcons
            onChange={(selectedTab) => {
              setCurrentTab(selectedTab);
            }}
          />
          {initialValues && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                updateWidgetMutation({
                  id: { id: values.id },
                  config: {
                    cart_config: values.cart_config,
                    cart_launcher_config: values.cart_launcher_config,
                    corner_cart_lang: values.corner_cart_lang,
                  },
                });
              }}
            >
              {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
                return (
                  <>
                    {dirty && (
                      <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                        <p>You've unsaved changes</p>
                        <div className="flex items-center">
                          <Button
                            onClick={() => {
                              resetForm();
                            }}
                            type="link"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            onClick={() => {
                              submitForm();
                            }}
                            type="primary"
                          >
                            Save Changes
                          </Button>
                          {/* The following button is only for dev purpose. Specifically for widget theme creation */}
                          {themeCreatorMode && (
                            <Button
                              className="ml-3"
                              onClick={() => {
                                console.log("themeValue", {
                                  name: "",
                                  type: "light",
                                  themeObject: {
                                    colors: values.cart_config.colors,
                                    banner: values.cart_config.banner,
                                    customCss:
                                      values.cart_config.general.customCss,
                                  },
                                });
                              }}
                              type="outline"
                            >
                              get Theme Values
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    <div className=" h-full flex flex-row items-stretch min-h-0">
                      <div className="desktop:max-w-lg laptop:max-w-sm mobile:w-full overflow-y-auto pb-12">
                        <div>{renderCurrentTab(values, setFieldValue)}</div>
                      </div>
                      <div className="grow py-3 overflow-hidden flex-wrap h-full bg-gray-100 border-l mobile:hidden laptop:flex  justify-center">
                        <WidgetPreview
                          isOpen
                          cartConfig={values.cart_config}
                          cartLauncherConfig={values.cart_launcher_config}
                          cornerCartWidgetLang={values.corner_cart_lang}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          )}
        </div>
      </PageViewContainer>
      {cartDrawerOnboarding.state.active && (
        <Joyride
          steps={cartDrawerSteps}
          callback={handleJoyrideCallback}
          run={joyRide.run}
          stepIndex={joyRide.stepIndex}
          styles={{
            options: {
              primaryColor: "#6d28d9",
              textColor: "#000000",
              zIndex: 1000,
            },
          }}
          locale={{
            last: "Finish",
          }}
          showProgress
          continuous
          disableScrolling
          spotlightClicks
        />
      )}
    </>
  );
}
