import React from "react";
import AuthorisedApp from "./AuthorisedApp";
import UnauthorisedApp from "./UnauthorisedApp";
import { useAuth } from "./contexts/AuthenticationContext";
import { useWorkspace } from "./contexts/WorkspaceContext";
import DoptOnboarding from "./contexts/DoptOnboarding";

function App() {
  const authState = useAuth();
  const workspace = useWorkspace();

  if (
    authState.status === "in" &&
    workspace.data &&
    workspace.data.installationId
  ) {
    return <AuthorisedApp />;
  } else {
    return <UnauthorisedApp />;
  }
}
export default App;
