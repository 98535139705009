import React, { useState, useEffect } from "react";
import RuleItem from "./RuleItem";
import LogicSelector from "./LogicSelector";
import PopupSelect from "../../PopupSelect";
import { deepCloneObject } from "../../../utilities/basicUtilityFunctions";

/**
 * This renders rule group
 * @component
 * @param {Number} groupIndex - The index of this group
 * @param {Object} ruleGroup - Object containging the jsonLogic of this rule group
 * @param {String} topLevelLogic - The top level logic
 * @param {Object} value - The complete JSON Logic object
 * @param {Function} onChange - What happens on Change
 * @param {Object} groupedOptions - The options from which user can select a new rule.
 */
const RuleItemGroup = ({
  groupIndex,
  ruleGroup,
  topLevelLogic,
  value,
  onChange,
  groupedOptions,
  productsMetadata,
}) => {
  let groupLevelLogic = ruleGroup.and ? "and" : "or";
  let ruleCount = ruleGroup[groupLevelLogic].length;
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  // Since options are gropued, this following function will flatten them to a single array without groups
  let ungroupedOptions =
    groupedOptions.length > 1
      ? groupedOptions.reduce((prev, current, index) => {
          if (index === 1) return [...prev.options, ...current.options];
          else return [...prev, ...current.options];
        })
      : [...groupedOptions[0].options];

  /**
   * Adds a new Rule item
   * @param {Object} selectedObject The selcted object contains the information about the rule needed to be added
   */
  const handleRuleAddition = (selectedObject) => {
    const objectToAdd = selectedObject.defaultValue;
    const updatedValue = deepCloneObject(value);
    let ruleArray = updatedValue[topLevelLogic][groupIndex][groupLevelLogic];
    updatedValue[topLevelLogic][groupIndex][groupLevelLogic] = [
      ...ruleArray,
      objectToAdd,
    ];

    onChange({ jsonLogic: updatedValue, productsMetadata: productsMetadata });
  };

  /**
   * Define what happen when we edit a rule
   * @param {Object} ruleObject The updated rule object
   * @param {Number} ruleIndex The index of the rule that needed to be changed
   */
  const handleRuleChange = (ruleObject, ruleIndex, updatedProductsMetadata) => {
    let updatedValue = deepCloneObject(value);

    updatedValue[topLevelLogic][groupIndex][groupLevelLogic][ruleIndex] =
      ruleObject;
    onChange({
      jsonLogic: updatedValue,
      productsMetadata: updatedProductsMetadata,
    });
  };

  /**
   * A group will only have one logic operator. And this function is used to edit that
   * @param {String} updatedOperator The updated logic operator
   */
  const handleGroupLevelLogicOperatorChange = (updatedOperator) => {
    let updatedValue = deepCloneObject(value);
    updatedValue[topLevelLogic][groupIndex] = {
      [updatedOperator]:
        updatedValue[topLevelLogic][groupIndex][groupLevelLogic],
    };
    onChange({ jsonLogic: updatedValue, productsMetadata: productsMetadata });
  };

  /**
   * Define what happen when we delete a rule
   * @param {Number} indexToDelete The index of the rule that needed to be deleted
   */
  const handleRuleDelete = (indexToDelete, productToRemove) => {
    let updatedValue = deepCloneObject(value);
    let updatedProductsMetadata = productsMetadata;
    if (productToRemove) {
      delete updatedProductsMetadata[productToRemove];
    }
    let updatedRuleArray =
      updatedValue[topLevelLogic][groupIndex][groupLevelLogic];

    // if the rule group have more than one rule item
    if (updatedRuleArray.length > 1) {
      updatedRuleArray.splice(indexToDelete, 1);
      updatedValue[topLevelLogic][groupIndex][groupLevelLogic] =
        updatedRuleArray;
      // if there is only one rule item in the group and total number of rule groups is more than 1
    } else if (value[topLevelLogic].length > 1) {
      let updatedGroupArray = deepCloneObject(value[topLevelLogic]);
      updatedGroupArray.splice(groupIndex, 1);
      updatedValue[topLevelLogic] = updatedGroupArray;
      // if this rule item is the last rule in the last rule group
    } else {
      updatedValue = { everyone: true };
      updatedProductsMetadata = null;
    }

    onChange({
      jsonLogic: updatedValue,
      productsMetadata: updatedProductsMetadata,
    });
  };
  return (
    <div className="rule-group flex items-center flex-wrap">
      {ruleGroup[groupLevelLogic].map((rule, index) => {
        let variableDetails = ungroupedOptions.find((option) => {
          if (
            Object.keys(rule)[0] === "in" ||
            Object.keys(rule)[0] === "not-in"
          )
            return option.value === rule[Object.keys(rule)[0]][1].var;
          else return option.value === rule[Object.keys(rule)[0]][0].var;
        });
        return (
          <div
            key={`${groupIndex}-rule-${index}`}
            className="flex items-center"
          >
            <RuleItem
              key={`${groupIndex}-rule-item-${index}`}
              productsMetadata={productsMetadata}
              index={groupIndex * 10 + index}
              variableDetails={variableDetails}
              onDelete={(productToRemove) => {
                handleRuleDelete(index, productToRemove);
              }}
              onChange={(updatedValues) => {
                handleRuleChange(
                  updatedValues.jsonLogic,
                  index,
                  updatedValues.productsMetadata
                );
              }}
              rule={rule}
              firstItem={index === 0}
            />

            {ruleCount > 1 && index !== ruleCount - 1 ? (
              <LogicSelector
                key={`${groupIndex}-logic-${index}`}
                type="groupLevel"
                logicOperator={groupLevelLogic}
                onChange={(updatedOperator) =>
                  handleGroupLevelLogicOperatorChange(updatedOperator)
                }
              />
            ) : (
              <PopupSelect
                key={`${groupIndex}-ruleadder`}
                display=""
                alignment="bottom"
                isOpen={isEditPopupOpen}
                options={groupedOptions}
                onClose={() => setIsEditPopupOpen((prevState) => !prevState)}
                onChange={(selectedObject) => {
                  handleRuleAddition(selectedObject);
                  setIsEditPopupOpen((prevState) => !prevState);
                }}
                trigger={
                  <button
                    onClick={() => {
                      setIsEditPopupOpen((prevState) => !prevState);
                    }}
                    className="py-2 inline-block text-tiny px-4 transition-all font-bold rounded-r-lg bg-gray-100 hover:bg-white hover:shadow-lg hover:text-violet-700 border hover:border-violet-700 "
                  >
                    +
                  </button>
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RuleItemGroup;
