import React, { useState, useEffect } from "react";
import Layout from "./components/structure/Layout";
import SucoPricingModal from "./components/SucoPricingModal";
import OfcoPricingModal from "./components/OfcoPricingModal";
import LoadingSpinner from "./components/LoadingSpinner";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AssistantRouter from "./pages/support/AssistantRouter";
import OffersRouter from "./pages/offers/OffersRouter";
import SettingsRouter from "./pages/settings/SettingsRouter";
import PaymentsLoader from "./pages/misc/PaymentsLoader";
import { useWorkspace } from "./contexts/WorkspaceContext";
import { useGraphQl } from "./contexts/GraphqlClient";
import { useQuery } from "react-query";
import { queryBillingInfo } from "./api/billing";
import toaster from "toasted-notes";
import SucoOnboarding from "./pages/onboard/SucoOnboarding";
import OfcoOnboarding from "./pages/onboard/OfcoOnboarding";
import CocaOnboarding from "./pages/onboard/CocaOnboarding";
import CartRouter from "./pages/cart/CartRouter";
import useLanguageVersionManager from "./utilities/languageVersionManager";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Fullscreen } from "@shopify/app-bridge/actions";
import NewCocaPricingModal from "./components/NewCocaPricingModal";
import { Crisp } from "crisp-sdk-web";
import CocaPricingTakeOver from "./components/CocaPricing/CocaPricingTakeOver";
import DoptOnboarding from "./contexts/DoptOnboarding";
import fetchRequestWrapper from "./utilities/fetchRequestWrapper";
import sendDetailsToIntercom from "./utilities/sendDetailsToIntercom";
import { queryInstallationsById } from "./api/installations";

function AuthorisedApp() {
  const workspace = useWorkspace();
  const location = useLocation();
  const graphQL = useGraphQl();

  const app = useAppBridge();
  const fullscreen = Fullscreen.create(app);

  const [isFullscreen, setIsFullScreen] = useState(false);

  const { data: installationData } = useQuery(
    "queryInstallationsById",
    () =>
      graphQL(queryInstallationsById, { id: workspace.data.installationId }),
    {
      refetchOnWindowFocus: false,
    }
  );

  // the following useQuery hooks only fires if the respective app is installed
  const { data: sucoBillingInfo } = useQuery(
    "querySucoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${1}`, //  getting billing status for SUCO
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 1),
      refetchOnWindowFocus: false,
    }
  );
  const { data: ofcoBillingInfo } = useQuery(
    "queryOfcoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${2}`, // getting billing status for OFCO
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 2),
      refetchOnWindowFocus: false,
    }
  );
  const { data: cocaBillingInfo, refetch } = useQuery(
    "queryCocoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${3}`, // getting billing status for COCA
        sourceId: `${workspace.data?.sourceid}`,
      }),
    {
      enabled: workspace.data.installedApps.find((item) => item.appid === 3),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const { currentAppID } = workspace.data;
    const manageCocaStorefrontEvenTrigger = async () => {
      const isStorefrontEnabled = await localStorage.getItem(
        "cornerCartStorefrontEnabled"
      );
      if (isStorefrontEnabled === null && currentAppID === 3) {
        const cocaEventTriggerRqst = {
          shop_url: workspace.data.storeMyShopifyUrl,
          event: "login",
          data: {},
        };
        const cocaEventTrigger = await fetchRequestWrapper(
          `${process.env.REACT_APP_REST_API_URL}/app/cornercart/event/`,
          cocaEventTriggerRqst
        );
        if (cocaEventTrigger.status === "OK") {
          localStorage.setItem("cornerCartStorefrontEnabled", true);
        }
      }
    };
    manageCocaStorefrontEvenTrigger();
    return () => {};
  }, []);

  useEffect(() => {
    if (installationData && (cocaBillingInfo || sucoBillingInfo)) {
      const { contact_name, contact_email, created_at } =
        installationData.installations_by_pk;
      let appInstalled = "";
      let cornerPlan = "";
      if (cocaBillingInfo && workspace.data.currentAppID === 3) {
        appInstalled = "Corner Cart";
        cornerPlan = cocaBillingInfo.billingInfo.currentPlan
          ? cocaBillingInfo.billingInfo.currentPlan?.plan_display_name
          : "Not Active";
      } else if (sucoBillingInfo) {
        cornerPlan = sucoBillingInfo.billingInfo.currentPlan
          ? sucoBillingInfo.billingInfo.currentPlan.plan_display_name
          : "Not Active";
        appInstalled = "Support Corner";
      }
      const signUpDate = new Date(created_at).getTime();
      const unixFormatSignUpDate = Math.floor(signUpDate / 1000);
      sendDetailsToIntercom({
        name: contact_name ? contact_name : "Unknown",
        email: contact_email ? contact_email : "Unknown",
        storeUrl: workspace.data.storeMyShopifyUrl,
        appInstalled: appInstalled,
        unixFormatSignUpDate: unixFormatSignUpDate,
        cornerPlanName: cornerPlan,
        shopifyPlanName: workspace.data.shopifyPlanDetails.planName,
        shopifyPlanDisplayName:
          workspace.data.shopifyPlanDetails.planDisplayName,
      });
    }
    return () => {};
  }, [installationData, cocaBillingInfo, sucoBillingInfo]);

  /**
   * This function this function lets you toggle between full screen and minimised screen
   * @function
   * @param {"toggle"|"expand"|"minimize"} action key name
   */
  const toggleScreenState = (action) => {
    switch (action) {
      case "toggle": {
        setIsFullScreen((currentState) => {
          if (currentState === true)
            fullscreen.dispatch(Fullscreen.Action.EXIT);
          else fullscreen.dispatch(Fullscreen.Action.ENTER);
          return !currentState;
        });
      }
      case "expand": {
        if (isFullscreen === false) {
          fullscreen.dispatch(Fullscreen.Action.ENTER);
          setIsFullScreen(true);
        }
        break;
      }
      case "minimize": {
        if (isFullscreen === true) {
          fullscreen.dispatch(Fullscreen.Action.EXIT);
          setIsFullScreen(false);
        }
        break;
      }
    }
  };

  window.toggleScreenState = toggleScreenState;

  const renderPricingModals = () => {
    if (workspace.data.currentAppID === 1 && sucoBillingInfo) {
      return (
        <SucoPricingModal
          title="Select a plan to start"
          // This modal only opens in routes other than payment-loader plus if the plansID is null
          isOpen={
            (!sucoBillingInfo?.billingInfo.planId ||
              sucoBillingInfo?.billingInfo.currentPlan.plan_display_name ===
                "Approval Needed") &&
            location.pathname !== "/payment-loader"
          }
          onClose={() =>
            toaster.notify("You have to select a plan to continue", {
              duration: 2000,
            })
          }
          nonClosable
        />
      );
    } else if (
      workspace.data.currentAppID === 2 &&
      location.pathname.search("get-started") === -1 &&
      ofcoBillingInfo
    ) {
      return (
        <OfcoPricingModal
          title="Choose a plan to continue."
          // This modal only opens in routes other than payment-loader plus if the plansID is null
          isOpen={
            (!ofcoBillingInfo?.billingInfo.planId ||
              ofcoBillingInfo?.billingInfo.currentPlan.plan_display_name ===
                "Approval Needed") &&
            location.pathname !== "/payment-loader"
          }
          onClose={() =>
            toaster.notify("You have to select a plan to continue", {
              duration: 2000,
            })
          }
          nonClosable
        />
      );
    } else if (workspace.data.currentAppID === 3 && cocaBillingInfo) {
      return (
        <CocaPricingTakeOver
          nonClosable
          isOpen={
            (!cocaBillingInfo?.billingInfo.planId ||
              cocaBillingInfo?.billingInfo.currentPlan.plan_display_name ===
                "Approval Needed") &&
            location.pathname !== "/cornercart/payment-loader"
          }
          onClose={() => refetch()}
        />
      );
    }
  };

  const isOnboardingVisible = () => {
    if (
      (workspace.data.currentAppID === 1 &&
        workspace.data.suco_onboarding_progress === 0) ||
      (workspace.data.currentAppID === 2 &&
        workspace.data.ofco_onboarding_progress === 0) ||
      (workspace.data.currentAppID === 3 &&
        workspace.data.coca_onboarding_progress < 101)
    )
      return true;
    else return false;
  };

  if (workspace.data) {
    if (window.$crisp && Crisp.isCrispInjected()) {
      const $crisp = window.$crisp;
      const {
        data: {
          storeUrl,
          user: { email },
        },
      } = workspace;
      Crisp.session.setData({
        store_url: storeUrl,
        shopify_plan_name: workspace.data.shopifyPlanDetails.planName,
        shopify_plan_display_name:
          workspace.data.shopifyPlanDetails.planDisplayName,
      });
      Crisp.user.setEmail(email);
      // crisp method added to suppress warning (js shim code)
      $crisp.push(["safe", true]);

      switch (workspace.data.currentAppID) {
        case 1: {
          Crisp.session.setData({
            app_name: "SupportCorner",
            corner_billing_plan_name:
              sucoBillingInfo?.billingInfo?.currentPlan?.plan_display_name,
          });
          break;
        }
        case 2: {
          Crisp.session.setData({
            app_name: "OffersCorner",
            corner_billing_plan_name:
              ofcoBillingInfo?.billingInfo?.currentPlan?.plan_display_name,
          });
          break;
        }
        case 3: {
          Crisp.session.setData({
            app_name: "CornerCart",
            corner_billing_plan_name:
              cocaBillingInfo?.billingInfo?.currentPlan?.plan_display_name,
          });
          break;
        }
      }
    }
  }

  // This hook makes sure the language JSONs are uptodate in the right version.
  useLanguageVersionManager();

  // This function finds the default page to load based on the currentAppID Value
  const findDefaultPage = () => {
    switch (workspace.data.currentAppID) {
      case 1:
        return <Redirect to="/support/" />;
      case 2:
        return <Redirect to="/offers/" />;
      case 3:
        return <Redirect to="/cart/" />;
    }
  };

  if (workspace.data && workspace.data.currentAppID) {
    let apiKey;
    switch (workspace.data.currentAppID) {
      case 1: {
        apiKey = `${process.env.REACT_APP_SUCO_API_KEY}`;
        break;
      }
      case 2: {
        apiKey = `${process.env.REACT_APP_OFCO_API_KEY}`;
        break;
      }
      case 3: {
        apiKey = `${process.env.REACT_APP_COCA_API_KEY}`;
        break;
      }
    }

    return (
      <DoptOnboarding>
        {renderPricingModals()}
        <Switch>
          <Route exact path="/:app/payment-loader" component={PaymentsLoader} />
          {isOnboardingVisible() ? (
            <>
              <Route path="/">
                <Redirect to="/get-started/" />
              </Route>
              <Route
                path="/get-started"
                component={() => {
                  switch (workspace.data.currentAppID) {
                    case 1:
                      return <SucoOnboarding />;
                    case 2:
                      return <OfcoOnboarding />;
                    case 3:
                      return <CocaOnboarding />;
                  }
                }}
              />
            </>
          ) : (
            <>
              <Layout>
                <Switch>
                  <Route path="/support" component={AssistantRouter} />
                  <Route path="/settings" component={SettingsRouter} />
                  <Route path="/cart" component={CartRouter} />
                  <Route path="/offers" component={OffersRouter} />
                  <Route path="/">{findDefaultPage}</Route>
                </Switch>
              </Layout>
            </>
          )}
        </Switch>
      </DoptOnboarding>
    );
  } else return <LoadingSpinner />;
}

export default AuthorisedApp;
