import React, { useState } from "react";

import { RiAddCircleLine } from "react-icons/ri";
import { useQuery, useQueryCache } from "react-query";
import Button from "../../../../components/Button";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { queryAllAutomations } from "../../../../api/automations";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import AutomationsList from "./AutomationsList";
import CreateAutomationsModal from "./CreateAutomationsModal";

export default function Automations(historyProps) {
  const graphQL = useGraphQl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryCache = useQueryCache();

  const { data, refetch } = useQuery("queryAllAutomations", () =>
    graphQL(queryAllAutomations)
  );

  /**
   * This function renders when the automation list is empty
   */
  const RenderEmptyListAutomations = () => {
    return (
      <div className="h-full flex overflow-y-auto">
        <div className="w-full flex flex-col py-16 px-24 desktop:px-32 items-center">
          <img
            className="w-32"
            alt=""
            src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/2261a309-0f69-4352-223f-430adfaccd00/public"
          />
          <p className="font-bold text-base desktop:text-lg text-violet-700 my-4">
            Automations
          </p>
          <p className="text-gray-700 text-tiny desktop:text-base text-center max-w-lg">
          Automatically do X when Y happens. For example, If customer add a specific product, redirect to a URL which upsells other similar products.
          </p>
          <Button
            onClick={() => setIsModalVisible(true)}
            type="primary"
            className="mt-8"
          >
            Show me an example
          </Button>
        </div>
      </div>
    );
  };

  if (data && data.automations) {
    return (
      <>
        <PageViewContainer
          title="Automations"
          action={
            <div className="flex">
              <Button
                onClick={() => setIsModalVisible(true)}
                type="primary"
                icon={<RiAddCircleLine />}
              >
                Create a new Automation
              </Button>
            </div>
          }
        >
          <div className=" py-3 h-full overflow-y-auto">
            <AutomationsList
              data={data.automations}
              draggable
              onUpdate={() => {
                queryCache.invalidateQueries("queryAllAutomations");
                refetch();
              }}
              emptyState={<RenderEmptyListAutomations />}
            />
          </div>
        </PageViewContainer>
        <CreateAutomationsModal
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          automationCount={data.automations.length}
        />
      </>
    );
  } else {
    return <LoadingSpinner />;
  }
}
