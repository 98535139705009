import React, { useState } from "react";
import { FieldArray } from "formik";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import GoalItem from "./GoalItem";
import AddGoalButton from "./AddGoalButton";
import { RiAddCircleLine, RiCloseCircleLine } from "react-icons/ri";
import TabRadioButton from "../../../../../components/TabRadioButton";
import CartGoalPreview from "../CartGoalPreview";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Campaign({
  values,
  setFieldValue,
  isValidationFailed,
  currentCampaignKey,
}) {
  const [expandedGoals, setExpandedGoals] = useState(0);
  const [isAddGoalOpen, setisAddGoalOpen] = useState(false);
  const [previewCartValue, setPreviewCartValue] = useState(0);
  const GoalTypeOptions = [
    { label: "Total Cart Value", value: "totalOrderValue" },
    { label: "Product Quantity", value: "cartItemCount" },
    // { label: "Cart Weight", value: "cartWeight" },
  ];
  return (
    <div className=" h-full flex flex-row items-stretch min-h-0">
      <div className="desktop:max-w-2xl laptop:max-w-lg mobile:w-full px-6 desktop:px-8  overflow-y-auto overflow-x-visible pb-12">
        {/* Select Goal Type */}
        <CustomiserUnit
          title="Goal Type"
          description="Determine based on what the rewards are applied"
          customiserUnitId="cart-goal-type"
        >
          <TabRadioButton
            defaultValue={values?.campaign_cart_goals[0]?.goalType}
            options={GoalTypeOptions}
            onChange={(selectedType) => {
              setFieldValue("campaign_cart_goals[0].goalType", selectedType);
            }}
          />
        </CustomiserUnit>

        {/* Cart Goals */}
        <CustomiserUnit title="Cart Goals" description="Customise Cart Goals">
          <FieldArray
            name="campaign_cart_goals[0].milestones"
            render={(arrayHelpers) => {
              return (
                <div className="max-w-4xl border rounded-lg">
                  {values.campaign_cart_goals[0].milestones.map(
                    (milestone, index) => {
                      return (
                        <GoalItem
                          key={`goal-item-${milestone.id}`}
                          isValidationFailed={isValidationFailed}
                          currentMilestone={milestone}
                          values={values}
                          onDelete={() => {
                            if (expandedGoals === index) {
                              setExpandedGoals(index - 1);
                              if (index - 1 === 0) {
                                setPreviewCartValue(0);
                              } else {
                                setPreviewCartValue(
                                  values.campaign_cart_goals[0].milestones[
                                    index - 2
                                  ].goal + 1
                                );
                              }
                            }
                            arrayHelpers.remove(index);
                          }}
                          isExpanded={expandedGoals === index}
                          onExpand={() => {
                            if (expandedGoals !== index) {
                              setExpandedGoals(index);
                              if (index === 0) {
                                setPreviewCartValue(0);
                              } else {
                                setPreviewCartValue(
                                  values.campaign_cart_goals[0].milestones[
                                    index - 1
                                  ].goal + 1
                                );
                              }
                            } else setExpandedGoals(null);
                          }}
                          currentCampaignKey={currentCampaignKey}
                          index={index}
                          setFieldValue={setFieldValue}
                        />
                      );
                    }
                  )}
                  {/* Add New Goal Button */}
                  {/* {values.campaign_cart_goals[0].milestones.length < 4 && ( */}
                  <div id="add-new-goal">
                    {isAddGoalOpen ? (
                      <AddGoalButton
                        title="Select the reward for the new goal"
                        values={values}
                        currentCampaignKey={currentCampaignKey}
                        onSelect={(value) => {
                          // assign a id to rewards
                          value.rewards.id = `${values.id}-${values.campaign_cart_goals[0].milestones.length}`;
                          arrayHelpers.push(value);
                          setExpandedGoals(
                            values.campaign_cart_goals[0].milestones.length
                          );
                          setPreviewCartValue(
                            values.campaign_cart_goals[0].milestones[
                              values.campaign_cart_goals[0].milestones.length -
                                1
                            ].goal + 1
                          );
                        }}
                        onClose={() => {
                          setisAddGoalOpen(false);
                        }}
                      />
                    ) : (
                      <button
                        disabled={
                          values.campaign_cart_goals[0].milestones[0].rewards
                            .rewardStack.length === 0
                        }
                        onClick={() => {
                          setisAddGoalOpen(true);
                        }}
                        className="w-full  text-center py-6 font-bold flex items-center justify-center text-violet-700 transition-all hover:border-violet-50 hover:bg-violet-50 flex-col"
                      >
                        <div className="flex flex-row items-center">
                          <RiAddCircleLine />
                          <span className="ml-1 ">Add a New Goal</span>
                        </div>
                        {values.campaign_cart_goals[0].milestones[0].rewards
                          .rewardStack.length === 0 && (
                          <p className="text-xs text-black">
                            Please select the rewards for current mile stone
                          </p>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </CustomiserUnit>
      </div>
      <div className=" mobile:hidden laptop:flex flex-col items-center grow shrink-0 bg-gray-100 border-l py-8 px-3">
        <p className="mb-3 text-sm font-bold text-gray-400">
          Progress Bar Preview
        </p>
        <CartGoalPreview
          values={values.campaign_cart_goals[0]}
          currValue={previewCartValue}
        />
        <div className="mt-4 border px-4 py-2 rounded-lg ">
          <p className="text-sm font-bold text-gray-600">
            Use this slider to adjust the status of the progress bar
          </p>
          <input
            type="range"
            className="appearance-none w-full h-2 accent-violet-700 focus:outline-none focus:ring-0 focus:shadow-none bg-gray-200 rounded-full "
            id="customRange1"
            max={
              values.campaign_cart_goals[0].milestones[
                values.campaign_cart_goals[0].milestones.length - 1
              ].goal
            }
            value={previewCartValue}
            onChange={(e) => {
              setPreviewCartValue(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
