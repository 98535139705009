/**
 * This function is a wrapper written over fetch
 * and will always return a responsed with status  "OK" || "ERROR"
 * @param {URL} fetchURL url for fetch
 * @param {JSON} fetchRequest request
 * @param {Boolean} parseFetchResponse flag for parsing the JSON from response
 *
 * @return {{status: "OK || ERROR", data: "JSON || null", error: "null || Error", endpoint: URL, request: fetchRequest }} json response
 */
const fetchRequestWrapper = async (
  fetchURL,
  fetchRequest,
  parseFetchResponse = false
) => {
  /**
   * This function return the Error object
   * @param {*} error error object
   * @param {*} errorStatus error Status
   * @param {*} errorDetails error Details if any
   * @returns
   */
  const getErrorDetails = (error, errorStatus, errorDetails) => {
    const errorResponse = {
      status: "ERROR",
      endpoint: fetchURL,
      request: fetchRequest,
      error: error,
      errorStatus: errorStatus,
      errorDetails: errorDetails,
    };
    return errorResponse;
  };

  const response = await fetch(fetchURL, {
    headers: {
      "Content-Type": "application/json",
      "corner-token": window.sessionStorage.getItem("token"),
    },
    mode: "cors",
    method: "POST",
    body: JSON.stringify(fetchRequest),
  })
    .then(async (response) => {
      if (response.ok) {
        const responseData = await response.json();
        if (parseFetchResponse) {
          // currently response data is stringfied json so we need to parse it
          const jsonResponse = JSON.parse(responseData);
          return {
            status: "OK",
            data: jsonResponse,
          };
        } else {
          return {
            status: "OK",
            data: responseData,
            endpoint: fetchURL,
            request: fetchRequest,
            error: null,
          };
        }
      } else {
        // recieved error response
        console.log("Error response received in fetch", response);
        try {
          const parsedError = await response.json();
          return { ...getErrorDetails(response, response.status, parsedError) };
        } catch (parsingError) {
          return {
            ...getErrorDetails(response, response.status, parsingError),
          };
        }
      }
    })
    .catch((error) => {
      console.log("Failed to Fetch", error);
      return { ...getErrorDetails(error, "Fetch Failed", error.message) };
    });

  // the response will always be returned with status  "OK" || "ERROR"
  return response;
};

export default fetchRequestWrapper;
