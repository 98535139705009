import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  RiArchiveFill,
  RiDeleteBin2Line,
  RiArrowDownSLine,
  RiPauseCircleFill,
  RiPlayCircleFill,
  RiFileWarningFill,
  RiEdit2Line,
  RiCheckboxBlankCircleFill,
} from "react-icons/ri";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import List from "../../../../components/List";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import {
  bulkUpdateAutomations,
  updateAutomations,
} from "../../../../api/automations";
import { useHistory } from "react-router-dom";

/**
 * An UI element designed to render camapaign lists
 * @component
 * @param {Object} data - Automations Data
 * @param {String} onUpdate - Calback function for what happens on update. Usually refetch the data provided to this component on update
 * @param {Node} emptyState - Empty State
 * @param {Boolean} draggable - Dragabble or not
 * @param {Boolean} automationsLimitCount - Number of camapigns before triggering paywall
 */
export default function Automations({
  data,
  onUpdate,
  emptyState,
  draggable,
  automationsLimitCount,
}) {
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [automationsList, setAutomationsList] = useState([]);
  const [activeSortOption, setActiveSortOption] = useState("all");
  const graphQL = useGraphQl();
  const [isArticleDeleteWarningOpen, setIsArticleDeleteWarningOpen] =
    useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

  const updateAutomationsDetails = async (input) => {
    await graphQL(updateAutomations, {
      automationsId: { id: input.id },
      automationsInput: input.values,
    });
  };

  const bulkUpdateAutomationsDetails = async (input) => {
    let valueToUpdate;
    switch (input.updateType) {
      case "archive":
        valueToUpdate = { status: "archived" };
        break;
      case "draft":
        valueToUpdate = { status: "draft" };
        break;
      case "active":
        valueToUpdate = { status: "active" };
        break;
      case "delete":
        valueToUpdate = { isDeleted: true };
        break;
    }

    await graphQL(bulkUpdateAutomations, {
      idArray: input.id,
      automationsInput: valueToUpdate,
    });
  };

  const [updateAutomationsMutation] = useMutation(updateAutomationsDetails, {
    onSuccess: () => {
      onUpdate();
    },
  });

  const [bulkUpdateAutomationsMutation] = useMutation(
    bulkUpdateAutomationsDetails,
    {
      onSuccess: (e) => {
        setSelectedItems([]);
        toaster.notify("Updated successfully", {
          duration: 2000,
        });
        onUpdate();
      },
    }
  );

  const handleBulkAction = (action, idArray) => {
    bulkUpdateAutomationsMutation({
      id: idArray,
      updateType: action,
    });
  };
  const getSortedData = () => {
    switch (activeSortOption) {
      case "all":
        return automationsList.filter(
          (automations) =>
            automations.status !== "archived" && !automations.isDeleted
        );
      case "active":
        return automationsList.filter(
          (automations) => automations.status === "active"
        );
      case "draft":
        return automationsList.filter(
          (automations) => automations.status === "draft"
        );
      case "archived":
        return automationsList.filter(
          (automations) => automations.status === "archived"
        );
    }
  };
  const sortOptions = [
    { label: "All", value: "all" },
    { label: "Active automations", value: "active" },
    { label: "Paused automations", value: "draft" },
    { label: "Archived", value: "archived" },
  ];

  const AutomationsStatus = ({ status }) => {
    switch (status) {
      case "active":
        return (
          <span className="flex items-center rounded-sm py-2">
            <RiPlayCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />
            Active
          </span>
        );
      case "draft":
        return (
          <span className="flex items-center  rounded-sm py-2 ">
            <RiPauseCircleFill className="flex-none text-amber-400 mr-2 text-lg" />
            Paused
          </span>
        );
      case "archived":
        return (
          <span className="flex items-center  rounded-sm py-2">
            <RiArchiveFill className="flex-none text-red-400 mr-2 text-lg" />
            Archived
          </span>
        );
      default:
        return (
          <span className="flex items-center  rounded-sm py-2">
            <RiCheckboxBlankCircleFill className="flex-none text-gray-400 mr-2 text-lg" />
            Deleted
          </span>
        );
    }
  };

  // When user drags an automations, the whole new automationsList array which is aranged in the new order is passed on to this fuction. Here we update each automations's priority with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateAutomationsMutation({
        id: item.id,
        values: {
          priority: updatedList.length - 1 - index,
        },
      });
    });
  };

  /**
   * This component renders the automations list
   * @param {Object} automationsItem the entire automations object
   * @returns React Element
   */
  const AutomationsItem = ({ automationsItem }) => {
    return (
      <div className="grid grid-cols-12 items-center justify-center py-3 desktop:py-5">
        <div className="col-span-1 mobile:hidden laptop:flex justify-center ">
          <img
            className="w-9 desktop:w-12"
            src={
              "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/2261a309-0f69-4352-223f-430adfaccd00/public"
            }
            alt=""
          />
        </div>
        <button
          onClick={() => {
            history.push(`/cart/cro/automations/edit/${automationsItem.id}`);
          }}
          className="laptop:col-span-5 mobile:col-span-12 desktop:px-7 cursor-pointer mobile:px-3 text-left"
        >
          <p className="font-bold text-black-500 hover:text-violet-700  desktop:text-base text-tiny">
            {automationsItem?.settings?.title}
          </p>
          <div className="flex items-center">
            <p className="text-gray-500 flex items-center text-tiny desktop:text-base mr-3">
              {automationsItem.templateType}
            </p>
            <div className="ml-3 mobile:block laptop:hidden">
              <AutomationsStatus status={automationsItem.status} />
            </div>
          </div>
        </button>
        <div className="col-span-2 laptop:flex mobile:hidden ">
          <AutomationsStatus status={automationsItem.status} />
        </div>
        <div className="px-3 col-span-3 mobile:hidden laptop:flex flex-center">
          <Button
            onClick={() => {
              history.push(`/cart/cro/automations/edit/${automationsItem.id}`);
            }}
            type="outline"
            icon={<RiEdit2Line />}
          >
            <span className="laptop:block mobile:hidden ml-2">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  // each time anything is updated this functio makes sure the updated campoaign list is sorted.
  const initialiseList = () => {
    const arrangedAutomationsList = data.sort(
      (a, b) => b.priority - a.priority
    );
    setAutomationsList(arrangedAutomationsList);
  };

  useEffect(() => {
    initialiseList();
  }, [data]);

  return (
    <>
      <div className="flex  py-1 px-2 desktop:py-2 desktop:px-4 flex-wrap">
        <div className="flex items-center">
          <p className="mr-3 desktop:block hidden  ">View</p>
          <Select
            value={sortOptions.find(
              (option) => option.value === activeSortOption
            )}
            isSearchable={false}
            onChange={(e) => setActiveSortOption(e.value)}
            className="w-48 mr-2"
            options={sortOptions}
          />
        </div>
        <div className="flex items-center ">
          <Dropdown
            alignment="right"
            type="link"
            icon={<RiArrowDownSLine />}
            triggerText="Bulk Actions"
            disabled={selectedItems.length === 0}
          >
            <Button
              onClick={() => {
                handleBulkAction("archive", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiArchiveFill />}
            >
              Archive Automations
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("draft", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiPauseCircleFill />}
            >
              Pause Automations
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("active", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiPlayCircleFill />}
            >
              Activate Automations
            </Button>

            <Button
              onClick={() => {
                setIsArticleDeleteWarningOpen(true);
              }}
              type="danger-link"
              className="mr-2"
              icon={<RiDeleteBin2Line />}
            >
              Delete Selected Automations
            </Button>
          </Dropdown>
        </div>

        <ModalTransition>
          {isArticleDeleteWarningOpen && (
            <Modal
              onClose={() => {
                setIsArticleDeleteWarningOpen(false);
              }}
            >
              <div className="p-8 overflow-y-auto">
                <h2 className="text-base font-bold mb-4">
                  You’re about to delete these selected automations
                </h2>
                <p className="text-base mb-4">
                  We won't be able to undo this later, are you sure you want to
                  conitnue with the deletion?
                </p>
                <div className="flex flex-row justify-end">
                  <Button
                    onClick={() => setIsArticleDeleteWarningOpen(false)}
                    type="link"
                  >
                    No, keep it
                  </Button>
                  <Button
                    className="ml-4"
                    onClick={() => {
                      handleBulkAction("delete", selectedItems);
                      setIsArticleDeleteWarningOpen(false);
                    }}
                    type="danger"
                  >
                    Delete it
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </div>

      <div className="desktop:px-8 mobile:px-3  pt-4 ">
        <List
          draggable={draggable && activeSortOption === "all"}
          onDrag={handleDrag}
          items={getSortedData()}
          selectedItemsId={selectedItems}
          onSelectionChange={setSelectedItems}
          emptyState={emptyState}
          header={
            <div className="grid grid-cols-12 items-center justify-center ">
              <div className="laptop:col-span-6 mobile:col-span-12 px-3 desktop:px-7">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">
                  Automations
                </p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden">
                <p className="font-bold text-gray-600 desktop:text-base text-tiny">
                  Status
                </p>
              </div>
              <div className="col-span-3 laptop:block mobile:hidden"></div>
            </div>
          }
          render={(item, index) => {
            return (
              <AutomationsItem
                key={`list-items-${index}`}
                automationsItem={item}
              />
            );
          }}
        />
      </div>
    </>
  );
}
