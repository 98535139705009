import React, { Children } from "react";
import { RiSubtractFill, RiAddFill } from "react-icons/ri";

/**
 * A component to input numbers
 * @component
 * @param {Number} value - value passed in
 * @param {Number} max - max value allowed
 * @param {Number} min - minimum value allowed
 * @param {Boolean} typeable - is it possibkle to change by typing
 * @param {Function} onChange - What happens on change
 */
const NumberInput = ({ value, min, max, onChange, typeable }) => {
  return (
    <div className="flex">
      <div className="flex items-center border rounded-lg p-1 bg-gray-100">
        <button
          onClick={() => {
            let updatedValue = value - 1;
            if (min === null || updatedValue >= min) onChange(updatedValue);
          }}
          className={`p-3 flex justify-center items-center appearance-none  bg-white border rounded-lg  hover:bg-violet-700 hover:text-white hover:shadow-md transition-all cursor-pointer ${
            min === value && "bg-gray-100 text-gray-400 pointer-events-none"
          }`}
        >
          <span>
            <RiSubtractFill />
          </span>
        </button>
        <input
          onChange={(event) => {
            let updatedValue = parseInt(event.target.value);
            if (
              typeable &&
              updatedValue &&
              (min === null || updatedValue >= min) &&
              (max === null || updatedValue <= max)
            ) {
              onChange(updatedValue);
            }
          }}
          value={value}
          className={`mx-3 text-center bg-gray-100 font-bold appearance-none bg-none max-w-min w-8 ${
            !typeable && "pointer-events-none"
          }`}
        />
        <button
          onClick={() => {
            let updatedValue = value + 1;
            if (max === null || updatedValue <= max) onChange(updatedValue);
          }}
          className={`p-3 flex justify-center items-center appearance-none  bg-white border rounded-lg  hover:bg-violet-700 hover:text-white hover:shadow-md transition-all cursor-pointer ${
            max === value && "bg-gray-100  text-gray-400 pointer-events-none"
          }`}
        >
          <span>
            <RiAddFill />
          </span>
        </button>
      </div>
    </div>
  );
};

export default NumberInput;
