import React, { useState } from "react";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "./index.css";

const CustomTimePicker = ({ isDisabled, value, onChange }) => {
  return (
    <div className="border border-solid border-gray-200 rounded-md p-2 text-left">
      <TimePicker
        format="h:m a"
        onChange={onChange}
        value={value ? new Date(value) : null}
        disableClock={true}
        clockIcon={null}
        disabled={isDisabled}
      />
    </div>
  );
};

export default CustomTimePicker;
