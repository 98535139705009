import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import { RiPencilFill, RiDeleteBin6Line, RiAddFill } from "react-icons/ri";
import Button from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { langOptions } from "../../../../utilities/variables";

const LanguageEditPopup = ({
  translatedLanguages,
  currentLanguage,
  onLangugageAdd,
  onLangugageEdit,
  onLanguageDelete,
}) => {
  const history = useHistory();
  const workspace = useWorkspace();

  const [isOpen, setIsOpen] = useState(false);

  /**
   *  This function returns language options
   * @returns {Array}  an array of language options
   */
  const getTranslatedLangOptions = () => {
    if (
      Array.isArray(workspace.data.translated_languages) &&
      workspace.data.translated_languages.length > 0
    ) {
      const translatedLangOptions = langOptions.filter((langOption) =>
        workspace.data.translated_languages.includes(langOption.value)
      );
      let otherLangOptions = [];
      translatedLanguages.forEach((language) => {
        // if any languages in content is not included in warkspace translated languages
        // add their language options from langOptions
        if (!workspace.data.translated_languages.includes(language)) {
          const langOption = langOptions.find(
            (langOption) => langOption.value === language
          );
          otherLangOptions = [...otherLangOptions, langOption];
        }
      });
      const langEditPopupOptions = [
        ...translatedLangOptions,
        ...otherLangOptions,
      ];
      const sortedLangEditPopupOptions = langEditPopupOptions.sort((a, b) => {
        // sort array so that default language comes as the first elemen
        if (a.value === workspace.data.default_language) {
          return -1; // default_language item first
        } else if (b.value === workspace.data.default_language) {
          return 1; // default_language item first
        } else if (!translatedLanguages.includes(a.value)) {
          return 1; // translation not added, comes last
        } else if (!translatedLanguages.includes(b.value)) {
          return -1; // translation not added, comes last
        } else return 0; // rest of theitems
      });
      return sortedLangEditPopupOptions;
    } else {
      const translatedLangOptions = langOptions.filter(
        (langOption) => workspace.data.default_language === langOption.value
      );
      return translatedLangOptions;
    }
  };

  const translatedLangOptions = getTranslatedLangOptions();

  const renderLangOption = (langOption) => {
    return (
      <div
        className={`flex flex-row p-2 justify-between items-center ${
          currentLanguage === langOption.value && "bg-violet-50 rounded-md"
        }`}
        key={langOption.value}
      >
        <span>{langOption.label}</span>
        <div>
          {translatedLanguages.includes(langOption.value) ? (
            <span className="flex flex-row">
              {workspace.data.default_language !== langOption.value && (
                <Button
                  noPadding
                  className="p-2 rounded-full border border-solid border-red-300"
                  type="danger-ghost"
                  icon={<RiDeleteBin6Line />}
                  onClick={() => {
                    onLanguageDelete(langOption);
                    setIsOpen(false);
                  }}
                />
              )}
              <Button
                noPadding
                className="p-2 rounded-full ml-4"
                type="outline"
                icon={<RiPencilFill />}
                onClick={() => {
                  onLangugageEdit(langOption);
                  setIsOpen(false);
                }}
              />
            </span>
          ) : (
            <Button
              noPadding
              type="outline"
              className="py-1 pl-2 pr-3"
              icon={<RiAddFill />}
              onClick={() => {
                onLangugageAdd(langOption);
                setIsOpen(false);
              }}
            >
              Add
            </Button>
          )}
        </div>
      </div>
    );
  };

  const getCurrentLanguage = () => {
    const currentLangItem = translatedLangOptions.find(
      (language) => language.value === currentLanguage
    );
    return currentLangItem.label;
  };

  return (
    <Popup
      isOpen={isOpen}
      placement="bottom-start"
      onClose={() => setIsOpen(false)}
      content={() => (
        <div className="p-2">
          {translatedLangOptions.map((langOption) => {
            return renderLangOption(langOption);
          })}
          <div className="border-t border-solid border-gray-200 flex flex-row pt-3 pb-2">
            <p className="text-primary text-base mr-1">To add Languages,</p>
            <Button
              noPadding
              type="link-primary"
              className={"font-normal"}
              onClick={() => {
                history.push("/settings/language");
              }}
            >
              Click here
            </Button>
          </div>
        </div>
      )}
      trigger={(triggerProps) => (
        <button
          {...triggerProps}
          className="flex items-center font-bold text-gray-700"
          type="link"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-gray-600 flex items-center font-semibold">
            {getCurrentLanguage()}
            <RiPencilFill className="ml-2" />
          </p>
        </button>
      )}
    />
  );
};

export default LanguageEditPopup;
