import React from "react";
import AudienceRuleBuilder from "../../../../../components/jsonLogicBuilders/AudienceRuleBuilder";
import CustomiserUnit from "../../../../../components/CustomiserUnit";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Content({ values, setFieldValue }) {
  return (
    <div className="h-full  overflow-y-auto px-8 py-4">
      {/* Audience targeting Rule Builder */}
      <CustomiserUnit
        title="Target an Audience"
        description="Create a rule to define the target audience"
      >
        <AudienceRuleBuilder
          value={values.audience}
          popupAlignment="bottom"
          onChange={(updatedJsonLogic) => {
            setFieldValue("audience", { ...updatedJsonLogic });
          }}
          emptyTitle="Targeting Everyone"
          emptySubtitle="Right now, this campaign is visible to all customers. If you want to show it only to a specific group of customers, add a new targeting rule."
        />
      </CustomiserUnit>
    </div>
  );
}
