import React, { useState } from "react";
import TabRadioButton from "../TabRadioButton";
import TakeOverDrawer from "../TakeOverDrawer";
import FixingPricingTable from "./FixedPricingTable";
import UsagePricingTable from "./UsagePricingTable";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import ErrorModal from "../ErrorModal";
import contactCrisponApiError from "../../utilities/contactCrisponApiError";
import redirectToURLviaAppBrige from "../../utilities/redirectToURLviaAppBrige";
import { nanoid } from "nanoid";
import fetchRequestWrapper from "../../utilities/fetchRequestWrapper";
import { useAppBridge } from "@shopify/app-bridge-react";
import toaster from "toasted-notes";
import Button from "../Button";
import getCocaPricingDetails from "./getCocaPricingDetails";
import { RiShieldCheckFill, RiInformationLine } from "react-icons/ri";

/**
 * Component for rendering Corner cart pricing
 * @param {Boolean} isOpen controls whether the takeover is closed or not
 * @param {Function} onClose function for closing the takeover
 * @param {Boolean} nonClosable the takeover can be closed or not
 * @param {Function} refreshBilling function to refresh billing when downgrading
 * @param {String} currentPlanType current plan type: free_plan for free
 */
const CocaPricingTakeOver = ({
  isOpen,
  onClose,
  nonClosable,
  refreshBilling,
  currentPlanType = "",
}) => {
  const { data, refreshWorkspace } = useWorkspace();
  const appBridge = useAppBridge();

  const [pricingType, setPricingType] = useState("fixed");
  const [isFreePlanLoading, setIsFreePlanLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorKeyRef, setErrorKeyRef] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const pricingTypeOptions = [
    { label: <span className="">Usage Based Billing</span>, value: "usage" },
    {
      label: <span className="px-10">Fixed Rate</span>,
      value: "fixed",
    },
  ];

  const managePricingAPIError = (errorDetails) => {
    const apiErrorKey = nanoid(10);
    setErrorKeyRef(apiErrorKey);
    setOpenErrorModal(true);
    window.Rollbar.error(
      "Cornercart billing subscribe api failed upon install",
      {
        ...errorDetails,
        API_ERROR_KEY: apiErrorKey,
      }
    );
  };

  const onSelectFreePlan = async () => {
    setIsFreePlanLoading(true);
    const cocaPricingRequest = {
      shop: data.storeMyShopifyUrl,
      invite_code: "",
      corner_plan_code: "COCA_FREE_2023_APR",
    };
    const cocaPricingDetails = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/billing/subscribe/`,
      cocaPricingRequest
    );
    setIsFreePlanLoading(false);
    if (cocaPricingDetails.status === "OK") {
      if (refreshBilling) {
        refreshBilling();
      }
      refreshWorkspace();
      if (onClose) {
        onClose();
      }
    } else {
      managePricingAPIError(cocaPricingDetails);
    }
  };

  const getCurrentBillingPlanCode = () => {
    const currentPlanDetails = getCocaPricingDetails(
      data.shopifyPlanDetails.planDisplayName
    );
    if (currentPlanDetails.pricing !== "Free") {
      return currentPlanDetails.planCode;
    } else {
      // if the plan pricing is free, ie if the shopify plan is developer preview or trial etc
      // return basic pricing plan code
      return `${getCocaPricingDetails("Basic Shopify").planCode}`;
    }
  };

  const onSelectProPlan = async () => {
    const currentPlanCode = getCurrentBillingPlanCode();
    setIsLoading(true);
    const cocaPricingRequest = {
      shop: data.storeMyShopifyUrl,
      invite_code: "",
      corner_plan_code: currentPlanCode,
    };
    const cocaPricingDetails = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/billing/subscribe/`,
      cocaPricingRequest
    );
    setIsLoading(false);
    if (cocaPricingDetails.status === "OK") {
      const { confirmationUrl } = cocaPricingDetails.data;
      redirectToURLviaAppBrige(confirmationUrl, appBridge);
    } else {
      managePricingAPIError(cocaPricingDetails);
    }
  };

  const onSelectUsagePlan = async () => {
    setIsLoading(true);
    const cocaPricingRequest = {
      shop: data.storeMyShopifyUrl,
      invite_code: "",
      corner_plan_code: "COCA_USAGE_BASED_2023_APR",
    };
    const cocaPricingDetails = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/billing/subscribe/`,
      cocaPricingRequest
    );
    setIsLoading(false);
    if (cocaPricingDetails.status === "OK") {
      const { confirmationUrl } = cocaPricingDetails.data;
      redirectToURLviaAppBrige(confirmationUrl, appBridge);
    } else {
      managePricingAPIError(cocaPricingDetails);
    }
  };

  const handleCocaPricingClose = () => {
    if (nonClosable) {
      toaster.notify("You have to select a plan to continue", {
        duration: 2000,
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      <TakeOverDrawer isOpen={isOpen} onClose={() => handleCocaPricingClose()}>
        <div className="flex flex-col w-full p-16 items-center overflow-y-auto">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="grow text-center text-violet-700 font-bold text-2xl ">
              Select a pricing that fits your business
            </div>
            {!nonClosable && (
              <div className="">
                <Button onClick={() => onClose()}>Close</Button>
              </div>
            )}
          </div>

          <div className="max-w-4xl ">
            <p className="text-base text-black mt-3">
              We offer both fixed monthly charge based plans and usage based
              plans. Our fixed prices are based on your shopify plan and our
              usage based pricing will be based on your orders. Select one which
              fits your business model.
            </p>
            {getCocaPricingDetails(data.shopifyPlanDetails.planDisplayName)
              .pricing === "Free" ? (
              <div className="flex justify-center mt-3">
                <p className="px-2 flex items-center py-1 border bg-amber-100 border-amber-500 rounded-lg text-amber-800 font-bold text-sm">
                  <RiInformationLine size={16} />
                  <span className="ml-2">
                    Development & Trial stores will not be charged. Select a
                    plan & continue
                  </span>
                </p>
              </div>
            ) : (
              <div className="flex justify-center mt-3">
                <p className="flex items-center px-2 py-1 border bg-green-100 border-green-500 rounded-lg text-green-800 font-bold text-sm">
                  <RiShieldCheckFill size={16} />
                  <span className="ml-2">
                    If you are not satisfied, let us know within 60 days of
                    installing the app, and we'll refund you in full, no
                    questions asked.
                  </span>
                </p>
              </div>
            )}
            <div className="flex justify-center my-8">
              <TabRadioButton
                defaultValue={pricingType}
                options={pricingTypeOptions}
                onChange={(selectedValue) => setPricingType(selectedValue)}
              />
            </div>

            <div className="w-full">
              {pricingType === "fixed" ? (
                <FixingPricingTable
                  onSelectFreePlan={() => onSelectFreePlan()}
                  onSelectProPlan={(planCode) => onSelectProPlan(planCode)}
                  isProPlanLoading={isLoading}
                  isFreePlanLoading={isFreePlanLoading}
                  currentPlanType={currentPlanType}
                />
              ) : (
                <UsagePricingTable
                  onSelectUsagePlan={onSelectUsagePlan}
                  isLoading={isLoading}
                  currentPlanType={currentPlanType}
                />
              )}
            </div>
          </div>
        </div>
      </TakeOverDrawer>
      <ErrorModal
        isOpen={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        title="Billing Request Failed"
        description="There was an error while calling Shopify Billing API. Please contact our team so we can help you resolve this."
        handleApiError={() =>
          contactCrisponApiError(
            errorKeyRef,
            `I'm facing an issue: subscribing to cornercart billing failed`
          )
        }
      />
    </>
  );
};

export default CocaPricingTakeOver;
