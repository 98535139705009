import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import { RiFlagFill, RiAlertFill } from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import Button from "../../../../../components/Button";
import toaster from "toasted-notes";
import MergeTags from "../../../../../components/MergeTags";

/**
 * A UI component for render a milestone item
 * @param {Object} currentMilestone The milestone this specific component need to render
 * @param {Object} index index of this milestone in the milestone array
 * @param {Object} values values from the parent formik
 * @param {Function} setFieldValue formik funtion
 * @param {Array} onExpand what happens if you click expand/collapse button
 * @param {Function} isExpanded - Boolean showing if the goal item is expanded or not
 * @param {Function} onDelete - What happens on deletion of milestone
 */
export default function GoalItem({
  currentMilestone,
  index,
  values,
  setFieldValue,
  onExpand,
  isExpanded,
  onDelete,
  isValidationFailed,
  currentCampaignKey,
}) {
  const [isGoalValid, setIsGoalValid] = useState(true);
  const workspace = useWorkspace();

  const rewardOptions = [
    { label: "Give Free Shipping", value: "shippingDiscount" },
    { label: "Give Free Products", value: "freeProduct" },
    {
      label: "Give an order Discount",
      value: "orderDiscount",
      disabled: true,
    },
  ];

  const getRewardType = () => {
    let currentReward = rewardOptions.find(
      (item) => item.value === currentMilestone.rewards.rewardStack[0].type
    );
    return currentReward.label;
  };

  /**
   * This function return a warning toaster
   * @param {String} warningText text to be shown in toaster
   * @returns {JSX} warning toaster
   */
  const showWarningToaster = (warningText) => {
    toaster.notify(
      <div className="flex flex-row items-center">
        <RiAlertFill className="text-orange-400" />
        <p className="text-base ml-2">{warningText}</p>
      </div>,
      {
        duration: 5000,
      }
    );
  };

  useEffect(() => {
    if (currentMilestone.rewards.rewardStack.length > 0) {
      if (
        currentMilestone.rewards.rewardStack[0].type === "freeProduct" &&
        !currentMilestone.rewards.rewardStack[0]?.target?.id
      ) {
        setIsGoalValid(false);
      } else if (currentMilestone.goal < 1 || !currentMilestone.goal) {
        setIsGoalValid(false);
      }
      // The following condition makes sure the value doesnt increase than the next stage
      else if (
        values.campaign_cart_goals[0].milestones.length > index + 1 &&
        currentMilestone.goal >=
          values.campaign_cart_goals[0].milestones[index + 1].goal
      ) {
        setIsGoalValid(false);
      }
      // The following condition makes sure the value doesnt decrease than the previous stage
      else if (
        index > 0 &&
        currentMilestone.goal <=
          values.campaign_cart_goals[0].milestones[index - 1].goal
      ) {
        setIsGoalValid(false);
      } else {
        setIsGoalValid(true);
      }
    } else {
      setIsGoalValid(false);
    }
  }, [currentMilestone]);

  return (
    <div
      className={`border-b ${
        !isGoalValid && isValidationFailed && "border-red-100"
      }`}
    >
      <div
        className={`flex items-center transition-all justify-between w-full px-4 py-4 desktop:py-6 ${
          isExpanded && "bg-gray-100"
        }`}
      >
        <button onClick={onExpand} className="flex flex-grow items-center ">
          <div className="flex justify-center items-center mr-2  rounded-full h-8 w-8 bg-violet-100 text-violet-600">
            <RiFlagFill />
          </div>
          <h2 className={`font-bold text-lg shrink-0`}>
            Goal{" "}
            {values.campaign_cart_goals[0].milestones.length > 1 && index + 1} :
          </h2>
          <p className="ml-2 text-sm text-left text-gray-500">
            {currentMilestone.rewards.rewardStack.length > 0 &&
              `${getRewardType()} when ${
                values?.campaign_cart_goals[0]?.goalType === "totalOrderValue"
                  ? "total cart value"
                  : "total items in cart"
              } reaches ${currentMilestone.goal} ${
                values.campaign_cart_goals[0].goalType === "totalOrderValue"
                  ? workspace.data.storeCurrency
                  : ""
              }`}
          </p>
        </button>
        <div className="flex items-center">
          <Button onClick={onExpand} type="link">
            {isExpanded ? "Collapse" : "Expand"}
          </Button>
        </div>
      </div>
      {isExpanded && (
        <div className="px-6">
          {/* Above Progress bar */}
          <CustomiserUnit
            title="Text Above Progress bar"
            description="Enter the text to be shown above the progress bar after customer achieves the goal"
          >
            <MergeTags
              tags={[
                {
                  tag: "{{goal}}",
                  description:
                    "Will be replaced with amount required to reach the goal",
                },
                {
                  tag: "{{current_status}}",
                  description: "Will be replaced with the current cart value",
                },
                {
                  tag: "{{discount}}",
                  description: "Will be replaced with the discount amount",
                },
              ]}
            />

            <div className="mt-2 items-center">
              <p className=" text-gray-600 text-sm mr-2 ">
                Before Achieving the goal
              </p>
              <Textfield
                value={currentMilestone.content.preGoalText}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `campaign_cart_goals[0].milestones[${index}].content.preGoalText`,
                    onChangeEvent.target.value
                  );
                }}
                name="basic"
              />
            </div>
            <div className="mt-2">
              <p className=" text-gray-600 text-sm">After Achieving the goal</p>
              <Textfield
                value={currentMilestone.content.postGoalText}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `campaign_cart_goals[0].milestones[${index}].content.postGoalText`,
                    onChangeEvent.target.value
                  );
                }}
                name="basic"
              />
            </div>
          </CustomiserUnit>

          {/* Below progress bar */}
          <CustomiserUnit
            title="Text Below Progress Bar"
            description="Enter the text to be shown below the progress bar"
          >
            <MergeTags
              tags={[
                {
                  tag: "{{goal}}",
                  description:
                    "Will be replaced with amount required to reach the goal",
                },
                {
                  tag: "{{current_status}}",
                  description: "Will be replaced with the current cart value",
                },
                {
                  tag: "{{discount}}",
                  description: "Will be replaced with the discount amount",
                },
              ]}
            />
            <Textfield
              value={currentMilestone.content.milestoneLabel}
              onChange={(onChangeEvent) => {
                setFieldValue(
                  `campaign_cart_goals[0].milestones[${index}].content.milestoneLabel`,
                  onChangeEvent.target.value
                );
              }}
              name="basic"
            />
          </CustomiserUnit>

          {values?.campaign_cart_goals[0]?.config.featureInOffersPage && (
            <>
              {/* Offer page title */}
              <CustomiserUnit
                title="Title Shown in Offer Page"
                description="Enter the text to be shown as the title of the card shown in the offer page"
              >
                <MergeTags
                  tags={[
                    {
                      tag: "{{goal}}",
                      description:
                        "Will be replaced with amount required to reach the goal",
                    },
                    {
                      tag: "{{current_status}}",
                      description:
                        "Will be replaced with the current cart value",
                    },
                    {
                      tag: "{{discount}}",
                      description: "Will be replaced with the discount amount",
                    },
                  ]}
                />

                <div className="mt-2 items-center">
                  <p className=" text-gray-600 text-sm mr-2 ">
                    Before Achieving the goal
                  </p>
                  <Textfield
                    value={
                      currentMilestone.content.preGoalOfferPageTitle ||
                      currentMilestone.content.milestoneLabel
                    }
                    onChange={(onChangeEvent) => {
                      setFieldValue(
                        `campaign_cart_goals[0].milestones[${index}].content.preGoalOfferPageTitle`,
                        onChangeEvent.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
                <div className="mt-2">
                  <p className=" text-gray-600 text-sm">
                    After Achieving the goal
                  </p>
                  <Textfield
                    value={
                      currentMilestone.content.postGoalOfferPageTitle ||
                      currentMilestone.content.milestoneLabel
                    }
                    onChange={(onChangeEvent) => {
                      setFieldValue(
                        `campaign_cart_goals[0].milestones[${index}].content.postGoalOfferPageTitle`,
                        onChangeEvent.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
              </CustomiserUnit>

              {/* Offer page subtitle */}
              <CustomiserUnit
                title="Sub-Title Shown in Offer Page"
                description="Enter the text to be shown as the sub-title of the card shown in the offer page"
              >
                <MergeTags
                  tags={[
                    {
                      tag: "{{goal}}",
                      description:
                        "Will be replaced with amount required to reach the goal",
                    },
                    {
                      tag: "{{current_status}}",
                      description:
                        "Will be replaced with the current cart value",
                    },
                    {
                      tag: "{{discount}}",
                      description: "Will be replaced with the discount amount",
                    },
                  ]}
                />

                <div className="mt-2 items-center">
                  <p className=" text-gray-600 text-sm mr-2 ">
                    Before Achieving the goal
                  </p>
                  <Textfield
                    value={
                      currentMilestone.content.preGoalOfferPageSubTitle ||
                      currentMilestone.content.preGoalText
                    }
                    onChange={(onChangeEvent) => {
                      setFieldValue(
                        `campaign_cart_goals[0].milestones[${index}].content.preGoalOfferPageSubTitle`,
                        onChangeEvent.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
                <div className="mt-2">
                  <p className=" text-gray-600 text-sm">
                    After Achieving the goal
                  </p>
                  <Textfield
                    value={
                      currentMilestone.content.postGoalOfferPageSubTitle ||
                      currentMilestone.content.postGoalText
                    }
                    onChange={(onChangeEvent) => {
                      setFieldValue(
                        `campaign_cart_goals[0].milestones[${index}].content.postGoalOfferPageSubTitle`,
                        onChangeEvent.target.value
                      );
                    }}
                    name="basic"
                  />
                </div>
              </CustomiserUnit>
            </>
          )}
        </div>
      )}
    </div>
  );
}
