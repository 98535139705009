import React, { useState } from "react";
import { RiArrowLeftLine, RiMenuFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import MobileSideBar from "./MobileSideBar";
import { useQueryCache } from "react-query";

/* interface Props { */
/*   title: string; */
/*   customBackButton: React.ReactNode; */
/*   action?: React.ReactNode; */
/*   children: React.ReactNode; */
const PageViewContainer = ({
  title,
  action,
  children,
  customBackButton,
  queryToBeInvalidated,
}) => {
  // const app = useAppBridge();
  const [mobileSideBarVisibility, setMobileSideBarVisibility] = useState(false);

  let history = useHistory();
  const queryCache = useQueryCache();
  return (
    <div className="flex flex-col h-full min-w-0 grow">
      {/*  <div className="grid grid-rows-shrinked-page-row desktop:grid-rows-page-row grid-cols-page-col  "> */}
      <div className="flex shrink-0 items-center justify-between mx-3 h-16 desktop:h-24">
        <div className="flex flex-none items-center">
          {customBackButton || (
            <button
              onClick={() => {
                history.goBack();
                if (queryToBeInvalidated) {
                  queryCache.removeQueries([queryToBeInvalidated]);
                }
              }}
              className="grow-0 bg-white p-2 desktop:p-3 mobile:hidden laptop:block  mr-3 border  text-violet-700 hover:border-violet-700 transition-all rounded-lg"
            >
              <RiArrowLeftLine />
            </button>
          )}
          <button
            onClick={() => setMobileSideBarVisibility(true)}
            className="grow-0 bg-white p-2 desktop:p-3 mobile:block laptop:hidden  mr-3 border  text-violet-700 hover:border-violet-700 transition-all rounded-lg"
          >
            <RiMenuFill />
          </button>

          <div className="">
            <h1 className="font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl">
              {title}
            </h1>
          </div>
        </div>

        {action && <div>{action}</div>}
      </div>

      <div className="flex relative flex-col h-2 bg-white mx-3 mb-3 rounded-lg shadow-md grow overflow-hidden">
        {children}
      </div>
      <MobileSideBar
        isVisible={mobileSideBarVisibility}
        onClose={() => setMobileSideBarVisibility(false)}
      />
    </div>
  );
};
export default PageViewContainer;
