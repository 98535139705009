import React from "react";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import ColorPicker from "../../../../components/ColorPicker";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import toaster from "toasted-notes";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ProfitCalculator from "./ProfitCalculator";

const GiftOffer = (props) => {
  const workspace = useWorkspace();
  const dimensionOptions = [
    { label: "%", value: "percentage" },
    { label: `${workspace.data.storeCurrency}`, value: "fixedAmount" },
  ];
  const discountSharePageTypeOptions = [
    {
      value: "popup",
      label: "Show gift pop-up on home page",
    },
    { value: "page", label: "Send to a dedicated gift page" },
  ];
  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;

  return (
    <>
      <div className="w-fulldesktop:py-3 desktop:px-8  py-0 px-4">
        <CustomiserUnit
          title="Discount Value"
          description="Choose the value for the gift coupon"
        >
          <div className="flex items-center ">
            <div className="w-20">
              <Textfield
                value={props.values.gift_config.discountValue * -1}
                onChange={(onChangeEvent) => {
                  if (onChangeEvent.target.value < 1) {
                    toaster.notify("The value must be greater than 0", {
                      duration: 2000,
                    });
                  } else if (
                    onChangeEvent.target.value > 100 &&
                    props.values.gift_config.discountType === "percentage"
                  ) {
                    toaster.notify("You cant give a dicount more than 100%", {
                      duration: 2000,
                    });
                  } else {
                    props.setFieldValue(
                      "gift_config.discountValue",
                      parseInt(onChangeEvent.target.value, 10) * -1
                    );
                  }
                }}
                min={1}
                type="number"
                name="basic"
              />
            </div>
            <Select
              isSearchable={false}
              value={dimensionOptions.find(
                (option) =>
                  option.value === props.values.gift_config.discountType
              )}
              onChange={(onChangeEvent) => {
                // The following condition makes sure the percentage doesnt exceeds 100 when switching from fixedAmount. If it exceded discount value is set to 20% by default
                if (
                  props.values.gift_config.discountValue * -1 > 100 &&
                  onChangeEvent.value === "percentage"
                ) {
                  props.setFieldValue("gift_config.discountValue", -20);
                }
                props.setFieldValue(
                  "gift_config.discountType",
                  onChangeEvent.value
                );
              }}
              className="w-24 "
              options={dimensionOptions}
            />
          </div>
        </CustomiserUnit>
        <CustomiserUnit
          title="Gift URL Option"
          description="Select how the gift offer is shown to a visitor when they click the gift url shared by the referrer"
        >
          <Select
            isSearchable={false}
            value={
              props.values.gift_config.discountSharePageType
                ? discountSharePageTypeOptions.find(
                    (option) =>
                      option.value ===
                      props.values.gift_config.discountSharePageType
                  )
                : discountSharePageTypeOptions.find(
                    (option) => option.value === "page"
                  )
            }
            onChange={(e) =>
              props.setFieldValue("gift_config.discountSharePageType", e.value)
            }
            className="max-w-sm mr-2"
            options={discountSharePageTypeOptions}
          />
        </CustomiserUnit>
        {props.values.settings_config.giftPageColor && (
          <CustomiserUnit
            title="Share Page Theme Color"
            description="Theme color for the page referrers share with their friends "
          >
            <div className="max-w-sm">
              <ColorPicker
                color={props.values.settings_config.giftPageColor}
                onChange={(color) => {
                  props.setFieldValue(
                    "settings_config.giftPageColor",
                    convertRGB(color.rgb)
                  );
                }}
              />
            </div>
          </CustomiserUnit>
        )}
        <div className="">
          <ProfitCalculator {...props.values} selectedStage={0} />
        </div>
      </div>
    </>
  );
};

export default GiftOffer;
