import React, { useState } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import RichTextEditor from "../../../../../components/RichTextEditor";
import Button from "../../../../../components/Button";
import { langOptions } from "../../../../../utilities/variables";

import {
  RiEditFill,
  RiDeleteBinFill,
  RiCheckboxCircleLine,
} from "react-icons/ri";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function MessageItem({
  message,
  onChange,
  onDelete,
  showDelete,
}) {
  const workspace = useWorkspace();

  const [isEditModeOpen, setIsEditModeOpen] = useState(false);

  const getLanguageName = (langCode) => {
    let langObject = langOptions.find((lang) => lang.value === langCode);
    if (langObject) {
      return langObject.label;
    } else {
      return langCode;
    }
  };

  return (
    <div>
      {!isEditModeOpen ? (
        <div className="flex py-2 justify-between items-center">
          <div className="px-3">
            <small className="text-xs font-bold text-gray-600">
              {getLanguageName(message.language)}
            </small>
            <div
              className="desktop:text-base laptop:text-sm"
              dangerouslySetInnerHTML={{
                __html: message.content || "Click Edit to add text",
              }}
            ></div>
          </div>
          <div className="flex items-center ml-2">
            <button
              className="text-gray-700 flex items-center hover:text-violet-800 hover:bg-violet-200 leading-none hover:border-violet-600 transition-all text-sm mr-4 border rounded-full p-2"
              onClick={() => {
                setIsEditModeOpen((prevState) => !prevState);
              }}
            >
              <RiEditFill size={12} />
              <span className="ml-2">Edit</span>
            </button>
            {showDelete && (
              <button
                className="text-gray-700 flex items-center hover:text-red-600 hover:bg-red-100 leading-none hover:border-red-600 transition-all text-sm mr-4 border rounded-full p-2"
                onClick={() => {
                  onDelete();
                }}
              >
                <RiDeleteBinFill size={12} />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="px-3">
            <RichTextEditor
              value={
                message.content.length >= 0
                  ? message.content
                  : "Type your text here"
              }
              type="minimal"
              extraButtons={["source"]}
              onChange={(htmlValue) => onChange(htmlValue)}
            />
          </div>
          <div className="flex justify-end p-2 border-t bg-gray-100">
            <Button
              onClick={() => {
                setIsEditModeOpen(false);
              }}
              noPadding
              className="p-2"
              type="link"
              icon={<RiCheckboxCircleLine />}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
