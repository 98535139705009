import React, { useState, useEffect, useRef } from "react";
import toaster from "toasted-notes";
import { useMutation, useQuery } from "react-query";
import {
  insertCocaCampaign,
  insertCampaignCartGoals,
  insertCampaignOneClick,
  insertCampaignCartAnnouncement,
  queryHighestCampaignKey,
  queryAllCampaign,
  insertCampaignBXGYFree,
  insertCampaignDiscountCode,
  insertCampaignCartTimer,
} from "../../../../api/campaign";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useHistory } from "react-router-dom";
import ItemSelectorModal from "../../../../components/ItemSelectorModal";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import { useBlock } from "@dopt/react";
import {
  campaignCreateItemList,
  defaultBXGY,
  defaultCartAnnouncement,
  defaultCartGoal,
  defaultOneClickUpsell,
  defaultCartTimer,
} from "../../../../defaultValues/campaign";

/**
 * This component renders a modal with option to create various campaigns
 * @param {Boolean} isModalVisible determinses the modal is open or not
 * @param {Function} closeModal function to close modal
 * @returns modal component
 */
const CampaignCreate = () => {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const campaignType = urlParams.get("campaignType");

  const [isLoading, setIsLoading] = useState(false);

  const [newCartGoalFlow, CartGoalFlowTransition] = useBlock(
    "cart-goal-onboard.create-campaign"
  );
  const [oneClickFlow, oneClickFlowTransition] = useBlock(
    "one-click-upsell.create-campaign-page"
  );
  const [cartAnnouceFlow, cartAnnouceFlowTransition] = useBlock(
    "cart-annoucement-bar.create-campaign-page"
  );

  const { data: campaignList } = useQuery("queryHighestCampaignKey", () =>
    graphQL(queryHighestCampaignKey)
  );

  const { data, refetch } = useQuery("queryAllCampaign", () =>
    graphQL(queryAllCampaign)
  );

  const getDefaultCampaignName = (type) => {
    const campaignCount = data.campaign.length;
    switch (type) {
      case "cartGoal":
        return `Cart Goal ${campaignCount + 1}`;
      case "oneClickUpsell":
        return `One Click Upsell ${campaignCount + 1}`;
      case "cartAnnouncement":
        return `Cart Announcement Bar ${campaignCount + 1}`;

      case "buyXGetY":
        return `Buy X Get Y ${campaignCount + 1}`;
      case "discountCode":
        return `Discount Code ${campaignCount + 1}`;
      case "cartTimer":
        return `Cart Timer ${campaignCount + 1}`;
      default:
        return `Campaign ${campaignCount + 1}`;
    }
  };

  /**
   * This function checks if campaign's table has a key
   * and increment the highest key to create a new key
   * @returns new campaign key
   */
  const getNewCampaignKey = () => {
    if (campaignList) {
      let newCampaignKey;
      if (campaignList.campaign.length === 0) {
        // managing edge case when there are no campaigns
        newCampaignKey = 1;
      } else {
        newCampaignKey = parseInt(campaignList.campaign[0].key) + 1;
      }
      return newCampaignKey;
    } else {
      // if campaign list doesn't exit return default value
      return 0;
    }
  };

  const returnDefaultCampaignDetails = (type) => {
    const newCampaignKey = getNewCampaignKey();
    const defaultCampaignDetails = {
      accountid: workspace.data && workspace.data.accountid,
      shop: workspace.data && workspace.data.storeMyShopifyUrl,
      surface: "cart_widget",
      status: "draft",
      settings: {
        title: getDefaultCampaignName(type),
        campaignTermsAndConditions: "While supply lasts.",
      },
      key: newCampaignKey.toString(),
      audience: { everyone: true },
      priority: data && data.campaign.length,
      schedule: {
        startsAt: new Date().toISOString(),
        endsAt: "",
      },
    };
    switch (type) {
      case "cartGoal":
        return {
          ...defaultCampaignDetails,
          type: "goal_meter_in_cart",
        };
      case "oneClickUpsell":
        return {
          ...defaultCampaignDetails,
          type: "one_click_upsell",
        };
      case "cartAnnouncement":
        return {
          ...defaultCampaignDetails,
          type: "cart_announcement",
        };
      case "buyXGetY":
        return {
          ...defaultCampaignDetails,
          type: "bxgy_free",
        };
      case "discountCode":
        return {
          ...defaultCampaignDetails,
          type: "discount_code",
        };
      case "cartTimer":
        return {
          ...defaultCampaignDetails,
          type: "cart_timer",
        };
      default:
        return {
          ...defaultCampaignDetails,
          type: "goal_meter_in_cart",
        };
    }
  };

  // transition to the next step in the onbaording flow
  const handleOnboardingFlow = (campaignType) => {
    switch (campaignType) {
      case "cartGoal": {
        if (CartGoalFlowTransition) CartGoalFlowTransition("next");
        break;
      }
      case "oneClickUpsell": {
        if (oneClickFlowTransition) oneClickFlowTransition("next");
        break;
      }
      case "cartAnnouncement": {
        if (cartAnnouceFlowTransition) cartAnnouceFlowTransition("next");
        break;
      }
      default: {
        if (CartGoalFlowTransition) CartGoalFlowTransition("next");
        break;
      }
    }
  };

  // this fn checks the url params type and return id from campaign array
  const getInitialCampaignType = () => {
    switch (campaignType) {
      case "cart-goal":
        return "cartGoal";
      case "one-click":
        return "oneClickUpsell";
      case "cart-message":
        return "cartAnnouncement";
      case "cart-timer":
        return "cartTimer";
      default:
        return "";
    }
  };

  const insertCampaignCartGoal = async (input) => {
    const defaultCartGoalValue = {
      ...defaultCartGoal,
      goalUnit: workspace.data && workspace.data.storeCurrency,
      accountid: workspace.data && workspace.data.accountid,
    };
    defaultCartGoalValue.milestones[0].rewards.id = `${input.campaignId}-0`;
    return await graphQL(insertCampaignCartGoals, {
      cartGoalInput: {
        campaignid: input.campaignId,
        ...defaultCartGoalValue,
      },
    });
  };

  const [insertCampaignCartGoalsMutation] = useMutation(
    insertCampaignCartGoal,
    {
      onError: (err) => {
        window.Rollbar.error("Error in insertCampaignCartGoalsMutation ", err);
      },
      onSuccess: (data) => {
        history.replace(
          `/cart/cro/campaigns/cartgoals/edit/${data.insert_campaign_cart_goals_one.campaignid}`
        );
      },
    }
  );

  const insertCampaignOneClickUpsell = async (input) => {
    return await graphQL(insertCampaignOneClick, {
      oneClickInput: {
        campaignid: input.campaignId,
        ...defaultOneClickUpsell,
      },
    });
  };

  const [insertCampaignOneClickMutation] = useMutation(
    insertCampaignOneClickUpsell,
    {
      onError: (err) => {
        window.Rollbar.error("Error in insertCampaignCartGoalsMutation ", err);
        setIsLoading(false);
      },
      onSuccess: (data) => {
        setIsLoading(false);
        history.replace(
          `/cart/cro/campaigns/oneclickupsells/edit/${data.insert_campaign_one_click_one.campaignid}`
        );
      },
    }
  );

  const insertCampaignBuyXGetY = async (input) => {
    const defaultBXGYDetails = defaultBXGY;
    defaultBXGYDetails.config.rewards.id = `${input.campaignid}-0`;
    return await graphQL(insertCampaignBXGYFree, {
      BXGYFreeInput: {
        ...input,
        ...defaultBXGYDetails,
        content: [
          {
            lang: workspace.data.default_language,
            value: {
              preGoalOfferPageSubTitle: "Buy something and get something",
              postGoalOfferPageSubTitle: "Yay! you got the reward",
              preGoalOfferPageTitle: "Free Gift",
              postGoalOfferPageTitle: "Free Gift",
            },
          },
        ],
      },
    });
  };

  const [insertCampaignBuyXGetYMutation] = useMutation(insertCampaignBuyXGetY, {
    onError: (err) => {
      window.Rollbar.error("Error in insertCampaignBuyXGetYMutation ", err);
      setIsLoading(false);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      history.replace(
        `/cart/cro/campaigns/buyxgety/edit/${data.insert_campaign_bxgy_free_one.campaignid}`
      );
    },
  });

  const insertCampaignDiscountCodeDetails = async (input) => {
    return await graphQL(insertCampaignDiscountCode, {
      DiscountCodeInput: {
        ...input,
        discount_code: "",
        content: [
          {
            lang: workspace.data.default_language,
            value: {
              cardTitle: "Discount Code",
              cardSubTitle: "Apply this discount Code",
              preApplicationCta: "Apply Code",
              postApplicationCta: "Applied",
            },
          },
        ],
        config: {
          imageUrl: "",
        },
      },
    });
  };

  const [insertCampaignDiscountCodeMutation] = useMutation(
    insertCampaignDiscountCodeDetails,
    {
      onError: (err) => {
        window.Rollbar.error("Error in insertCampaignBuyXGetYMutation ", err);
        setIsLoading(false);
      },
      onSuccess: (data) => {
        setIsLoading(false);
        history.replace(
          `/cart/cro/campaigns/discountcode/edit/${data.insert_campaign_discount_code_one.campaignid}`
        );
      },
    }
  );

  const insertCampaignCartAnnouncementBar = async (input) => {
    // import constant data and updating values with workspace
    const defaultCartAnnouncementData = {
      message: [
        {
          language: workspace.data && workspace.data.default_language,
          content: "<p>Write Your Message Here</p>",
        },
      ],
      ...defaultCartAnnouncement,
    };
    return await graphQL(insertCampaignCartAnnouncement, {
      cartAnnouncementInput: {
        campaignid: input.campaignId,
        shop: workspace.data.storeMyShopifyUrl,
        accountid: workspace.data && workspace.data.accountid,
        ...defaultCartAnnouncementData,
      },
    });
  };

  const [insertCampaignCartAnnouncementBarMutation] = useMutation(
    insertCampaignCartAnnouncementBar,
    {
      onError: (err) => {
        window.Rollbar.error(
          "Error in insertCampaignCartAnnouncementBarMutation ",
          err
        );
        setIsLoading(false);
      },
      onSuccess: (data) => {
        setIsLoading(false);
        history.replace(
          `/cart/cro/campaigns/cartannouncement/edit/${data.insert_campaign_cart_announcement_one.campaignid}`
        );
      },
    }
  );

  const insertCampaignCartTimerDetails = async (input) => {
    return await graphQL(insertCampaignCartTimer, {
      cartTimerInput: {
        ...input,
        ...defaultCartTimer,

        content: [
          {
            language: workspace.data.default_language,
            value: {
              postCartTimerMessage: "<p>Your cart has expired</p>",
              preCartTimerMessage:
                "<p>Your Cart will expire in {{timeRemaining}}</p>",
            },
          },
        ],
      },
    });
  };

  const [insertCampaignCartTimerMutation] = useMutation(
    insertCampaignCartTimerDetails,
    {
      onError: (err) => {
        window.Rollbar.error("Error in insertCampaignCartTimerMutation ", err);
        setIsLoading(false);
      },
      onSuccess: (data) => {
        setIsLoading(false);
        history.replace(
          `/cart/cro/campaigns/carttimer/edit/${data.insert_campaign_cart_timer_one.campaignid}`
        );
      },
    }
  );

  const insertCocaCampaigns = async (inputType) => {
    return await graphQL(insertCocaCampaign, {
      campaignInput: returnDefaultCampaignDetails(inputType),
    });
  };

  const [insertCocaCampaignsMutation] = useMutation(insertCocaCampaigns, {
    onError: (err) => {
      window.Rollbar.error("Error in insertCocaCampaignsMutation", err);
    },
    onSuccess: (data) => {
      if (data) {
        switch (data.insert_campaign_one.type) {
          case "goal_meter_in_cart": {
            toaster.notify("Cart Goal created", {
              duration: 2000,
            });
            insertCampaignCartGoalsMutation({
              campaignId: data.insert_campaign_one.id,
            });
            break;
          }
          case "one_click_upsell": {
            toaster.notify("One Click Upsell Created", {
              duration: 2000,
            });
            insertCampaignOneClickMutation({
              campaignId: data.insert_campaign_one.id,
            });
            break;
          }
          case "cart_announcement": {
            toaster.notify("Cart Announcement Created", {
              duration: 2000,
            });
            insertCampaignCartAnnouncementBarMutation({
              campaignId: data.insert_campaign_one.id,
            });
            break;
          }
          case "bxgy_free": {
            toaster.notify("Buy X Get Y Created", {
              duration: 2000,
            });
            insertCampaignBuyXGetYMutation({
              campaignid: data.insert_campaign_one.id,
              accountid: workspace.data.accountid,
              shop_url: workspace.data.storeMyShopifyUrl,
              shopid: workspace.data.sourceid,
            });
            break;
          }
          case "discount_code": {
            toaster.notify("Discount Code Campaign Created", {
              duration: 2000,
            });
            insertCampaignDiscountCodeMutation({
              campaignid: data.insert_campaign_one.id,
              accountid: workspace.data.accountid,
              shop_url: workspace.data.storeMyShopifyUrl,
              shopid: workspace.data.sourceid,
            });
            break;
          }
          case "cart_timer": {
            toaster.notify("Cart Timer Created", {
              duration: 2000,
            });
            insertCampaignCartTimerMutation({
              campaignid: data.insert_campaign_one.id,
              accountid: workspace.data.accountid,
              shop_url: workspace.data.storeMyShopifyUrl,
              installationid: workspace.data.installationId,
            });
            break;
          }
        }
      } else {
        window.Rollbar.error(
          "Error in insertCocaCampaignsMutation insert operation"
        );
      }
    },
  });

  return (
    <PageViewContainer title="Campaign Types">
      <div className="h-full over">
        <ItemSelectorModal
          title="Select a Campaign Type"
          description="Campigns are created to improve "
          campaignArray={campaignCreateItemList}
          isLoading={isLoading}
          initialSelectItem={getInitialCampaignType()}
          onCreate={(selectedCampaign) => {
            handleOnboardingFlow(selectedCampaign.id);
            setIsLoading(true);
            insertCocaCampaignsMutation(selectedCampaign.id);
          }}
        />
      </div>
    </PageViewContainer>
  );
};

export default CampaignCreate;
