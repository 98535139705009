import React, { useState, useCallback, useEffect } from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import DatePicker from "../../../../../components/DatePicker";
import CustomTimePicker from "../../../../../components/Timepicker";
import { Checkbox } from "@atlaskit/checkbox";
import {
  getNewDateValue,
  getNewDateTimeValue,
} from "../../../../../utilities/dateTimeFunctions";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../../components/TabRadioButton";

const Settings = ({ values, setFieldValue }) => {
  const [isEndDateEnabled, setIsEndDatEnabled] = useState(
    values.schedule?.endsAt ? true : false
  );

  const usesPerOrderOptions = [
    {
      label: <div className="flex items-center">Yes </div>,
      value: true,
    },
    {
      label: <div className="flex items-center">No </div>,
      value: false,
    },
  ];

  const onStartDateChange = useCallback(
    (selectedDate) => {
      const utcDateString = getNewDateValue(
        values.schedule.startsAt,
        selectedDate
      );
      setFieldValue(`schedule.startsAt`, utcDateString);
    },
    [values]
  );

  const onStartTimeChange = useCallback(
    (selectedTime) => {
      const formattedDate = getNewDateTimeValue(
        values.schedule.startsAt,
        selectedTime
      );
      setFieldValue(`schedule.startsAt`, formattedDate);
    },
    [values]
  );

  const onEndDateChange = useCallback(
    (selectedDate) => {
      const utcDateString = getNewDateValue(
        values.schedule.endsAt,
        selectedDate
      );
      setFieldValue(`schedule.endsAt`, utcDateString);
    },
    [values]
  );

  const onEndTimeChange = useCallback(
    (selectedTime) => {
      const formattedDate = getNewDateTimeValue(
        values.schedule.endsAt,
        selectedTime
      );
      setFieldValue(`schedule.endsAt`, formattedDate);
    },
    [values]
  );

  return (
    <div className=" overflow-y-auto h-full px-8 py-4">
      <CustomiserUnit
        title="Campaign Schedule"
        description="Define when the campaign is active"
      >
        <div className="border border-solid border-gray-200 p-4 max-w-md">
          <div className="flex flex-row gap-5">
            <div className="flex flex-col flex-grow">
              <p className="text-xs font-bold text-gray-600 mb-2">START DATE</p>
              <DatePicker
                value={values.schedule.startsAt}
                onChange={(selectedDate) => onStartDateChange(selectedDate)}
              />
            </div>
            <div className="flex flex-col flex-grow">
              <p className="text-xs font-bold text-gray-600 mb-2">START TIME</p>
              <CustomTimePicker
                value={values.schedule.startsAt}
                onChange={(selectedTime) => onStartTimeChange(selectedTime)}
              />
            </div>
          </div>
          <div className="py-2">
            <Checkbox
              isChecked={isEndDateEnabled}
              label="Set End Date"
              onChange={(event) => setIsEndDatEnabled(event.target.checked)}
            />
          </div>

          <div className="flex flex-row gap-5">
            <div className="flex flex-col flex-grow">
              <p className="text-xs font-bold text-gray-600 mb-2">END DATE</p>
              <DatePicker
                isDisabled={!isEndDateEnabled}
                value={values.schedule.endsAt}
                onChange={(selectedDate) => onEndDateChange(selectedDate)}
                minDate={values.schedule.startsAt}
              />
            </div>
            <div className="flex flex-col flex-grow">
              <p className="text-xs font-bold text-gray-600 mb-2">END TIME</p>
              <CustomTimePicker
                isDisabled={!isEndDateEnabled}
                value={values.schedule.endsAt}
                onChange={(selectedTime) => onEndTimeChange(selectedTime)}
              />
            </div>
          </div>
        </div>
      </CustomiserUnit>
      <CustomiserUnit
        title="Set a maximum number of uses per order"
        description="For example if you select 2, then your customer can only use this offer 2 times per order"
      >
        <div className="flex flex-row">
          <TabRadioButton
            defaultValue={
              values.campaign_bxgy_frees[0].config.usesPerOrderEnabled
            }
            options={usesPerOrderOptions}
            onChange={(selectedType) => {
              setFieldValue(
                "campaign_bxgy_frees[0].config.usesPerOrderEnabled",
                selectedType
              );
              if (selectedType === false) {
                setFieldValue(
                  "campaign_bxgy_frees[0].config.usesPerOrderLimit",
                  null
                );
              }
            }}
          />
          {values.campaign_bxgy_frees[0].config.usesPerOrderEnabled && (
            <div className="ml-4 w-20">
              <Textfield
                value={values.campaign_bxgy_frees[0].config.usesPerOrderLimit}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    "campaign_bxgy_frees[0].config.usesPerOrderLimit",
                    onChangeEvent.target.value
                  );
                }}
                min={1}
                name="basic"
                type="number"
              />
            </div>
          )}
        </div>
      </CustomiserUnit>
    </div>
  );
};

export default Settings;
