const cornerWidgetThemes = [
  {
    name: "Oh So Minimal",
    type: "light",
    themeObject: {
      colors: {
        accentBg: "rgb(38,38,38)",
        accentFg: "rgb(255,253,253)",
        bannerText: "rgb(45,45,45)",
        cardBg: "rgb(255,255,255)",
        outlines: "rgb(220,215,214)",
        primaryType: "rgb(44,54,57)",
        secondaryBg: "rgb(250,238,236)",
        secondaryType: "rgb(63,78,79)",
        widgetBg: "rgb(255,255,255)",
      },
      banner: {
        gradientEnd: "rgb(232,230,226)",
        gradientStart: "rgb(232,230,226)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e11aea3c-53a7-4138-451a-bd6e30552c00/public",
        type: "gradient",
      },
      customCss:
        "#corner-cowi-cart-item-list-card,#corner-cowi-cart-summary-card,#corner-cowi-offers-item-list-bg-card{\nmargin:0!important;\nborder-radius:0!important\n}\n#corner-cowi-header{\npadding:18px!important\n}\n#corner-cowi-page-selectors-wrapper,#corner-widget-page-wrapper{\npadding:0!important;\nborder-top:1px solid var(--corner-cowi-outlines)\n}\n#corner-cowi-close-btn{\nbox-shadow:none!important;\nborder:1px solid var(--corner-cowi-outlines)\n}",
    },
  },

  {
    name: "Fresh Gradient",
    type: "light",
    themeObject: {
      colors: {
        accentBg: "rgb(159,66,235)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        cardBg: "rgb(255,255,255)",
        outlines: "rgb(230,230,235)",
        primaryType: "rgb(9,27,107)",
        secondaryBg: "rgb(230,230,235)",
        secondaryType: "rgb(86,100,113)",
        widgetBg: "rgb(241,241,241)",
      },
      banner: {
        gradientEnd: "rgb(216,0,0)",
        gradientStart: "rgb(185,59,15)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/2ecedba6-0715-4d0e-1640-a72a13f12700/public",
        type: "image",
      },
      customCss: "",
    },
  },

  {
    name: "Aqua",
    type: "light",
    themeObject: {
      colors: {
        accentBg: "rgb(35,91,134)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        cardBg: "rgb(255,254,254)",
        outlines: "rgb(228,234,242)",
        primaryType: "rgb(44,54,57)",
        secondaryBg: "rgb(233,239,242)",
        secondaryType: "rgb(63,78,79)",
        widgetBg: "rgb(242,246,246)",
      },
      banner: {
        gradientEnd: "rgb(52,59,82)",
        gradientStart: "rgb(147,183,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/1261767a-8892-4aa7-32ee-1f191a2a6100/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Golden Hour",
    type: "light",
    themeObject: {
      colors: {
        accentBg: "rgb(255,207,141)",
        accentFg: "rgb(42,38,31)",
        bannerText: "rgb(42,38,31)",
        cardBg: "rgb(255,255,255)",
        outlines: "rgb(230,230,235)",
        primaryType: "rgb(55,36,22)",
        secondaryBg: "rgb(230,230,235)",
        secondaryType: "rgb(94,83,65)",
        widgetBg: "rgb(255,252,246)",
      },
      banner: {
        gradientEnd: "rgb(216,0,0)",
        gradientStart: "rgb(185,59,15)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/f1ba4b8a-09f9-476a-57e0-a6a81a19f500/public",
        type: "image",
      },
      customCss: "",
    },
  },

  {
    name: "Sharp Edge",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(44,54,57)",
        outlines: "rgb(220,215,214)",
        secondaryBg: "rgb(220,215,214)",
        accentBg: "rgb(5,5,6)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(247,247,247)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e11aea3c-53a7-4138-451a-bd6e30552c00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner.corner-widget #corner-cowi-open-wrapper * {border-radius:4px!important}",
    },
  },
  {
    name: "Poseidon",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(173,222,253)",
        outlines: "rgb(65,80,140)",
        secondaryBg: "rgb(65,80,140)",
        accentBg: "rgb(11,242,255)",
        accentFg: "rgb(4,45,22)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(152,166,197)",
        cardBg: "rgb(5,21,47)",
        widgetBg: "rgb(8,32,68)",
      },
      banner: {
        gradientStart: "rgb(63,83,146)",
        gradientEnd: "rgb(63,83,146)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/b394f672-7fce-4ec9-23c6-999cd7d8f900/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Sand Dunes",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(44,54,57)",
        outlines: "rgb(212,208,187)",
        secondaryBg: "rgb(212,208,187)",
        accentBg: "rgb(162,123,92)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(255,253,249)",
        widgetBg: "rgb(230,227,221)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e4ccee5c-c84c-493a-bdb7-2fb3eeedf800/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Bubblegum",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(59,62,86)",
        outlines: "rgb(189,197,178)",
        secondaryBg: "rgb(189,197,178)",
        accentBg: "rgb(255,145,157)",
        accentFg: "rgb(92,11,48)",
        bannerText: "rgb(92,11,48)",
        secondaryType: "rgb(156,168,180)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(255,241,246)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e95c4721-55e8-472f-9413-d18e30ecee00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Cape Town",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(9,27,107)",
        outlines: "rgb(230,230,235)",
        secondaryBg: "rgb(230,230,235)",
        accentBg: "rgb(179,57,57)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(86,100,113)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(246,241,238)",
      },
      banner: {
        gradientStart: "rgb(185,59,15)",
        gradientEnd: "rgb(216,0,0)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/971fc57c-19f3-466c-1e20-d625fcd0e800/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Blackout",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(237,241,243)",
        outlines: "rgb(65,65,65)",
        secondaryBg: "rgb(65,65,65)",
        accentBg: "rgb(255,255,255)",
        accentFg: "rgb(10,10,10)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(63,78,79)",
        cardBg: "rgb(24,24,24)",
        widgetBg: "rgb(0,0,0)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/0a8f19e2-8450-4667-2646-b8e8798db100/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Urban Underground",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(233,227,234)",
        outlines: "rgb(90,79,96)",
        secondaryBg: "rgb(90,79,96)",
        accentBg: "rgb(124,29,183)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(115,117,125)",
        cardBg: "rgb(53,45,67)",
        widgetBg: "rgb(36,29,51)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/62581c93-c814-4e9c-f313-aa62435cb300/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Cyber Pink",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(255,255,255)",
        outlines: "rgb(97,97,109)",
        secondaryBg: "rgb(97,97,109)",
        accentBg: "rgb(222,71,128)",
        accentFg: "rgb(251,251,251)",
        bannerText: "rgb(251,251,251)",
        secondaryType: "rgb(144,137,185)",
        cardBg: "rgb(55,44,76)",
        widgetBg: "rgb(40,40,51)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/d24d4bd1-d6a3-44e9-d538-0a51cb4eee00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Key Lime Pie",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(241,255,137)",
        outlines: "rgb(97,97,109)",
        secondaryBg: "rgb(97,97,109)",
        accentBg: "rgb(217,255,54)",
        accentFg: "rgb(18,15,15)",
        bannerText: "rgb(18,15,15)",
        secondaryType: "rgb(144,137,185)",
        cardBg: "rgb(48,49,40)",
        widgetBg: "rgb(41,41,41)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/bbcd8567-0925-45e5-611e-cd1a1254fe00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Lemonade",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(57,61,19)",
        outlines: "rgb(189,197,178)",
        secondaryBg: "rgb(189,197,178)",
        accentBg: "rgb(253,215,103)",
        accentFg: "rgb(86,77,45)",
        bannerText: "rgb(86,77,45)",
        secondaryType: "rgb(123,131,80)",
        cardBg: "rgb(255,252,238)",
        widgetBg: "rgb(255,255,255)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/ad025384-beb1-4140-f373-3acd7041a000/public",
        type: "image",
      },
      customCss: " .corner-widget .rounded-lg{border-radius:100px !important;}",
    },
  },
  {
    name: "Nile",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(253,225,194)",
        outlines: "rgb(100,98,94)",
        secondaryBg: "rgb(100,98,94)",
        accentBg: "rgb(188,127,64)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(168,158,150)",
        cardBg: "rgb(27,53,35)",
        widgetBg: "rgb(38,65,45)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(52,59,82)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a0ec8023-a951-453e-aef0-d41bae303b00/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Lavender",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(59,62,86)",
        outlines: "rgb(189,197,178)",
        secondaryBg: "rgb(189,197,178)",
        accentBg: "rgb(153,139,221)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(156,168,180)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(241,244,255)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/51d563dc-ccd7-4bec-ac93-2d97aff0d300/public",
        type: "image",
      },
      customCss: "",
    },
  },
  {
    name: "Magma Lake",
    type: "dark",
    themeObject: {
      colors: {
        primaryType: "rgb(255,254,254)",
        outlines: "rgb(80,64,70)",
        secondaryBg: "rgb(80,64,70)",
        accentBg: "rgb(226,45,45)",
        accentFg: "rgb(255,255,255)",
        bannerText: "rgb(255,255,255)",
        secondaryType: "rgb(251,231,231)",
        cardBg: "rgb(41,25,25)",
        widgetBg: "rgb(10,9,9)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/7afc6ada-c6cc-46de-59ec-7c826bf8df00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow: 0px -6px 65px -8px #ff3059!important;}",
    },
  },
  {
    name: "Smooth Silk",
    type: "light",
    themeObject: {
      colors: {
        primaryType: "rgb(86,59,65)",
        outlines: "rgb(189,197,178)",
        secondaryBg: "rgb(189,197,178)",
        accentBg: "rgb(220,166,169)",
        accentFg: "rgb(78,47,48)",
        bannerText: "rgb(78,47,48)",
        secondaryType: "rgb(180,156,171)",
        cardBg: "rgb(255,255,255)",
        widgetBg: "rgb(251,243,243)",
      },
      banner: {
        gradientStart: "rgb(147,183,255)",
        gradientEnd: "rgb(63,105,255)",
        imageUrl:
          "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/2e24ce08-d6a5-45f8-5a45-7fa273176e00/public",
        type: "image",
      },
      customCss:
        ".widgets-by-corner .bg-cowi-accent-bg{box-shadow:1px -10px 20px -8px #dca6a9 !important;}  .widgets-by-corner .drop-shadow {box-shadow: 4px 5px 5px 0px rgba(209, 183, 191, .25), -4px -4px 8px #ffffff !important}",
    },
  },
];

export { cornerWidgetThemes };
