import React, { useState } from "react";
import { useQuery } from "react-query";
import CustomiserUnit from "../../../components/CustomiserUnit";
import Button from "../../../components/Button";
import InlineTooltip from "../../../components/InlineTooltip";
import SucoPricingModal from "../../../components/SucoPricingModal";
import OfcoPricingModal from "../../../components/OfcoPricingModal";
import { RiAwardFill, RiBookMarkFill } from "react-icons/ri";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { queryBillingInfo, queryUsageStats } from "../../../api/billing";
import moment from "moment";
import CocaPricingTakeOver from "../../../components/CocaPricing/CocaPricingTakeOver";

export default function Billing(props) {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();

  // the following useQuery hooks only fires if the respective app is installed
  const { data: usageData } = useQuery(
    "queryUsageStats",
    () =>
      graphQL(queryUsageStats, {
        appId: `${2}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
        accountId: `${workspace.data && workspace.data.accountid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 2) }
  );

  // the following useQuery hooks only fires if the respective app is installed
  const { data: sucoData } = useQuery(
    "querysucoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${1}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 1) }
  );

  // the following useQuery hooks only fires if the respective app is installed
  const { data: ofcoData } = useQuery(
    "queryofcoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${2}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 2) }
  );

  const { data: cocaData, refetch } = useQuery(
    "querycocaBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${3}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 3) }
  );

  const [isOfcoBillingModalOpen, setIsOfcoBillingModalOpen] = useState(false);
  const [isSucoBillingModalOpen, setIsSucoBillingModalOpen] = useState(false);
  const [isCocaBillingOpen, setIsCocaBillingOpen] = useState(false);

  const getCurrentBillingAmount = () => {
    if (usageData.getUsageStats.currentReferralSales < 100) return "$0";
    else if (usageData.getUsageStats.currentReferralSales < 500) return "$19";
    else if (usageData.getUsageStats.currentReferralSales < 2000) return "$49";
    else if (usageData.getUsageStats.currentReferralSales > 2000) return "$129";
  };

  /**
   * Render Billing based on current app
   * @returns Component
   */
  const renderAppBasedBilling = () => {
    switch (workspace.data.currentAppID) {
      case 1: {
        return (
          sucoData && (
            <div className=" border rounded-md desktop:p-6 p-3 my-4 flex items-center justify-between max-w-xl">
              <div className="flex items-center desktop:text-3xl text-2xl text-violet-700">
                <RiBookMarkFill />
                <div className="ml-4">
                  <h3 className="font-bold text-base desktop:text-lg">
                    SupportCorner
                  </h3>
                  <p className="text-xs desktop:text-sm text-gray-600">
                    Current Plan :{" "}
                    <b>
                      {sucoData.billingInfo?.currentPlan?.plan_display_name}
                    </b>
                  </p>
                </div>
              </div>
              <Button
                type="primary"
                onClick={() => setIsSucoBillingModalOpen(true)}
              >
                {" "}
                Change Plan
              </Button>
            </div>
          )
        );
      }
      case 2: {
        return (
          ofcoData && (
            <div className="border desktop:p-6 p-3 rounded-md my-4 max-w-xl">
              <div className="flex items-center justify-between ">
                <div className="flex items-center text-3xl text-violet-700">
                  <RiAwardFill />
                  <div className="ml-4">
                    <h3 className="font-bold text-base desktop:text-lg">
                      OffersCorner
                    </h3>
                    <p className="text-xs desktop:text-sm text-gray-600">
                      Current Plan :{" "}
                      <b>
                        {ofcoData.billingInfo?.currentPlan?.plan_display_name}
                      </b>
                    </p>
                  </div>
                </div>
                {ofcoData.billingInfo.planId !== 8 && (
                  <Button
                    type={
                      ofcoData.billingInfo.planId === 10 ? "primary" : "outline"
                    }
                    onClick={() => setIsOfcoBillingModalOpen(true)}
                  >
                    {ofcoData.billingInfo.planId === 10
                      ? "Upgrade to PRO"
                      : "Learn how Pro Plan works"}
                  </Button>
                )}
              </div>
              {usageData && ofcoData.billingInfo.planId !== 8 && (
                <div className="p-3 border rounded-lg mt-4">
                  <h3 className="font-bold text-center text-gray-700">
                    Billing Cycle Information
                  </h3>
                  <div className="mt-2 flex">
                    {/* Extra revenue Generated */}
                    <div className="px-2 grow">
                      <small className="text-xs text-gray-600">
                        Extra Revenue Generated
                      </small>
                      <h1 className="">
                        {usageData.getUsageStats.currentReferralSales.toFixed(
                          2
                        )}
                      </h1>
                    </div>
                    {/* The Billing date: (only visible in the paid plan) */}
                    {ofcoData.billingInfo.planId !== 10 && (
                      <div className="px-2 grow">
                        <small className="text-xs text-gray-600">
                          Next Billing Date
                        </small>
                        <h1 className="">
                          {moment(
                            usageData.getUsageStats.nextBillingDate
                          ).format("MMM Do")}
                        </h1>
                      </div>
                    )}
                    {/* Amount due */}
                    <div className="px-2 grow">
                      <small className="text-xs text-gray-600">
                        Amount Due
                      </small>
                      <h1 className="">
                        {ofcoData.billingInfo.planId === 10
                          ? "$0"
                          : getCurrentBillingAmount()}
                      </h1>
                    </div>
                  </div>
                  <div className="mt-3 py-2 bg-violet-100  text-violet-800 rounded-lg text-base text-center">
                    <p>
                      <InlineTooltip message="Credits will be deducted from the amount due for the next billing">
                        Credits Avaliable
                      </InlineTooltip>
                      :{" "}
                      <span className="font-bold">
                        {usageData.getUsageStats.creditAmount} USD
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )
        );
      }
      case 3: {
        return (
          cocaData && (
            <div className=" border rounded-md desktop:p-6 p-3 my-4 flex items-center justify-between max-w-xl">
              <div className="flex items-center desktop:text-3xl text-2xl text-violet-700">
                <RiBookMarkFill />
                <div className="ml-4">
                  <h3 className="font-bold text-base desktop:text-lg">
                    CornerCart
                  </h3>
                  <p className="text-xs desktop:text-sm text-gray-600">
                    Current Plan :{" "}
                    <b>
                      {cocaData.billingInfo?.currentPlan?.plan_display_name}
                    </b>
                  </p>
                </div>
              </div>
              <Button type="primary" onClick={() => setIsCocaBillingOpen(true)}>
                Change Plan
              </Button>
            </div>
          )
        );
      }
    }
  };
  return (
    <div className="px-8 py-4 h-full overflow-y-auto">
      <CustomiserUnit
        title="Your current plan"
        description="Upgrade or downgrade to any plan we offer using the settings given below."
      >
        <div className="max-w-2xl">{renderAppBasedBilling()}</div>
      </CustomiserUnit>

      <OfcoPricingModal
        isOpen={isOfcoBillingModalOpen}
        onClose={() => {
          setIsOfcoBillingModalOpen(false);
        }}
      />
      <SucoPricingModal
        isOpen={isSucoBillingModalOpen}
        onClose={() => setIsSucoBillingModalOpen(false)}
      />
      <CocaPricingTakeOver
        isOpen={isCocaBillingOpen}
        onClose={() => {
          setIsCocaBillingOpen(false);
        }}
        refreshBilling={() => refetch()}
        currentPlanType={cocaData?.billingInfo?.currentPlan?.billing_type}
      />
    </div>
  );
}
