import React from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import Select from "@atlaskit/select";
import { Checkbox } from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import { RiAlarmWarningFill } from "react-icons/ri";
import ArrayInput from "../../../../../components/ArrayInput";
import { FieldArray } from "formik";

const SideTab = (props) => {
  const OnClickOptions = [
    { label: "Open widget", value: "assistant" },
    { label: "Open FAQ page", value: "faq-page" },
  ];

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const alignment = [
    { label: "Right", value: "right" },
    { label: "Left", value: "left" },
  ];

  return (
    <>
      <CustomiserUnit
        title="Widget Alignment"
        description="Define on which side of the website the widget should be rendered"
      >
        <Select
          isSearchable={false}
          value={alignment.find(
            (option) => option.value === props.values.alignment
          )}
          onChange={(e) => props.setFieldValue("alignment", e.value)}
          className="w-48 mr-2 "
          classNamePrefix="react-select"
          options={alignment}
          placeholder="Select widget position"
        />
      </CustomiserUnit>

      <CustomiserUnit
        title="Tab Text"
        description="What text  should we displayed on the tab?"
      >
        <Textfield
          defaultValue={props.values.sideTab.text}
          onChange={(e) => {
            props.setFieldValue("sideTab.text", e.target.value);
          }}
          name="basic"
        />
        {props.values.sideTab.text.length > 20 && (
          <small className="text-red-500 flex items-center">
            <RiAlarmWarningFill />
            <span className="ml-2">
              Exceeded the recommended character length
            </span>
          </small>
        )}
      </CustomiserUnit>

      {!props.hideOnclick && (
        <CustomiserUnit
          title="On Click Action"
          description="Select what should happen when the side-tab is clicked"
        >
          <Select
            isSearchable={false}
            value={OnClickOptions.find(
              (option) => option.value === props.values.sideTab.onClick
            )}
            onChange={(e) => props.setFieldValue("sideTab.onClick", e.value)}
            className="w-64"
            classNamePrefix="react-select"
            options={OnClickOptions}
            placeholder="Select widget position"
          />
        </CustomiserUnit>
      )}

      <CustomiserUnit
        title="Vertical Position"
        description="Define the vertical positioning of the launcher"
      >
        <Textfield
          defaultValue={props.values.sideTab.verticalPositioning}
          onChange={(e) => {
            props.setFieldValue("sideTab.verticalPositioning", e.target.value);
          }}
          name="basic"
          type="number"
        />
      </CustomiserUnit>

      <CustomiserUnit
        title="Visibility"
        description="Select whether the launcher should be shown in mobile/desktop "
      >
        <div className="flex items-center">
          <div>
            <label className="text-xs text-gray-700">Desktop</label>
            <Select
              isSearchable={false}
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.sideTab.visibility.desktop
              )}
              onChange={(e) =>
                props.setFieldValue("sideTab.visibility.desktop", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
          <div className="ml-4">
            <label className="text-xs text-gray-700">Mobile</label>
            <Select
              isSearchable={false}
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.sideTab.visibility.mobile
              )}
              onChange={(e) =>
                props.setFieldValue("sideTab.visibility.mobile", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
        </div>
        <div className="border rounded-lg mt-3 p-4 flex items-center justify-between">
          <Checkbox
            onChange={(e) => {
              props.setFieldValue(
                "sideTab.visibility.showOnProductPage",
                e.target.checked
              );
            }}
            label="Show on product pages"
            name="checkbox-basic"
            defaultChecked={props.values.sideTab.visibility.showOnProductPage}
          />
          <div className="border-r h-6 w-1" />
          <Checkbox
            onChange={(e) => {
              props.setFieldValue(
                "sideTab.visibility.showOnCollectionPage",
                e.target.checked
              );
            }}
            label="Show on collection pages"
            name="checkbox-basic"
            defaultChecked={
              props.values.sideTab.visibility.showOnCollectionPage
            }
          />
        </div>
      </CustomiserUnit>

      <CustomiserUnit
        title="Blocked URLs"
        description="The widgets will not be rendered in these URLs"
      >
        <FieldArray
          name="blockedUrls"
          render={(arrayHelpers) => (
            <ArrayInput
              urlCheck
              addButtonText="Add URL"
              onAddition={(value) => arrayHelpers.push(value)}
              onDeletion={(index) => arrayHelpers.remove(index)}
              arrayItems={props.values.blockedUrls ? props.values.blockedUrls :[]}
            />
          )}
        />
      </CustomiserUnit>

      <CustomiserUnit
        title="Z-index Value"
        description="Controls whether the Cornercart widget is displayed OVER an existing element on your website, or BEHIND it."
      >
        <div className="max-w-xs">
          <Textfield
            defaultValue={props.values.zIndex}
            onChange={(e) => {
              props.setFieldValue("zIndex", e.target.value);
            }}
            name="basic"
            type="number"
          />
        </div>
      </CustomiserUnit>
    </>
  );
};

export default SideTab;
