import React, { useState, useEffect } from "react";

import { DoptProvider } from "@dopt/react";
import { useWorkspace } from "./WorkspaceContext";
import createDoptUser from "../api/dopUser";
import LoadingSpinner from "../components/LoadingSpinner";

const DoptOnboarding = ({ children }) => {
  const workspace = useWorkspace();

  const [isUserOnboarded, setIsUserOnboarded] = useState(false);

  useEffect(async () => {
    // here isUserOnboarded is acting a flag to prevent api invoking multiple times
    if (workspace.data && isUserOnboarded === false) {
      const userIdentifier = workspace.data.storeMyShopifyUrl;
      const userProperties = {
        ...workspace.data.shopifyPlanDetails,
      };
      const userOnboardDopt = await createDoptUser(
        userIdentifier,
        userProperties
      );
      setIsUserOnboarded(true);
    }
    return () => {};
  }, [workspace.data]);

  return (
    <>
      {isUserOnboarded === true && workspace.data ? (
        <DoptProvider
          userId={
            workspace && workspace.data && workspace.data.storeMyShopifyUrl
          }
          apiKey={process.env.REACT_APP_DOPT_BLOCK_API_KEY}
          flowVersions={{
            "one-click-upsell": 2,
            "cart-goal-onboard": 4,
            "connect-with-team": 2,
            "turn-on-app-widget": 3,
            "create-campaign-flow": 1,
            "cart-drawer-settings": 1,
            "cart-annoucement-bar": 3,
            "sticky-add-to-cart-bar": 3,
          }}
        >
          {children}
        </DoptProvider>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default DoptOnboarding;
