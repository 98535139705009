const createDoptUser = async (identifier, userProperties) => {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "X-Api-Key": `${process.env.REACT_APP_DOPT_USER_API_KEY}`,
    },
    body: JSON.stringify({
      properties: userProperties,
      identifier: `${identifier}`,
    }),
  };
  const isDoptUserCreated = await fetch(
    "https://users.dopt.com/identify",
    options
  )
    .then(async (response) => {
      if (response) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => console.error(err));
  return isDoptUserCreated;
};

export default createDoptUser;
