/* --------------- Queries ------------------- */
export const queryAllIntegrations = `
  query queryAllIntegrations{
    integration {
      id
      auth
      credentials
      provider
      providerByProvider {
        description
      }
    }
  }
`;

/* --------------- Mutations ------------------- */

export const insertIntegration = `
  mutation insertIntegration ($integrationInput: integration_insert_input!){
    insert_integration_one(object: $integrationInput) {
      auth
      credentials
    }
  }
`;

export const deleteIntegration = `
  mutation deleteIntegration ($integrationId:Int!){
    delete_integration_by_pk(id: $integrationId) {
      id
    }
  }
`;