import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Account from "./Account";
import Language from "./Language";
import Integration from "./Integration";
import Billing from "./Billing";
import PageViewContainer from "../../components/structure/PageViewContainer";
import { RiArrowLeftLine } from "react-icons/ri";
import { useQueryCache } from "react-query";

export default function SettingsRouter() {
  let { path } = useRouteMatch();
  let history = useHistory();

  const queryCache = useQueryCache();

  return (
    <PageViewContainer
      // title="Settings"
      customBackButton={
        <div>
          {" "}
          <button
            onClick={() => {
              queryCache.invalidateQueries();
              history.push("/");
            }}
            className="flex items-center grow-0 bg-white p-2 desktop:p-3  mr-3 border  text-gray-700 hover:border-gray-700 transition-all rounded-lg"
          >
            <RiArrowLeftLine />
            <span className="ml-3"> Exit Settings </span>
          </button>
        </div>
      }
    >
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to="/settings/account/" />
        </Route>
        <Route path={`${path}/account`} component={Account} />
        <Route path={`${path}/language`} component={Language} />
        <Route path={`${path}/integration`} component={Integration} />
        <Route path={`${path}/Billing`} component={Billing} />
      </Switch>
    </PageViewContainer>
  );
}
