import React from "react";
import { gql, GraphQLClient } from "graphql-request";
import {useAuth} from "./AuthenticationContext"
import CocaSession from "../session";

/* Here we will export a graphql query fetch function
 * which will later can be used in subsequent componentes to
 * resolve queries and mutation without explicitly instantiating
 * graphql clients.
 */

const GraphqlClientContext = React.createContext({});


const getCurrentUserAuthorization = async () => {
  let token = CocaSession.getJWTToken()
  if(CocaSession.isExpired()) {
    token = await CocaSession.refreshJWTToken()
  }
  if (token) {
    return `Bearer ${token}`
  } else {
    return ''
  }
}

const GraphqlClientProvider = (props) => {
  const authState= useAuth();
  const endpoint = process.env.REACT_APP_API_URL;

  const graphqlFetch = async (query, variables) => {
    try {
      if (authState.token)
        {
          const client = new GraphQLClient(endpoint, {
          headers: {
             authorization: await getCurrentUserAuthorization(),
          },
        });
      const data = await client.request(
        gql`
          ${query}
        `,
        variables
        );
        return data;
      }else {
        window.Rollbar.error("Authstate is missing token")
      }
      
    } catch (err) {
      if(err?.response?.errors[0]?.extensions?.code){
        const errorCode = err?.response?.errors[0]?.extensions?.code
        if(errorCode==='invalid-jwt'){
        try{
          await CocaSession.refreshJWTToken()
         return graphqlFetch(query,variables)
        } catch(err){
          //todo rollbar error report
          window.Rollbar.error("Graphql error", err);
          console.error(err)
        }

        }
      }
    }
  };
  return <GraphqlClientContext.Provider value={graphqlFetch} {...props} />;
};

const useGraphQl = () => React.useContext(GraphqlClientContext);

export { GraphqlClientProvider, useGraphQl,gql };
