import React, { useState, useCallback } from "react";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { useHistory } from "react-router-dom";
import { RiPencilFill, RiDeleteBin6Line, RiAddFill } from "react-icons/ri";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import { langOptions } from "../../../../../utilities/variables";
import Button from "../../../../../components/Button";
import TakeOverDrawer from "../../../../../components/TakeOverDrawer";
import MergeTags from "../../../../../components/MergeTags";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";

const Content = ({ values, setFieldValue }) => {
  const history = useHistory();
  const workspace = useWorkspace();
  const [isEditorVisibile, setIsEditorVisibile] = useState(false);
  const [showLangSelector, setShowLangSelector] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");

  const getLanguageLabel = (langKey) => {
    const currentLang = langOptions.filter(
      (language) => language.value === langKey
    );
    return currentLang[0].label;
  };

  const getLangOptions = useCallback(() => {
    const translatedLangArray = workspace.data.translated_languages;
    const addedLangArray = values.campaign_bxgy_frees[0].content.map(
      (contentItem) => contentItem.lang
    );
    const translatedLangOptions = translatedLangArray.filter(
      (lang) => !addedLangArray.includes(lang)
    );
    const langOptionDetails = langOptions.filter((langOption) =>
      translatedLangOptions.includes(langOption.value)
    );
    const addNewLangOption = {
      label: (
        <div className="w-full py-1 text-violet-700 hover:text-violet-800 cursor-pointer">
          To add Languages, Click here
        </div>
      ),
      value: "redirect",
    };
    return [...langOptionDetails, addNewLangOption];
  }, [values]);

  const getTextFieldValue = (field) => {
    const selectedLangDetails = values.campaign_bxgy_frees[0].content.filter(
      (contentItem) => contentItem.lang === selectedLang
    );
    const returnValue = selectedLangDetails[0].value[field];
    return returnValue;
  };

  const LanguageListCard = ({ currentLang }) => {
    return (
      <div
        className={`p-3 flex items-center justify-between border-b border-gray-200`}
      >
        <h2 className="text-base font-bold ml-2">
          {getLanguageLabel(currentLang)}
        </h2>
        <div className="flex flex-row">
          <Button
            onClick={() => {
              setIsEditorVisibile(true);
              setSelectedLang(currentLang);
            }}
            icon={<RiPencilFill />}
            type="ghost"
          >
            Edit
          </Button>
          {currentLang !== workspace.data.default_language &&
            values.campaign_bxgy_frees[0].content.length > 1 && (
              <Button
                className="ml-4"
                onClick={() => {
                  const updatedContent =
                    values.campaign_bxgy_frees[0].content.filter(
                      (contentItem) => contentItem.lang !== currentLang
                    );
                  setFieldValue(
                    "campaign_bxgy_frees[0].content",
                    updatedContent
                  );
                }}
                icon={<RiDeleteBin6Line />}
                type="danger-ghost"
              >
                Delete
              </Button>
            )}
        </div>
      </div>
    );
  };

  const renderLanguageEditor = () => {
    return (
      <div className="h-full overflow-y-auto desktop:p-8 px-4 py-2 max-w-3xl">
        <CustomiserUnit
          title="Title before the getting the reward"
          description="Title before the getting the reward"
        >
          <Textfield
            value={getTextFieldValue("preGoalOfferPageTitle")}
            onChange={(onChangeEvent) => {
              const langIndex = values.campaign_bxgy_frees[0].content.findIndex(
                (contentItem) => contentItem.lang === selectedLang
              );
              setFieldValue(
                `campaign_bxgy_frees[0].content[${langIndex}].value.preGoalOfferPageTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="Title after the getting the reward"
          description="Title after the getting the reward"
        >
          <Textfield
            value={getTextFieldValue("postGoalOfferPageTitle")}
            onChange={(onChangeEvent) => {
              const langIndex = values.campaign_bxgy_frees[0].content.findIndex(
                (contentItem) => contentItem.lang === selectedLang
              );
              setFieldValue(
                `campaign_bxgy_frees[0].content[${langIndex}].value.postGoalOfferPageTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="Text before the getting the reward"
          description="Text before the getting the reward"
        >
          <MergeTags
            tags={[
              {
                tag: "{{goal}}",
                description:
                  "Will be replaced with amount required to reach the goal",
              },
              {
                tag: "{{current_status}}",
                description: "Will be replaced with the current cart value",
              },
              {
                tag: "{{discount}}",
                description: "Will be replaced with the discount amount",
              },
            ]}
          />
          <Textfield
            value={getTextFieldValue("preGoalOfferPageSubTitle")}
            onChange={(onChangeEvent) => {
              const langIndex = values.campaign_bxgy_frees[0].content.findIndex(
                (contentItem) => contentItem.lang === selectedLang
              );
              setFieldValue(
                `campaign_bxgy_frees[0].content[${langIndex}].value.preGoalOfferPageSubTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
        <CustomiserUnit
          title="Text after the getting the reward"
          description="Text after the getting the reward"
        >
          <Textfield
            value={getTextFieldValue("postGoalOfferPageSubTitle")}
            onChange={(onChangeEvent) => {
              const langIndex = values.campaign_bxgy_frees[0].content.findIndex(
                (contentItem) => contentItem.lang === selectedLang
              );
              setFieldValue(
                `campaign_bxgy_frees[0].content[${langIndex}].value.postGoalOfferPageSubTitle`,
                onChangeEvent.target.value
              );
            }}
            name="basic"
          />
        </CustomiserUnit>
      </div>
    );
  };

  return (
    <>
      <div className=" overflow-y-auto h-full px-8 py-4">
        <CustomiserUnit
          title="Content"
          description="choose the text to be displayed in the cart"
        >
          <div className="rounded-lg border border-gray-300 max-w-lg">
            {values.campaign_bxgy_frees[0].content.map((contentLang) => {
              return (
                <LanguageListCard
                  currentLang={contentLang.lang}
                  key={contentLang.lang}
                />
              );
            })}
            <div className="flex flex-row justify-center p-3">
              {showLangSelector ? (
                <Select
                  onChange={(selectedItem) => {
                    if (selectedItem.value !== "redirect") {
                      setFieldValue("campaign_bxgy_frees[0].content", [
                        ...values.campaign_bxgy_frees[0].content,
                        {
                          lang: selectedItem.value,
                          value: {
                            preGoalOfferPageSubTitle: "",
                            postGoalOfferPageSubTitle: "",
                            preGoalOfferPageTitle: "",
                            postGoalOfferPageTitle: "",
                          },
                        },
                      ]);
                    } else {
                      history.push("/settings/language");
                    }
                    setShowLangSelector(false);
                  }}
                  className="w-full"
                  options={getLangOptions()}
                  placeholder="Click to select a langugage"
                />
              ) : (
                <Button
                  type="link-primary"
                  icon={<RiAddFill />}
                  onClick={() => setShowLangSelector(true)}
                >
                  Add Translations
                </Button>
              )}
            </div>
          </div>
        </CustomiserUnit>
      </div>

      {/* Text Editor Section */}
      {isEditorVisibile && selectedLang && (
        <TakeOverDrawer
          title={
            <div className="flex items-center">
              <span className="ml-3 flex">
                {getLanguageLabel(selectedLang)} Language Editor
              </span>
            </div>
          }
          actions={
            <div className="flex items-center">
              <Button
                onClick={() => setIsEditorVisibile(false)}
                className="mr-2"
                type="ghost"
              >
                Close
              </Button>
            </div>
          }
          isOpen={isEditorVisibile}
          onClose={() => setIsEditorVisibile(false)}
        >
          {renderLanguageEditor()}
        </TakeOverDrawer>
      )}
    </>
  );
};

export default Content;
