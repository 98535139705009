import React from "react";
import Textfield from "@atlaskit/textfield";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import ProductDetailsRow from "../../../../../components/ProductDetailsRow";
import ShopifyResourcePicker from "../../../../../components/ShopifyResourcePicker";
import TabRadioButton from "../../../../../components/TabRadioButton";
import VariantDetails from "../../../../../components/VariantDetails";
import MergeTags from "../../../../../components/MergeTags";
import { parseShopifyProductId } from "../../../../../utilities/basicUtilityFunctions";
import { RiAlertFill } from "react-icons/ri";

const Upsell = ({ isValidationFailed, values, setFieldValue }) => {
  const showhideOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const variantSelectOptions = [
    { label: "Allow user to select variant", value: "userSelect" },
    { label: "Pre-select a variant now", value: "merchantSelect" },
  ];
  return (
    <div className=" overflow-y-auto px-8 py-4">
      {/* Upsell Product */}
      <CustomiserUnit
        title="Add-on Product to Upsell"
        description="Select the product that you want to display as the addon to upsell"
        customiserUnitId="upsell-product-select"
      >
        {values.campaign_one_clicks[0].upsellProduct?.target ? (
          <ProductDetailsRow
            className={"mt-4"}
            product={values.campaign_one_clicks[0].upsellProduct.target}
            onDelete={() =>
              setFieldValue(`campaign_one_clicks[0].upsellProduct`, {
                target: null,
                preferenceValue: "",
                preferenceType: "userSelect",
              })
            }
          />
        ) : (
          <div className="flex items-center">
            <ShopifyResourcePicker
              key={"productSelector"}
              btnText="Select Product"
              selectMultiple={false}
              onChange={(selectedProduct) => {
                setFieldValue("campaign_one_clicks[0].upsellProduct.target", {
                  title: selectedProduct[0].title,
                  id: selectedProduct[0].id,
                  product_id: parseShopifyProductId(selectedProduct[0].id),
                  handle: selectedProduct[0].handle,
                  productType: selectedProduct[0].productType,
                  images: selectedProduct[0].images,
                  status: selectedProduct[0].status,
                  totalInventory: selectedProduct[0].totalInventory,
                  tracksInventory: selectedProduct[0].tracksInventory,
                  tags: selectedProduct[0].tags,
                  variants: selectedProduct[0].variants,
                });
              }}
            />
            {isValidationFailed && (
              <div className="ml-2 items-center text-tiny flex text-red-700 border border-red-500 p-2 rounded-lg max-w-xl">
                <RiAlertFill />
                <span className="ml-2">
                  You need to select a product to continue
                </span>
              </div>
            )}
          </div>
        )}
      </CustomiserUnit>

      {/* User Select Variant*/}
      {values.campaign_one_clicks[0].upsellProduct?.target?.variants.length >
        1 && (
        <CustomiserUnit
          title="Variant Choice"
          description="Choose whether to allow the user to select a variant when they click the checkbox"
        >
          <TabRadioButton
            defaultValue={
              values.campaign_one_clicks[0].upsellProduct.preferenceType
            }
            options={variantSelectOptions}
            onChange={(selectedType) => {
              setFieldValue(
                "campaign_one_clicks[0].upsellProduct.preferenceType",
                selectedType
              );
            }}
          />
          {values.campaign_one_clicks[0].upsellProduct?.preferenceType !==
            "userSelect" && (
            <div className="my-5 w-1/2">
              {values.campaign_one_clicks[0].upsellProduct?.preferenceValue ? (
                <VariantDetails
                  title={
                    values.campaign_one_clicks[0].upsellProduct?.preferenceValue
                      ?.displayName
                  }
                  onDelete={() =>
                    setFieldValue(
                      "campaign_one_clicks[0].upsellProduct.preferenceValue",
                      ""
                    )
                  }
                />
              ) : (
                <div className="flex items-center">
                  <ShopifyResourcePicker
                    key={"variantSelector"}
                    btnText="Select Variant"
                    initialQuery={`product_id:${values.campaign_one_clicks[0].upsellProduct.target.product_id}`}
                    selectMultiple={false}
                    resoucePickerType="variant"
                    onChange={(selectedVariant) => {
                      setFieldValue(
                        "campaign_one_clicks[0].upsellProduct.preferenceValue",
                        {
                          id: parseShopifyProductId(selectedVariant[0].id),
                          displayName: selectedVariant[0].displayName,
                        }
                      );
                    }}
                  />
                  {isValidationFailed && (
                    <div className="ml-2 items-center text-tiny flex text-red-700 border border-red-500 p-2 rounded-lg max-w-xl">
                      <RiAlertFill />
                      <span className="ml-2">
                        You need to select a variant to continue
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </CustomiserUnit>
      )}
      {/*Show / Hide in Cart List */}
      <CustomiserUnit
        title="Show added item with other products in cart "
        description="Choose whether to show the add-on products along with other items in the cart"
        customiserUnitId="upsell-product-show-or-hide"
      >
        <TabRadioButton
          defaultValue={
            values.campaign_one_clicks[0].upsellConfig.isDisplayedinCart
          }
          options={showhideOptions}
          onChange={(selectedType) => {
            setFieldValue(
              "campaign_one_clicks[0].upsellConfig.isDisplayedinCart",
              selectedType
            );
          }}
        />
      </CustomiserUnit>
      {/*Show / Hide Product Image */}
      <CustomiserUnit
        title="Show Product Image"
        description="Choose whether to show the image of add-on product"
      >
        <TabRadioButton
          defaultValue={
            values.campaign_one_clicks[0].upsellConfig.isImageVisible
          }
          options={showhideOptions}
          onChange={(selectedType) => {
            setFieldValue(
              "campaign_one_clicks[0].upsellConfig.isImageVisible",
              selectedType
            );
          }}
        />
      </CustomiserUnit>
      {/*Pre-selected */}
      {/* <CustomiserUnit
      title="Pre-select or Manual select"
      description="Should the one-click upsell be pre-selected or not"
    >
      <TabRadioButton
        defaultValue={
          values.campaign_one_clicks[0].upsellConfig.isPreSelected
        }
        options={showhideOptions}
        onChange={(selectedType) => {
          setFieldValue(
            "campaign_one_clicks[0].upsellConfig.isPreSelected",
            selectedType
          );
        }}
      />
    </CustomiserUnit> */}
      {/* One click upsell text */}
      <CustomiserUnit
        title="Upsell Text"
        description="Enter the text to be displayed near the add-on product checkbox"
        customiserUnitId="upsell-product-text"
      >
        <div className="w-1/2">
          <MergeTags
            tags={[
              {
                tag: "{{title}}",
                description: "Will be replaced with the title of the product",
              },
              {
                tag: "{{amount}}",
                description:
                  " Will be replaced with amount price of the product",
              },
            ]}
          />

          <Textfield
            value={values.campaign_one_clicks[0].upsellConfig.upsellDisplayText}
            onChange={(e) => {
              setFieldValue(
                "campaign_one_clicks[0].upsellConfig.upsellDisplayText",
                e.target.value
              );
            }}
            name="basic"
          />
        </div>
      </CustomiserUnit>
    </div>
  );
};

export default Upsell;
