import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

import CustomiserUnit from "../../../../components/CustomiserUnit";
import {
  RiFlagFill,
  RiDeleteBin6Line,
  RiAlertFill,
  RiShoppingCart2Fill,
  RiLinksFill,
  RiDraftFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";

import ShopifyResourcePicker from "../../../../components/ShopifyResourcePicker";
import ProductDetailsRow from "../../../../components/ProductDetailsRow";
import VariantDetails from "../../../../components/VariantDetails";
import { parseShopifyProductId } from "../../../../utilities/basicUtilityFunctions";

/**
 * A UI component for render a milestone item
 * @param {Object} action The action this specific component need to render
 * @param {Object} index index of this milestone in the milestone array
 * @param {Object} values values from the parent formik
 * @param {Function} setFieldValue formik funtion
 * @param {Array} onExpand what happens if you click expand/collapse button
 * @param {Function} isExpanded - Boolean showing if the goal item is expanded or not
 * @param {Function} onDelete - What happens on deletion of milestone
 */
export default function ActionItem({
  action,
  index,
  values,
  setFieldValue,
  onExpand,
  isExpanded,
  onDelete,
  isValidationFailed,
}) {
  const [isActionValid, setIsActionValid] = useState(true);
  const workspace = useWorkspace();

  const urlOpenOptions = [
    { label: "Open in new tab", value: true },
    { label: "Open in current tab", value: false },
  ];
  const variantSelectOptions = [
    { label: "Select the Variant Now", value: "merchantSelect" },
    { label: "Allow user to Select Variant", value: "userSelect" },
  ];
  let actionContentObject;
  switch (action.actionType) {
    case "add_item": {
      actionContentObject = {
        title: "Add an item to the cart",
        icon: <RiShoppingCart2Fill />,
        content: (
          <div>
            {/* Upsell Product */}
            <CustomiserUnit
              title="Select Product"
              description="Select the product that you want to add to cart when this automation is triggered"
            >
              {action.actionConfig.target ? (
                <ProductDetailsRow
                  className={"mt-4"}
                  product={action.actionConfig.target}
                  onDelete={() =>
                    setFieldValue(`actions[${index}].actionConfig.target`, null)
                  }
                />
              ) : (
                <div className="flex items-center">
                  <ShopifyResourcePicker
                    key={`${index}-productSelector`}
                    btnText="Select Product"
                    selectMultiple={false}
                    onChange={(selectedProduct) => {
                      setFieldValue(`actions[${index}].actionConfig.target`, {
                        title: selectedProduct[0].title,
                        id: selectedProduct[0].id,
                        product_id: parseShopifyProductId(
                          selectedProduct[0].id
                        ),
                        handle: selectedProduct[0].handle,
                        productType: selectedProduct[0].productType,
                        images: selectedProduct[0].images,
                        status: selectedProduct[0].status,
                        totalInventory: selectedProduct[0].totalInventory,
                        tracksInventory: selectedProduct[0].tracksInventory,
                        tags: selectedProduct[0].tags,
                        variants: selectedProduct[0].variants,
                      });
                      setFieldValue(
                        `actions[${index}].actionConfig.preferenceValue`,
                        {
                          id: parseShopifyProductId(
                            selectedProduct[0].variants[0].id
                          ),
                          displayName:
                            selectedProduct[0].variants[0].displayName,
                        }
                      );
                      setFieldValue(
                        `actions[${index}].actionConfig.preferenceType`,
                        "merchantSelect"
                      );
                    }}
                  />
                  {isValidationFailed && (
                    <div className="ml-2 items-center text-tiny flex text-red-700 border border-red-500 p-2 rounded-lg max-w-xl">
                      <RiAlertFill />
                      <span className="ml-2">
                        You need to select a product to continue
                      </span>
                    </div>
                  )}
                </div>
              )}
            </CustomiserUnit>

            {/* User Select Variant*/}
            {action.actionConfig.target?.variants.length > 1 && (
              <CustomiserUnit
                title="Allow user to select a variant"
                description="Allow user to select a variant or you can select a prefered variant"
              >
                <TabRadioButton
                  defaultValue={action.actionConfig.preferenceType}
                  options={variantSelectOptions}
                  onChange={(selectedType) => {
                    setFieldValue(
                      `actions[${index}].actionConfig.preferenceType`,
                      selectedType
                    );
                  }}
                />
                {action.actionConfig.preferenceType !== "userSelect" && (
                  <div className="my-5 w-1/2">
                    {action.actionConfig.preferenceValue ? (
                      <VariantDetails
                        title={
                          action.actionConfig?.preferenceValue?.displayName
                        }
                        onDelete={() =>
                          setFieldValue(
                            `actions[${index}].actionConfig.preferenceValue`,
                            null
                          )
                        }
                      />
                    ) : (
                      <div className="flex items-center">
                        <ShopifyResourcePicker
                          key={"variantSelector"}
                          btnText="Select Variant"
                          initialQuery={`product_id:${action.actionConfig.target.product_id}`}
                          selectMultiple={false}
                          resoucePickerType="variant"
                          onChange={(selectedVariant) => {
                            setFieldValue(
                              `actions[${index}].actionConfig.preferenceValue`,
                              {
                                id: parseShopifyProductId(
                                  selectedVariant[0].id
                                ),
                                displayName: selectedVariant[0].displayName,
                              }
                            );
                          }}
                        />
                        {isValidationFailed && (
                          <div className="ml-2 items-center text-tiny flex text-red-700 border border-red-500 p-2 rounded-lg max-w-xl">
                            <RiAlertFill />
                            <span className="ml-2">
                              You need to select a variant to continue
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </CustomiserUnit>
            )}
          </div>
        ),
      };
      break;
    }
    case "add_cart_attribute": {
      actionContentObject = {
        title: "Add a cart attribute",
        icon: <RiDraftFill />,
        content: (
          <div className="max-w-xl">
            {/* Attribute Name */}
            <CustomiserUnit
              title="Attribute Name"
              description="Enter the name of the attribute"
            >
              <Textfield
                value={action.actionConfig.attributeName}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `actions[${index}].actionConfig.attributeName`,
                    onChangeEvent.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
            {/* Attribute Value */}
            <CustomiserUnit
              title="Attribute Value"
              description="Enter the value for the attribute"
            >
              <Textfield
                value={action.actionConfig.attributeValue}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `actions[${index}].actionConfig.attributeValue`,
                    onChangeEvent.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>
          </div>
        ),
      };
      break;
    }
    case "goto_url": {
      actionContentObject = {
        title: "Redirect to a URL",
        icon: <RiLinksFill />,
        content: (
          <div className="max-w-xl">
            {/* Redirect URL */}
            <CustomiserUnit
              title="Redirect URL "
              description="Enter the url to which the user should be redirected to"
            >
              <Textfield
                value={action.actionConfig.url}
                onChange={(onChangeEvent) => {
                  setFieldValue(
                    `actions[${index}].actionConfig.url`,
                    onChangeEvent.target.value
                  );
                }}
                name="basic"
              />
            </CustomiserUnit>

            {/* OPen in new tab */}
            <CustomiserUnit
              title="Where to open the URL"
              description="Should we open on new tab or not"
            >
              <TabRadioButton
                defaultValue={action.actionConfig.openInNewTab}
                options={urlOpenOptions}
                onChange={(selectedType) => {
                  setFieldValue(
                    `actions[${index}].actionConfig.openInNewTab`,
                    selectedType
                  );
                }}
              />
            </CustomiserUnit>
          </div>
        ),
      };
      break;
    }
  }

  return (
    <div
      key={`goal-item-${index}`}
      className={`rounded-lg border mt-3 ${!isActionValid && "border-red-100"}`}
    >
      <div className="flex items-center justify-between w-full border-b px-4">
        <button onClick={onExpand} className="flex flex-grow items-center p-4 ">
          {isActionValid ? (
            <div className="flex justify-center items-center mr-2  rounded-full h-8 w-8 bg-violet-100 text-violet-600">
              {actionContentObject.icon}
            </div>
          ) : (
            <div className="flex animate-bounce justify-center items-center mr-2  rounded-full h-8 w-8 bg-red-100 text-red-600">
              <RiAlertFill />
            </div>
          )}
          <h2 className="font-bold text-lg">{actionContentObject.title}</h2>
        </button>
        <div className="flex items-center">
          <Button onClick={onExpand} type="link">
            {isExpanded ? "Collapse" : "Expand"}
          </Button>

          <Button onClick={onDelete} type="ghost" icon={<RiDeleteBin6Line />}>
            Remove
          </Button>
        </div>
      </div>

      {isExpanded && (
        <div className="p-4 pl-9">{actionContentObject.content}</div>
      )}
    </div>
  );
}
