import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "@atlaskit/select";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../api/widgets";
import { updateSourceDetails } from "../../../api/workspace";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { langOptions } from "../../../utilities/variables";
import { getDefaultLangJson } from "../../../utilities/basicUtilityFunctions";
import { useHistory } from "react-router-dom";
import { useWorkspace } from "../../../contexts/WorkspaceContext";

export default function LanguageSelector({ goFront, goBack }) {
  let history = useHistory();
  const workspace = useWorkspace();
  const [languageCode, setlanguageCode] = useState("en");
  const graphQL = useGraphQl();
  const { data } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateDefaultLanguage = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        default_language: languageCode,
        translated_languages: [languageCode],
      },
    });
  };
  const [updateDefaultLanguageMutation] = useMutation(updateDefaultLanguage, {
    onSuccess: () => {
      goFront();
    },
  });

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      updateDefaultLanguageMutation();
    },
  });

  const handleSubmit = async () => {
    let offerPageLang = await getDefaultLangJson("offer-page", languageCode);
    let offerWidgetLang = await getDefaultLangJson(
      "offer-widget",
      languageCode
    );

    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        offer_widget_lang: { [`${languageCode}`]: offerWidgetLang },
        offer_page_lang: { [`${languageCode}`]: offerPageLang },
      },
    });
  };

  return (
    <div className="laptop:flex mobile:block items-center h-full w-full mt-8 justify-center">
      <div className="p-8 shrink grow max-w-xl">
        <div className="mb-8 text-center">
          <h1 className="font-bold text-gray-700 mb-3">
            Select your default language
          </h1>
          <p>
            Based on this default language the text inside the widgets made by
            this app will be initialised.
          </p>
        </div>
        <div className="max-w-s flex justify-center mb-8">
          {data && (
            <Select
              isSearchable={false}
              value={langOptions.find(
                (option) => option.value === languageCode
              )}
              onChange={(e) => {
                setlanguageCode(e.value);
              }}
              className="w-48 mr-2"
              classNamePrefix="react-select"
              options={langOptions}
            />
          )}
        </div>
        <div className="flex justify-center px-8">
          <button
            onClick={() => handleSubmit()}
            className="text-center py-6 px-24 bg-violet-700 text-white font-bold rounded-lg hover:bg-gray-700 transition-all"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}
