import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  updateSimpleReferral,
  updateCampaign,
  deleteCampaign,
  querySimpleReferral,
} from "../../../../api/offers";
import InlineEdit from "@atlaskit/inline-edit";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import Select from "@atlaskit/select";
import { Formik } from "formik";
import {
  RiCheckboxBlankCircleFill,
  RiArrowDropDownLine,
  RiGift2Fill,
  RiHandCoinFill,
  RiListSettingsFill,
  RiWindowFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import GiftOffer from "./GiftOffer";
import ThankyouPage from "./ThankyouPage/index";
import Rewards from "./Rewards";
import Settings from "./Settings";
import EmailSettings from "./EmailSettings";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import toaster from "toasted-notes";
import Button from "../../../../components/Button";

const CustomAccordionEdit = (historyProps) => {
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("thankyou-page");

  const graphQL = useGraphQl();
  const queryCache = useQueryCache();

  const updateSimpleReferrals = async (input) => {
    await graphQL(updateSimpleReferral, {
      campaignId: { campaignid: input.campaignid },
      simpleReferralInput: input.values,
    });
  };
  const updateCampaigns = async (input) => {
    await graphQL(updateCampaign, {
      campaignId: { id: input.id },
      campaignInput: input.values,
    });
  };

  const { data } = useQuery(
    `querySimpleReferral${historyProps.match.params.id}`,
    () =>
      graphQL(querySimpleReferral, {
        simpleReferralId: historyProps.match.params.id,
      })
  );
  const [updateSimpleReferralMutation] = useMutation(updateSimpleReferrals, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        `querySimpleReferral${historyProps.match.params.id}`
      );
      toaster.notify("Campaign Updated", {
        duration: 2000,
      });
    },
  });
  const [updatCampaignMutation] = useMutation(updateCampaigns);
  const handleSubmision = (value) => {
    updatCampaignMutation({
      id: data.simple_referrals[0].campaignid,
      values: value.campaignPriority,
    });
    updateSimpleReferralMutation({
      campaignid:
        data && data.simple_referrals[0] && data.simple_referrals[0].campaignid,
      values: value.simpleReferralValues,
    });
  };
  const deleteCampaigns = async () => {
    await graphQL(deleteCampaign, {
      campaignId: historyProps.match.params.id,
    });
  };
  const [deleteCampaignMutation] = useMutation(deleteCampaigns, {
    onSuccess: () => {
      toaster.notify("Simple referral campaign deleted", {
        duration: 2000,
      });
      historyProps.history.push(`/offers/referral/campaigns/`);
    },
  });

  const campaignStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
          Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Paused{" "}
        </div>
      ),
      value: "paused",
    },
  ];

  const renderTabContent = (formikProps) => {
    switch (currentTab) {
      case "thankyou-page":
        return (
          <ThankyouPage
            {...formikProps}
            data={data.simple_referrals[0]}
            nextStepTrigger={() => setCurrentTab("rewards")}
            updateData={() => {
              queryCache.invalidateQueries(
                `querySimpleReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "rewards":
        return (
          <Rewards
            {...formikProps}
            data={data.simple_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `querySimpleReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "gift-offer":
        return (
          <GiftOffer
            {...formikProps}
            data={data.simple_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `querySimpleReferral${historyProps.match.params.id}`
              );
            }}
          />
        );
      case "campaign-settings":
        return (
          <Settings
            {...formikProps}
            data={data.simple_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `querySimpleReferral${historyProps.match.params.id}`
              );
            }}
          />
        );

      case "email-settings":
        return (
          <EmailSettings
            {...formikProps}
            data={data.simple_referrals[0]}
            updateData={() => {
              queryCache.invalidateQueries(
                `querySimpleReferral${historyProps.match.params.id}`
              );
            }}
          />
        );
    }
  };

  const tabItems = [
    { label: "Thank you Page", id: "thankyou-page", icon: <RiWindowFill /> },
    { label: "Reward for Referrer", id: "rewards", icon: <RiHandCoinFill /> },
    {
      label: "Gift Coupon for Friends",
      id: "gift-offer",
      icon: <RiGift2Fill />,
    },
    {
      label: "Campaign Settings",
      id: "campaign-settings",
      icon: <RiListSettingsFill />,
    },
  ];

  if (data) {
    return (
      <PageViewContainer
        title="Simple Referrals"
        action={
          <div className="flex items-center">
            <div className="mt-3 mr-5 border px-2 py-1 text-xs text-gray-500 rounded-lg">
              <span className="mobile:hidden laptop:inline"> Campaign ID:</span>
              <span className="font-bold">
                {data.simple_referrals[0].campaignid}
              </span>
            </div>
            {/* <Button
              className="mt-3 mr-5"
              icon={<RiDeleteBin2Fill />}
              type="danger-ghost"
              onClick={() => setIsDeleteWarningOpen(true)}
            >
              <span className="laptop:block mobile:hidden">
                Delete Campaign
              </span>
            </Button> */}
            <div className="max-w-md ">
              <InlineEdit
                defaultValue={data.simple_referrals[0].status}
                hideActionButtons
                editView={() => (
                  <div className="w-40">
                    <Select
                      isSearchable={false}
                      options={campaignStatus}
                      onChange={(onChangeEvent) => {
                        updateSimpleReferralMutation({
                          campaignid:
                            data &&
                            data.simple_referrals[0] &&
                            data.simple_referrals[0].campaignid,
                          values: { status: onChangeEvent.value },
                        });
                      }}
                      autoFocus
                      openMenuOnFocus
                    />
                  </div>
                )}
                readView={() => {
                  if (data.simple_referrals[0].status === "active") {
                    return (
                      <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                        <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
                        Active{" "}
                        <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                      </div>
                    );
                  } else if (data.simple_referrals[0].status === "paused") {
                    return (
                      <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                        <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
                        Paused{" "}
                        <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                      </div>
                    );
                  } else {
                    <div className="flex items-center py-3 px-4 rounded-md bg-gray-200 cursor-pointer ">
                      <RiCheckboxBlankCircleFill className="flex-none text-red-500 mr-2 text-lg" />{" "}
                      Expired{" "}
                      <RiArrowDropDownLine className="flex-none ml-2 text-lg" />{" "}
                    </div>;
                  }
                }}
                onConfirm={(e) => console.log("is_published", e)}
              />
            </div>
          </div>
        }
      >
        <div className="h-full flex flex-col">
          <div className="border-b grow-0 ">
            <HorizontalTabs
              items={tabItems}
              showSeperatorIcons
              onChange={(selectedTab) => {
                setCurrentTab(selectedTab);
              }}
            />
          </div>
          <div className="grow-1  h-full overflow-y-auto ">
            <Formik
              enableReinitialize
              initialValues={data.simple_referrals[0]}
              onSubmit={(values) => {
                const updatedPriorities = {
                  priority: values.campaign.priority,
                };
                const updatedSimpleReferralValues = {
                  name: values.name,
                  blocked_collections: values.blocked_collections,
                  starts_at: values.starts_at,
                  expires_at: values.expires_at,
                  gift_config: values.gift_config,
                  reward_type: values.reward_type,
                  reward_config: values.reward_config,
                  is_auto_optin: values.is_auto_optin,
                  max_discount_value: values.max_discount_value,
                  min_order_value: values.min_order_value,
                  postpurchase_config: values.postpurchase_config,
                  terms_config: values.terms_config,
                  email_config: values.email_config,
                };
                handleSubmision({
                  simpleReferralValues: updatedSimpleReferralValues,
                  campaignPriority: updatedPriorities,
                });
              }}
            >
              {(formikProps) => {
                return (
                  <div className="w-full h-full">
                    {formikProps.dirty && (
                      <div className="z-50 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                        <p>You've unsaved changes</p>
                        <div className="flex items-center">
                          <Button
                            onClick={() => {
                              formikProps.resetForm();
                            }}
                            type="link"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            onClick={() => {
                              formikProps.submitForm();
                            }}
                            type="primary"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    )}
                    {renderTabContent(formikProps)}
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
        <ModalTransition>
          {isDeleteWarningOpen && (
            <Modal
              actions={[
                {
                  text: "Delete it",
                  onClick: () => {
                    deleteCampaignMutation();
                    setIsDeleteWarningOpen(false);
                  },
                },
                {
                  text: "Cancel",
                  onClick: () => {
                    setIsDeleteWarningOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsDeleteWarningOpen(false);
              }}
              heading="Are you sure?"
              appearance="danger"
            >
              <p>
                Are you sure you want to delete this simple referral campaign.
              </p>
            </Modal>
          )}
        </ModalTransition>
      </PageViewContainer>
    );
  } else return <LoadingSpinner />;
};

export default CustomAccordionEdit;
