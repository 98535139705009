import React from "react";
import { Switch, Route, useRouteMatch,Redirect } from "react-router-dom";
import WidgetCreation from "./WidgetCreation";
// import LauncherCreation from "./LauncherCreation";
import LanguageSelector from "./LanguageSelector";

export default function SucoOnboarding() {
  let { path } = useRouteMatch();
  return (
    <div className="flex h-screen">
      <div className="p-14 laptop:flex mobile:hidden items-center grow-0 max-w-md  ">
        <div>
          <img
            src="https://assets.cornercart.io/coca-v2/images/onboard/wave-hand.png"
            alt=""
          />
          <h1 className="text-4xl mt-4 text-violet-700 leading-tight font-light ">
            Hi there,
          </h1>
          <p className="text-gray-500 text-lg leading-7 mt-4">
            Welcome to SupportCorner. Lets get started by setting up our widget and its launcher. More customisation options will be available for you once you complete these 2 onboarding steps
          </p>
        </div>
      </div>
      <div className="bg-gray-100 grow shrink-0 border-l border-gray-200 px-7 py-8 flex  justify-center ">
        <Switch>
          <Route exact path={`${path}/`}>
            <Redirect to={`${path}/1/`} />
          </Route>
          <Route exact path={`${path}/1/`} component={WidgetCreation} />
          <Route exact path={`${path}/2/`} component={LanguageSelector} />
          {/* removed the LauncherCreation step from onboarding*/}
          {/* <Route exact path={`${path}/3/`} component={LauncherCreation} /> */}
        </Switch>
      </div>
    </div>
  );
}
