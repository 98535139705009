import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../components/structure/PageViewContainer";

import {
  RiEdit2Fill,
  RiPlayCircleFill,
  RiPauseCircleFill,
  RiPencilFill,
  RiListSettingsFill,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  queryCampaignCartAnnouncementById,
  updateCartAnnouncementCampaignDetails,
} from "../../../../api/campaign";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import toaster from "toasted-notes";
import Settings from "./Settings";
import Content from "./Content";
import CampaignPaywall from "../../../../components/CampaignPaywall";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import Joyride from "react-joyride";
import { useBlock } from "@dopt/react";
import useCompleteDoptFlow from "../../../../utilities/useCompleteDoptFlow";
import useClearCache from "../../../../utilities/useClearCache";

export default function CartAnnouncementBar() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const clearCache = useClearCache();
  const { finishFlow } = useCompleteDoptFlow();

  const [currentTab, setCurrentTab] = useState("content");
  const [cartAnnouncementFlow, cartAnnouncementFlowTransition] = useBlock(
    "cart-annoucement-bar.edit-cart-annoucement"
  );

  const cartAnnouceSteps = [
    {
      target: "#cart-annouce-message-input",
      content:
        "Write your message here. You an also add multiple translations of your message if your store is multi-lingual.",
      disableBeacon: true,
    },
    {
      target: "#cart-annouce-message-save",
      content: `Set this campaign as active and click on "Save Changes"`,
      // disableBeacon: true,
      placementBeacon: "bottom-end",
      styles: {
        options: {
          primaryColor: "#3d2266",
        },
      },
    },
  ];

  const { data, refetch } = useQuery("queryCampaignCartAnnouncementById", () =>
    graphQL(queryCampaignCartAnnouncementById, { id: params.id })
  );
  const updateCartAnnouncementCampaign = async (values) => {
    await graphQL(updateCartAnnouncementCampaignDetails, {
      campaignId: values.campaignId,
      campaignInput: values.campaignDetails,
      cartAnnouncementId: values.cartAnnouncementId,
      cartAnnouncementInput: values.cartAnnouncementInput,
    });
    refetch();
  };

  const [updateCartAnnouncementCampaignMutation] = useMutation(
    updateCartAnnouncementCampaign,
    {
      onSuccess: () => {
        clearCache();
        if (cartAnnouncementFlowTransition)
          cartAnnouncementFlowTransition("next");
        queryCache.invalidateQueries("queryCampaignCartAnnouncementById");
        toaster.notify("Car announcement updated 🎉", {
          duration: 2000,
        });
      },
    }
  );

  const visibilityOptions = [
    {
      label: (
        <div className="flex items-center">
          <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active{" "}
        </div>
      ),
      value: "active",
    },
    {
      label: (
        <div className="flex items-center">
          <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused{" "}
        </div>
      ),
      value: "draft",
    },
  ];

  const tabItems = [
    { label: "Content", id: "content", icon: <RiPencilFill /> },
    {
      label: "Settings",
      id: "settings",
      icon: <RiListSettingsFill />,
    },
  ];

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "content":
        return <Content values={values} setFieldValue={setFieldValue} />;
      case "settings":
        return <Settings values={values} setFieldValue={setFieldValue} />;
    }
  };

  if (data && data.campaign_by_pk)
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data.campaign_by_pk}
          onSubmit={async (values) => {
            const { audience, settings, status } = values;
            updateCartAnnouncementCampaignMutation({
              campaignId: { id: values.id },
              campaignDetails: {
                audience: audience,
                settings: settings,
                status: status,
              },
              cartAnnouncementId: {
                id: values.campaign_cart_announcements[0].id,
              },
              cartAnnouncementInput: {
                attributes: values.campaign_cart_announcements[0].attributes,
                message: values.campaign_cart_announcements[0].message,
                config: values.campaign_cart_announcements[0].config,
              },
            });
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <PageViewContainer
                title={
                  <InlineEdit
                    defaultValue={values.settings.title}
                    editView={({ ...fieldProps }) => (
                      <Textfield {...fieldProps} name="basic" />
                    )}
                    readView={() => (
                      <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                        <b>{values.settings.title}</b>
                        <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                          <RiEdit2Fill
                            size={15}
                            className="mr-3 text-gray-500"
                          />
                          Edit name
                        </div>
                      </h2>
                    )}
                    onConfirm={(e) => {
                      setFieldValue("settings.title", e);
                    }}
                  />
                }
                action={
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <div className="flex items-center">
                      <span className="font-bold text-gray-700 mr-3">
                        Campaign Status:
                      </span>
                      <TabRadioButton
                        btnId="cart-annouce-message-save"
                        defaultValue={values.status}
                        options={visibilityOptions}
                        onChange={(selectedType) => {
                          setFieldValue("status", selectedType);
                        }}
                      />
                    </div>
                  )
                }
              >
                <HorizontalTabs
                  items={tabItems}
                  showSeperatorIcons
                  onChange={(selectedTab) => {
                    setCurrentTab(selectedTab);
                  }}
                />
                {dirty && (
                  <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          finishFlow();
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPaywall />
                )}
                {!dirty &&
                  values.status === "draft" &&
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <CampaignPauseWarning />
                  )}

                {renderCurrentTab(values, setFieldValue)}
              </PageViewContainer>
            );
          }}
        </Formik>
        {cartAnnouncementFlow.state.active && (
          <Joyride
            steps={cartAnnouceSteps}
            styles={{
              options: {
                primaryColor: "#6d28d9",
                textColor: "#000000",
                zIndex: 1000,
              },
            }}
            locale={{
              last: "Finish",
            }}
            continuous
            showProgress
            spotlightClicks
          />
        )}
      </>
    );
  else return <LoadingSpinner />;
}
