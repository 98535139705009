import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import { BsLink45Deg } from "react-icons/bs";
import toaster from "toasted-notes";
import CustomiserUnit from "../../../components/CustomiserUnit";
import Button from "../../../components/Button";
import IntegrationCard from "./IntegrationCard";
import { useGraphQl } from "../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import {
  queryAllIntegrations,
  insertIntegration,
  deleteIntegration,
} from "../../../api/integrations";

export default function Integrations() {
  const graphQL = useGraphQl();
  const [ismodalopen, setIsmodalopen] = useState(false);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const [selectedid, setSelectedid] = useState();
  const [apikey, setApikey] = useState("");
  const workspace = useWorkspace();

  const { data, refetch } = useQuery("queryAllIntegrations", () =>
    graphQL(queryAllIntegrations)
  );

  const insertIntegrations = async (input) => {
    return await graphQL(insertIntegration, {
      integrationInput: input,
    });
  };

  const [insertIntegrationsMutation] = useMutation(insertIntegrations, {
    onError: (err) => {
      console.error("Error in insertIntegrationsMutation", err);
      window.Rollbar.error("Error in insertIntegrationsMutation", err);
    },
    onSuccess: (e) => {
      setIsmodalopen(false);
      refetch();
      toaster.notify("Integration Connected", {
        duration: 2000,
      });
    },
  });

  const onConnectclick = () => {
    if (apikey) {
      const integrationInput = {
        aid: workspace.data.accountid,
        auth: apikey,
        credentials: {
          "public-api-key": apikey,
        },
        provider: "klaviyo",
        sid: workspace.data.sourceid,
        surl: workspace.data.storeMyShopifyUrl,
      };
      insertIntegrationsMutation(integrationInput);
    } else {
      toaster.notify("Empty API Key", {
        duration: 2000,
      });
    }
  };

  const deleteIntegrationbyid = async () => {
    await graphQL(deleteIntegration, {
      integrationId: selectedid,
    });
  };

  const [deleteIntegrationmutation] = useMutation(deleteIntegrationbyid, {
    onSuccess: () => {
      refetch();
      toaster.notify("Integration disconnected", {
        duration: 2000,
      });
    },
  });

  const onDisconnectclick = (id) => {
    setSelectedid(id);
    setIsDeleteWarningOpen(true);
  };

  return (
    <div className="px-8 py-4">
      <CustomiserUnit
        title={"Integrations"}
        description="Manage the integration in the following apps"
      >
        {workspace.data.currentAppID === 2 ? (
          <div className="mobile:max-w-sm laptop:max-w-4xl">
            {data && data.integration.length !== 0 ? (
              data.integration.map((item) => (
                <IntegrationCard
                  title={item.provider}
                  description={item.providerByProvider.description}
                  onDisconnect={() => {
                    onDisconnectclick(item.id);
                  }}
                />
              ))
            ) : (
              <div className="mobile:mt-3 laptop:mt-0 flex justify-between border rounded-md p-4 items-center">
                <h3 className="font-bold text-base desktop:text-lg">Klaviyo</h3>
                <Button
                  onClick={() => setIsmodalopen(true)}
                  type="primary"
                  icon={<BsLink45Deg style={{ color: "#fff" }} />}
                >
                  Connect
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-gray-500 p-4 bg-gray-100 rounded-lg border">
            <p className="font-bold"> Limited Feature</p>
            <p className="">
              {" "}
              This feature is only available if you've installed our Offers
              Corner App
            </p>
          </div>
        )}
      </CustomiserUnit>
      <ModalTransition>
        {ismodalopen && (
          <Modal width="600px">
            <div className="my-10 p-8 overflow-y-auto">
              <div className="flex justify-center ">
                <div className="max-w-3xl text-center">
                  <h1 className="font-bold mb-3 mx-auto">
                    Klaviyo API Integration
                  </h1>
                  <p className="text-base mt-5">Paste the Klaviyo API Key</p>
                  <div className="flex justify-center mt-5">
                    <div className="w-80">
                      <Textfield
                        defaultValue={apikey}
                        placeholder="API Key"
                        name="basic"
                        onChange={(e) => {
                          setApikey(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <Button
                  onClick={() => setIsmodalopen(false)}
                  type="link"
                  className="mr-4"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  icon={<BsLink45Deg style={{ color: "#fff" }} />}
                  onClick={async () => onConnectclick()}
                >
                  Connect
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {isDeleteWarningOpen && (
          <Modal
            actions={[
              {
                text: "Disconnect it",
                onClick: () => {
                  deleteIntegrationmutation();
                  setIsDeleteWarningOpen(false);
                },
              },
              {
                text: "No, keep it",
                onClick: () => {
                  setIsDeleteWarningOpen(false);
                },
              },
            ]}
            onClose={() => {
              setIsDeleteWarningOpen(false);
            }}
            heading="You’re about to disconnect this integration"
            appearance="danger"
          >
            <p>
              This process cannot be reversed, are you sure you want to
              disconnect integration?
            </p>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
}
