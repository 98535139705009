import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { Formik } from "formik";
import { useGraphQl } from "../../../../../contexts/GraphqlClient";
import {
  queryCornerConvertWidgets,
  updatCornerStickybarWidget,
} from "../../../../../api/widgets";
import {
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
  RiTranslate,
  RiPaletteFill,
  RiListSettingsFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import PageViewContainer from "../../../../../components/structure/PageViewContainer";
import Button from "../../../../../components/Button";
import TabRadioButton from "../../../../../components/TabRadioButton";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import HorizontalTabs from "../../../../../components/HorizontalTabs";
import StickyBarGeneralSettings from "./GeneralSettings";
import StickyBarDesignSettings from "./DesignSettings";
import StickyBarLanguageSettings from "./LanguageSettings";
import toaster from "toasted-notes";
import WidgetPreview from "../../WidgetPreview";
import { useBlock } from "@dopt/react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useClearCache from "../../../../../utilities/useClearCache";

/**
 * Componet for Rendering the Entire Stickybar settings
 */
export default function StickyBar() {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const clearCache = useClearCache();
  const [isWidgetActive, setIsWidgetActive] = useState("");
  const [currentTab, setCurrentTab] = useState("design");
  const [joyRide, setJoyRide] = useState({
    run: true,
    stepIndex: 0,
  });

  const [stickyBarOnboard, stickyBarOnboardTransition] = useBlock(
    "sticky-add-to-cart-bar.enable-sticky-atc"
  );

  const { data, refetch } = useQuery("queryCornerConvertWidgets", () =>
    graphQL(queryCornerConvertWidgets)
  );

  useEffect(() => {
    if (data) {
      setIsWidgetActive(data.widget_stickybar[0].is_active);
    }
  }, [data]);

  const stickyBarSteps = [
    {
      target: "#sticky-atc-bar-settings",
      content: `You can customize the ATC widget settings for desktop and mobile, separately.`,
      disableBeacon: true,
      disableScrolling: true,
      placement: "bottom",
    },
    {
      target: "#sticky-atc-bar-status",
      content: `Click the button to enable the sticky add to cart bar`,
    },
  ];

  const WidgetStates = [
    {
      label: (
        <div className="flex items-center">
          <RiEyeFill className="flex-none  mr-2 text-lg" /> Enabled
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiEyeOffFill className="flex-none mr-2 text-lg" /> Disabled
        </div>
      ),
      value: false,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setJoyRide((prevState) => ({
        ...prevState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      }));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyRide((prevState) => ({
        ...prevState,
        run: false,
      }));
      if (stickyBarOnboardTransition) stickyBarOnboardTransition("next");
    }
  };

  /**
   * Render function each tab inside the convert screen section
   * @param {object} values - formik values
   * @param {funtion} setFieldValue - formik method for setting form field values
   */
  const renderTabContent = (values, setFieldValue) => {
    switch (currentTab) {
      case "general":
        return (
          <StickyBarGeneralSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "design":
        return (
          <StickyBarDesignSettings
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case "language":
        return (
          <StickyBarLanguageSettings
            values={values}
            setFieldValue={setFieldValue}
            currentDefaultLang={data?.workspaces[0]?.source?.default_language}
          />
        );
    }
  };

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.stickybarDetails,
    });
    refetch();
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      clearCache();
      queryCache.invalidateQueries("queryCornerConvertWidgets");
      toaster.notify("Widget settings updated successfully", {
        duration: 2000,
      });
    },
    onError: () => {},
  });

  const handleSubmitForm = (formValues) => {
    updatCornerStickybarMutation({
      id: { id: data.widget_stickybar[0].id },
      stickybarDetails: {
        stickybar_config: formValues.stickybar_config,
        sticky_atc_lang: formValues.sticky_atc_lang,
      },
    });
  };

  const handleStickyBarControl = (value) => {
    updatCornerStickybarMutation({
      id: { id: data.widget_stickybar[0].id },
      stickybarDetails: { is_active: value },
    });
  };

  const tabItems = [
    { label: "Design Settings", id: "design", icon: <RiPaletteFill /> },
    { label: "General Settings", id: "general", icon: <RiListSettingsFill /> },
    { label: "Language Settings", id: "language", icon: <RiTranslate /> },
  ];

  if (data) {
    return (
      <>
        <PageViewContainer
          title="Add to Cart Bar"
          action={
            <div className="flex items-center">
              <p className="mobile:hidden laptop:block mr-3 font-bold text-sm desktop:text-base text-gray-700 ">
                Add to Cart Bar Status :{" "}
              </p>
              <TabRadioButton
                btnId="sticky-atc-bar-status"
                defaultValue={isWidgetActive}
                options={WidgetStates}
                onChange={(selectedValue) => {
                  handleStickyBarControl(selectedValue);
                }}
              />
            </div>
          }
        >
          <div className="flex flex-col h-full">
            {!isWidgetActive && (
              <div className="bg-amber-300 text-amber-800 text-center py-2 flex items-center justify-center">
                <RiFileWarningFill className="text-xl mr-3" />
                <p className="text-sm">
                  The Add To Cart Bar is currently disabled. Enable it on your
                  store using the toggle button in the top right
                </p>
              </div>
            )}
            <HorizontalTabs
              items={tabItems}
              showSeperatorIcons
              onChange={(selectedTab) => {
                setCurrentTab(selectedTab);
              }}
            />

            <Formik
              enableReinitialize
              initialValues={data.widget_stickybar[0]}
              onSubmit={(values) => {
                handleSubmitForm(values);
              }}
            >
              {({ values, dirty, setFieldValue, resetForm, submitForm }) => {
                return (
                  <>
                    {dirty && (
                      <div className="z-20 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                        <p>You've unsaved changes</p>
                        <div className="flex items-center">
                          <Button
                            onClick={() => {
                              resetForm();
                            }}
                            type="link"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            onClick={() => {
                              submitForm();
                            }}
                            type="primary"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    )}
                    <div className="grow flex flex-row min-h-0">
                      <div className="desktop:max-w-lg laptop:max-w-md mobile:w-full px-6 desktop:px-8 overflow-y-auto">
                        <div className="w-full h-full">
                          {renderTabContent(values, setFieldValue)}
                        </div>
                      </div>
                      <div className="grow flex-wrap py-3 overflow-hidden h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center ">
                        <WidgetPreview
                          stickybarConfig={values.stickybar_config}
                          stickyAtcWidgetLang={values.sticky_atc_lang}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </PageViewContainer>
        {stickyBarOnboard.state.active && (
          <Joyride
            steps={stickyBarSteps}
            run={joyRide.run}
            stepIndex={joyRide.stepIndex}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                primaryColor: "#6d28d9",
                textColor: "#000000",
                zIndex: 1000,
              },
            }}
            locale={{
              last: "Finish",
            }}
            continuous
            showProgress
            disableScrolling
            scrollOffset={200}
            spotlightClicks
          />
        )}
      </>
    );
  } else {
    return <LoadingSpinner />;
  }
}
