import React, { useState } from "react";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

const FAQAccordion = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className="p-4 border border-solid border-gray-200 rounded-md cursor-pointer mb-6"
      onClick={() => setIsExpanded((currentValue) => !currentValue)}
    >
      <div className="flex justify-between items-center flex-row">
        <h5 className="flex flex-grow shrink-0 text-base">{title}</h5>
        <div className="">
          <span className={`text-black`}>
            {isExpanded ? (
              <RiArrowUpSLine size={23} />
            ) : (
              <RiArrowDownSLine size={23} />
            )}
          </span>
        </div>
      </div>
      {isExpanded && (
        <div className={`mt-4`}>
          <p className="text-base">{content}</p>
        </div>
      )}
    </div>
  );
};

export default FAQAccordion;
