import React from "react";
import { useHistory } from "react-router-dom";
import AlertBanner from "./AlertBanner";

const CampaignPaywall = ({ onPlanUpgrade }) => {
  const history = useHistory();

  const redirectToBilling = () => {
    history.push("/settings/Billing");
  };

  return (
    <AlertBanner
      type="error"
      layout="banner"
      title="Campaign on Hold"
      message="This campaign is currently on hold. Please upgrade to a paid plan to
    avail this feature"
      cta={{
        text: "Upgrade Now",
        onClick: () => {
          if (onPlanUpgrade) {
            onPlanUpgrade();
          } else {
            redirectToBilling();
          }
        },
      }}
    />
  );
};

export default CampaignPaywall;
