import React from "react";
import FaqCategories from "./FaqCategories";



export default function Assistant(props) {

    const findBgColor = (opacity) => {
      var RGBvalues = props.bgEndColor.substring(
        props.bgEndColor.indexOf("(") + 1,
        props.bgEndColor.length - 1
      );
      const bgColor = `rgba(${RGBvalues},${opacity})`;
      return bgColor;
    };

const SearchBox = (props) => {
  return (
    <div
      ref={(node) => {
        if (node)
          node.style.setProperty(
            "border",
            `1px solid ${props.bgEndColor}`,
            "important"
          );
      }}
      className="coca-cowi-search-box coca-d-flex coca-align-items-center coca-cowi-card"
    >
      <input
        id="search-input"
        className="coca-search-input"
        type="text"
        placeholder="Search for answers..."
      />
      <div className="coca-d-flex coca-cowi-search-btn">
        <svg
          fill={props.bgEndColor}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="18px"
        >
          <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z" />
        </svg>
      </div>
    </div>
  );
};


  return (
    <div className="coca-d-flex coca-cowi-assitant">
    <div className="coca-cowi-assistant-title-header">
      <div className="coca-cowi-assistant-title-content">
        <div className="coca-cowi-assistant-title-emoji ">
          {
            props.introEmoji
          }
        </div>
        <h1 className="">
          <b>
            {props.langObject[props.defaultLang].assistant.welcome_text
              ? props.langObject[props.defaultLang].assistant.welcome_text
              : "Hi there, how  may I help you?"}
          </b>
        </h1>
      </div>

      {props.search && <SearchBox />}
      {(props.contactOptions.email.length > 0 || props.contactOptions.sms.length > 0 || props.contactOptions.whatsapp.length > 0 || props.contactOptions.messenger.length > 0) &&
      <div className="coca-cowi-contact-options coca-d-flex coca-align-items-center">
        <small className="coca-cowi-contact-label">
          {props.langObject[props.defaultLang].assistant.contact_us_label
            ? props.langObject[props.defaultLang].assistant.contact_us_label
            : "Contact Us"}
        </small>

   
        {props.contactOptions.email.length > 0 && (
            <a
              className="coca-cowi-contact-icon coca-cowi-mail-icon"
            >
              <img
                src="https://assets.cornercart.io/coca-v2/images/contact-icons/mail.svg"
                alt=""
              />
            </a>
          )}
        {props.contactOptions.whatsapp.length > 0 && (
            <a
              className="coca-cowi-contact-icon coca-cowi-wa-icon"
            >
              <img
                src="https://assets.cornercart.io/coca-v2/images/contact-icons/wa.svg"
                alt=""
              />
            </a>
          )}
        {props.contactOptions.messenger.length > 0 && (
            <a
              className="coca-cowi-contact-icon coca-cowi-me-icon"
              
            >
              <img
                src="https://assets.cornercart.io/coca-v2/images/contact-icons/me.svg"
                alt=""
              />
            </a>
          )}
        {props.contactOptions.sms.length > 0 && (
            <a
              className="coca-cowi-contact-icon coca-cowi-sms-icon"
            >
              <img
                src="https://assets.cornercart.io/coca-v2/images/contact-icons/sms.svg"
                alt=""
              />
            </a>
          )}
      </div>
       }
   </div>

      <div
        ref={(node) => {
          if (node)
            node.style.setProperty(
              "background",
              `linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ${findBgColor(
                0.02
              )}`,
              "important"
            );
        }}
        className="coca-cowi-bg coca-d-col"
      >
        <FaqCategories {...props}/>
      </div>
    </div>
  );
}
