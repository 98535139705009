import React from "react";
import AlertBanner from "./AlertBanner";

/**
 * Warning component when campaigns are paused
 * @returns {JSX} component
 */
const CampaignPauseWarning = () => {
  return (
    <AlertBanner
      type="warning"
      layout="banner"
      message="This campaign is currently paused. Make it Active on your store using the toggle button in the top right"
    />
  );
};

export default CampaignPauseWarning;
