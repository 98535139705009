import React, { useState, useEffect } from "react";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { langOptions } from "../utilities/variables";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";
import { RiAddCircleFill, RiCheckFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import Textfield from "@atlaskit/textfield";

const CategoryLangEditPopup = ({ isOpen, onClose, initialData, OnSave }) => {
  const workspace = useWorkspace();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState([]);

  const sortLangByDefaultLanguage = (languageData) => {
    const sortedLangArray = languageData.sort((a, b) => {
      // sort array so that default language comes as the first element
      if (a.value === workspace.data.default_language) {
        return -1;
      }
      return 0;
    });
    return sortedLangArray;
  };

  useEffect(() => {
    let sortedLangDetails = [];
    if (initialData) {
      // if the initialData is provided then pick the category name, and add that to form intial value array
      const translatedLangOptions = langOptions.filter((langOption) =>
        workspace.data.translated_languages.includes(langOption.value)
      );
      translatedLangOptions.forEach((langOption) => {
        const catgoryNameFound = initialData.find(
          (category) => category.lang === langOption.value
        );
        if (catgoryNameFound) {
          langOption.categoryName = catgoryNameFound.value.name;
        } else {
          langOption.categoryName = "";
        }
      });
      sortedLangDetails = sortLangByDefaultLanguage(translatedLangOptions);
      setInitialValues(sortedLangDetails);
    }
  }, [initialData]);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal width="medium" onClose={() => onClose()}>
          <div className="p-6">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(formikValues) => {
                OnSave(formikValues);
              }}
            >
              {({ values, dirty, resetForm, setFieldValue, submitForm }) => {
                return (
                  <div>
                    <div>
                      <p className="text-primary-600 font-bold text-lg">
                        Category Language Settings
                      </p>
                      <p className="text-primary-600 text-base">
                        Decide how this category is named in each language
                      </p>
                    </div>
                    <div className="my-6">
                      {values.map((language, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center p-2"
                          >
                            <p className="text-primary-600 w-1/6">
                              {language.label}
                            </p>
                            <div className="flex w-5/6">
                              <Textfield
                                id={`categoryName_${index}`}
                                placeholder="Category Name"
                                autoFocus={true}
                                name="basic"
                                defaultValue={language.categoryName}
                                onChange={(e) => {
                                  setFieldValue(
                                    `[${index}].categoryName`,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex justify-center my-4">
                      <Button
                        type="link"
                        icon={<RiAddCircleFill />}
                        onClick={() => history.push("/settings/language")}
                      >
                        Add Language
                      </Button>
                    </div>
                    <div className="flex flex-row justify-end mt-12">
                      <Button type="link" onClick={() => onClose()}>
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        icon={<RiCheckFill />}
                        onClick={() => submitForm()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CategoryLangEditPopup;
