import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { Checkbox } from "@atlaskit/checkbox";
import Select from "@atlaskit/select";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Button from "../../../../components/Button";
import List from "../../../../components/List";
import WidgetStatusWarning from "../../../../components/WidgetStatusWarning";
import Tooltip from "@atlaskit/tooltip";
import {
  RiFileAddLine,
  RiEdit2Line,
  RiCheckboxBlankCircleFill,
  RiCheckboxCircleFill,
  RiQuestionLine,
  RiAlarmWarningFill,
} from "react-icons/ri";
import {
  updateViralReferral,
  updateSimpleReferral,
  queryAllReferrals,
  querySimpelReferralRequests,
} from "../../../../api/offers";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import toaster from "toasted-notes";
import Analytics from "./Analytics";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import OfcoBillingWarning from "../../../../components/OfcoBillingWarning";
import AlertBanner from "../../../../components/AlertBanner";

export default function Referrals(historyProps) {
  const workspace = useWorkspace();
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const [fromDate, setFromDate] = useState(
    moment().subtract(30, "days").toISOString()
  );
  const [toDate, setToDate] = useState(moment().toISOString());
  const [color, setColor] = useState("blue");
  const [activeSortOption, setActiveSortOption] = useState("all");
  const [prioritySort, setPrioritySort] = useState(true);

  const { data: referralRequests } = useQuery(
    "querySimpelReferralRequests",
    () => graphQL(querySimpelReferralRequests)
  );

  const { data } = useQuery("queryAllReferrals", () =>
    graphQL(queryAllReferrals, {
      fromDate: fromDate,
      toDate: toDate,
    })
  );

  // the following variable will contained combined data of both Simple Referrals and the Offer Refferals
  const combinedData =
    data && data.viral_referrals.concat(data.simple_referrals);

  const updateViralReferrals = async (input) => {
    await graphQL(updateViralReferral, {
      campaignId: { campaignid: input.campaignid },
      viralReferralInput: input.values,
    });
  };
  const [updateViralReferralMutation] = useMutation(updateViralReferrals, {
    onSuccess: () => {
      queryCache.invalidateQueries(`queryAllReferrals`);
      toaster.notify("Campaign Updated", {
        duration: 2000,
      });
    },
    onError: (err) => {
      window.Rollbar.error("Error in updateViralReferralMutation ", err);
    },
  });

  const updateSimpleReferrals = async (input) => {
    await graphQL(updateSimpleReferral, {
      campaignId: { campaignid: input.campaignid },
      simpleReferralInput: input.values,
    });
  };

  const [updateSimpleReferralMutation] = useMutation(updateSimpleReferrals, {
    onSuccess: () => {
      queryCache.invalidateQueries(`queryAllReferrals`);
      toaster.notify("Campaign Updated", {
        duration: 2000,
      });
    },
    onError: (err) => {
      window.Rollbar.error("Error in updateSimpleReferralMutation ", err);
    },
  });

  const sortOptions = [
    { label: "All Campaigns", value: "all" },
    { label: "Active Campaigns", value: "active" },
    { label: "Paused Campaigns", value: "paused" },
    { label: "Finished Campaigns", value: "expired" },
  ];

  const getSortedData = () => {
    // return data.viral_referrals;
    let sortData;
    switch (activeSortOption) {
      case "all":
        sortData = combinedData.sort((a, b) => {
          // this logic makes sure the active campaigns comes first in the list followed by paused and expired
          if (a.status === "active" || b.status === "expired") return -1;
          else if (b.status === "active" || a.status === "expired") return 1;
        });
        break;
      case "active":
        sortData = combinedData.filter(
          (campaign) => campaign.status === "active"
        );
        break;
      case "paused":
        sortData = combinedData.filter(
          (campaign) => campaign.status === "paused"
        );
        break;
      case "expired":
        sortData = combinedData.filter(
          (campaign) => campaign.status === "expired"
        );
        break;
    }
    // the following statement makes sure the campaign with higher priority is shown above
    if (prioritySort)
      return sortData.sort((a, b) => {
        return b.campaign.priority - a.campaign.priority;
      });
    else return sortData;
  };
  const campaignStatus = (status) => {
    switch (status) {
      case "active": {
        return (
          <span className="flex items-center rounded-sm mobile:text-xs desktop:text-base ">
            <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-1 text-xs" />{" "}
            <span className="mobile:hidden laptop:block">Active</span>{" "}
          </span>
        );
        break;
      }
      case "paused": {
        return (
          <span className="flex items-center rounded-sm mobile:text-xs desktop:text-base ">
            <RiCheckboxBlankCircleFill className="flex-none text-amber-500 mr-1 text-xs" />{" "}
            <span className="mobile:hidden laptop:block">Paused</span>{" "}
          </span>
        );
        break;
      }
      case "expired": {
        return (
          <span className="flex items-center rounded-sm mobile:text-xs desktop:text-base ">
            <RiCheckboxBlankCircleFill className="flex-none text-red-500 mr-1 text-xs" />{" "}
            <span className="mobile:hidden laptop:block">Expired</span>{" "}
          </span>
        );
        break;
      }
    }
  };

  const findCampaignRevenue = (item) => {
    if (item.campaign.type === "SIMPLE_REFERRAL") {
      if (
        item.simple_referrals_results_aggregate.aggregate.sum
          .revenue_from_participant !== null
      )
        return item.simple_referrals_results_aggregate.aggregate.sum.revenue_from_participant.toFixed(
          2
        );
      else return 0;
    } else if (item.campaign.type === "VIRAL_REFERRAL") {
      if (
        item.viral_referrals_results_aggregate.aggregate.sum
          .revenue_from_participant !== null
      )
        return item.viral_referrals_results_aggregate.aggregate.sum.revenue_from_participant.toFixed(
          2
        );
      else return 0;
    }
  };

  if (combinedData)
    return (
      <PageViewContainer
        title="Campaigns"
        action={
          <div className="flex">
            <Button
              onClick={() =>
                historyProps.history.push(`/offers/referral/campaigns/create`)
              }
              type="primary"
              icon={<RiFileAddLine />}
            >
              Create a referral campaign
            </Button>
          </div>
        }
      >
        <AlertBanner
          type="error"
          layout="banner"
          message={
            <p className="text-base leading-none ">
              Offers Corner app is being discontinued on 30th June 2023. For
              more details please read this
              <a
                className="cursor-pointer pointer-events-auto text-base px-1 text-violet-700 hover:text-violet-800"
                href="https://help.cornercart.io/en/article/sunsetting-offers-corner-ohz45/?bust=1687153529457"
                target="_blank"
              >
                announcement
              </a>
              or the email we sent on 1st of June
            </p>
          }
          styles="px-5 py-5"
        />
        <WidgetStatusWarning />
        <OfcoBillingWarning />
        <div className="desktop:px-8 mobile:px-3 h-full overflow-y-auto  pt-4 ">
          <div className="my-4">
            <Analytics fromDate={fromDate} toDate={toDate} />
          </div>
          {referralRequests &&
            referralRequests.simple_referral_cashback_requests &&
            referralRequests.simple_referral_cashback_requests.filter(
              (request) => request.status === "CASHBACK_PENDING"
            ).length > 0 && (
              <div className="flex items-center justify-center ">
                <div className="flex items-center p-3 rounded-lg border border-violet-600 bg-violet-100 ">
                  <span className="text-2xl mr-2 text-violet-800">
                    <RiAlarmWarningFill />
                  </span>
                  <div className="mr-3 text-violet-800">
                    You have{" "}
                    {
                      referralRequests.simple_referral_cashback_requests.filter(
                        (request) => request.status === "CASHBACK_PENDING"
                      ).length
                    }{" "}
                    pending cashback requests to approve
                  </div>
                  <Link to={`/offers/referral/requests/`}>
                    <button
                      className="bg-violet-400 text-violet-800 font-bold px-3 py-2 rounded-lg"
                      type="outline"
                    >
                      Go to requests
                    </button>
                  </Link>
                </div>
              </div>
            )}
          <h3 className="font-bold text-gray-700 text-base desktop:text-lg">
            {" "}
            Campaigns{" "}
          </h3>
          <div className="flex items-center justify-between mt-8 mb-4">
            <div className="flex items-center">
              <p className="mr-3 desktop:block mobile:hidden">View</p>
              <Select
                isSearchable={false}
                value={sortOptions.find(
                  (option) => option.value === activeSortOption
                )}
                onChange={(e) => setActiveSortOption(e.value)}
                className="w-48 mr-2"
                options={sortOptions}
              />
            </div>
            <div className="flex items-center">
              <Checkbox
                onChange={(e) => {
                  setPrioritySort(!e.target.checked);
                }}
                label=""
                name="checkbox-basic"
                defaultChecked={prioritySort}
              />
              <small className="text-xs">Sort based on campaign priority</small>
            </div>
          </div>
          <List
            items={getSortedData()}
            header={
              <div className="grid grid-cols-12 items-center justify-center desktop:text-tiny text-xs">
                <div className="px-3 laptop:col-span-4 mobile:col-span-10">
                  <p className="font-bold text-gray-600 desktop:text-tiny text-xs">
                    Title
                  </p>
                </div>
                <div className="px-3 laptop:col-span-1 mobile:hidden laptop:block">
                  <Tooltip content="Revenue generated from a campaign in last 30 days">
                    <p className="font-bold text-gray-600 flex items-center desktop:text-tiny text-xs">
                      Revenue{" "}
                      <span className="cursor-pointer desktop:text-lg text-md  ml-2">
                        <RiQuestionLine />
                      </span>
                    </p>{" "}
                  </Tooltip>
                </div>
                <div className="px-3 laptop:col-span-1 mobile:hidden laptop:block">
                  <Tooltip content="Total number of referrers joined in last 30 days">
                    <p className="font-bold text-gray-600 flex items-center desktop:text-tiny text-xs">
                      Referrers
                      <span className="cursor-pointer desktop:text-lg text-md ml-2">
                        <RiQuestionLine />
                      </span>
                    </p>{" "}
                  </Tooltip>
                </div>
                <div className="px-3 col-span-2 mobile:hidden laptop:block">
                  <p className="font-bold text-gray-600 desktop:text-tiny text-xs">
                    Status
                  </p>
                </div>
              </div>
            }
            emptyState={
              <div>
                <div className="flex justify-center my-4 desktop:my-8">
                  <img
                    src="https://assets.cornercart.io/coca-v2/images/empty-states/campaign.png"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h3 className="font-bold text-gray-700 mb-3 text-base desktop:text-lg">
                    No campaigns found{" "}
                  </h3>
                  <p className="text-gray-800 text-tiny desktop:text-base">
                    Start expanding your revenue . Create your first Viral
                    Referral
                  </p>
                </div>
              </div>
            }
            render={(item, index) => {
              return (
                <div className="grid grid-cols-12 items-center justify-center">
                  <div className="px-3 laptop:col-span-4 mobile:col-span-7">
                    <Link
                      to={`/offers/referral/campaigns/${
                        item.campaign.type === "VIRAL_REFERRAL"
                          ? "viral-referral"
                          : "simple-referral"
                      }/edit/${item.campaign.id}`}
                    >
                      <div className="py-3 desktop:py-5">
                        <p className="font-bold text-gray-700 flex items-center">
                          <span className="mobile:block laptop:hidden">
                            {campaignStatus(item.status)}
                          </span>
                          <span className="mr-3 desktop:text-base text-tiny">
                            {item.name}
                          </span>{" "}
                        </p>
                        <div className="flex mt-2">
                          <div className="text-xs text-gray-500 mr-3 pr-4 border-r">
                            <p className="font-bold">STARTS AT </p>
                            <p>
                              {moment(item.starts_at)
                                .utc()
                                .format("DD-MM-YYYY")}
                            </p>
                          </div>
                          <div className="text-xs text-gray-500">
                            <p className="font-bold">ENDS AT </p>
                            <p>
                              {moment(item.expires_at)
                                .utc()
                                .format("DD-MM-YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="px-3 laptop:col-span-1 mobile:hidden laptop:block text-sm">
                    ${findCampaignRevenue(item)}
                  </div>
                  <div className="px-3 laptop:col-span-1 mobile:hidden laptop:block">
                    {item.campaign.coupons_aggregate.aggregate.count
                      ? item.campaign.coupons_aggregate.aggregate.count
                      : 0}
                  </div>

                  <div className="px-3 col-span-2 mobile:hidden laptop:block flex">
                    <p className="text-grey-700 ">
                      {campaignStatus(item.status)}
                    </p>
                  </div>
                  <div className="px-3 col-span-4 flex items-center">
                    {item.status === "paused" && (
                      <button
                        onClick={() => {
                          item.campaign.type === "VIRAL_REFERRAL"
                            ? updateViralReferralMutation({
                                campaignid: item.campaignid,
                                values: { status: "active" },
                              })
                            : updateSimpleReferralMutation({
                                campaignid: item.campaignid,
                                values: { status: "active" },
                              });
                        }}
                        className="pointer-cursor desktop:text-base text-sm mr-2 p-2 desktop:p-3 text-gray-700 flex rounded-md items-center bg-amber-400 hover:bg-amber-500 cursor-pointer focus:outline-none"
                      >
                        <RiCheckboxCircleFill />
                        <span className="ml-1 "> Activate</span>
                      </button>
                    )}
                    <Link
                      to={`/offers/referral/campaigns/${
                        item.campaign.type === "VIRAL_REFERRAL"
                          ? "viral-referral"
                          : "simple-referral"
                      }/edit/${item.campaign.id}`}
                    >
                      <p className="pointer-cursor text-gray-700 hover:text-violet-700 desktop:text-base text-sm p-2 desktop:p-3 border border-gray-500  hover:border-violet-700 flex rounded-md items-center">
                        <RiEdit2Line />
                        <span className="laptop:block mobile:hidden ml-2">
                          {" "}
                          Edit
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </PageViewContainer>
    );
  else return <LoadingSpinner />;
}
