import React, { useState, useEffect } from "react";
import { RiLoader2Line } from "react-icons/ri";
import Button from "./components/Button";
import { useAuth } from "./contexts/AuthenticationContext";
import crispInitialiser from "./components/crispInitialiser";

function UnauthorisedApp() {
  const [loadingState, setLoadingState] = useState("loading");
  const authState = useAuth();

  useEffect(() => {
    if (authState.status === "out") {
      if (authState.reason === "NO_TOKEN") {
        setLoadingState("NO_TOKEN");
      } else {
        setLoadingState("warning");
      }
    }
    crispInitialiser();
  }, [authState]);

  const renderLoadingState = (state) => {
    switch (state) {
      case "loading":
        return (
          <div className=" animate-spin text-5xl text-violet-700">
            <RiLoader2Line />
          </div>
        );
      case "error":
        return (
          <>
            <div className="text-center max-w-2xl">
              <div className="text-5xl">🤔</div>
              <h2 className="text-violet-700 font-bold">
                It is taking longer than usual due to internet
                traffic...(sigh)...almost there...
              </h2>
            </div>
          </>
        );
      case "NO_TOKEN":
        return (
          <>
            <div className="text-center max-w-2xl">
              <div className="text-5xl">🤔</div>
              <h2 className="text-violet-700 font-bold">
                Uh oh. Your session is expired. Please relogin via Shopify
                dashboard
              </h2>
            </div>
          </>
        );
      case "warning":
        return (
          <>
            <div className="text-center max-w-2xl">
              <div className="text-5xl">🤕</div>
              <h2 className="text-violet-700 font-bold">
                Something is wrong! But don't you worry."
              </h2>
              <p>
                Our engineers are scrambling to investigate why login failed for
                you. Please try reloading the page (surprisingly that works at
                times!). Also please ping us in chat so that we can get this
                sorted asap. page. If the issue persists reachout to us via chat
                and we can help you out
              </p>
              <div className="flex items-center justify-center mt-4">
                <Button
                  type="link"
                  onClick={() => $crisp.push(["do", "chat:open"])}
                >
                  Contact our support agent
                </Button>
                <Button onClick={() => window.location.reload()}>
                  Reload Page
                </Button>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      {renderLoadingState(loadingState)}
    </div>
  );
}

export default UnauthorisedApp;
