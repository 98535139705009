import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import List from "../../../../components/List";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../components/TabRadioButton";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { FieldArray } from "formik";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import toaster from "toasted-notes";
import ProfitCalculator from "./ProfitCalculator";

const Rewards = (props) => {
  const workspace = useWorkspace();
  const [selectedStage, setSelectedStage] = useState(0);
  const dimensionOptions = [
    { label: "%", value: "percentage" },
    { label: `${workspace.data.storeCurrency}`, value: "fixedAmount" },
  ];

  const defaultDiscountConfig = {
    id: Math.floor(Math.random() * 10000),
    rewardTarget:
      props.values.reward_stage.length > 0
        ? props.values.reward_stage[props.values.reward_stage.length - 1]
            .rewardTarget + 3
        : 1,
    discountConfig: {
      discountType:
        props.values.reward_stage.length > 1
          ? props.values.reward_stage[props.values.reward_stage.length - 1]
              .discountConfig.discountType
          : "percentage",
      discountValue:
        props.values.reward_stage.length > 1
          ? props.values.reward_stage[props.values.reward_stage.length - 1]
              .discountConfig.discountValue - 5
          : 10,
      targetType: props.values.gift_config.targetType,
      minimumRequirementType: props.values.gift_config.minimumRequirementType,
      targetItems: {
        productIds: props.values.gift_config.targetItems.productIds,
        collectionIds: props.values.gift_config.targetItems.collectionIds,
      },
      minimumRequirementValue: props.values.gift_config.minimumRequirementValue,
    },
  };

  return (
    <div className="w-full min-h-full flex">
      <FieldArray
        name="reward_stage"
        render={(arrayHelpers) => (
          <>
            <div className="border-r max-w-xs">
              <div className="desktop:p-5 p-3">
                <h3 className="mb-1 font-bold text-base desktop:text-lg">
                  Reward Stages
                </h3>
                <small className="leading-tight text-gray-500 text-sm desktop:text-tiny">
                  {" "}
                  Rewards are unlocked by visitors when their friends buy using
                  the coupons they gift.
                </small>
              </div>
              <List
                items={props.values.reward_stage}
                emptyState={
                  <div className="p-8">
                    <p>No rewards found</p>
                  </div>
                }
                render={(item, index) => {
                  return (
                    <div
                      key={index}
                      className={`px-3 text-left m-0 w-full focus:outline-none flex items-center justify-between ${
                        selectedStage === index &&
                        "border-l-4 border-violet-700 "
                      }`}
                    >
                      <button
                        className="desktop:py-5 py-3 text-left grow focus:outline-none"
                        onClick={() => setSelectedStage(index)}
                      >
                        <p
                          className={`font-bold text-tiny desktop:text-base ${
                            selectedStage === index
                              ? "text-violet-700"
                              : "text-gray-700"
                          }`}
                        >
                          Stage {index + 1}
                        </p>
                        <small
                          className={`text-sm desktop:text-tiny  ${
                            selectedStage === index
                              ? "text-gray-600"
                              : "text-gray-400"
                          }`}
                        >
                          Unlocked on <b>{item.rewardTarget}</b> referral sales
                        </small>
                      </button>
                      {selectedStage === index && index !== 0 && (
                        <button
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setSelectedStage(index - 1);
                          }}
                          className="text-gray-700 p-3 border border-white rounded-full hover:text-red-500 hover:bg-white hover:border-100 hover:border-1 hover:border-red-200"
                        >
                          <RiDeleteBinLine />
                        </button>
                      )}
                    </div>
                  );
                }}
              />
              <button
                onClick={() => {
                  arrayHelpers.push(defaultDiscountConfig);
                }}
                className="w-full desktop:py-4 py-3 font-bold bg-indigo-100 text-violet-700 bg-opacity-50 hover:bg-opacity-100 hover:text-gray-700  text-tiny desktop:text-base "
              >
                Add a stage
              </button>
            </div>
            <div className="w-full ">
              {props.values.reward_stage.length > 0 && (
                <div className="desktop:py-3 desktop:px-8  py-0 px-4">
                  <CustomiserUnit
                    title="Target referral conversions "
                    description="The number of people required to unlock this reward stage"
                  >
                    <div className="w-20">
                      <Textfield
                        key={`${selectedStage}-reward-target`}
                        value={
                          props.values.reward_stage[selectedStage].rewardTarget
                        }
                        onChange={(onChangeEvent) => {
                          if (onChangeEvent.target.value < 1) {
                            toaster.notify("The value must be greater than 0", {
                              duration: 2000,
                            });
                          }
                          // The following condition makes sure the value doesnt increase than the next stage
                          else if (
                            props.values.reward_stage.length >
                              selectedStage + 1 &&
                            onChangeEvent.target.value >=
                              props.values.reward_stage[selectedStage + 1]
                                .rewardTarget
                          ) {
                            toaster.notify(
                              `The value must be lower than the referral target of the next stage`,
                              {
                                duration: 2000,
                              }
                            );
                          }
                          // The following condition makes sure the value doesnt decrease than the previous stage
                          else if (
                            selectedStage > 0 &&
                            onChangeEvent.target.value <=
                              props.values.reward_stage[selectedStage - 1]
                                .rewardTarget
                          ) {
                            toaster.notify(
                              "The value must be greater than the referral target of the previous stage",
                              {
                                duration: 2000,
                              }
                            );
                          }
                          // And finally if both of the condition above fail the value is set
                          else {
                            props.setFieldValue(
                              `reward_stage[${selectedStage}].rewardTarget`,
                              parseInt(onChangeEvent.target.value, 10)
                            );
                          }
                        }}
                        min={1}
                        type="number"
                        name="basic"
                      />
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Discount Value"
                    description="Choose the value for the reward coupon"
                  >
                    <div className="flex items-center">
                      <div className="w-20">
                        <Textfield
                          key={`${selectedStage}-discount-value`}
                          value={
                            props.values.reward_stage[selectedStage]
                              .discountConfig.discountValue * -1
                          }
                          onChange={(onChangeEvent) => {
                            if (onChangeEvent.target.value < 1) {
                              toaster.notify(
                                "The value must be greater than 0 ",
                                {
                                  duration: 2000,
                                }
                              );
                            } else if (
                              onChangeEvent.target.value > 100 &&
                              props.values.reward_stage[selectedStage]
                                .discountConfig.discountType === "percentage"
                            ) {
                              toaster.notify(
                                "You cannot give a discount more than 100%",
                                {
                                  duration: 2000,
                                }
                              );
                            } else {
                              props.setFieldValue(
                                `reward_stage[${selectedStage}].discountConfig.discountValue`,
                                parseInt(onChangeEvent.target.value, 10) * -1
                              );
                            }
                          }}
                          type="number"
                          name="basic"
                          min={1}
                        />
                      </div>
                      <Select
                        isSearchable={false}
                        value={dimensionOptions.find(
                          (option) =>
                            option.value ===
                            props.values.reward_stage[selectedStage]
                              .discountConfig.discountType
                        )}
                        onChange={(onChangeEvent) => {
                          // The following condition makes sure the discountValue doesnt exceeds 100 when switching from fixedAmount to percentage. If it exceded discount value is set to 20% by default
                          if (
                            props.values.reward_stage[selectedStage]
                              .discountConfig.discountValue *
                              -1 >
                              100 &&
                            onChangeEvent.value === "percentage"
                          ) {
                            props.setFieldValue(
                              `reward_stage[${selectedStage}].discountConfig.discountValue`,
                              -20
                            );
                          }
                          props.setFieldValue(
                            `reward_stage[${selectedStage}].discountConfig.discountType`,
                            onChangeEvent.value
                          );
                        }}
                        className="w-24 "
                        isSearchable={false}
                        options={dimensionOptions}
                      />
                    </div>
                  </CustomiserUnit>
                  <ProfitCalculator
                    {...props.values}
                    selectedStage={selectedStage}
                  />
                </div>
              )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default Rewards;
