import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

/**
 * This component renders supported merge tags
 * @param {Object} tags array of tags and the explanation
 */
const MergeTags = ({ tags }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border max-w-max rounded-md bg text-sm px-3 py-1 mb-3">
      <button
        className="w-full flex item-center justify-between"
        onClick={() => {
          setIsExpanded((prevState) => !prevState);
        }}
      >
        <p className=" font-bold"> Supported Tags</p>
        <p className=" font-bold flex items-center">
          <RiArrowDropDownLine
            size="18"
            className={`ml-1 transition-all rotate-0 ${
              isExpanded && "rotate-180"
            }`}
          />{" "}
        </p>
      </button>
      {isExpanded && (
        <div className="mt-2">
          {tags.map((item) => (
            <div className="mb-2">
              <span className="font-mono font-bold mr-2 bg-gray-200 px-2 py-1 rounded-md">
                {item.tag}
              </span>:
              {item.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MergeTags;
