/* --------------- Queries ------------------- */
export const queryWidgetSettings = `
  query queryWidgetSettings{
    workspaces {
      source {
        default_language
        tracking_pixels
      }
    }
    widgets_cornerwidget {
      id
      common_config
      launcher_config
      faq_config
      corner_lang
      faq_lang
      offer_widget_lang
      offer_page_lang
      cart_config
      cart_launcher_config
      corner_cart_lang
    }
      accounts{
    id
  }
  }
`;


export const queryDefaultWidgetConfigs = `
  query queryDefaultWidgetConfigs{
    admin_config_templates {
      name
      app
      value
      version
    }
  }
`;
export const queryCornerConvertWidgets = `
  query queryCornerConvertWidgets{
    workspaces {
      source {
        default_language
      }
    }
    widget_stickybar {
      id
      is_active
      stickybar_config
      sticky_atc_lang
    }
  }
`;

export const queryAllLangObjects = `
query queryAllLangObjects{
  widgets_cornerwidget {
    id
    corner_cart_lang
    corner_lang
    faq_lang
    offer_page_lang
    offer_widget_lang
  }
  widget_stickybar {
    id
    sticky_atc_lang
  }
  workspaces {
    source {
      default_language
      translated_languages
    }
  }
}
`;

/* --------------- Mutations ------------------- */
export const updateWidgetSettings = `
  mutation updateWidgetSettings ($widgetSettingsId:widgets_cornerwidget_pk_columns_input!, $widgetSettingsInput:widgets_cornerwidget_set_input){
    update_widgets_cornerwidget_by_pk(pk_columns: $widgetSettingsId, _set:$widgetSettingsInput) {
      id
    }
  }
`;

export const updatCornerStickybarWidget = `
  mutation updatCornerStickybarWidget ($stickyBarId:widget_stickybar_pk_columns_input!, $stickyBarInput:widget_stickybar_set_input){
    update_widget_stickybar_by_pk(pk_columns: $stickyBarId, _set:$stickyBarInput) {
      id
    }
  }
`;
