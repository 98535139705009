import React from "react";
import crispInitialiser from "./components/crispInitialiser";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    //any extra debug info
    window.Rollbar.error("ErrorBoundary caught an error: ", error);
  }

  componentDidMount() {
    crispInitialiser();
  }

  render() {
    const { hasError } = this.state;
    {
      if (hasError) {
        return (
          <>
            <div className="w-screen h-screen flex items-center justify-center">
              <div className="text-center">
                <div className=" flex items-center justify-center">
                  <img
                    src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/1a1dc30c-ce14-45df-0f4f-702c9fc48d00/w=300"
                    alt=""
                  />
                </div>
                <h2 className="text-violet-700 font-bold text-xl">
                  Whooops, something just didn't go as planned.
                </h2>
                <p>
                  Please do try logging in again, and if the error persists
                  please do get in touch with us via chat.
                </p>
              </div>
            </div>
          </>
        );
      }
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
