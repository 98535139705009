import React, { useState } from "react";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import Button from "../Button";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import getCocaPricingDetails from "./getCocaPricingDetails";

/**
 * Component for rendering fixed pricing details
 * @param {Function} onSelectFreePlan  what happens when user selects free plan
 * @param {Function} onSelectProPlan  what happens when user selects pro plan
 * @param {Boolean} isProPlanLoading triggers the loading state in Pro Select Button
 * @param {Boolean} isFreePlanLoading triggers the loading state in Free Select Button
 */
const FixingPricingTable = ({
  onSelectFreePlan,
  onSelectProPlan,
  isProPlanLoading,
  isFreePlanLoading,
  currentPlanType,
}) => {
  const workspace = useWorkspace();

  const cocaFeatures = [
    {
      feature: "Cart Drawer",
      isAvailableInFree: true,
    },
    {
      feature: "Sticky Add to Cart Bar",
      isAvailableInFree: true,
    },
    {
      feature: "Campaigns",
      isAvailableInFree: false,
    },
    {
      feature: "Automations",
      isAvailableInFree: false,
    },
    {
      feature: "Priority Support",
      isAvailableInFree: false,
    },
  ];

  const renderProSelectPlan = () => {
    return (
      <div className="flex flex-col justify-center py-6 px-4">
        <p className="text-lg text-violet-700 font-bold mb-1">Pro Plan</p>
        <p className="text-gray-800 mb-2">
          <span className="font-bold mr-1">{`$ ${getShopifyPriceValue()}`}</span>
          <span className="font-normal">/ month</span>
        </p>
        {currentPlanType === "recurring_charge" ? (
          <div className="text-center text-violet-600 rounded-lg py-2 px-4">
            Current plan
          </div>
        ) : (
          <Button
            type="primary"
            loading={isProPlanLoading}
            className="self-center desktop:px-10 laptop:px-5"
            onClick={() => onSelectProPlan()}
          >
            Select
          </Button>
        )}
      </div>
    );
  };

  const renderFreeSelectPlan = () => {
    return (
      <div className="flex flex-col justify-center py-6 px-4">
        <p className="text-lg text-violet-700 font-bold mb-1">Free Plan</p>
        <p className="text-gray-800 mb-2">
          <span className="font-bold mr-1">{`$ 0`}</span>
          <span className="font-normal">/ month</span>
        </p>
        {currentPlanType === "free_plan" ? (
          <div className="text-center text-violet-600 rounded-lg py-2 px-4">
            Current plan
          </div>
        ) : (
          <Button
            type="primary"
            loading={isFreePlanLoading}
            className="self-center desktop:px-10 laptop:px-5"
            onClick={() => onSelectFreePlan()}
          >
            Select
          </Button>
        )}
      </div>
    );
  };

  const renderFixedPricingRow = (feature, isAvailableInFree, index) => {
    return (
      <tr className={index % 2 === 0 ? "bg-gray-100" : "bg-white"} key={index}>
        <td className="px-4 py-3">{feature}</td>
        <td className="">
          {isAvailableInFree ? (
            <span className="flex justify-center text-violet-500">
              <RiCheckboxCircleFill size={24} color={"text-violet-500"} />
            </span>
          ) : (
            <span className="flex justify-center text-gray-400">
              <RiCloseCircleFill size={24} />
            </span>
          )}
        </td>
        <td className="">
          <span className="flex justify-center text-violet-500">
            <RiCheckboxCircleFill size={24} />
          </span>
        </td>
      </tr>
    );
  };

  const getShopifyPriceValue = () => {
    const currentPlanDetails = getCocaPricingDetails(
      workspace.data.shopifyPlanDetails.planDisplayName
    );
    if (currentPlanDetails.pricing !== "Free") {
      return currentPlanDetails.pricing;
    } else {
      // if the plan pricing is free, ie if the shopify plan is developer preview or trial etc
      // return basic pricing plan pricing
      return `${getCocaPricingDetails("Basic Shopify").pricing}`;
    }
  };

  return (
    <div className="w-full border rounded-lg">
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th></th>
            <th className="w-1/3">{renderFreeSelectPlan()}</th>
            <th className="w-1/3">{renderProSelectPlan()}</th>
          </tr>
        </thead>
        <tbody>
          {cocaFeatures.map((cocaFeature, index) => {
            return renderFixedPricingRow(
              cocaFeature.feature,
              cocaFeature.isAvailableInFree,
              index
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FixingPricingTable;
