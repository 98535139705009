import React, { useState } from "react";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "../../../../components/Button";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  RiFileCopy2Fill,
  RiArrowRightUpFill,
  RiPlayCircleLine,
} from "react-icons/ri";

const FaqPage = (props) => {
  const workspace = useWorkspace();
  const [isTutModalOpen, setIsTutModalOpen] = useState(false);
  const [isVidModalOpen, setIsVidModalOpen] = useState(false);

  return (
    <div className="desktop:mx-8 mobile:mx-4 p-3 desktop:p-8 bg-indigo-100 bg-opacity-50 rounded-lg mt-8 overflow-y-auto ">
      <div className="mobile:block laptop:flex items-center">
        <button
          onClick={() => setIsVidModalOpen(true)}
          className=" relative desktop:w-64 laptop:w-56 mobile:w-52 mb-3 shrink-0 desktop:m-4 hover:opacity-90   hover:shadow-lg transition-all focus:outline-none"
        >
          <div className="absolute left-0 top-0 w-full h-full text-6xl text-white bg-gray-700  bg-opacity-50 opacity-10 rounded-lg transition-all hover:opacity-100 flex justify-center items-center">
            <RiPlayCircleLine />
          </div>
          <img
            className="rounded-lg"
            src="https://assets.cornercart.io/coca-v2/images/video-thumbnails/hiw-faq-page.png"
            alt=""
          />
        </button>
        <div>
          <h1 className="font-bold mb-1 desktop:mb-4 text-gray-700 desktop:text-2xl text-xl ">
            Add FAQ Page to your store
          </h1>
          <p className=" text-gray-700 text-tiny desktop:text-base">
            Watch this tutorial video or click on the link below to learn how to
            add the link to the FAQ page to your Shopify store (in the main
            menu)
          </p>
          <div className="mt-3">
            <Button
              type="primary"
              className="mr-3 mt-2"
              onClick={() => setIsTutModalOpen(true)}
            >
              Learn how to add FAQ page to your store
            </Button>
          </div>
        </div>
      </div>
      <ModalTransition>
        {isTutModalOpen && (
          <Modal width={840} onClose={() => setIsTutModalOpen(false)}>
            <div className="h-full flex flex-col">
              <div className="shrink p-6 overflow-auto">
                <h2 className="text-base font-bold mb-4">
                  Add FAQ page to Shopify store
                </h2>
                <p>
                  In this short step-by-step tutorial, we will see how to setup
                  the link to your brand new FAQ page in your Shopify store. As
                  you can see, the FAQ page is already ready for you at the FAQ
                  Page URL below. You can see it by copying the URL below and
                  pasting in a new browser tab. All we need to do is add a link
                  to the FAQ Page to the navigation menu in your Shopify store.
                  So let's start!
                </p>
                <div className="mt-14">
                  <h2 className="font-bold mb-1 text-gray-700">
                    Step 1 : Copy FAQ page URL
                  </h2>
                  <p>
                    Copy the following FAQ page url to your clipboard by
                    clicking on it
                  </p>
                  <Button
                    icon={<RiFileCopy2Fill />}
                    type="ghost"
                    className="mt-5"
                    onClick={() => {
                      navigator.clipboard.writeText(props.url);
                      toaster.notify("URL copied ", {
                        duration: 1000,
                      });
                    }}
                  >
                    {`${props.url}`}
                  </Button>
                </div>

                <div className="mt-14">
                  <h2 className="font-bold text-gray-700">
                    Step 2: Go to the "Navigations" setting in your Shopify
                    theme
                  </h2>
                  <p>
                    Click the button below to visit the your store's navigations
                    admin page. (This page can be manually accesed by going to
                    your Shopify admin ▶ Online Store ▶ Navigations)
                  </p>
                  <Button
                    icon={<RiArrowRightUpFill />}
                    type="ghost"
                    className="mt-5"
                  >
                    <a href={`https://${workspace.data.storeUrl}/admin/menus`}>
                      Go to Shopify Navigations Page
                    </a>
                  </Button>
                </div>

                <div className="mt-14">
                  <h2 className="font-bold text-gray-700">
                    Step 3 : Add the FAQ page link to one or more than one menus
                  </h2>
                  <p className="my-3">
                    <b>(i)</b> Select a menu to which you wish to add the link
                    to the FAQ page to
                  </p>
                  <p className="my-3">
                    <b>(ii)</b>Once you get inside, click the{" "}
                    <b>Add menu Item</b> button.
                  </p>
                  <img
                    src="https://assets.cornercart.io/coca-v2/images/tutorial/faq-page-add/add-menu.png"
                    width="75%"
                    alt=""
                  />
                  <p className="my-3">
                    <b>(iii)</b>Give a name and paste the link that we had
                    copied earlier to the link box as shown below.
                    <b>
                      <i>
                        {" "}
                        IMPORTANT: You have to click on the link after pasting
                        it for it to be accepted 🤷‍♂️
                      </i>
                    </b>
                  </p>
                  <img
                    src="https://assets.cornercart.io/coca-v2/images/tutorial/faq-page-add/paste-link.png"
                    width="75%"
                    alt=""
                  />

                  <p className="my-3">
                    <b>(iv)</b>Press <b>SAVE button</b>.
                  </p>
                  <p className="my-3">
                    That's it! Your store visitors will now be able to see a
                    link to the FAQ Page on your Shopify store 🙌
                  </p>
                </div>
              </div>
              <div className="flex p-4 border-t justify-end">
                <Button onClick={() => setIsTutModalOpen(false)} type="primary">
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {isVidModalOpen && (
          <Modal onClose={() => setIsVidModalOpen(false)}>
            <div className="p-6 overflow-y-auto">
              <h2 className="text-base font-bold mb-4">
                Add FAQ page to Shopify store
              </h2>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/8rw8z2a_5Eg"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="flex justify-end mt-4">
                <Button onClick={() => setIsVidModalOpen(false)} type="primary">
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default FaqPage;
