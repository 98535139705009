import React, { useState, useEffect } from "react";
import {
  RiSmartphoneLine,
  RiSmartphoneFill,
  RiComputerFill,
  RiComputerLine,
  RiFullscreenFill,
  RiInformationLine,
} from "react-icons/ri";

import { useWorkspace } from "../../../contexts/WorkspaceContext";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Button from "../../../components/Button";
import TakeOverDrawer from "../../../components/TakeOverDrawer";

/**
 * A preview window for showcasing widgets. The config is passed to the iframe messages. Inside the each widget there is a event lisner listening to these messages. And the config is updated when ever a mesage  is triggered from here
 * @component
 * @param {Object} cartConfig - The cart Config object . If left blank, the values will be fetched from the db
 * @param {Object} cartLauncherConfig - The cart launcher config. If left blank, the values will be fetched from the db
 * @param {Object} stickybarConfig - The sticky bar config. If left blank, the values will be fetched from the db
 * @param {Object} cornerCartWidgetLang - cart widget lang config. If left blank, the values will be fetched from the db
 * @param {Object} stickyAtcWidgetLang - sticky atc widget lang. If left blank, the values will be fetched from the db
 * @param {String} previewLang - the languge which is to be showed inside the previe. If left blank, the values will be default language
 * @param {boolean} noLimitPreview - if this value is true every feature will be visible in preview
 * @param {boolean} hideExpand - if this value is true tyhe option to hide expand button will be removed
 */
const WidgetPreview = ({
  cartConfig,
  cartLauncherConfig,
  stickybarConfig,
  cornerCartWidgetLang,
  stickyAtcWidgetLang,
  previewLang,
  noLimitPreview,
  isOpen = false,
  hideExpand = false,
}) => {
  const workspace = useWorkspace();
  const [previewMode, setPreviewMode] = useState("desk");
  const [basicConfig, setBasicConfig] = useState();
  const [isExpandedViewVisible, setIsExpandedViewVisible] = useState(false);

  const defaultFeatureflag = {
    remove_corner_branding: true,
    multi_language_support: true,
    dev_options: true,
    fb_pixel: true,
    ga_pixel: true,
    stickybar: {
      stickybar_custom_css: true,
      stickybar_themes: true,
      stickybar_on_any_page: true,
      stickybar_mobile_specific_customisations: true,
    },
    cornerwidget: {
      cornerwidget_custom_css: true,
      cornerwidget_advanced_color_customisation: true,
      cornerwidget_banner_image: true,
      cornerwidget_related_products: true,
      cornerwidget_optional_note: true,
      cornerwidget_custom_attributes: true,
    },
  };
  useEffect(() => {
    const url = `${process.env.REACT_APP_STARLINK_URL}/corner/config/${workspace.data.storeMyShopifyUrl}/conf.json`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setBasicConfig(json.json_build_object);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  let previewConfig = basicConfig && {
    ...basicConfig,
    general_config: {
      ...basicConfig.general_config,
      currencyFormat: "<span class=money>${{amount}} USD</span>",
      featureFlag: {
        apps: {
          cornercart: noLimitPreview
            ? defaultFeatureflag
            : {
                ...defaultFeatureflag,
                ...basicConfig.general_config.featureFlag.apps.cornercart,
              },
        },
      },
    },

    cornercart: {
      cartLauncherConfig: cartLauncherConfig
        ? { ...cartLauncherConfig }
        : { ...basicConfig.cornercart.cartLauncherConfig },
      cartConfig: cartConfig
        ? { ...cartConfig }
        : { ...basicConfig.cornercart.cartConfig },
      stickybarConfig: stickybarConfig
        ? { ...stickybarConfig }
        : basicConfig.cornercart.stickybarConfig,
      langConfig: {
        cornerCartWidget: cornerCartWidgetLang
          ? { version: 1, ...cornerCartWidgetLang }
          : basicConfig.cornercart.langConfig.cornerCartWidget,
        stickyAtcWidget: stickyAtcWidgetLang
          ? { version: 1, ...stickyAtcWidgetLang }
          : basicConfig.cornercart.langConfig.stickyAtcWidget,
      },
    },
  };

  const triggerPreviewConfigChange = () => {
    if (document.getElementById(`preview-iframe`)) {
      var previewIframe = document.getElementById("preview-iframe");
      previewIframe.contentWindow.postMessage(
        JSON.stringify(previewConfig),
        "*"
      );
    }
  };

  useEffect(() => {
    triggerPreviewConfigChange();
  }, [
    cartConfig,
    cartLauncherConfig,
    stickybarConfig,
    cornerCartWidgetLang,
    stickyAtcWidgetLang,
  ]);

  if (basicConfig) {
    return (
      <div>
        <div className="origin-top scale-50 desktop:scale-70 ">
          <div
            style={{
              height: "652px",
              width: previewMode === "desk" ? "713px" : "350px",
            }}
            className={` bg-white shadow-lg rounded-lg overflow-hidden flex flex-col  transition-all `}
          >
            <div className="w-full h-8 bg-gray-100 flex pt-1">
              <div className="flex h-full items-center mb-2">
                <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
                <div className="w-2 h-2 mr-2 rounded-full bg-amber-400" />
                <div className="w-2 h-2 mr-2 rounded-full bg-violet-400" />
              </div>
              <div className="flex items-end ml-3">
                <div className="bg-white w-40 h-4/5 rounded-t-md" />
              </div>
            </div>

            <div className="flex border-b p-3 shadow-sm ">
              <button
                onClick={() => setPreviewMode("desk")}
                className={`flex text-lg desktop:text-xs items-center border rounded-l-full py-1 pl-2 pr-2 focus:outline-none ${
                  previewMode === "desk" && "border-violet-700 text-violet-700"
                }`}
              >
                {previewMode === "desk" ? (
                  <RiComputerFill />
                ) : (
                  <RiComputerLine />
                )}
                <span className="ml-1 ">Desktop</span>
              </button>
              <button
                onClick={() => setPreviewMode("mob")}
                className={`flex text-lg desktop:text-xs items-center border rounded-r-full py-1 pr-2 pl-2 focus:outline-none ${
                  previewMode === "mob" && "border-violet-700 text-violet-700 "
                }`}
              >
                {previewMode === "mob" ? (
                  <RiSmartphoneFill />
                ) : (
                  <RiSmartphoneLine />
                )}
                <span className="ml-1 ">Mobile</span>
              </button>
              <div className="grow py-1 px-4 mx-3 font-bold rounded-full border text-lg desktop:text-xs">
                Preview
              </div>
              {!hideExpand &&
              <button
                onClick={() => {
                  setIsExpandedViewVisible((prevState) => !prevState);
                  window.toggleScreenState("expand");
                }}
                className="border border-violet-600 rounded-full px-3 text-violet-700 hover:bg-violet-600 hover:text-white flex transition-all items-center focus:outline-none"
              >
                <span className="text-md">
                  <RiFullscreenFill />{" "}
                </span>
                {previewMode === "desk" && (
                  <span className="ml-2 font-bold text-lg desktop:text-md ">
                    Expand Preview
                  </span>
                )}
              </button>
              }
            </div>
            <div id="iframe-wrapper" className="flex flex-col w-full relative">
              {!isExpandedViewVisible && (
                <iframe
                  id={`preview-iframe`}
                  name={`preview-iframe`}
                  className="transition-all "
                  onLoad={() => {
                    setTimeout(triggerPreviewConfigChange, 500);
                  }}
                  style={{ transform: "scale(.66)", transformOrigin: "0% 0%" }}
                  width={previewMode === "desk" ? "1080px" : "530px"}
                  height="890px"
                  src={`${process.env.REACT_APP_WIDGET_PREVIEW_LINK}${
                    isOpen ? "&openCart" : ""
                  }&lang=${
                    previewLang ? previewLang : workspace.data.default_language
                  }`}
                  title="Thank you page"
                />
              )}
            </div>
          </div>
        </div>
        <TakeOverDrawer
          title={
            <div className="flex items-center">
              <span className="ml-3 flex">Widget Preview</span>
            </div>
          }
          actions={
            <div className="flex items-center">
              {" "}
              <Button
                noPadding
                onClick={() => {
                  window.toggleScreenState("minimize");
                  setIsExpandedViewVisible((prevState) => !prevState);
                }}
                type="link"
              >
                Close preview
              </Button>
            </div>
          }
          isOpen={isExpandedViewVisible}
          coverage="full"
          onClose={() => {
            setIsExpandedViewVisible((prevState) => !prevState);
          }}
        >
          <div className="w-full h-full relative">
            {isExpandedViewVisible && (
              <iframe
                id={`preview-iframe`}
                name={`preview-iframe`}
                className="transition-all absolute top-0 right-0 left-0 bottom-0 w-full h-full"
                onLoad={() => {
                  setTimeout(triggerPreviewConfigChange, 500);
                }}
                // style={{ transform: "scale(.66)", transformOrigin: "0% 0%" }}
                src={`${process.env.REACT_APP_WIDGET_PREVIEW_LINK}${
                  isOpen ? "&openCart" : ""
                }&lang=${
                  previewLang ? previewLang : workspace.data.default_language
                }`}
                title="Thank you page"
              />
            )}
          </div>
        </TakeOverDrawer>
      </div>
    );
  } else return <LoadingSpinner />;
};

export default WidgetPreview;
