import React from "react";
import { RiDeleteBinFill } from "react-icons/ri";

/**
 * This component renders the product details as row
 * @type {function({product,onDelete, className}): JSX}
 * @param {Object} product product
 * @param {Function} onDelete function
 * @param {Object} className extra styles to be added if needed
 */
const ProductDetailsRow = ({
  showImage = true,
  product,
  onDelete,
  className,
}) => {
  const getProductImage = () => {
    if (product.images && product.images[0]) {
      return product.images[0].originalSrc;
    }
  };
  return (
    <div
      className={`flex flex-row justify-between border rounded-lg items-center p-4 ${className}`}
    >
      <div className="flex flex-row items-center">
        {showImage && (
          <img
            alt=""
            src={getProductImage()}
            className="w-12 h-12 rounded-md"
          />
        )}
        <div className="ml-4">
          <p className="text-tiny desktop:text-base">{product.title} </p>
        </div>
      </div>
      <button
        className="text-red-600 text-xl mr-4"
        onClick={() => {
          if (onDelete) {
            onDelete();
          }
        }}
      >
        <RiDeleteBinFill />
      </button>
    </div>
  );
};

export default ProductDetailsRow;
