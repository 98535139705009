/* -------------- Queries ---------- */

export const queryBillingInfo = `
query queryBillingInfo($appId: ID!,$sourceId: ID!) {
  billingInfo(appInfo: {appId: $appId, sourceId: $sourceId}) {
    planId
    billingStatus
    currentPlan {
      plan_display_name
      billing_type
    }
  }
}
`;

export const queryUsageStats = `
query queryUsageStats($appId: ID!,$sourceId: ID!,$accountId:ID!) {

  getUsageStats(statsInput:{
    accountId:$accountId,
    sourceId:$sourceId,
    appId:$appId
  }) {
    creditAmount
    currentReferralSales
    nextBillingDate
  }
}
`;

/* --------------- Mutations ------------------- */

export const activateFreePlan = `
  mutation activateFreePlan ($appId:ID!, $planId:ID!, $sourceId:ID!){
    activateFreePlan(planInfo: {appId: $appId, planId: $planId, sourceId: $sourceId}) {
      success
    }
  }
`;
export const activateCharge = `
  mutation activateCharge ($chargeId:String!){
    activateCharge(charge: {chargeId: $chargeId}) {
      billingStatus
    }
  }
`;
export const choosePlan = `
  mutation choosePlan ($appId:ID!, $planId:ID!, $sourceId:ID!){
    choosePlan(planInfo: {appId: $appId, planId: $planId, sourceId: $sourceId}) {
      confirmationUrl
    }
  }
`;
