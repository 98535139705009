import React from "react";
import { RiVipCrown2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";

/**
 * An UI element designed to show settings elements.
 * @component
 * @param {String} title - The title
 * @param {String} description - description
 * @param {Node} children - contents of the customiser unitc
 * @param {Boolean} disabled - Whether this particluar customiser unit is accesible or not
 * @param {Boolean} paywall - whether to show paywall or not
 * @param {Number} paywallPlan - Which plan to show on the paywall
 */
const CustomiserUnit = ({
  customiserUnitId = "",
  title,
  description,
  children,
  disabled,
  paywall,
}) => {
  const history = useHistory();

  const redirectToBilling = () => {
    history.push("/settings/Billing");
  };

  return (
    <div
      className={`py-5 ${
        disabled && "opacity-25 pointer-events-none cursor-not-allowed"
      }  max-w-6xl`}
      id={customiserUnitId}
    >
      <div>
        <div className="flex items-center">
          <p className="font-bold text-tiny desktop:text-base">{title}</p>
          {paywall && (
            <p className="font-bold text-xs bg-violet-700 text-white rounded-full px-3 ml-2 flex items-center ">
              <span className="mr-2">
                <RiVipCrown2Fill />
              </span>
              <span>Pro</span>
            </p>
          )}
        </div>
        {description && (
          <small className=" text-gray-700 mt-1 text-sm desktop:text-tiny">
            {description}
          </small>
        )}
      </div>
      {paywall ? (
        <div className=" shadow-sm border my-1 desktop:my-3 rounded-md  flex items-center overflow-hidden">
          <div className=" flex items-center mr-3 text-center self-stretch text-violet-700 p-4 bg-violet-100  ">
            <RiVipCrown2Fill size={22} />
          </div>
          <div className="grow px-4 py-2">
            <p className=" desktop:text-base text-sm  mb-1 text-gray-700">
              This feature is only available in a higher plan
            </p>
            <a
              onClick={() => redirectToBilling()}
              className="font-bold text-violet-700 desktop:text-base text-sm  cursor-pointer transition-all px-0 hover:bg-indigo-100 hover:px-2 py-1 rounded-md "
            >
              Upgrade Now
            </a>
          </div>
        </div>
      ) : (
        <div className="mt-3">
          {disabled ? (
            <div className="bg-gray-200 w-64 h-12 rounded-md " />
          ) : (
            <div>{children}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomiserUnit;
