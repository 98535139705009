import React from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import AudienceRuleBuilder from "../../../../../components/jsonLogicBuilders/AudienceRuleBuilder";

const Settings = ({ values, setFieldValue }) => {
  return (
    <div className=" overflow-y-auto h-full px-8 py-4">
      {/* Audience targeting Rule Builder */}
      <CustomiserUnit
        title="Target an Audience"
        description="Create a rule to define the target audience"
      >
        <AudienceRuleBuilder
          value={values.audience}
          onChange={(updatedJsonLogic) => {
            setFieldValue("audience", { ...updatedJsonLogic });
          }}
          popupAlignment="bottom"
          emptyTitle="Targeting Everyone"
          emptySubtitle="Right now, this campaign is visible to all customers. If you want to show it only to a specific group of customers, add a new targeting rule."
        />
      </CustomiserUnit>
    </div>
  );
};

export default Settings;
