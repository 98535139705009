import React from "react";
import {
  RiCloseLine,
  RiErrorWarningFill,
  RiAlertFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";

/**
 * A component to show alert Banner
 * @param {function} onClose - if available deside what happens on close
 * @param {"info" || "success" || "warning" || "Error" } type - if available deside what happens on close
 * @param {"banner" || "box" } layout - layout of the banner
 * @param {string} message - If this is true an icon with seperators will be visible between each tabs
 * @param {string} title - If this is true an icon with seperators will be visible between each tabs
 * @param {Object} cta - An object containing infgormation on the Cta
 * @param {String} cta.text - text on cta
 * @param {Function} cta.onClick - what happens on cta click
 */
const AlertBanner = ({
  type = "info",
  layout = "banner",
  onClose,
  message,
  title,
  cta,
  styles,
}) => {
  const getTypeStyle = () => {
    switch (type) {
      case "info": {
        return {
          bg: "bg-gray-100",
          text: "text-gray-700",
          border: "border-gray-500",
          icon: <RiErrorWarningFill />,
        };
      }
      case "success": {
        return {
          bg: "bg-green-100",
          text: "text-green-700",
          border: "border-green-500",
          icon: <RiCheckboxCircleFill />,
        };
      }
      case "warning": {
        return {
          bg: "bg-amber-100",
          text: "text-amber-700",
          border: "border-amber-500",
          icon: <RiErrorWarningFill />,
        };
      }
      case "error": {
        return {
          bg: "bg-red-100",
          text: "text-red-700",
          border: "border-red-500",
          icon: <RiAlertFill />,
        };
      }
    }
  };

  if (layout === "banner")
    return (
      <div
        className={`${
          getTypeStyle().bg
        } py-3 px-4 flex flex-row items-center justify-center border-b ${styles}`}
      >
        <div
          className={`mr-2 text-xl mobile:hidden laptop:block ${
            getTypeStyle().text
          }`}
        >
          {getTypeStyle().icon}
        </div>
        <div className="flex">
          {message && (
            <p className="text-tiny">
              {title && <span className="font-bold">{title} : </span>}
              {message}
            </p>
          )}
        </div>
        {cta && (
          <button
            className={`font-bold ml-2 px-2 rounded-lg shrink-0 transition-all hover:bg-white ${
              getTypeStyle().text
            }`}
            onClick={cta.onClick}
          >
            {cta.text}
          </button>
        )}
        {onClose && (
          <button
            className={`font-bold ml-2 px-2 shrink-0 transition-all hover:bg-white `}
            onClick={onClose}
          >
            <RiCloseLine />
          </button>
        )}
      </div>
    );
  else
    return (
      <div
        className={`${getTypeStyle().bg} ${
          getTypeStyle().border
        } p-4 m-2 rounded-lg border flex flex-row items-start `}
      >
        <div
          className={`mt-1 text-2xl mobile:hidden laptop:block ${
            getTypeStyle().text
          }`}
        >
          {getTypeStyle().icon}
        </div>
        <div className="grow ml-4">
          {title && <p className="font-bold ">{title}</p>}
          {message && <p className="text-tiny">{message}</p>}
          {cta && (
            <button
              className={` mt-2 px-2 py-2 text-tiny border shrink-0 font-b rounded-md ${
                getTypeStyle().border
              }`}
              onClick={cta.onClick}
            >
              {cta.text}
            </button>
          )}
        </div>
        {onClose && (
          <button className={`m-3 text-2xl`} onClick={onClose}>
            <RiCloseLine />
          </button>
        )}
      </div>
    );
};

export default AlertBanner;
