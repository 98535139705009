import React from "react";
import { RiCheckboxFill, RiGiftFill } from "react-icons/ri";
import Button from "../../../../../components/Button";

/**
 * cart goal example
 */
const OneClickExample = () => {
  return (
    <div className="flex py-6 px-4 items-center bg-white shadow-lg rounded-lg w-4/5">
      <div className="p-3 mr-2 rounded-full text-violet-700 bg-violet-100">
        <RiGiftFill size={16} />
      </div>
      <div className="text-sm text-gray-700 text-left">
        Add <span className="font-bold text-violet-700">Gift Wrapping</span> to
        this order for <span className="font-bold ">3.99 USD</span>
      </div>
      <div className="text-violet-700 ml-2">
        <RiCheckboxFill size={26} />
      </div>
    </div>
  );
};

export default OneClickExample;
