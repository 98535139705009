import React from "react";

import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./Button";

/**
 * A modal for managing the API Errors
 * @type {function({isOpen, onClose, title, description, handleApiError, secondaryAction }): JSX}
 * @param {Boolean} isOpen Boolean which control whether the modal is open or not
 * @param {Function} onClose What happens on Close
 * @param {String} title heading of the modal
 * @param {String} description description of the Error
 * @param {Function} handleApiError function for managing the Error
 * @param {Node} secondaryAction a secondary action button if there is any
 */
const ErrorModal = ({
  isOpen,
  onClose,
  title,
  description,
  handleApiError,
  secondaryAction,
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal width="x-large" onClose={() => onClose()}>
          <div className="flex flex-col w-full py-16 px-8 overflow-y-auto">
            <div className="flex justify-center">
              <h2 className="text-lg text-gray-900 font-bold">{title}</h2>
            </div>
            <p className="text-base text-gray-800 mt-4">{description}</p>
            <div className="flex justify-end mt-8">
              {secondaryAction}
              {handleApiError && (
                <a
                  className="bg-violet-700 ml-3 hover:bg-violet-600 text-white font-bold laptop:p-2 desktop:p-3 flex  items-center  rounded-md transition duration-200 ease-in-out cursor-pointer focus:outline-none focus:shadow-none"
                  type="primary"
                  onClick={() => handleApiError()}
                >
                  Contact Us
                </a>
              )}
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ErrorModal;
