import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { RiPaintFill, RiCloseLine } from "react-icons/ri";

/**
 * Color Picker Component returns a color object with rgb array, hex code
 * @param {string} color - the rgb value (eg:rgb(255, 255, 255))
 * @param {string} align - Define the alignment of popup
 * @param {function} onChange - the onchange function
 */
const ColorPicker = ({ color, onChange, align }) => {
  const [colorPickerVisibility, setColorPickerVisibility] = useState(false);
  const [foreground, setForeground] = useState("white");
  const calculateForeground = (rgb) => {
    // Based on the R, G, B values foreground color is calculated (either black or white)
    const calculatedForeground =
      rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186
        ? "rgba(0, 0, 0, 0.6)"
        : "white";
    setForeground(calculatedForeground);
  };
  useEffect(() => {
    let rgbArray = color.replace(/[^\d,]/g, "").split(",");
    let rgb = { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2] };
    calculateForeground(rgb);
  }, []);
  return (
    <div className="relative flex flex-row-reverse">
      <button
        onClick={() => setColorPickerVisibility(!colorPickerVisibility)}
        style={{ background: color }}
        className="w-8 h-8 rounded-md flex items-center justify-center border"
      >
        <div style={{ color: `${foreground}` }}>
          <RiPaintFill />
        </div>
      </button>
      {colorPickerVisibility && (
        <div
          className={`absolute z-20 text-center top-0 ${
            align && align === "right" ? "-right-5" : "-left-5"
          }`}
        >
          <button
            onClick={() => setColorPickerVisibility(false)}
            className="fixed h-full w-full top-0 right-0"
          />

          <div className="bg-white z-10 rounded-lg overflow-hidden shadow-lg  flex flex-col ">
            <button
              onClick={() => setColorPickerVisibility(false)}
              className="self-end font-bold p-1 "
            >
              <RiCloseLine />
            </button>
            <ChromePicker
              color={color}
              disableAlpha
              onChange={(color) => {
                calculateForeground(color.rgb);
                onChange(color);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
