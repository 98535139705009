import React, { useState, useEffect } from "react";
import { useGraphQl } from "../../contexts/GraphqlClient";
import { useMutation } from "react-query";
import { activateCharge } from "../../api/billing";
import toaster from "toasted-notes";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import fetchRequestWrapper from "../../utilities/fetchRequestWrapper";
import ErrorModal from "../../components/ErrorModal";
import { nanoid } from "nanoid";
import contactCrisponApiError from "../../utilities/contactCrisponApiError";

function PaymentsLoader() {
  const [invalidChargeID, setInvalidChargeID] = useState(false);
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const urlParams = new URLSearchParams(window.location.search);
  const chargeId = urlParams.get("charge_id");
  const planCode = urlParams.get("plan");
  const [errorKeyRef, setErrorKeyRef] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const chargeActivation = async () => {
    const status = await graphQL(activateCharge, {
      chargeId: chargeId,
    });
    return status;
  };

  const [chargeActivationMutation] = useMutation(chargeActivation, {
    onError: (err) => {
      console.error("Error in chargeActivationMutation ", err);
      window.Rollbar.error("Error in chargeActivationMutation ", err);
      toaster.notify("Redirection Failed, please try logging in again", {
        duration: 4000,
      });
    },
    onSuccess: (data) => {
      if (data.activateCharge.billingStatus === "active")
        window.location.href = "/";
      else
        toaster.notify("Redirection Failed", {
          duration: 2000,
        });
    },
  });

  /**
   * This function calls the corner pricing api to activate the cornercart feature flags
   * and redirects the user back to cornercart home page after confirming the pricing
   */
  const confirmCocaPricingPlanActivation = async () => {
    const confirmCocaPricingRequest = {
      shop: workspace.data.storeMyShopifyUrl,
      subscription_id: chargeId,
      invite_code: "",
      corner_plan_code: planCode,
    };
    const confirmCocaPricing = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/billing/activate/`,
      confirmCocaPricingRequest
    );
    if (confirmCocaPricing.status === "OK") {
      window.location.href = "/";
    } else {
      const apiErrorKey = nanoid(10);
      setErrorKeyRef(apiErrorKey);
      setOpenErrorModal(true);
      window.Rollbar.error("Cornercart billing activate api failed", {
        ...confirmCocaPricing,
        API_ERROR_KEY: apiErrorKey,
      });
    }
  };

  useEffect(() => {
    if (chargeId) {
      if (workspace.data.currentAppID === 3) {
        confirmCocaPricingPlanActivation();
      } else {
        chargeActivationMutation();
      }
    } else {
      setInvalidChargeID(true);
    }
  }, []);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center bg-indigo-100">
        <div className="text-center bg-white shadow-xl p-8 rounded-lg">
          <h1 className="text-6xl animate-bounce -mb-7">💵</h1>
          <h1 className="text-7xl leading-3 mb-14">🤲</h1>
          <h1 className="text-violet-700 font-bold mb-4">
            Updating Payment Status...
          </h1>
          <p className="max-w-lg">
            Congrats! And Thank you for upgrading your plan! You will be
            redirected back to the app shortly.
          </p>
          {invalidChargeID && (
            <p className="mt-3 text-sm text-red-600">
              <b>Error:</b> No charge ID found in the URL
            </p>
          )}
        </div>
      </div>
      <ErrorModal
        isOpen={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        title="Billing Activation Failed"
        description="There was an error while activating your billing plan. Please contact our team so we can help you resolve this."
        handleApiError={() =>
          contactCrisponApiError(
            errorKeyRef,
            `Please help with an issue I'm facing: Billing activation failed for my store.`
          )
        }
      />
    </>
  );
}

export default PaymentsLoader;
