import React, { useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import { useAppBridge } from "@shopify/app-bridge-react";
import ArticlesList from "./faq/ArticlesList";
import ArticleEdit from "./faq/ArticleEdit";
import CustomAccordionList from "./faq/CustomAccordionList";
import CustomAccordionEdit from "./faq/CustomAccordionEdit";
import ProductAccordionEdit from "./faq/ProductAccordionEdit";
import CategoryList from "./faq/CategoryList";
import CategoryEdit from "./faq/CategoryEdit";
import OpenedState from "./widget/OpenedState";
import ClosedState from "./widget/ClosedState";
import FaqPage from "./faq/FaqPage";
import { useWorkspace } from "../../contexts/WorkspaceContext";

export default function AssistantRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 1)
      workspace.data.changeCurrentAppID(1);
  }, [workspace]);

  // const app = useAppBridge();

  return (
    <>
      <Switch>
        <Route exact path={`${path}/faq/articles/`} component={ArticlesList} />
        <Route
          exact
          path={`${path}/faq/articles/:id/edit`}
          component={ArticleEdit}
        />
        <Route
          exact
          path={`${path}/faq/categories/`}
          component={CategoryList}
        />
        <Route
          exact
          path={`${path}/faq/categories/:id`}
          component={CategoryEdit}
        />
        <Route
          exact
          path={`${path}/faq/custom-accordions/`}
          component={CustomAccordionList}
        />
        <Route
          exact
          path={`${path}/faq/product-accordion/`}
          component={ProductAccordionEdit}
        />
        <Route
          path={`${path}/faq/custom-accordions/:id`}
          component={CustomAccordionEdit}
        />
        <Route
          exact
          path={`${path}/widget/close-state/`}
          component={ClosedState}
        />
        <Route
          exact
          path={`${path}/widget/open-state/`}
          component={OpenedState}
        />
        <Route exact path={`${path}/faq/page`} component={FaqPage} />
        <Route exact path={`${path}/widget/launcher`} component={FaqPage} />
        <Route path={`${path}/faq/`}>
          <Redirect to={`/support/faq/articles/`} />
        </Route>
        <Route exact path={path}>
          <Redirect to={`/support/faq/articles/`} />
        </Route>
      </Switch>
    </>
  );
}
