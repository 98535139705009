import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useBlock } from "@dopt/react";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import {
  RiEdit2Fill,
  RiArrowGoForwardFill,
  RiMoneyDollarCircleFill,
  RiListSettingsFill,
  RiTranslate,
  RiLoader3Line,
} from "react-icons/ri";
import Textfield from "@atlaskit/textfield";
import TabRadioButton from "../../../../components/TabRadioButton";
import Button from "../../../../components/Button";
import { Formik } from "formik";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useParams } from "react-router-dom";
import InlineEdit from "@atlaskit/inline-edit";
import {
  queryCampaignBuyXGetYById,
  queryHighestCampaignKey,
  updateBXGYCampaignDetails,
} from "../../../../api/campaign";
import toaster from "toasted-notes";
import CampaignPauseWarning from "../../../../components/CampaignPauseWarning";
import HorizontalTabs from "../../../../components/HorizontalTabs";
import Rewards from "./Rewards";
import Settings from "./Settings";
import Content from "./Content";
import getJsonLogic from "./getJsonLogic";
import generateShopifyConfig from "./generateShopifyConfig";
import useCompleteDoptFlow from "../../../../utilities/useCompleteDoptFlow";
import { nanoid } from "nanoid";
import ErrorModal from "../../../../components/ErrorModal";
import contactCrisponApiError from "../../../../utilities/contactCrisponApiError";
import { visibilityOptions } from "../../../../defaultValues/campaign";
import fetchRequestWrapper from "../../../../utilities/fetchRequestWrapper";
import CampaignPaywall from "../../../../components/CampaignPaywall";

export default function BuyXGetY() {
  const graphQL = useGraphQl();
  const params = useParams();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const { finishFlow } = useCompleteDoptFlow();

  const [validationFailed, setValidationFailed] = useState(false);
  const [currentTab, setCurrentTab] = useState("rewards");
  const [openSyncErrorModal, setOpenSyncErrorModal] = useState(false);
  const [campaignErrModal, setCampaignErrModal] = useState(false);
  const [errorKeyRef, setErrorKeyRef] = useState("");
  const [showRetryButton, setShowRetryButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [shopifyErrInfo, setshopifyErrInfo] = useState("");
  const [currentCampaignKey, setCurrentCampaignKey] = useState(0);

  const { data, refetch } = useQuery(
    "queryCampaignBuyXGetYById",
    () => graphQL(queryCampaignBuyXGetYById, { id: params.id }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: campaignWithHighestKey } = useQuery(
    "queryHighestCampaignKey",
    () => graphQL(queryHighestCampaignKey)
  );

  // this useEffect manages the campaign key
  // checks if the campaign key is in the table, then save it to the state
  // else if the campaign are old ones, the campaign key will be "0", generate new campaign key
  // and save it on update
  useEffect(() => {
    if (data) {
      const campaignKey = data.campaign_by_pk.key;
      if (campaignKey === 0) {
        if (campaignWithHighestKey) {
          if (campaignWithHighestKey.campaign.length === 0) {
            // ideally this should not happen.
            // either there is a campaign with properkeys
            // or it should have intial value as 0
            setCurrentCampaignKey(0);
          } else {
            const highestCampaignKey = campaignWithHighestKey.campaign[0]?.key;
            const newCampaignKey = parseInt(highestCampaignKey) + 1;
            setCurrentCampaignKey(newCampaignKey);
          }
        }
      } else {
        setCurrentCampaignKey(campaignKey);
      }
    }
  }, [data, campaignWithHighestKey]);

  const triggerCampaignAPI = async (type, campaignTriggerRqst) => {
    const isCampaignTriggered = await fetchRequestWrapper(
      `${process.env.REACT_APP_REST_API_URL}/app/cornercart/campaign/${type}`,
      campaignTriggerRqst
    );
    if (isCampaignTriggered.status !== "OK") {
      const apiErrorKey = nanoid(10);
      setErrorKeyRef(apiErrorKey);
      const { errorDetails } = isCampaignTriggered;
      // checking to see if the error is caused by existing title name
      if (
        errorDetails &&
        errorDetails.reason &&
        errorDetails.reason.includes("Title must be unique")
      ) {
        setshopifyErrInfo(
          "Please change the name of the campaign and try saving the campaign again. if the issue is still there please contact our support team"
        );
        setCampaignErrModal(true);
      }
      // checking to see if the error is caused by minimun spend < least price item
      else if (
        errorDetails &&
        errorDetails.reason &&
        errorDetails.reason.includes(
          "the same as the cheapest item customers need to buy"
        )
      ) {
        const regex = /[-+]?[0-9]*\.?[0-9]+/;
        // use regex to find the minumum spend
        const matches = errorDetails.reason.match(regex);

        if (matches) {
          const minAmount = parseInt(matches[0]);
          setshopifyErrInfo(
            `Please make sure that the minumum spend amount to greater than ${minAmount} (the price of the lowest priced item in the selected collection)`
          );
        } else
          setshopifyErrInfo(
            "Please change the minumum spend amount to greater than the least price item in the selected collection"
          );
        setCampaignErrModal(true);
      } else setOpenSyncErrorModal(true);

      window.Rollbar.error(`Cornercart ${type} api failed in BXGY`, {
        ...isCampaignTriggered,
        shopifyStoreURL: workspace.data.storeMyShopify,
        API_ERROR_KEY: apiErrorKey,
      });
    } else {
      refetch();
    }
    setIsLoading(false);
  };

  const updateBXGYCampaign = async (values) => {
    const updatedCampaignDetails = await graphQL(updateBXGYCampaignDetails, {
      campaignId: values.id,
      campaignInput: values.campaignDetails,
      bxgyId: values.bxgyId,
      bxgyInput: values.bxgyDetails,
    });
    refetch();
    return updatedCampaignDetails;
  };

  const [updateBXGYCampaignMutation] = useMutation(updateBXGYCampaign, {
    onSuccess: (updatedData) => {
      if (updatedData && updatedData.update_campaign_by_pk) {
        const { id, status } = updatedData.update_campaign_by_pk;
        const campaignTriggerRequest = {
          campaign_id: parseInt(id),
          shop_url: workspace.data.storeMyShopifyUrl,
        };
        if (status === "not_published") {
          setIsLoading(true);
          triggerCampaignAPI("publish", campaignTriggerRequest);
        }
        if (data.campaign_by_pk.status === "active") {
          setIsLoading(true);
          triggerCampaignAPI("unpublish", campaignTriggerRequest);
        }
      }
      queryCache.invalidateQueries("queryCampaignBuyXGetYById");
      toaster.notify("BXGY updated 🎉", {
        duration: 2000,
      });
    },
  });

  const tabItems = [
    {
      label: "Rewards",
      id: "rewards",
      icon: <RiMoneyDollarCircleFill />,
    },
    {
      label: "Content",
      id: "content",
      icon: <RiTranslate />,
    },
    {
      label: "Settings",
      id: "settings",
      icon: <RiListSettingsFill />,
    },
  ];

  const renderCurrentTab = (values, setFieldValue) => {
    switch (currentTab) {
      case "rewards":
        return (
          <Rewards
            values={values}
            setFieldValue={setFieldValue}
            validationFailed={validationFailed}
            currentCampaignKey={currentCampaignKey}
          />
        );
      case "settings":
        return <Settings values={values} setFieldValue={setFieldValue} />;
      case "content":
        return <Content values={values} setFieldValue={setFieldValue} />;
    }
  };

  const validateCampaign = (formikvalues) => {
    let isCampaignValid = true;
    const { config } = formikvalues.campaign_bxgy_frees[0];
    if (!config.type) isCampaignValid = false;
    // check if products are added in BUYX
    if (
      config.type === "BUYX" &&
      config.customerBuys.items.products.productsToAdd.length === 0
    ) {
      isCampaignValid = false;
    }
    // check if the collection is selected
    if (
      (config.type === "SPENDXINY" || config.type === "BUYXFROMY") &&
      config.customerBuys.items.collections.add.length === 0
    ) {
      isCampaignValid = false;
    }
    if (
      config.type === "SPENDXINY" &&
      config.customerBuys.value.amount === ""
    ) {
      isCampaignValid = false;
    }
    //check for for reward product
    if (config.rewards.rewardStack.length === 0) {
      isCampaignValid = false;
    }

    if (isCampaignValid) setValidationFailed(false);
    else setValidationFailed(true);

    return isCampaignValid;
  };

  const onCampaignErrModalClose = () => {
    setCampaignErrModal(false);
    setshopifyErrInfo("");
  };

  if (data && data.campaign_by_pk)
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data.campaign_by_pk}
          onSubmit={async (values) => {
            if (validateCampaign(values)) {
              const {
                id: bxgyId,
                config,
                content,
                shopify_config,
              } = values.campaign_bxgy_frees[0];
              const prerequisites = getJsonLogic(config);
              const shopifyConfig = generateShopifyConfig(
                values.settings.title,
                config,
                shopify_config,
                values.schedule
              );
              // adding a reward unique id for each reward format `${campaignId}-0`
              const rewardUniqueId = `${values.id}-0`;
              const updatedConfig = {
                ...config,
                rewards: {
                  ...config.rewards,
                  prerequisites: prerequisites,
                  id: rewardUniqueId,
                },
              };

              updateBXGYCampaignMutation({
                bxgyId: { id: bxgyId },
                bxgyDetails: {
                  config: updatedConfig,
                  content: content,
                  shopify_config: shopifyConfig,
                },
                id: { id: values.id },
                campaignDetails: {
                  status:
                    values.status === "active"
                      ? "not_published"
                      : values.status,
                  settings: values.settings,
                  key: currentCampaignKey,
                  schedule: values.schedule,
                },
              });
            } else {
              toaster.notify(
                "Please fix the highlighted issues in rewards section before you continue.",
                {
                  duration: 6000,
                }
              );
              setCurrentTab("rewards");
            }
          }}
        >
          {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
            return (
              <PageViewContainer
                title={
                  <InlineEdit
                    defaultValue={values.settings.title}
                    editView={({ ...fieldProps }) => (
                      <Textfield {...fieldProps} name="basic" />
                    )}
                    readView={() => (
                      <h2 className="flex items-center font-extrabold text-gray-700 leading-5 desktop:leading-6 text-xl desktop:text-2xl py-3 border-b-2 border-dotted border-gray-400 cursor-pointer ">
                        <b>{values.settings.title}</b>
                        <div className="flex items-center ml-2 text-tiny font-normal border border-gray-300 rounded-lg px-2">
                          <RiEdit2Fill
                            size={15}
                            className="mr-3 text-gray-500"
                          />
                          Edit name
                        </div>
                      </h2>
                    )}
                    onConfirm={(e) => {
                      setFieldValue("settings.title", e);
                    }}
                  />
                }
                action={
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <div className="flex items-center">
                      <span className="font-bold text-gray-700 mr-3">
                        Campaign Status:
                      </span>
                      {isLoading === true ? (
                        <span className="text-primary flex flex-row items-center">
                          <RiLoader3Line className="animate-spin h-16 w-8" />
                          <p className="ml-2 mr-6">Syncing Details</p>
                        </span>
                      ) : (
                        <TabRadioButton
                          btnId="cart-goal-status"
                          defaultValue={values.status}
                          options={visibilityOptions}
                          onChange={(selectedType) => {
                            setFieldValue("status", selectedType);
                          }}
                        />
                      )}
                    </div>
                  )
                }
              >
                <HorizontalTabs
                  items={tabItems}
                  showSeperatorIcons
                  onChange={(selectedTab) => {
                    setCurrentTab(selectedTab);
                  }}
                  externalTabValue={currentTab}
                />
                {dirty && (
                  <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                    <p>You've unsaved changes</p>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        type="link"
                      >
                        Discard Changes
                      </Button>
                      <Button
                        onClick={() => {
                          finishFlow;
                          submitForm();
                        }}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
                {!workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                  <CampaignPaywall />
                )}
                {!dirty &&
                  values.status === "draft" &&
                  workspace.data?.feature_flag.apps?.cornercart?.campaigns && (
                    <CampaignPauseWarning />
                  )}
                {renderCurrentTab(values, setFieldValue)}
              </PageViewContainer>
            );
          }}
        </Formik>
        <ErrorModal
          isOpen={openSyncErrorModal}
          onClose={() => setOpenSyncErrorModal(false)}
          secondaryAction={
            <Button
              icon={<RiArrowGoForwardFill />}
              disabled={!showRetryButton}
              type="outline"
              loading={isLoading}
              onClick={() => {
                setShowRetryButton(false);
                setOpenSyncErrorModal(false);
                triggerCampaignAPI("publish", {
                  campaign_id: parseInt(params.id),
                  shop_url: workspace.data.storeMyShopifyUrl,
                });
              }}
            >
              Retry Syncing
            </Button>
          }
          title="Sync Incomplete !"
          description="There was an error in syncing reward details with Shopify. It's probably due to rate limits imposed by Shopify. 
          Please try once again and if this happens again, please contact our support team."
          handleApiError={() =>
            contactCrisponApiError(
              errorKeyRef,
              `Could you please help with this issue: "Syncing campaign details to Shopify failed" ?`
            )
          }
        />
        {shopifyErrInfo && (
          <ErrorModal
            isOpen={campaignErrModal}
            onClose={() => onCampaignErrModalClose()}
            title="Sorry! Shopify Sync Error"
            description={shopifyErrInfo}
            secondaryAction={
              <Button type="primary" onClick={() => onCampaignErrModalClose()}>
                Close
              </Button>
            }
          />
        )}
      </>
    );
  else return <LoadingSpinner />;
}
