import React, { useState } from "react";
import Button from "../../../components/Button";

const OnboardingOptionalStep = ({
  ctaBtnText,
  title = "",
  description = "",
  imgSrc,
  imgAltText = "",
  handleCTA,
}) => {
  return (
    <div className="flex flex-row justify-between items-center px-4 py-2 mb-4 border bg-gray-100 border-solid border-gray-50 rounded-md">
      <div className="flex flex-row">
        <img className="w-32" src={imgSrc} alt={imgAltText} />
        <div className="flex flex-col justify-center ml-4">
          <p className="text-base font-bold text-gray-900">{title}</p>
          <p className="text-base text-gray-600">{description}</p>
        </div>
      </div>
      <div className="flex shrink-0">
        <Button
          type="link"
          className="text-primary-600"
          onClick={() => handleCTA()}
        >
          {ctaBtnText}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingOptionalStep;
