export const queryAllCampaign = `
query queryAllCampaign{
  campaign(where: { delete_after: {_is_null: true }}) {
    id
    shop
    audience
    priority
    settings
    status
    type
    campaign_cart_goals {
      id
    }
  }
}
`;

export const queryHighestCampaignKey = `
query queryHighestCampaignKey{
  campaign(limit: 1, order_by: {key: desc}) {
    key
  }
}
`;

export const queryCampaignCartGoalById = `
query queryCampaignCartGoalById($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    shop
    audience
    priority
    settings
    status
    type
    key
    campaign_cart_goals {
      id
      goalType
      goalUnit
      milestones
      stackable
      config
      rewards
    }
  }
}

`;
export const queryCampaignCartAnnouncementById = `
query queryCampaignCartAnnouncementById($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    shop
    audience
    priority
    settings
    status
    type
    key
    campaign_cart_announcements {
      id
      message
      attributes
      config
    }
  }
}
`;

export const queryDiscountCodeCampaignById = `
query queryDiscountCodeCampaignById($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    shop
    audience
    priority
    settings
    status
    type
    key
    campaign_discount_codes {
      id
      config
      content
      discount_code
    }
  }
}
`;

export const queryCampaignOneClickById = `
query queryCampaignOneClickById($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    audience
    priority
    settings
    status
    type
    campaign_one_clicks {
      id
      upsellConfig
      upsellProduct
      cartState
    }
  }
}
`;

export const queryCampaignCartTimerById = `
query queryCampaignCartTimer($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    audience
    priority
    settings
    status
    type
    schedule
    key
    campaign_campaign_cart_timers {
      id
      config
      content
    } 
  }
}
`;
//      shopify_config,is_in_sync not in table
export const queryCampaignBuyXGetYById = `
query queryCampaignOneClickById($id: Int!) {
  campaign_by_pk(id: $id) {
    id
    audience
    priority
    settings
    status
    type
    schedule
    key
    campaign_bxgy_frees {
      id
      config
      content
      shopify_config
      is_in_sync
    } 
  }
}
`;
/* --------------- Mutations ------------------- */

export const insertCocaCampaign = `
  mutation insertCocaCampaign ($campaignInput: campaign_insert_input!){
    insert_campaign_one(object: $campaignInput) {
        id
        type
    }
  }
`;

export const insertCampaignCartGoals = `
  mutation insertCampaignCartGoals ($cartGoalInput: campaign_cart_goals_insert_input!){
    insert_campaign_cart_goals_one(object: $cartGoalInput) {
      id
      campaignid
    }
  }
`;

export const insertCampaignOneClick = `
  mutation insertCampaignOneClick ($oneClickInput: campaign_one_click_insert_input!){
    insert_campaign_one_click_one(object: $oneClickInput) {
      id
      campaignid
    }
  }
`;
export const insertCampaignCartAnnouncement = `
  mutation insertCampaignCartAnnouncement ($cartAnnouncementInput: campaign_cart_announcement_insert_input!){
    insert_campaign_cart_announcement_one(object: $cartAnnouncementInput) {
      campaignid
    }
  }
`;

export const insertCampaignBXGYFree = `
  mutation insertCampaignBXGYFree ($BXGYFreeInput: campaign_bxgy_free_insert_input!){
    insert_campaign_bxgy_free_one(object: $BXGYFreeInput) {
      campaignid
    }
  }
`;

export const insertCampaignDiscountCode = `
  mutation insertCampaignDiscountCode ($DiscountCodeInput: campaign_discount_code_insert_input!){
    insert_campaign_discount_code_one(object: $DiscountCodeInput) {
      campaignid
    }
  }
`;

export const insertCampaignCartTimer = `
  mutation insertCampaignCartTimer ($cartTimerInput: campaign_cart_timer_insert_input!){
    insert_campaign_cart_timer_one(object: $cartTimerInput) {
      campaignid
    }
  }
`;

export const updateCartAnnouncementCampaignDetails = `
  mutation updateCartAnnouncementCampaignDetails ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$cartAnnouncementId:campaign_cart_announcement_pk_columns_input!, $cartAnnouncementInput:campaign_cart_announcement_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
    }
    update_campaign_cart_announcement_by_pk(pk_columns: $cartAnnouncementId, _set:$cartAnnouncementInput) {
      id
    }
  }
`;
export const updateOneClickCampaignDetails = `
  mutation updateOneClickCampaignDetails ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$oneClickId:campaign_one_click_pk_columns_input!, $oneClickInput:campaign_one_click_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
    }
    update_campaign_one_click_by_pk(pk_columns: $oneClickId, _set:$oneClickInput) {
      id
    }
  }
`;

export const updateCartGoalCampaignDetails = `
  mutation updateCartGoalCampaign ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$cartGoalId:campaign_cart_goals_pk_columns_input!, $cartGoalInput:campaign_cart_goals_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
      status
    }
    update_campaign_cart_goals_by_pk(pk_columns: $cartGoalId, _set:$cartGoalInput) {
      id
    }
  }
`;

export const updateBXGYCampaignDetails = `
  mutation updateBXGYCampaign ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$bxgyId:campaign_bxgy_free_pk_columns_input!, $bxgyInput:campaign_bxgy_free_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
      status
    }
    update_campaign_bxgy_free_by_pk(pk_columns: $bxgyId, _set:$bxgyInput) {
      id
    }
  }
`;
export const updateCartTimerCampaignDetails = `
  mutation updateCartTimerCampaign ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$cartTimerId:campaign_cart_timer_pk_columns_input!, $cartTimerInput:campaign_cart_timer_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
      status
    }
    update_campaign_cart_timer_by_pk(pk_columns: $cartTimerId, _set:$cartTimerInput) {
      id
    }
  }
`;
export const updateDiscountCodeDetails = `
  mutation updateDiscountCodeDetails ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input,$discountCodeId:campaign_discount_code_pk_columns_input!, $discountCodeInput:campaign_discount_code_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
      status
    }
    update_campaign_discount_code_by_pk(pk_columns: $discountCodeId, _set:$discountCodeInput) {
      id
    }
  }
`;

export const bulkUpdateCampaigns = `
mutation bulkUpdateCampaigns($idArray: [Int!],$campaignInput:campaign_set_input) {
  update_campaign(_set:$campaignInput, where:{_and:{id:{_in:$idArray}}}) {
     affected_rows
    }
}
`;

export const updateCampaign = `
  mutation updateCampaign ($campaignId:campaign_pk_columns_input!, $campaignInput:campaign_set_input){
    update_campaign_by_pk(pk_columns: $campaignId, _set:$campaignInput) {
      id
    }
  }
`;
