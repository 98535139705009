import React, { useState } from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import TabRadioButton from "../../../../../components/TabRadioButton";
import AudienceRuleBuilder from "../../../../../components/jsonLogicBuilders/AudienceRuleBuilder";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Settings({ values, setFieldValue }) {
  const stackOptions = [
    { label: "All milestones", value: true },
    { label: "Only the latest milestone", value: false },
    // { label: "Cart Weight", value: "cartWeight" },
  ];
  const showhideOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const validateInitialMileStoneReward = () => {
    return (
      values.campaign_cart_goals[0].milestones[0].rewards.rewardStack.length > 0
    );
  };

  return (
    <div className="h-full  overflow-y-auto px-8 py-4">
      {/* Is gift stackable */}
      {values.campaign_cart_goals[0].milestones.length > 1 && (
        <CustomiserUnit
          title="How rewards stack"
          description="Select how rewards are combined when customer crosses multiple milestones."
        >
          <div className="flex justify-between items-center border rounded-lg p-3 max-w-lg">
            <p className="mr-2 font-bold text-gray-600">Give rewards for</p>
            <TabRadioButton
              defaultValue={values?.campaign_cart_goals[0]?.stackable}
              options={stackOptions}
              onChange={(selectedType) => {
                setFieldValue("campaign_cart_goals[0].stackable", selectedType);
              }}
            />
          </div>
        </CustomiserUnit>
      )}

      {/* Feature Each Goal in Offers Section: Tdemporarily commented till we implement campaign wise offer hiding */}
      {/* <CustomiserUnit
        title="Feature Each Goal in Offers Section"
        description="Show rewards in each milestone in the Offers Section inside the cart drawer. If you have multiple goals we would reccomend to keep this turned on"
      >
        <TabRadioButton
          defaultValue={
            values?.campaign_cart_goals[0]?.config.featureInOffersPage || false
          }
          options={showhideOptions}
          onChange={(selectedType) => {
            setFieldValue(
              "campaign_cart_goals[0].config.featureInOffersPage",
              selectedType
            );
          }}
        />
      </CustomiserUnit> */}

      {/* Feature free gifts (only shown if there is free product in the milestones) */}
      {validateInitialMileStoneReward() &&
        values.campaign_cart_goals[0]?.milestones.findIndex(
          (milestone) =>
            milestone.rewards.rewardStack[0]?.type === "freeProduct"
        ) !== -1 && (
          <CustomiserUnit
            title="Showcase Free Gifts In Cart Page"
            description="Showcase the free gifts in the cart drawer under the products added"
          >
            <TabRadioButton
              defaultValue={
                values?.campaign_cart_goals[0]?.config.featureBelowCartItems
              }
              options={showhideOptions}
              onChange={(selectedType) => {
                setFieldValue(
                  "campaign_cart_goals[0].config.featureBelowCartItems",
                  selectedType
                );
              }}
            />
          </CustomiserUnit>
        )}

      <CustomiserUnit
        title="Exclude Other Discounts while Calculating Cart Value"
        description="If the visitor have other discounts applied to the cart, you can choose to exclude them while calculating the cart value for the campaign"
      >
        <TabRadioButton
          defaultValue={
            values?.campaign_cart_goals[0]?.config.excludeExternalDiscounts ||
            false
          }
          options={yesNoOptions}
          onChange={(selectedType) => {
            setFieldValue(
              "campaign_cart_goals[0].config.excludeExternalDiscounts",
              selectedType
            );
          }}
        />
      </CustomiserUnit>

      {/* Mandatory Reward Selection (only shown if there is a free product reward with mutiple products to choose from) */}
      {validateInitialMileStoneReward() &&
        values.campaign_cart_goals[0]?.milestones.findIndex(
          (milestone) => milestone.rewards.rewardStack[0].type === "freeProduct"
        ) !== -1 && (
          <CustomiserUnit
            title="Is Reward Selection Mandatory"
            description="Making the reward selection mandatory will remove the cancel button on the reward selection popup. This way you can make sure user selects the free gifts before moving forward"
          >
            <TabRadioButton
              defaultValue={
                values?.campaign_cart_goals[0]?.config
                  .mandatoryRewardSelection || false
              }
              options={yesNoOptions}
              onChange={(selectedType) => {
                setFieldValue(
                  "campaign_cart_goals[0].config.mandatoryRewardSelection",
                  selectedType
                );
              }}
            />
          </CustomiserUnit>
        )}
      {/* Audience targeting Rule Builder */}
      <CustomiserUnit
        title="Target an Audience"
        description="Create a rule to define the target audience"
      >
        <AudienceRuleBuilder
          value={values.audience}
          onChange={(updatedJsonLogic) => {
            setFieldValue("audience", { ...updatedJsonLogic });
          }}
          emptyTitle="Targeting Everyone"
          emptySubtitle="Right now, this campaign is visible to all customers. If you want to show it only to a specific group of customers, add a new targeting rule."
        />
      </CustomiserUnit>
    </div>
  );
}
