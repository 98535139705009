import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";

const CustomDateTimePicker = ({ isDisabled, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      onClick={() => setIsOpen(!open)}
      className="border border-solid border-gray-200 rounded-md p-2 text-left"
      disabled={isDisabled}
    >
      <DateTimePicker
        format="y-MM-dd h:mm:ss a"
        onChange={onChange}
        isOpen={isOpen}
        disabled={isDisabled}
        value={value ? new Date(value) : null}
        disableClock={true}
        calendarIcon={null}
        maxDetail="minute"
        minDate={new Date(value) > new Date() ? new Date() : new Date(value)}
      />
    </button>
  );
};

export default CustomDateTimePicker;
