/**
 * This functions generates the shopify config for bxgy campaign
 * @param {String} title
 * @param {Object} configValues
 * @param {Object} prevShopifyConfig
 * @param {Object} schedule
 * @returns {Object}
 */
const generateShopifyConfig = (
  title,
  configValues,
  prevShopifyConfig,
  schedule
) => {
  const bxgyType = configValues.type;
  const usesPerOrder = configValues.usesPerOrderLimit;

  /** get the products that customer buys
   * @returns {Array} returns an array of product ids
   */
  const getCustomerBuysProducts = () => {
    if (bxgyType === "BUYX") {
      const products =
        configValues.customerBuys.items.products.productsToAdd.map(
          (product) => product.id
        );
      const uniqueProducts = products.filter(
        (product, index) => products.indexOf(product) === index
      );

      let removeProducts = [];
      const bxgyDiscount = prevShopifyConfig.automaticBxgyDiscount;
      if (
        bxgyDiscount &&
        bxgyDiscount.customerBuys.items.products &&
        bxgyDiscount.customerBuys.items.products.productsToRemove
      ) {
        removeProducts =
          bxgyDiscount.customerBuys.items.products.productsToRemove;
      }

      return {
        productsToAdd: uniqueProducts,
        productsToRemove: removeProducts,
      };
    } else return null;
  };

  /** get the collections that customer buys
   * @returns {Array} returns an array of collection ids
   */
  const getCustomerBuysCollections = () => {
    if (bxgyType !== "BUYX") {
      const collections = configValues.customerBuys.items.collections.add.map(
        (collection) => collection.id
      );
      const uniqueCollections = collections.filter(
        (collection, index) => collections.indexOf(collection) === index
      );

      const bxgyDiscount = prevShopifyConfig.automaticBxgyDiscount;
      let removeCollections = [];
      if (
        bxgyDiscount &&
        bxgyDiscount.customerBuys.items.collections &&
        bxgyDiscount.customerBuys.items.collections.remove
      ) {
        removeCollections = bxgyDiscount.customerBuys.items.collections.remove;
      }

      return {
        add: uniqueCollections,
        remove: removeCollections,
      };
    } else return null;
  };

  /** get the collections that customer buys
   * @returns {Array} returns an array of collection ids
   */
  const getCustomerRewardProducts = () => {
    const { rewardStack } = configValues.rewards;
    const rewardProductList = rewardStack.map(
      (rewardProduct) => rewardProduct.target.id
    );
    const uniqueProductList = rewardProductList.filter(
      (product, index) => rewardProductList.indexOf(product) === index
    );
    let removeProducts = prevShopifyConfig.automaticBxgyDiscount
      ? prevShopifyConfig.automaticBxgyDiscount.customerGets.items.products
          .productsToRemove
      : [];
    return {
      productsToAdd: uniqueProductList,
      productsToRemove: removeProducts,
    };
  };

  const getCustomerBuysAmount = () => {
    if (bxgyType === "SPENDXINY") {
      return configValues.customerBuys.value.amount.toString();
    } else return null;
  };

  const getCustomerBuysQuantity = () => {
    if (bxgyType !== "SPENDXINY") {
      return configValues.customerBuys.value.quantity.toString();
    } else return null;
  };

  // shopify config structure
  const shopifyConfig = {
    automaticBxgyDiscount: {
      combinesWith: {
        orderDiscounts: true,
        productDiscounts: true,
        shippingDiscounts: true,
      },
      customerBuys: {
        items: {
          all: false,
          collections: getCustomerBuysCollections(),
          products: getCustomerBuysProducts(),
        },
        value: {
          amount: getCustomerBuysAmount(),
          quantity: getCustomerBuysQuantity(),
        },
      },
      customerGets: {
        items: {
          all: false,
          products: getCustomerRewardProducts(),
        },
        value: {
          // discountAmount: {
          //   amount: "",
          //   appliesOnEachItem: true,
          // },
          discountOnQuantity: {
            effect: {
              percentage: 1,
            },
            quantity: configValues.rewards.stackSelectCount
              ? `${configValues.rewards.stackSelectCount}`
              : null,
          },
        },
      },
      endsAt: schedule.endsAt ? schedule.endsAt : null,
      startsAt: schedule.startsAt ? schedule.startsAt : null,
      title: title.toString(),
      usesPerOrderLimit: usesPerOrder,
    },
  };

  return shopifyConfig;
};

export default generateShopifyConfig;
