import React from "react";
import ThankyouPage from "./previews/ThankyouPage";
import { Crisp } from "crisp-sdk-web";
// import ThankyouPage from "./previews/ThankyouPage";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ShopifyAppBridgeProvider } from "./contexts/ShopifyAppBridgeProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import { useParams } from "react-router-dom";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import App from "./App";
import crispInitialiser from "./components/crispInitialiser";

const ManageAuthFlowRedirect = ({}) => {
  /* app is passed in from the router based on the URL path */
  const { app } = useParams();
  /* setup app bridge (need to be nested under the ShopifyAppBridgeProvider */
  const appBridge = useAppBridge();

  const urlParams = new URLSearchParams(window.location.search);
  const host = urlParams.get("host");
  const shop = urlParams.get("shop");
  const token = urlParams.get("token");
  const embedded = urlParams.get("embedded");
  const shopHash = urlParams.get("shop_hash");

  /* The URL path will contain corner token if auth succedded in the backend.
   * In that case, render the App
   * */
  if (!!token) {
    if (shopHash) {
      crispInitialiser();
      Crisp.setTokenId(shopHash);
      sessionStorage.setItem("shopHash", shopHash);
    } else {
      window.Rollbar.error("ShopHash not found");
    }
    return <App />;
  } else {
    const starlinkAuthURL = `${process.env.REACT_APP_STARLINK_URL}/shopify/install/${app}?shop=${shop}&host=${host}`;

    /* If the URL contains "embedded" and it's "1", need to break out of iFrame and
     * only then re-direct to the backend to kick-off the install flow
     * */
    if (!!appBridge && embedded === "1") {
      const redirect = Redirect.create(appBridge);
      redirect.dispatch(Redirect.Action.REMOTE, starlinkAuthURL);
    } else {
      window.location.replace(starlinkAuthURL);
    }

    return <LoadingSpinner />;
  }
};

function CornerRouter() {
  return (
    <BrowserRouter>
      <ShopifyAppBridgeProvider>
        <Switch>
          {/* Handle redirect to backend for the auth flow */}
          <Route
            exact
            path="/shopify/install/:app"
            component={ManageAuthFlowRedirect}
          />
          {/* Route for the preview */}
          <Route exact path="/preview/thankyou-page" component={ThankyouPage} />
          {/* Root path for the App*/}
          <Route path="/" component={App} />
        </Switch>
      </ShopifyAppBridgeProvider>
    </BrowserRouter>
  );
}

export default CornerRouter;
