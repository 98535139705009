import React from "react";
import Close from "./Close";
import Open from "./Open";
import "./style.css";

export default function cornerWidget(props) {
  if (props.state === "open")
    return (
      <div className="coca-cowi">
        <Open
        {...props}
        />
      </div>
    );
  else return (
  <div className="coca-cowi">
    <Close shape={props.shape} size={props.size} />;
    </div>
  );
}
