/**
 * This function generates a new Date without losing the time values in the old date
 * @param {String} prevDate prevoiusly selected date
 * @param {String} newDate new date to be updated
 * @returns {String} formated utc date value in string
 */
const getNewDateValue = (prevDate, newDate) => {
  if (prevDate) {
    const currentDate = new Date(prevDate);
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const newDateTime = new Date(newDate);
    newDateTime.setHours(hours, minutes);
    return newDateTime.toISOString();
  } else {
    const newDateValue = new Date(newDate);
    return newDateValue.toISOString();
  }
};

/**
 * This function generates updates the time for a date and returns a datetime string in utc format
 * @param {String} dateValue current date value
 * @param {String} timeValue new time values to be updated in the format ("07:07")
 * @returns {String} formated utc date value in string
 */
const getNewDateTimeValue = (dateValue, timeValue) => {
  const currentDate = new Date(dateValue);
  const [hours, minutes] = timeValue.split(":");
  const updatedDate = currentDate.setHours(hours, minutes);
  const formattedDate = new Date(updatedDate).toISOString();
  return formattedDate;
};

export { getNewDateValue, getNewDateTimeValue };
