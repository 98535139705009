import React, { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import moment from "moment";
import Select from "@atlaskit/select";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import List from "../../../../components/List";
import Popup from "@atlaskit/popup";
import Button from "../../../../components/Button";
import Tooltip from "@atlaskit/tooltip";
import TrustTag from "./TrustTag";
import {
  RiArrowRightLine,
  RiMenuLine,
  RiCheckboxCircleFill,
  RiForbidFill,
  RiLoader2Line,
  RiQuestionLine,
} from "react-icons/ri";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import toaster from "toasted-notes";
import {
  querySimpelReferralRequests,
  updateSimpleReferralCashbackRequest,
} from "../../../../api/offers";

export default function ReferralRequests(historyProps) {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const [activeSortOption, setActiveSortOption] = useState("CASHBACK_PENDING");
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState({
    itemId: null,
    isOpen: false,
  });
  const { data } = useQuery("querySimpelReferralRequests", () =>
    graphQL(querySimpelReferralRequests)
  );

  const sortOptions = [
    { label: "Pending requests", value: "CASHBACK_PENDING" },
    {
      label: "Completed requests",
      value: "CASHBACK_PROCESSED",
    },
    { label: "Requests under processing", value: "CASHBACK_APPROVED" },
    { label: "Denied requests", value: "CASHBACK_USER_DENIED" },
    { label: "Failed requests", value: "CASHBACK_DENIED" },
  ];

  const updateSimpleReferralCashbackRequests = async (input) => {
    await graphQL(updateSimpleReferralCashbackRequest, {
      id: { id: input.id },
      simpleReferralCashbackRequestInput: input.values,
    });
  };
  const [updateSimpleReferralCashbackRequestMutation] = useMutation(
    updateSimpleReferralCashbackRequests,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`querySimpelReferralRequests`);
        toaster.notify("Request Updated", {
          duration: 2000,
        });
      },
      onError: (err) => {
        window.Rollbar.error(
          "Error in updateSimpleReferralCashbackRequestMutation ",
          err
        );
      },
    }
  );

  const MoreOptions = ({ item }) => (
    <div className="ml-2">
      <Popup
        isOpen={
          isMoreOptionsOpen.itemId === item.id &&
          isMoreOptionsOpen.isOpen === true
        }
        placement="bottom-start"
        onClose={() => setIsMoreOptionsOpen(false)}
        content={() => (
          <div className="p-4">
            {/* {item.simple_referrals_result.order_id.split("/")} */}
            <Button
              type="link"
              onClick={() => {
                window.open(
                  `https://${workspace.data.storeUrl}/admin/orders/${item.simple_referrals_result.order_id}`,
                  "_blank"
                );
              }}
            >
              Go to referrer order details
            </Button>
            <Button
              type="link"
              onClick={() => {
                window.open(
                  `https://${workspace.data.storeUrl}/admin/orders/${
                    item.referee_order_id.split("/")[4]
                  }`,
                  "_blank"
                );
              }}
            >
              Go to referree order details
            </Button>
          </div>
        )}
        trigger={(triggerProps) => (
          <button
            {...triggerProps}
            className="flex text-center bg-gray-100  items-center font-bold text-gray-600 p-3 rounded-lg"
            type="link"
            // Here the state is an object because this state has to handle open/close state of multiple items in the list
            onClick={() =>
              setIsMoreOptionsOpen({
                itemId: item.id,
                isOpen: !isMoreOptionsOpen.isOpen,
              })
            }
          >
            <RiMenuLine />
          </button>
        )}
      />
    </div>
  );
  const sortedData =
    data &&
    data.simple_referral_cashback_requests.filter(
      (request) => request.status === activeSortOption
    );

  if (data)
    return (
      <PageViewContainer title="Requests">
        <div className="desktop:px-16 mobile:px-3 h-full overflow-y-auto  pt-4 ">
          <div className="flex items-center justify-between mt-8">
            <div className="flex items-center">
              <p className="mr-3 desktop:block mobile:hidden">View</p>
              <Select
                isSearchable={false}
                value={sortOptions.find(
                  (option) => option.value === activeSortOption
                )}
                onChange={(e) => setActiveSortOption(e.value)}
                className="w-48 mr-2"
                options={sortOptions}
              />
            </div>
          </div>
          <div className="my-6">
            <List
              items={sortedData}
              header={
                <div className="grid grid-cols-12 items-center justify-center pb-3">
                  <div className="px-3 desktop:col-span-3 laptop:col-span-5 mobile:col-span-8">
                    <p className="font-bold text-gray-600 text-xs desktop:text-sm ">
                      Referral
                    </p>
                  </div>
                  <div className="px-1 col-span-1 mobile:hidden laptop:block">
                    <p className="font-bold text-gray-600 text-xs desktop:text-sm">
                      Date
                    </p>
                  </div>
                  <div className="px-1 col-span-1 mobile:hidden laptop:block">
                    <Tooltip content="Is this referral request genuine? Or is someone trying to game the system? Trust score analyzes order data and assigns a score out of 10 on how trust worthy this referral is. Higher the score, the more likely that the referral was genuine. Click on each score card to know more">
                      <p className="font-bold text-gray-600 flex items-center desktop:text-sm text-xs">
                        <span className="flex-grow">Trust Score </span>
                        <span className="cursor-pointer desktop:text-lg text-md">
                          <RiQuestionLine />
                        </span>
                      </p>{" "}
                    </Tooltip>
                  </div>
                  <div className="px-1 desktop:col-span-2 laptop:col-span-1 mobile:hidden laptop:block text-center">
                    <Tooltip content="Order value of the referral sale">
                      <p className="font-bold text-gray-600 flex items-center desktop:text-sm text-xs">
                        Revenue{" "}
                        <span className="cursor-pointer desktop:text-lg text-md  ml-2">
                          <RiQuestionLine />
                        </span>
                      </p>{" "}
                    </Tooltip>
                  </div>
                  <div className="desktop:px-3 desktop:col-span-2 laptop:col-span-1 mobile:hidden laptop:block text-center ">
                    <Tooltip content="Cashback amount that's due to the referrer for referring their friends">
                      <p className="font-bold text-gray-600 flex items-center desktop:text-sm text-xs">
                        Cashback Due{" "}
                        <span className="cursor-pointer desktop:text-lg text-md  ml-2">
                          <RiQuestionLine />
                        </span>
                      </p>{" "}
                    </Tooltip>
                  </div>
                  <div className="px-3 laptop:col-span-3 mobile:col-span-4 laptop:block text-center">
                    <p className="font-bold text-gray-600 text-xs desktop:text-sm">
                      Actions
                    </p>
                  </div>
                </div>
              }
              emptyState={
                <div>
                  <div className="flex justify-center my-4 desktop:my-8">
                    <img
                      src="https://assets.cornercart.io/coca-v2/images/empty-states/campaign.png"
                      alt=""
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="font-bold text-gray-700 mb-3 text-base desktop:text-lg">
                      No requests found{" "}
                    </h3>
                  </div>
                </div>
              }
              render={(item, index) => {
                return (
                  <div className="grid grid-cols-12 items-center justify-center">
                    <div className="desktop:col-span-3 laptop:col-span-5 p-3 mobile:col-span-8">
                      <div className="flex items-center">
                        <div>
                          <p className="text-xs  text-gray-500">New Customer</p>
                          <p className="font-bold laptop:text-base mobile:text-sm text-gray-700 leading-none">
                            {item.customer_info.firstName}{" "}
                            {item.customer_info.lastName}
                          </p>
                        </div>
                        <div className="mx-3">
                          <RiArrowRightLine />
                        </div>
                        <div>
                          <p className="text-xs  text-gray-500">Referred by</p>
                          <p className="font-bold laptop:text-base mobile:text-sm text-gray-700 leading-none">
                            {/* TODO */}
                            {item.simple_referrals_result.audience.name}
                          </p>
                        </div>
                      </div>
                      <div className="laptop:flex mobile:hidden mt-2">
                        <p className="text-sm mt-3 text-gray-700 rounded-lg border bg-gray-100 px-3">
                          {item.simple_referrals_result.simple_referral.name}
                        </p>
                      </div>
                      <div className="laptop:hidden mobile:flex p-2 rounded-lg border border-gray-300 bg-gray-100 bg-opacity-50 mt-2">
                        <div className="pr-3 border-r">
                          <p className="text-xs  text-gray-500">Date</p>
                          <p className="font-bold laptop:text-base mobile:text-xs text-gray-700 leading-none">
                            {moment(item.created_at).format("MMM Do")}
                          </p>
                        </div>
                        <div className="px-3 border-r">
                          <p className="text-xs  text-gray-500">Revenue</p>
                          <p className="font-bold laptop:text-base mobile:text-xs text-gray-700 leading-none">
                            {item.customer_info.orderValue}{" "}
                            {workspace.data.storeCurrency}
                          </p>
                        </div>
                        <div className="px-3 border-r">
                          <p className="text-xs  text-gray-500">Cashback Due</p>
                          <p className="font-bold laptop:text-base mobile:text-xs text-gray-700 leading-none">
                            {item.cashback_amount.toFixed(2)}{" "}
                            {workspace.data.storeCurrency}
                          </p>
                        </div>
                        <div className="px-3">
                          <p className="text-xs  text-gray-500">Risk</p>
                          <p className="laptop:hidden mobile:block font-bold laptop:text-base mobile:text-xs text-gray-700 leading-none mt-2">
                            {/* In mobile this will not be clickable thats why isOpen is hardcoded false */}
                            <TrustTag score={0.4} riskItem={item.risk_score} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="desktop:px-3  laptop:col-span-1 mobile:hidden laptop:block">
                      <p className="text-gray-600 desktop:text-base laptop:text-tiny leading-none font-bold">
                        {moment(item.created_at).format("MMM Do")}
                      </p>
                      <p className="text-gray-600 text-xs font-light ">
                        {moment(item.created_at).fromNow()}
                      </p>
                    </div>
                    <div className=" laptop:col-span-1 px-3 mobile:hidden laptop:block">
                      <TrustTag
                        score={0.3}
                        riskItem={item.risk_score}
                        clickable
                        // score={item.risk_score}
                      />
                    </div>

                    <div className="desktop:col-span-2  laptop:col-span-1 mobile:hidden laptop:flex items-center justify-center py-3 desktop:py-5 bg-indigo-100 self-stretch bg-opacity-30">
                      <div className=" px-2 flex items-baseline  rounded-lg justify-center">
                        <p className="desktop:text-xl laptop:text-base font-bold text-violet-700">
                          {item.customer_info.orderValue}
                        </p>
                        <p className="text-xs text-violet-700 ml-1">
                          {" "}
                          {workspace.data.storeCurrency}
                        </p>
                      </div>
                    </div>
                    <div className="desktop:col-span-2  laptop:col-span-1 mobile:hidden laptop:flex items-center  justify-center py-3 desktop:py-5 bg-amber-100 bg-opacity-30  self-stretch">
                      <div className=" px-2 flex items-baseline  rounded-lg justify-center">
                        <p className="desktop:text-xl laptop:text-base font-bold text-amber-600">
                          {item.cashback_amount.toFixed(2)}
                        </p>
                        <p className="text-xs text-amber-600 ml-1">
                          {" "}
                          {workspace.data.storeCurrency}
                        </p>
                      </div>
                    </div>

                    {item.status === "CASHBACK_PENDING" && (
                      <div className="px-3 laptop:col-span-3 mobile:col-span-4  flex justify-center">
                        <Button
                          onClick={() => {
                            updateSimpleReferralCashbackRequestMutation({
                              id: item.id,
                              values: { status: "CASHBACK_APPROVED" },
                            });
                          }}
                          type="primary"
                        >
                          <span className="laptop:block mobile:hidden">
                            Approve
                          </span>
                          <span className="mobile:block laptop:hidden m-1 text-xl">
                            <RiCheckboxCircleFill />
                          </span>
                        </Button>
                        <Button
                          className="ml-2"
                          type="danger-outline"
                          onClick={() => {
                            updateSimpleReferralCashbackRequestMutation({
                              id: item.id,
                              values: { status: "CASHBACK_USER_DENIED" },
                            });
                          }}
                        >
                          <span className="laptop:block mobile:hidden">
                            Deny
                          </span>
                          <span className="mobile:block laptop:hidden m-1 text-xl">
                            <RiForbidFill />
                          </span>
                        </Button>
                        <MoreOptions item={item} />
                      </div>
                    )}
                    {item.status === "CASHBACK_APPROVED" && (
                      <div className="px-3 laptop:col-span-3 mobile:col-span-4  flex items-center justify-center">
                        <div className="flex items-center p-3  rounded-lg bg-gray-100">
                          <RiLoader2Line className="animate-spin mr-3" />{" "}
                          <p className="text-gray-500">Cashback Processing</p>
                        </div>
                      </div>
                    )}
                    {item.status === "CASHBACK_PROCESSED" && (
                      <div className="px-3 laptop:col-span-3 mobile:col-span-4  flex items-center justify-center">
                        <div>
                          <p>
                            {" "}
                            Cashback Processed:{" "}
                            <span className="rounded-lg border border-violet-200 px-2 py-1 bg-violet-100 text-violet-700">
                              {item.effective_cashback_amount.toFixed(2)}{" "}
                              {workspace.data.storeCurrency}{" "}
                            </span>
                          </p>
                          {item.effective_cashback_amount <
                            item.cashback_amount && (
                            <Tooltip content="Cashback due to the referrer is more than the max cashback value of this campaign. Hence we will be sending them only the difference amount">
                              <p className="cursor-pointer border rounded-lg p-1 text-gray-600 flex items-center text-xs mt-3">
                                <span className=" text-lg mr-1">
                                  <RiQuestionLine />
                                </span>
                                Why is it lesser than cashback due?{" "}
                              </p>{" "}
                            </Tooltip>
                          )}
                        </div>
                        <MoreOptions item={item} />
                      </div>
                    )}
                    {item.status === "CASHBACK_DENIED" && (
                      <div className="px-3 laptop:col-span-3 mobile:col-span-4  flex items-center justify-center">
                        <div>
                          <div className="flex items-center p-3  rounded-lg bg-red-100 text-red-700">
                            <RiForbidFill className=" mr-3" />{" "}
                            <p>Cashback Failed</p>
                          </div>
                          {item.reason && (
                            <Tooltip content={item.reason}>
                              <p className="cursor-pointer border rounded-lg p-1 text-gray-600 flex items-center text-xs mt-3">
                                <span className=" text-lg mr-1">
                                  <RiQuestionLine />
                                </span>
                                Why did it fail?{" "}
                              </p>{" "}
                            </Tooltip>
                          )}
                        </div>
                        <MoreOptions item={item} />
                      </div>
                    )}
                    {item.status === "CASHBACK_USER_DENIED" && (
                      <div className="px-3 laptop:col-span-3 mobile:col-span-4  flex items-center justify-center">
                        <div>
                          <div className="flex items-center p-3  rounded-lg bg-amber-100 text-amber-700">
                            <RiForbidFill className=" mr-3" />{" "}
                            <p>Cashback Denied</p>
                          </div>
                        </div>
                        <MoreOptions item={item} />
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </PageViewContainer>
    );
  else return <LoadingSpinner />;
}
