import React, { useState, useEffect } from "react";
import { RiSpeedMiniFill } from "react-icons/ri";

const TabButton = ({
  tabID,
  name,
  icon,
  currentTab,
  setCurrentTab,
  onChange,
}) => {
  return (
    <button
      onClick={() => {
        setCurrentTab(tabID);
        onChange(tabID);
      }}
      className={`flex shrink-0 items-center mx-3 px-1 py-3  desktop:py-5  text-xs laptop:text-sm desktop:text-base ${
        currentTab === tabID &&
        "border-b-2 border-violet-700 text-violet-700 font-bold "
      }`}
    >
      {icon && (
        <span className="mr-3 text-base mobile:hidden laptop:block">
          {icon}
        </span>
      )}
      <span>{name}</span>
    </button>
  );
};
/**
 * A component to show the horizontal tabs
 * @param {[Objects]} items - array of object which have the label and the link
 * @param {function} onChange - the onchange function
 * @param {boolean} showSeperatorIcons - If this is true an icon with seperators will be visible between each tabs
 */
const HorizontalTabs = ({
  items,
  onChange,
  showSeperatorIcons,
  horizontalTabsId = "",
  externalTabValue = "",
}) => {
  const [currentTab, setCurrentTab] = useState(items[0].id);

  /**
   * here useEffect make sure the current tab value from parent componet is reflected inside
   * if we manually change the tab value in a outside component the tab value should be changed here
   */
  useEffect(() => {
    if (externalTabValue) setCurrentTab(externalTabValue);
  }, [externalTabValue]);

  return (
    <div
      className="flex shrink-0 items-center border-b laptop:justify-center w-full  overflow-y-auto grow-0 px-3"
      id={horizontalTabsId}
    >
      {items.map((item, index) => {
        return (
          <>
            {index !== 0 && showSeperatorIcons && (
              <div className=" desktop:block hidden text-gray-300">
                <RiSpeedMiniFill />
              </div>
            )}
            <TabButton
              name={item.label}
              tabID={item.id}
              icon={item.icon}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              onChange={onChange}
            />
          </>
        );
      })}
    </div>
  );
};

export default HorizontalTabs;
