import React from "react";
import { useMutation, useQuery } from "react-query";
import { insertSimpleReferrals, insertCampaign } from "../../../../api/offers";
import { updateSourceDetails } from "../../../../api/workspace";
import { queryBillingInfo, activateFreePlan } from "../../../../api/billing";
import moment from "moment";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";

export default function Intro({
  simpleRewardConfig,
  maxDiscountValue,
  rewardType,
  giftStage,
  simplePostPurchaseConfig,
  goBack
}) {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  let history = useHistory();

  const freePlanActivation = async () => {
    await graphQL(activateFreePlan, {
      appId: `${2}`,
      planId: `${8}`,
      sourceId: `${workspace.data?.sourceid}`,
    });
  };

  const [ofcoFreePlanActivationMutation] = useMutation(freePlanActivation, {
    onError: (err) => {
      console.error("Error in ofcoFreePlanActivationMutation", err);
      window.Rollbar.error("Error in ofcoFreePlanActivationMutation", err);
    },
    onSuccess: () => {
      window.location.href = "/offers/referral/campaigns";
    },
  });

  const insertCampaigns = async () => {
    return await graphQL(insertCampaign, {
      campaignInput: {
        accountid: workspace.data && workspace.data.accountid,
        sourceid: workspace.data && workspace.data.sourceid,
        // this logic takes the campaign with highest priority and increments in with 1
        priority: 10,
        type: "SIMPLE_REFERRAL",
      },
    });
  };

  const [insertCampaignsMutation] = useMutation(insertCampaigns, {
    onError: (err) => {
      console.error("Error in insertCampaignsMutation onboarding", err);
      window.Rollbar.error("Error in insertCampaignsMutation onboarding", err);
    },
    onSuccess: (data) => {
      insertSimpleReferralMutation({
        campaignId: data.insert_campaigns_one.id,
      });
    },
  });

  const insertSimpleReferral = async (input) => {
    return await graphQL(insertSimpleReferrals, {
      simpleReferralsInput: {
        campaignid: input.campaignId,
        ...defaultSimpleReferralCampaign,
      },
    });
  };

  const updateOnboarding = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        offer_onboarding: 100,
      },
    });
  };

  const [updateOnboardingProgressMutation] = useMutation(updateOnboarding, {
    onSuccess: () => {
      // ofcoFreePlanActivationMutation();
      window.location.href = "/offers/referral/campaigns";
    },
  });

  const [insertSimpleReferralMutation] = useMutation(insertSimpleReferral, {
    onError: (err) => {
      console.error("Error in insertSimpleReferralMutation onboarding", err);
      window.Rollbar.error(
        "Error in insertSimpleReferralMutation onboarding",
        err
      );
    },
    onSuccess: () => {
      updateOnboardingProgressMutation();
    },
  });

  const defaultSimpleReferralCampaign = {
    accountid: workspace.data && workspace.data.accountid,
    sourceid: workspace.data && workspace.data.sourceid,
    name: `Simple Referral 1`,
    starts_at: moment().toISOString(),
    expires_at: moment().add(1, "years").toISOString(),
    status: "paused",
    is_auto_optin: false,
    max_discount_value: maxDiscountValue,
    min_order_value: 0,
    postpurchase_config: simplePostPurchaseConfig,
    reward_config: simpleRewardConfig,
    reward_type: rewardType,
    terms_config:
      "Reward coupons can be redeemed only once. Reward coupons are sent by email. You agree to permit us to use the email-id you provided to send these emails. The Minimum purchase amount may apply for gift and/or reward coupons. Other conditions may apply. Please email us if you need clarifications.",
    email_config: {
      rewardEmailConfig: {
        themeColor: "rgb(33,118,255)",
        shopName: workspace.data.storeName,
        fgColor: "white",
        subject: `${workspace.data.storeName}: Reward milestone unlocked - Thank you for referring more people!`,
        primaryTitle: "Reward Unlocked",
        greeting: "Hey congrats!",
        content:
          "You have unlocked a higher reward stage by sharing your coupon with more people. Here's the updated reward code for you. Enjoy",
        couponTitle: "Your reward coupon for",
        cta: "Redeem Now",
        subCta:
          "This reward code is just for you. It can be redeemed only once.Visit our website to get your unique referral code to share with more people and unlock bigger rewards.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
      },
      giftEmailConfig: {
        themeColor: "rgb(62,119,255)",
        shopName: workspace.data.storeName,
        primaryTitle: "Your Referrer code",
        subject: `${workspace.data.storeName}: Thank you for signing up & here's your referrer code`,
        subCta:
          "The referral code is meant for your friends. You cannot redeem it for yourself.",
        logoUrl:
          "https://assets.cornercart.io/coca-v2/images/email-icons/sample-logo.png",
        greeting: "Thank you for signing up for our referral program!",
        content:
          "Here's your personal referral code that you can share with your friends. Earn bigger rewards, when more friends use your referral code to make a purchase!. Happy sharing!.",
        fgColor: "white",
        couponTitle: "Share this coupon to Gift your friends a discount of",
        cta: "More share options",
      },
    },
    gift_config: giftStage,
  };

  return (
    <div className="text-center">
      <div className="max-w-5xl mb-8">
        <h1 className="text-4xl font-light text-gray-700 mb-5">
          Almost there! Visit the app console to set your campaign live
        </h1>
        <p>
          Next, You will be re-directed to the app console. There you can
          customize the campaign further. And finally set the offer widget live
          on your store.
        </p>
      </div>
      <div className="flex justify-center items-center mt-12">
        <Button
          className="mr-8"
          type="link"
          onClick={() => {
            goBack();
          }}
        >
          Go back
        </Button>
        <button
          onClick={async () => {
            insertCampaignsMutation();
          }}
          className="text-center py-6 px-24 bg-violet-700 text-white font-bold rounded-lg hover:bg-gray-700 transition-all"
        >
          Go to app console
        </button>
      </div>
    </div>
  );
}
