import React from "react";
import { RiArrowRightFill, RiArrowLeftFill } from "react-icons/ri";

const CornerButtonPreview = (props) => {
  const calculateForeground = (rgb) => {
    let rgbArray = rgb
      .substring(rgb.indexOf("(") + 1, rgb.length - 1)
      .split(",")
      .map(Number);
    let foreground =
      rgbArray[0] * 0.299 + rgbArray[1] * 0.587 + rgbArray[2] * 0.114 > 186
        ? "dark"
        : "light";
    return foreground;
  };

  const cornerButtonBorderRadius = () => {
    switch (props.cornerButtonConfig.shape) {
      case "circle":
        return "500px";
      case "square":
        return "0px";
      case "rounded-square":
        return "4px";
    }
  };
  const cornerButtonSize = () => {
    switch (props.cornerButtonConfig.size.desktop) {
      case "small":
        return "6px";
      case "medium":
        return "8px";
      case "large":
        return "12px";
    }
  };
  const cornerButtonIconSize = () => {
    switch (props.cornerButtonConfig.size.desktop) {
      case "small":
        return "8px";
      case "medium":
        return "10px";
      case "large":
        return "12px";
    }
  };

  const cornerButtonStyleValues = {
    background: props.themeColor,
    borderRadius: `${cornerButtonBorderRadius()}`,
    padding: `${cornerButtonSize()}`,
  };
  const cornerButtonPositionValues = {
    bottom: `${props.cornerButtonConfig.verticalPositioning}%`,
  };

  const sideTabStyleValues = {
    bottom: `${props.sideTabConfig.verticalPositioning}%`,
  };
  const cornerButtonTextStyleValues = {
    color:
      calculateForeground(props.themeColor) === "light" ? "white" : "black",
  };
  const sideTabTextStyleValues = {
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    color:
      calculateForeground(props.themeColor) === "light" ? "white" : "black",
  };

  return (
    <div className="w-full bg-white shadow-lg h-2/3 m-8 rounded-lg overflow-hidden flex flex-col ">
      <div className="w-full h-8 bg-gray-100 flex">
        <div className="flex h-full items-center">
          <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
          <div className="w-2 h-2 mr-2 rounded-full bg-amber-400" />
          <div className="w-2 h-2 mr-2 rounded-full bg-violet-400" />
        </div>
        <div className="flex items-end ml-3">
          <div className="bg-white w-40 h-4/5 rounded-t-md" />
        </div>
      </div>
      <div className="flex border-b p-3 shadow-sm ">
        <div className="w-5 h-5 mr-3 rounded-full border" />
        <div className="w-5 h-5 mr-3 rounded-full border" />
        <div className="grow h-5 rounded-full border" />
      </div>
      <div className="relative grow">
        {((props.launcherMaping.assistant === "cornerButton" &&
          props.showAssistantWidget) ||
          (props.launcherMaping.offers === "cornerButton" &&
            props.showOfferWidget)) && (
          <div
            className={`absolute flex ${
              props.alignment === "left" ? "left-0" : "right-0"
            }`}
            style={cornerButtonPositionValues}
          >
            <div
              className={`${
                props.alignment === "left" && "order-2"
              } bg-white px-4 py-2 text-xs text-right flex items-center text-gray-400  rounded-full`}
            >
              <span
                className={`${props.alignment === "left" && "order-2"} mx-2 `}
              >
                {" "}
                <b>
                  {props.launcherMaping.assistant === "cornerButton"
                    ? "FAQ"
                    : "Offers"}
                </b>{" "}
                Launcher
              </span>
              {props.alignment === "left" ? (
                <RiArrowLeftFill className="" />
              ) : (
                <RiArrowRightFill className="" />
              )}
            </div>
            <div
              style={cornerButtonStyleValues}
              className={` m-4 flex items-center justify-center shadow-md`}
            >
              {calculateForeground(props.themeColor) === "light" ? (
                <img
                  style={{ width: cornerButtonIconSize() }}
                  src={
                    props.launcherMaping.assistant === "cornerButton"
                      ? "https://assets.cornercart.io/images/corner-widget/chat-selected-light.svg"
                      : "https://assets.cornercart.io/images/corner-widget/offer-selected-light.svg"
                  }
                  alt="Cart"
                />
              ) : (
                <img
                  style={{ width: cornerButtonIconSize() }}
                  src={
                    props.launcherMaping.assistant === "cornerButton"
                      ? "https://assets.cornercart.io/images/corner-widget/chat-selected-dark.svg"
                      : "https://assets.cornercart.io/images/corner-widget/offer-selected-dark.svg"
                  }
                  alt="Cart"
                />
              )}
              <p
                className="font-bold text-xs ml-2"
                style={cornerButtonTextStyleValues}
              >
                {props.cornerButtonConfig.text}
              </p>
            </div>
          </div>
        )}
        {((props.launcherMaping.assistant === "sideTab" &&
          props.showAssistantWidget) ||
          (props.launcherMaping.offers === "sideTab" &&
            props.showOfferWidget)) && (
          <div
            className={`absolute flex items-center ${
              props.alignment === "left" ? "left-0" : "right-0"
            }`}
            style={{
              bottom: `${props.sideTabConfig.verticalPositioning}%`,
            }}
          >
            <div
              className={`${
                props.alignment === "left" && "order-2"
              } bg-white px-4 py-2 text-xs text-right flex items-center text-gray-400  rounded-full`}
            >
              <span
                className={`${props.alignment === "left" && "order-2"} mx-2 `}
              >
                {" "}
                <b>
                  {props.launcherMaping.assistant === "sideTab"
                    ? "FAQ"
                    : "Offers"}
                </b>{" "}
                Launcher
              </span>
              {props.alignment === "left" ? (
                <RiArrowLeftFill className="" />
              ) : (
                <RiArrowRightFill className="" />
              )}
            </div>
            <div
              style={{ background: props.themeColor }}
              className={`flex flex-col items-center justify-center p-2 rounded-r-sm shadow-md`}
            >
              <p className="font-bold text-xs" style={sideTabTextStyleValues}>
                {props.sideTabConfig.text}
              </p>

              {calculateForeground(props.themeColor) === "light" ? (
                <img
                  className="w-3 mt-2"
                  src={
                    props.launcherMaping.assistant === "sideTab"
                      ? "https://assets.cornercart.io/images/corner-widget/chat-selected-light.svg"
                      : "https://assets.cornercart.io/images/corner-widget/offer-selected-light.svg"
                  }
                  alt="Cart"
                />
              ) : (
                <img
                  className="w-3 mt-2"
                  src={
                    props.launcherMaping.assistant === "sideTab"
                      ? "https://assets.cornercart.io/images/corner-widget/chat-selected-dark.svg"
                      : "https://assets.cornercart.io/images/corner-widget/offer-selected-dark.svg"
                  }
                  alt="Cart"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CornerButtonPreview;
