import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Button from "../../../components/Button";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import Campaigns from "./Campaigns";
import CampaignCreate from "./Campaigns/CampaignCreate";
import Automations from "./Automations";
import AutomationsEdit from "./AutomationsEdit";
import CartGoals from "./CartGoals";
import OneClickUpsell from "./OneClickUpsell";
import CartAnnouncementBar from "./CartAnnouncementBar";
import CocaPricingTakeOver from "../../../components/CocaPricing/CocaPricingTakeOver";
import BuyXGetY from "./BuyXGetY";
import CartTimer from "./CartTimer";
import DiscountCodeCampaign from "./DiscountCode";

export default function WidgetRouter() {
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  const [isRedirectLoading, setIsRedirectLoading] = useState(false);
  const [openCocaPricing, setOpenCocaPricing] = useState(false);

  useEffect(() => {
    if (workspace && workspace.data.currentAppID !== 3)
      workspace.data.changeCurrentAppID(3);
  }, [workspace]);

  const Paywall = () => {
    return (
      <>
        <PageViewContainer title="Cart Goals">
          <div className="h-full flex items-center justify-center flex-col">
            <div className="flex justify-center my-8">
              <img
                src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/deeb0656-66d8-4110-21de-c9799924f300/public"
                alt=""
              />
            </div>
            <div className="text-center max-w-xl">
              <h3 className="font-bold text-gray-700 mb-3">
                {`Sorry! ${
                  window.location.pathname.includes("automations")
                    ? "Automations"
                    : "Campaigns"
                } are not available`}
              </h3>
              <p className="text-gray-800">
                This is a pro freature available only in our usage based pricing
                plan. To get this feature please upgrade your plan.
              </p>
            </div>
            <Button
              loading={isRedirectLoading}
              className="my-6"
              onClick={() => setOpenCocaPricing(true)}
            >
              Upgrade Plan
            </Button>
          </div>
        </PageViewContainer>
        <CocaPricingTakeOver
          isOpen={openCocaPricing}
          onClose={() => setOpenCocaPricing(false)}
          currentPlanType="free_plan"
        />
      </>
    );
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}/campaigns/cartgoals/edit/:id`}
          component={CartGoals}
        />
        <Route
          exact
          path={`${path}/campaigns/oneclickupsells/edit/:id`}
          component={OneClickUpsell}
        />
        <Route
          exact
          path={`${path}/campaigns/cartannouncement/edit/:id`}
          component={CartAnnouncementBar}
        />
        <Route
          exact
          path={`${path}/campaigns/discountcode/edit/:id`}
          component={DiscountCodeCampaign}
        />
        <Route
          exact
          path={`${path}/automations/edit/:id`}
          component={AutomationsEdit}
        />
        <Route
          exact
          path={`${path}/campaigns/buyxgety/edit/:id`}
          component={BuyXGetY}
        />
        <Route
          exact
          path={`${path}/campaigns/carttimer/edit/:id`}
          component={CartTimer}
        />
        <Route exact path={`${path}/campaigns`} component={Campaigns} />
        <Route
          exact
          path={`${path}/campaigns/create`}
          component={CampaignCreate}
        />
        <Route exact path={`${path}/automations`} component={Automations} />

        <Route exact path={path}>
          <Redirect to={`/cart/cro/campaigns/`} />
        </Route>
      </Switch>
    </>
  );
}
