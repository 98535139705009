import React, { useState } from "react";

import { RiAddCircleLine } from "react-icons/ri";
import { useQuery, useQueryCache } from "react-query";
import Button from "../../../../components/Button";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { queryAllCampaign } from "../../../../api/campaign";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import CampaignList from "./CampaignList";
import { useHistory } from "react-router-dom";
import OfcoPricingModal from "../../../../components/OfcoPricingModal";
import SucoPricingModal from "../../../../components/SucoPricingModal";

export default function Campaigns() {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const history = useHistory();

  const { data, refetch } = useQuery("queryAllCampaign", () =>
    graphQL(queryAllCampaign)
  );
  /**
   * This function renders when the campaign list is empty
   */
  const RenderEmptyListCampaigns = () => {
    return (
      <div className="h-full flex overflow-y-auto">
        <div className="w-full flex flex-col py-16 px-24 desktop:px-32 items-center">
          <img
            className="w-32"
            alt=""
            src="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/deeb0656-66d8-4110-21de-c9799924f300/public"
          />
          <p className="font-bold text-base desktop:text-lg text-violet-700 my-4">
            Conversion Optimizers
          </p>
          <p className="text-gray-700 text-tiny desktop:text-base">
            You don't have any campaigns. Create a new campaign to increase your
            average order value.
          </p>
          <Button
            onClick={() => {
              history.push(`/cart/cro/campaigns/create`);
            }}
            type="primary"
            className="mt-8"
            icon={<RiAddCircleLine />}
          >
            Create New Campaign
          </Button>
        </div>
      </div>
    );
  };

  if (data && data.campaign) {
    return (
      <>
        <PageViewContainer
          title="Campaigns"
          action={
            <div className="flex">
              <Button
                onClick={() => {
                  history.push(`/cart/cro/campaigns/create`);
                }}
                type="primary"
                icon={<RiAddCircleLine />}
              >
                Create New Campaign
              </Button>
            </div>
          }
        >
          <div className=" py-3 h-full overflow-y-auto">
            <CampaignList
              data={data.campaign}
              draggable
              onUpdate={() => {
                queryCache.invalidateQueries("queryAllCampaign");
                refetch();
              }}
              emptyState={<RenderEmptyListCampaigns />}
            />
          </div>
        </PageViewContainer>
      </>
    );
  } else {
    return <LoadingSpinner />;
  }
}
