import React, { useState } from "react";
/**
 * This Custom hook is used for rerendering a component forcefully
 * use it only when needed
 * @returns hooks that forces the component to rerender
 */
const useForceUpdate = () => {
  const [, setToggle] = useState(false);
  return () => setToggle((toggle) => !toggle);
};

export default useForceUpdate;
