import React, { useEffect, useState } from "react";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import List from "../../../../components/List";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import SucoPricingModal from "../../../../components/SucoPricingModal";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { Link } from "react-router-dom";
import {
  queryAllAssistantArticles,
  insertAssistantCategory,
  updateAssistantCategory,
} from "../../../../api/support";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  RiFileAddLine,
  RiEdit2Line,
  RiAlertLine,
  RiFileWarningFill,
} from "react-icons/ri";
import toaster from "toasted-notes";
import CategoryLangEditPopup from "../../../../components/CategoryLangEditPopup";
import useClearCache from "../../../../utilities/useClearCache";

export default function CategoryList(historyProps) {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const workspace = useWorkspace();
  const clearCache = useClearCache("supportcorner");

  const { data } = useQuery("queryAllAssistantArticles", () =>
    graphQL(queryAllAssistantArticles)
  );

  const defaultCategory = {
    content: [
      {
        lang: workspace.data.default_language,
        value: {
          name: "Untitled",
        },
      },
    ],
    accountid: workspace.data && workspace.data.accountid,
    icon_url:
      "https://assets.cornercart.io/coca-v2/images/category-icons/color/1.svg",
    sourceid: workspace.data && workspace.data.sourceid,
    position_rank: categoryList.length,
    show_in_widget: true,
    show_in_page: true,
  };

  const insertCategory = async () => {
    return await graphQL(insertAssistantCategory, {
      categoryInput: defaultCategory,
    });
  };

  const updateCategory = async (input) => {
    await graphQL(updateAssistantCategory, {
      categoryId: { id: input.id },
      categoryInput: input.values,
    });
  };
  const [updateCategoryMutation] = useMutation(updateCategory, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryArticleCategories");
      clearCache();
    },
  });

  const [insertCategoryMutation] = useMutation(insertCategory, {
    onSuccess: (data) => {
      toaster.notify("New category created", {
        duration: 2000,
      });
      clearCache();
      historyProps.history.push(
        `/support/faq/categories/${data.insert_assistant_categories_one.id}`
      );
    },
  });

  // When user drags an article, the whole new categoryList array which is aranged in the new order is passed on to this fuction. Here we update each artcle's position_rank with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateCategoryMutation({
        id: item.id,
        values: {
          position_rank: index,
        },
      });
    });
  };

  // each time anything is updated this functio makes sure the updated article list is sorted.
  const initialiseList = () => {
    const arrangedCategoryList = data.assistant_categories.sort(
      (a, b) => a.position_rank - b.position_rank
    );
    setCategoryList(arrangedCategoryList);
  };

  useEffect(() => {
    data && initialiseList();
  }, [data]);

  if (data) {
    return (
      <PageViewContainer
        title="Category"
        action={
          <div className="flex">
            <Button
              onClick={() => insertCategoryMutation()}
              type="primary"
              icon={<RiFileAddLine />}
            >
              Add Category
            </Button>
          </div>
        }
      >
        {categoryList.length >
          workspace.data?.feature_flag?.apps?.supportcorner?.category_count && (
          <div className="flex items-center justify-center bg-amber-300 text-amber-900 py-2">
            <h2 className="mr-3">
              <RiFileWarningFill />
            </h2>
            <p>Exceeded maximum categories allowed in your plan.</p>
            <Button
              type="ghost"
              className="shrink-0 border-amber-900 mx-3"
              onClick={() => setIsBillingModalOpen(true)}
            >
              {" "}
              Upgrade Plan
            </Button>
            <SucoPricingModal
              title="You need a higher plan to access this feature"
              isOpen={isBillingModalOpen}
              onClose={() => setIsBillingModalOpen(false)}
            />
          </div>
        )}

        <div className="desktop:px-8 mobile:px-3 pt-4 h-full overflow-y-auto">
          <List
            draggable
            onDrag={handleDrag}
            items={categoryList}
            header={
              <div className="grid grid-cols-12 items-center justify-center ">
                <div className="col-span-1" />
                <div className="px-2 desktop:px-3 col-span-11">
                  <p className="font-bold text-gray-600 text-sm desktop:text-tiny">
                    Title
                  </p>
                </div>
              </div>
            }
            emptyState={
              <div>
                <div className="flex justify-center desktop:my-8 my-4">
                  <img
                    src="https://assets.cornercart.io/coca-v2/images/empty-states/file.png"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h3 className="font-bold text-gray-700 mb-3 text-base desktop:text-lg">
                    Create a category{" "}
                  </h3>
                  <p className="text-gray-800 text-tiny desktop:text-base">
                    You currently have no categories. Click the the button below
                    to add one
                  </p>
                  <div className="flex justify-center mt-8">
                    <Button
                      onClick={() => insertCategoryMutation()}
                      type="ghost"
                      icon={<RiFileAddLine />}
                    >
                      Add New Category
                    </Button>
                  </div>
                </div>
              </div>
            }
            render={(item, index) => {
              const articleCount = data.assistant_articles.filter(
                (article) => article.categoryid === item.id
              ).length;
              return (
                <Link to={`/support/faq/categories/${item.id}`}>
                  <div className="grid grid-cols-12 items-center justify-center py-3 desktop:py-5">
                    <div className="col-span-1 flex justify-center">
                      <img
                        className="w-9 desktop:w-12"
                        src={item.icon_url}
                        alt=""
                      />
                    </div>
                    <div className="desktop:px-3 px-2 col-span-8">
                      <p className="font-bold text-gray-700 flex items-center desktop:text-base text-tiny">
                        <span> {item.content[0]?.value?.name}</span>{" "}
                        {index + 1 >
                          workspace.data?.feature_flag?.apps?.supportcorner
                            ?.category_count && (
                          <div className="flex items-center ml-3 text-red-600 text-sm rounded-full px-3 py-1 border border-red-300">
                            <RiAlertLine className="mr-1" />{" "}
                            <span>Exceeded Limit </span>
                          </div>
                        )}
                      </p>
                      {articleCount > 0 ? (
                        <small className="text-gray-700 text-sm">
                          {articleCount} articles
                        </small>
                      ) : (
                        <small className="text-red-400 flex text-sm items-center">
                          {" "}
                          <RiAlertLine className="mr-1" /> Empty Category
                        </small>
                      )}
                    </div>
                    <div className="px-3 col-span-3 flex">
                      <p className="pointer-cursor text-gray-700 hover:text-violet-700 p-2 desktop:p-3 border border-gray-500  hover:border-violet-700 flex rounded-md items-center desktop:text-base text-tiny">
                        <RiEdit2Line />
                        <span className="laptop:block mobile:hidden ml-2">
                          {" "}
                          Edit Category
                        </span>
                      </p>
                    </div>
                  </div>
                </Link>
              );
            }}
          />
        </div>
      </PageViewContainer>
    );
  } else return <LoadingSpinner />;
}
