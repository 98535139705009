import { Redirect } from "@shopify/app-bridge/actions";
/**
 * This function breaks out of iframe using appbridge to an external URL
 * @param {String} urlValue URL to redirect
 * @param {*} appBridgeInstance current appBridge instance
 */
const redirectToURLviaAppBrige = (urlValue, appBridgeInstance) => {
  const redirectInstance = Redirect.create(appBridgeInstance);
  redirectInstance.dispatch(Redirect.Action.REMOTE, urlValue);
};

export default redirectToURLviaAppBrige;
