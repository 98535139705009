let getUuid = require("uuid-by-string");

/**
 * A function to deep Clone JS object
 * @param {Object} sourceObject - original object to clone
 */
const deepCloneObject = (sourceObject) => {
  let clonedObject = JSON.parse(JSON.stringify(sourceObject));
  return clonedObject;
};

/**
 * This function gents th language json from the https://alt.usecorner.io
 * @param {String} project the folder which denotes the project
 * @param {String} language the language code
 * @returns {Object} json object of the languiage
 */
const getDefaultLangJson = async (project, language) => {
  const constlangJson = await (
    await window.fetch(`https://alt.usecorner.io/${project}/${language}.json`)
  ).json();
  return constlangJson;
};

/**
 * This function extracts the actual product id from shopify product id string
 * @param {String} shopifyProductId actual product id from shopify product object eg:- "gid://shopify/Product/7158172123225"
 * @returns {Integer} productId
 */
const parseShopifyProductId = (shopifyProductId) => {
  return parseInt(
    shopifyProductId.substring(shopifyProductId.lastIndexOf("/") + 1)
  );
};

/**
 * This function if  validating email;
 * It also manages the edge case where email might be null
 * @param {String} email
 * @returns {Boolean} True or False
 */
const validateEmail = (email) => {
  if (email) {
    const isEmailValid = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (isEmailValid) {
      return true;
    } else {
      return false;
    }
  } else return false;
};

const validateURL = (url) => {
  if (url) {
    let urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlPattern.test(url);
  } else return false;
};

export {
  getDefaultLangJson,
  deepCloneObject,
  parseShopifyProductId,
  validateEmail,
  validateURL,
};
