import React,{useState} from "react";
import { useQuery } from "react-query";
import { queryAllReferrals } from "../api/offers";
import { useGraphQl } from "../contexts/GraphqlClient";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";

const widgetStatusWarning = (props) => {
  const graphQL = useGraphQl();
  let { path } = useRouteMatch();
const [fromDate, setFromDate] = useState(
    moment().subtract(30, "days").toISOString()
  );
  const [toDate, setToDate] = useState(moment().toISOString());
  const { data } = useQuery("queryAllViralReferrals", () =>
    graphQL(queryAllReferrals, {
      fromDate: fromDate,
      toDate: toDate,
    })
  );

  // the following variable will contained combined data of both Simple Referrals and the Offer Refferals
  const combinedData =
    data && data.viral_referrals.concat(data.simple_referrals);

  if (
    data &&
    combinedData.filter((campaign) => campaign.status === "active")
      .length === 0
  )
    return (
      <div className="bg-amber-100 px-5 py-5 text-center flex justify-center items-center">
        <span>
          <b>No campaigns are active now</b>: Please make a campaign active once
          you are ready
        </span>
        {path !== "/offers/referral/campaigns/" && (
          <Link to={`/offers/referral/campaigns/`}>
            <button className="bg-amber-500 text-white font-bold p-3 rounded-lg mx-3 focus:outline-none">
              Take me to the campaign list
            </button>
          </Link>
        )}
      </div>
    );
  else return <div />;
};

export default widgetStatusWarning;
