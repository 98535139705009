import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  queryAllLangObjects,
  updateWidgetSettings,
  updatCornerStickybarWidget,
} from "../api/widgets";
import merge from "lodash.merge";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { useGraphQl } from "../contexts/GraphqlClient";
import { updateSourceDetails } from "../api/workspace";
import { getDefaultLangJson } from "./basicUtilityFunctions";

/**
 * This hook has 2 funtions.
 * 1) make sure the language versions are up to date
 * 2) Make the translated_languages is sync with the language objects
 * @function
 */
const useLanguageVersionManager = () => {
  // 🚨 IMPORTANT 🚨 : Whenever language version is changed the dev must update the language version in https://alt.usecorner.io/versions.json
  const {
    data: { currentAppID, default_language, sourceid },
  } = useWorkspace();

  const graphQL = useGraphQl();

  const [latestLangVersions, setLatestLangVersions] = useState();

  const { data, refetch } = useQuery(
    "queryAllLangObjects",
    () => graphQL(queryAllLangObjects),
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateTranslatedLanguageArray = async (input) => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: sourceid },
      sourceInput: {
        translated_languages: input,
      },
    });
  };

  const [updateTranslatedLanguageArrayMutation] = useMutation(
    updateTranslatedLanguageArray,
    {
      onSuccess: () => {},
    }
  );

  const updateCornerWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateCornerWidgetMutation] = useMutation(updateCornerWidget, {
    onSuccess: () => {
      refetch();
    },
  });

  const updateStickyBarWidget = async (values) => {
    await graphQL(updatCornerStickybarWidget, {
      stickyBarId: values.id,
      stickyBarInput: values.config,
    });
  };

  const [updatCornerStickybarMutation] = useMutation(updateStickyBarWidget, {
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });

  /**
   * This fuction makes sure the language object that the merchant have in the db is upto date with the lastest language object.
   * This function is supposed to run every time the merchant accesses the falcon
   * IMPORTANT: Whenever language version is changed the dev must update these 4 language versions in this function
   * @function
   */
  const versionManager = async () => {
    if (data && currentAppID && default_language && latestLangVersions) {
      /**
       * This function will merge the current lang JSON on top of the defaullt values saved in the falcon code base (which will always be up to date)
       * @function
       * @param {Object} currentJson - The current JSON object that is in the DB
       * @param {("cart-cowi"|"corner-widget"|"faq-page"|"offer-page"|"offer-widget"|"stickybar")} defaultValueFolder - The name of the folder from which the default value should be picked from
       * @param {Number} latestVersionNumber - The version to which the JSON should be updated to.
       */
      const mergeCurrentAndLatestLangJson = async (
        currentJson,
        defaultValueFolder,
        latestVersionNumber
      ) => {
        // updating the version number in the lang JSON
        let updatedValue = {};
        let langKeysArray = Object.keys(currentJson);

        // This following condition inserts the language JSON of the default language if the current Lang JSON is either null or empty. This might happen when we create new widgets
        if (langKeysArray.length === 0 || currentJson === null) {
          let defaultValues = await getDefaultLangJson(
            defaultValueFolder,
            default_language
          );
          updatedValue = {
            [`${default_language}`]: defaultValues,
            version: `${latestVersionNumber}`,
          };
        }
        // else if there is a JSON available, then it will merge the hard coded default values with the current lang JSON. Thus adding any missing newly added lang values
        else {
          await Promise.all(
            langKeysArray.map(async (language) => {
              if (language === "version") return null;
              else {
                let defaultValues = await getDefaultLangJson(
                  defaultValueFolder,
                  language
                );
                updatedValue = {
                  ...updatedValue,
                  [`${language}`]: merge(
                    defaultValues,
                    currentJson[`${language}`]
                  ),
                };
              }
            })
          );

          // adding updated version number at the end of updatedValue
          updatedValue = {
            ...updatedValue,
            version: `${latestVersionNumber}`,
          };
        }
        return updatedValue;
      };

      let cowiChangeFlag = false; // will be changed to true if there are any updates in the widgets_cornerwidget table
      let stickybarChangeFlag = false; // will be changed to true if there are any updates in the widget_stickybar table
      let updatedCowiConfig = {};
      let updatedStickybarConfig = {};
      switch (currentAppID) {
        // For support Corner
        case 1: {
          // getting current versions from the db value. Set as 0 if version is not found
          const currentCornerLangVersion = data.widgets_cornerwidget[0]
            ?.corner_lang.version
            ? parseFloat(data.widgets_cornerwidget[0]?.corner_lang.version)
            : 0;
          const currentFaqLangVersion = data.widgets_cornerwidget[0]?.faq_lang
            .version
            ? parseFloat(data.widgets_cornerwidget[0]?.faq_lang.version)
            : 0;
          // Checking & Updating corner_lang
          if (latestLangVersions.cornerLang > currentCornerLangVersion) {
            let updatedCornerLangValue = await mergeCurrentAndLatestLangJson(
              data.widgets_cornerwidget[0]?.corner_lang,
              "corner-widget",
              latestLangVersions.cornerLang
            );
            cowiChangeFlag = true;
            updatedCowiConfig = {
              ...updatedCowiConfig,
              corner_lang: updatedCornerLangValue,
            };
          }
          // Checking & Updating faq_lang
          if (latestLangVersions.faqLang > currentFaqLangVersion) {
            let updatedFaqLangValue = await mergeCurrentAndLatestLangJson(
              data.widgets_cornerwidget[0]?.faq_lang,
              "faq-page",
              latestLangVersions.faqLang
            );
            cowiChangeFlag = true;
            updatedCowiConfig = {
              ...updatedCowiConfig,
              faq_lang: updatedFaqLangValue,
            };
          }
          break;
        }
        // For Offers Corner
        case 2: {
          // getting current versions from the db value. Set as 0 if version is not found
          const currentOfferPageLangVersion = data.widgets_cornerwidget[0]
            ?.offer_page_lang?.version
            ? parseFloat(data.widgets_cornerwidget[0].offer_page_lang.version)
            : 0;
          const currentOfferWidgetLangVersion = data.widgets_cornerwidget[0]
            ?.offer_widget_lang?.version
            ? parseFloat(data.widgets_cornerwidget[0].offer_widget_lang.version)
            : 0;
          // Checking & Updating offer_page_lang
          if (latestLangVersions.offerPageLang > currentOfferPageLangVersion) {
            let updatedOfferPageLangValue = await mergeCurrentAndLatestLangJson(
              data.widgets_cornerwidget[0]?.offer_page_lang,
              "offer-page",
              latestLangVersions.offerPageLang
            );
            cowiChangeFlag = true;
            updatedCowiConfig = {
              ...updatedCowiConfig,
              offer_page_lang: updatedOfferPageLangValue,
            };
          }
          // Checking & Updating offer_widget_lang
          if (
            latestLangVersions.offerWidgetLang > currentOfferWidgetLangVersion
          ) {
            let updatedOfferWidgetLangValue =
              await mergeCurrentAndLatestLangJson(
                data.widgets_cornerwidget[0]?.offer_widget_lang,
                "offer-widget",
                latestLangVersions.offerWidgetLang
              );
            cowiChangeFlag = true;
            updatedCowiConfig = {
              ...updatedCowiConfig,
              offer_widget_lang: updatedOfferWidgetLangValue,
            };
          }
          break;
        }
        // For CornerCart
        case 3: {
          // getting current versions from the db value. Set as 0 if version is not found
          const currentCartCowiLangVersion = data.widgets_cornerwidget[0]
            ?.corner_cart_lang.version
            ? parseFloat(data.widgets_cornerwidget[0].corner_cart_lang.version)
            : 0;
          const currentStickybarLangVersion = data.widget_stickybar[0]
            ?.sticky_atc_lang.version
            ? parseFloat(data.widget_stickybar[0].sticky_atc_lang.version)
            : 0;

          // Checking & Updating corner_cart_lang
          if (latestLangVersions.cartCowiLang > currentCartCowiLangVersion) {
            let updatedCartCowiLangValue = await mergeCurrentAndLatestLangJson(
              data.widgets_cornerwidget[0]?.corner_cart_lang,
              "cart-cowi",
              latestLangVersions.cartCowiLang
            );
            cowiChangeFlag = true;
            updatedCowiConfig = {
              ...updatedCowiConfig,
              corner_cart_lang: updatedCartCowiLangValue,
            };
          }

          // Checking & Updating sticky_atc_lang
          if (latestLangVersions.stickybarLang > currentStickybarLangVersion) {
            let updatedStickybarLangValue = await mergeCurrentAndLatestLangJson(
              data.widget_stickybar[0].sticky_atc_lang,
              "stickybar",
              latestLangVersions.stickybarLang
            );
            stickybarChangeFlag = true;
            updatedStickybarConfig = {
              ...updatedStickybarConfig,
              sticky_atc_lang: updatedStickybarLangValue,
            };
          }

          break;
        }
      }

      if (cowiChangeFlag) {
        updateCornerWidgetMutation({
          id: { id: data && data.widgets_cornerwidget[0].id },
          config: updatedCowiConfig,
        });
      }

      if (stickybarChangeFlag) {
        updatCornerStickybarMutation({
          id: { id: data && data.widget_stickybar[0].id },
          config: updatedStickybarConfig,
        });
      }
    }
  };

  /**
   * This The following function updates the translated_languages if it is null.
   * translated_languages is relatively new column and this function is mailnly to back fill old customers
   * @function
   */
  const syncApplanguages = () => {
    /**
     * this function recieves a lang object and it isolates & returns just the language codes from it
     * @function
     * @param {Object} referenceLanguageObject - the lang object
     * @returns {String[]} - Array of lang codes
     */
    const getLangArray = (referenceLanguageObject) => {
      let referenceLangObject = referenceLanguageObject;
      let langArray = Object.keys(referenceLangObject);
      // langArray might containg the string "version"
      let versionIndex = langArray.indexOf("version");
      langArray.splice(versionIndex, 1);
      return langArray;
    };

    if (
      data &&
      currentAppID &&
      !data.workspaces[0].source.translated_languages
    ) {
      switch (currentAppID) {
        // For support Corner
        case 1: {
          if (data.widgets_cornerwidget[0]?.faq_lang)
            updateTranslatedLanguageArrayMutation(
              getLangArray(data.widgets_cornerwidget[0].faq_lang)
            );
          break;
        }
        // For Offers Corner
        case 2: {
          if (data.widgets_cornerwidget[0]?.offer_widget_lang)
            updateTranslatedLanguageArrayMutation(
              getLangArray(data.widgets_cornerwidget[0].offer_widget_lang)
            );
          break;
        }
        // For CornerCart
        case 3: {
          if (data.widgets_cornerwidget[0]?.corner_cart_lang) {
            updateTranslatedLanguageArrayMutation(
              getLangArray(data.widgets_cornerwidget[0].corner_cart_lang)
            );
          }
          break;
        }
      }
    }
  };

  useEffect(async () => {
    versionManager();
    syncApplanguages();
  }, [currentAppID, data, default_language, latestLangVersions]);

  useEffect(() => {
    fetch(`https://alt.usecorner.io/versions.json`)
      .then((res) => res.json())
      .then(
        (result) => {
          setLatestLangVersions(result);
        },
        (error) => {
          console.log(`error ----> `, error);
        }
      );
  }, []);
};

export default useLanguageVersionManager;
