import jwt_decode from "jwt-decode";

class CocaSession {
  static storage = window.sessionStorage;

  static setJWTToken(token) {
    this.storage.setItem("token", token);
  }
  static removeJWTToken() {
    this.storage.removeItem("token");
  }
  static getJWTToken() {
    return this.storage.getItem("token");
  }

  static setRefreshToken(token) {
    this.storage.setItem("rftoken", token);
  }
  static removeRefreshToken() {
    this.storage.removeItem("rftoken");
  }
  static getRefreshToken() {
    return this.storage.getItem("rftoken");
  }

  static getFingerPrint() {
    const decodedToken = jwt_decode(this.getJWTToken());
    return decodedToken?.fingerPrint;
  }

  static isHasuraClaimsExists(token) {
    const decodedToken = jwt_decode(token);
    return decodedToken?.["https://hasura.io/jwt/claims"] ? true : false;
  }

  static isExpired() {
    if (this.getJWTToken()) {
      const decodedToken = jwt_decode(this.getJWTToken());

      var currentTime = new Date().getTime() / 1000;
      if (currentTime > decodedToken.exp) {
        return true;
      }
      return false;
    }
    throw new Error("Session expired: No token found");
  }

  static async refreshJWTToken() {
    const response = await fetch(
      `${process.env.REACT_APP_STARLINK_URL}/token/refresh`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${this.getRefreshToken()}`,
        },
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify({
          fingerPrint: this.getFingerPrint(),
        }),
      }
    );
    if (response.ok) {
      const { token } = await response.json();
      this.setJWTToken(token);
      return token;
    } else {
      window.Rollbar.error("Error in fetching Refresh token", response);
    }
  }
}

export default CocaSession;
