import React from "react";
import { useHistory } from "react-router-dom";

const WidgetCard = ({ imgSrc, title, description, handleWidgetCard }) => {
  return (
    <button
      className="flex flex-col py-6 px-6 rounded-md bg-white cursor-pointer border hover:border-violet-800 hover:shadow max-w-xs text-center items-stretch"
      onClick={() => handleWidgetCard()}
    >
      <div className="flex justify-center">
        <img src={imgSrc} className="w-48" alt="" />
      </div>
      <h2 className="text-lg font-bold text-black">{title}</h2>
      <p className="text-base text-gray-700 mt-2">{description}</p>
    </button>
  );
};

export default function WidgetSetup() {
  let history = useHistory();

  return (
    <div className="px-7 py-8 flex flex-col items-center justify-center overflow-y-auto">
      <h2 className="text-lg font-bold text-black">
        Set up our app in your store
      </h2>
      <p className="text-base text-gray-700 mt-2">
        Select where do you want to initially setup our App. You can change this
        later.
      </p>
      <div className="flex flex-row flex-wrap gap-4 mt-6 justify-center">
        <WidgetCard
          title="Setup the app in the live store"
          imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/cacebd52-7d42-4103-5c09-ff3b3a917d00/w=300"
          description="The cart widget will be turned on the live store and visible to all customers"
          handleWidgetCard={() => history.push("/get-started/4/publish")}
        />
        <WidgetCard
          title="Turn on the app in a test theme"
          imgSrc="https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/c1e944c5-0fb7-4a49-7ce5-3f02861c1f00/w=300"
          description="The widget will be turned on in a test theme, So that you could test it before making it live on your store"
          handleWidgetCard={() => history.push("/get-started/4/duplicate")}
        />
      </div>
    </div>
  );
}
