import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { Formik } from "formik";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Button from "../../../../components/Button";
import TextArea from "@atlaskit/textarea";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import ColorPicker from "../../../../components/ColorPicker";
import { Checkbox } from "@atlaskit/checkbox";
import {
  queryProductAccordion,
  updateAccordion,
} from "../../../../api/support";
import InlineEdit from "@atlaskit/inline-edit";
import PageViewContainer from "../../../../components/structure/PageViewContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { RiCheckboxBlankCircleFill, RiArrowDropDownLine } from "react-icons/ri";
import { useGraphQl } from "../../../../contexts/GraphqlClient";
import toaster from "toasted-notes";
import useClearCache from "../../../../utilities/useClearCache";

const ProductAccordionEdit = (historyProps) => {
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const clearCache = useClearCache("supportcorner");

  const convertRGB = (rgb) => `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  const updateAccordions = async (input) => {
    await graphQL(updateAccordion, {
      accordionsId: { id: input.id },
      accordionsInput: input.values,
    });
  };
  const [updateAccordionMutation] = useMutation(updateAccordions, {
    onSuccess: () => {
      queryCache.invalidateQueries(`queryProductAccordion`);
      toaster.notify("Accordion Settings Updated", {
        duration: 2000,
      });
      clearCache();
    },
  });

  const { data } = useQuery(`queryProductAccordion`, () =>
    graphQL(queryProductAccordion)
  );

  const accordionStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-emerald-400 mr-2 text-lg" />{" "}
          Publish{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-amber-400 mr-2 text-lg" />{" "}
          Draft{" "}
        </div>
      ),
      value: false,
    },
  ];

  if (data?.accordions[0])
    return (
      <Formik
        enableReinitialize
        initialValues={data.accordions[0]}
        onSubmit={(values) => {
          updateAccordionMutation({
            id: data && data.accordions[0] && data.accordions[0].id,
            values: {
              is_active: values.is_active,
              custom_css: values.custom_css,
              config: {
                questionTextColor: values.config.questionTextColor,
                questionBgColor: values.config.questionBgColor,
                answerBgColor: values.config.answerBgColor,
                blockAutoRender: values.config.blockAutoRender,
                title: values.config.title,
              },
            },
          });
        }}
      >
        {(formikProps) => {
          return (
            <PageViewContainer
              title="Product Accordion"
              action={
                <div className="flex">
                  {formikProps.dirty && (
                    <Button
                      type="primary"
                      onClick={() => formikProps.submitForm()}
                    >
                      Save Settings
                    </Button>
                  )}
                </div>
              }
            >
              <div className="h-full overflow-y-auto">
                <div className="desktop:mx-11 mobile:mx-4 desktop:p-11 p-6 bg-indigo-100 bg-opacity-50 rounded-lg mt-4  overflow-y-auto flex items-center">
                  <div className="laptop:flex mobile:hidden justify-center desktop:mr-12 mr-6 w-96 shrink">
                    <img
                      src="https://assets.cornercart.io/coca-v2/images/empty-states/dynamic-accordion.png"
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="max-w-4xl">
                    <h1 className="font-bold text-gray-700 text-xl desktop:text-2xl ">
                      How product accordions work?
                    </h1>
                    <p className="mt-1 desktop:mt-3 text-gray-700">
                      Product Accordions are rendered at the bottom of every
                      product page by default. The questions in this accordion
                      are populated according to rules you have defined in the
                      articles. You can customize these rules by clicking{" "}
                      <b>Link to a product</b> button on an article's edit page.{" "}
                    </p>
                  </div>
                </div>
                <div className="laptop:w-1/2 mobile:w-full  p-8 ">
                  <h2 className="font-semibold mb-4 text-gray-700">
                    Customise Product Accordion
                  </h2>
                  <CustomiserUnit
                    title="Enable Product Accordion"
                    description="An accordion that appears on the bottom of every product page "
                  >
                    <Select
                      isSearchable={false}
                      value={accordionStatus.find(
                        (option) =>
                          option.value === formikProps.values.is_active
                      )}
                      onChange={(e) =>
                        formikProps.setFieldValue("is_active", e.value)
                      }
                      className="w-48 mr-2"
                      classNamePrefix="react-select"
                      options={accordionStatus}
                      placeholder="Turn on widget"
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    title="Embed Options"
                    description="By default product accordions are rendered at the bottom of every product page. But if you are planning to place the accordion in a custom location in code uncheck the checkbox & use the embed code below. Just keep in mind this accordion can only be placed inside a product page"
                  >
                    {formikProps.values.type === "dynamic" && (
                      <Checkbox
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "config.blockAutoRender",
                            !e.target.checked
                          );
                        }}
                        label="Auto embed below every product pages"
                        name="checkbox-basic"
                        defaultChecked={
                          !formikProps.values.config.blockAutoRender
                        }
                      />
                    )}
                    {formikProps.values.config.blockAutoRender && (
                      <div className="mt-3">
                        <div className="border rounded-lg  bg-gray-100  p-3 ">
                          <small className="font-bold text-xs text-gray-700 mb-4">
                            EMBED CODE
                          </small>
                          <div className="font-mono text-sm text-violet-700">{`<div class="coca-accordion" data-accordion-type="${formikProps.values.type}" data-accordion-id="${formikProps.values.id}"/>`}</div>
                        </div>
                        <small className="text-amber-600 text-xs mt-3">
                          Ping us in chat if you need help in custom placement
                          of the accodion
                        </small>
                      </div>
                    )}
                  </CustomiserUnit>
                  <CustomiserUnit
                    title="Accordion Title"
                    description="Customize the title that appears above the accordion"
                  >
                    <Textfield
                      defaultValue={formikProps.values.config.title}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "config.title",
                          e.target.value
                        );
                      }}
                      name="basic"
                    />
                  </CustomiserUnit>
                  <CustomiserUnit
                    title="Question Text Color"
                    description="Customise the color of questions of each FAQ"
                  >
                    <div className=" flex items-center">
                      <ColorPicker
                        color={formikProps.values.config.questionTextColor}
                        onChange={(color) => {
                          formikProps.setFieldValue(
                            "config.questionTextColor",
                            convertRGB(color.rgb)
                          );
                        }}
                      />
                      <small className="ml-2">Select Color</small>
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Question Background Color"
                    description="Customise the background color of questions "
                  >
                    <div className=" flex items-center">
                      <ColorPicker
                        color={formikProps.values.config.questionBgColor}
                        onChange={(color) => {
                          formikProps.setFieldValue(
                            "config.questionBgColor",
                            convertRGB(color.rgb)
                          );
                        }}
                      />
                      <small className="ml-2">Select Color</small>
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Answer Background Color"
                    description="Customise the background color of answers of each FAQ"
                  >
                    <div className=" flex items-center">
                      <ColorPicker
                        color={formikProps.values.config.answerBgColor}
                        onChange={(color) => {
                          formikProps.setFieldValue(
                            "config.answerBgColor",
                            convertRGB(color.rgb)
                          );
                        }}
                      />
                      <small className="ml-2">Select Color</small>
                    </div>
                  </CustomiserUnit>

                  <CustomiserUnit
                    title="Custom CSS"
                    description="Use this option to customise the accordion to any style. If you're not familiar with CSS, ping us in chat and we could help you out "
                  >
                    <div className="flex">
                      <small className="border rounded-lg bg-gray-100 p-3 mb-3">
                        Use the class{" "}
                        <span className="font-mono rounded-lg bg-gray-700 text-blue-200 px-2 py-1">
                          .accordion-id-{formikProps.values.id}
                        </span>{" "}
                        in css to select this particular accordion
                      </small>
                    </div>
                    <TextArea
                      defaultValue={formikProps.values.custom_css}
                      onChange={(e) => {
                        formikProps.setFieldValue("custom_css", e.target.value);
                      }}
                      name="basic"
                      minimumRows={7}
                    />
                    <small className="text-amber-600 text-xs mt-4">
                      Ping us in chat if you need help to write CSS to match
                      your theme
                    </small>
                  </CustomiserUnit>
                </div>
              </div>
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default ProductAccordionEdit;
