import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../../../../contexts/WorkspaceContext";
import Select from "@atlaskit/select";
import {
  RiCloseFill,
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiArrowDropDownLine,
} from "react-icons/ri";

/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Preview({ values }) {
  const workspace = useWorkspace();
  const [language, setLanguage] = useState(values.message[0].language);
  const [message, setMessage] = useState(values.message[0].content);

  const options = values.message.map((item) => {
    return {
      label: item.language,
      value: item.language,
    };
  });

  useEffect(() => {
    let currMessage = values.message.find((item) => item.language === language);
    if (currMessage) setMessage(currMessage.content);
  }, [language, values]);

  const renderCartItemPlaceholder = () => {
    return (
      <div className="w-full flex px-3 py-6 border-b ">
        <div className="w-12 h-12 bg-gray-200 rounded-lg"></div>
        <div className="grow ml-3">
          <div className="bg-gray-200 h-2 w-1/2 rounded-full"></div>
          <div className="bg-gray-200 h-2 w-1/3 rounded-full mt-2"></div>
          <div className="flex justify-end">
            <div className="bg-gray-200 h-3 w-6 rounded-full mt-2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="flex w-full justify-center relative h-80 rounded-t-lg overflow-hidden max-w-sm grow">
          <div className=" absolute top-0 bg-gradient-to-b from-white  w-full h-80 p-3 z-10"></div>
          <div className=" absolute top-0 bg-gradient-to-b from-gray-500 w-full h-40 p-3 z-10"></div>

          <div className="flex flex-col px-3 pt-3 z-20 w-full">
            {/* Header */}
            <div className=" flex justify-between items-center w-full">
              <p className="text-lg font-bold text-white">Cart</p>
              <p className="text-xs shadow-lg bg-white rounded-full text-gray-700 px-2 flex items-center">
                <RiCloseFill />
                <span> Close</span>
              </p>
            </div>
            {/* content wrapper */}
            <div className="grow pb-8 rounded-t-lg mt-2 bg-white shadow-lg  overflow-hidden">
              {/* Message Banner */}
              <div
                style={{
                  color: values.attributes.textColor,
                  background: values.attributes.bgColor,
                }}
                className="px-2 py-3 rounded-t-lg flex items-center justify-center text-sm text-center"
              >
                <span
                  className="mx-3"
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              </div>

              {renderCartItemPlaceholder()}
              {renderCartItemPlaceholder()}
            </div>
            <div className="bg-gradient-to-b from-white h-6"></div>
          </div>
        </div>
      </div>
      {options.length > 1 && (
        <div className="flex justify-center items-center w-full mt-1 mb-2 z-30 text-sm">
          <span>Preview Language</span>
          <Select
            // appearance="none"
            defaultValue={{
              label: language,
              value: language,
            }}
            onChange={(selectedObject) => {
              setLanguage(selectedObject.value);
            }}
            className="w-20 font-bold"
            options={options}
            placeholder="Select preview language "
          />
        </div>
      )}
    </>
  );
}
