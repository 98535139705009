import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  RiFileWarningFill,
  RiEyeFill,
  RiEyeOffFill,
  RiPaintBrushFill,
} from "react-icons/ri";
import Button from "../../../../../components/Button";
import TabRadioButton from "../../../../../components/TabRadioButton";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../../api/widgets";
import toaster from "toasted-notes";
import { useGraphQl } from "../../../../../contexts/GraphqlClient";
import PageViewContainer from "../../../../../components/structure/PageViewContainer";
import CartButtonSettings from "./CartButtonSettings";
import { Formik } from "formik";
import WidgetPreview from "../../WidgetPreview";
import useClearCache from "../../../../../utilities/useClearCache";

//convert screen sticky bar
export default function MiniCart(props) {
  const graphQL = useGraphQl();
  const [initialValues, setInitialValues] = useState();
  const clearCache = useClearCache();

  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      clearCache();
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const WidgetStates = [
    {
      label: (
        <div className="flex items-center">
          <RiEyeFill className="flex-none  mr-2 text-lg" /> Enabled{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiEyeOffFill className="flex-none mr-2 text-lg" /> Disabled{" "}
        </div>
      ),
      value: false,
    },
  ];

  const handleIsActiveChange = (value) => {
    updateWidgetMutation({
      id: { id: data.widgets_cornerwidget[0].id },
      config: {
        cart_launcher_config: {
          ...data.widgets_cornerwidget[0].cart_launcher_config,
          isActive: value,
        },
      },
    });
  };
  useEffect(() => {
    if (data) setInitialValues(data.widgets_cornerwidget[0]);
  }, [data]);

  return (
    <PageViewContainer
      title="Floating Mini Cart"
      action={
        <div className="flex items-center">
          <p className="mr-3 font-bold text-sm desktop:text-base text-gray-700 ">
            Mini Cart Status :{" "}
          </p>
          {data && (
            <TabRadioButton
              defaultValue={
                data.widgets_cornerwidget[0].cart_launcher_config.isActive
              }
              options={WidgetStates}
              onChange={(selectedValue) => {
                handleIsActiveChange(selectedValue);
              }}
            />
          )}
        </div>
      }
    >
      <div className="flex flex-col h-full">
        {data &&
          !data.widgets_cornerwidget[0]?.cart_launcher_config?.isActive && (
            <div className="bg-amber-300 text-amber-800 text-center py-2 flex items-center justify-center">
              <RiFileWarningFill className="text-xl mr-3" />
              <p className="text-sm">
                The Mini Cart is currently disabled. Enable it on your store
                using the toggle button in the top right
              </p>
            </div>
          )}
        {initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
              updateWidgetMutation({
                id: { id: values.id },
                config: {
                  cart_config: values.cart_config,
                  cart_launcher_config: values.cart_launcher_config,
                  corner_cart_lang: values.corner_cart_lang,
                },
              });
            }}
          >
            {({ values, dirty, submitForm, setFieldValue, resetForm }) => {
              return (
                <>
                  {dirty && (
                    <div className="z-30 sticky top-0 flex justify-between border-b bg-amber-100 items-center py-3 desktop:px-8 px-4 ">
                      <p>You've unsaved changes</p>
                      <div className="flex items-center">
                        <Button
                          onClick={() => {
                            resetForm();
                          }}
                          type="link"
                        >
                          Discard Changes
                        </Button>
                        <Button
                          onClick={() => {
                            submitForm();
                          }}
                          type="primary"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className=" h-full flex flex-row items-stretch min-h-0">
                    <div className="desktop:max-w-lg laptop:max-w-md mobile:w-full px-6 desktop:px-8 overflow-y-auto pb-12">
                      <div className="mt-5 p-3 border rounded-lg flex items-center ">
                        <div className="text-violet-700 text-2xl p-3 rounded-full bg-violet-100">
                          <RiPaintBrushFill />
                        </div>
                        <p className="ml-3 text-sm desktop:text-base">
                          The colors of the Mini Cart will be matching colors
                          setup for the Cart Drawer
                        </p>
                      </div>
                      <div>
                        <CartButtonSettings
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div className="grow flex-wrap py-3 overflow-hidden h-full bg-gray-100 border-l mobile:hidden laptop:flex items-center justify-center ">
                      <WidgetPreview
                        cartConfig={values.cart_config}
                        cartLauncherConfig={values.cart_launcher_config}
                        cornerCartWidgetLang={values.corner_cart_lang}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </PageViewContainer>
  );
}
