import React, { useState } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import ViralIntro from "./ViralReferral/Intro";
import ViralFinishing from "./ViralReferral/Finishing";
import ViralGift from "./ViralReferral/Gift";
import ViralThankyouPage from "./ViralReferral/ThankyouPage";
import ViralReward from "./ViralReferral/Reward";
import SimpleIntro from "./SimpleReferral/Intro";
import SimpleFinishing from "./SimpleReferral/Finishing";
import SimpleGift from "./SimpleReferral/Gift";
import SimpleThankyouPage from "./SimpleReferral/ThankyouPage";
import SimpleReward from "./SimpleReferral/Reward";
import LanguageSelector from "./LanguageSelector";
import CampaignSelector from "./CampaignSelector";
import ThankYouPreview from "../../offers/Referral/ViralReferralEdit/ThankyouPage/ThankYouPreview";

export default function OfcoOnboarding() {
  let { path } = useRouteMatch();

  const [currentStep, setCurrentStep] = useState(1);

  const [viralThankyouPageConfig, setViralThankyouPageConfig] = useState({
    bgStartColor: "rgb(255,255,255)",
    bgEndColor: "rgb(217, 238, 255)",
    fgColor: "dark",
    ctaColor: "rgb(62,119,255)",
    popupThemeColor: "rgb(62,119,255)",
    widgetPosition: 4,
    minOrderValue: 0,
  });
  const [simplePostPurchaseConfig, setSimplePostPurchaseConfig] = useState({
    bgStartColor: "rgb(255,255,255)",
    bgEndColor: "rgb(217, 238, 255)",
    fgColor: "dark",
    ctaColor: "rgb(62,119,255)",
    popupThemeColor: "rgb(62,119,255)",
    widgetPosition: 4,
    minOrderValue: 0,
    giftPageColor: "rgb(62,119,255)"
  });

  const [simpleRewardConfig, setSimpleRewardConfig] = useState({
    rewardValue: -10,
    rewardDimension: "percentage",
    targetType: "all",
    minimumRequirementType: "none",
    targetItems: {
      productIds: [],
      collectionIds: [],
    },
    minimumRequirementValue: 1,
  });

  const [maxDiscountValue, setMaxDiscountValue] = useState(999999999);

  const [rewardType, setRewardType] = useState("cashback");
  const [giftStage, setGiftStage] = useState({
    discountType: "percentage",
    discountValue: -10,
    targetType: "all",
    minimumRequirementType: "none",
    targetItems: {
      productIds: [],
      collectionIds: [],
    },
    minimumRequirementValue: 1,
  });

  const renderOnboardingStage = () => {
    switch (currentStep) {
      case 1:
        return (
          <LanguageSelector
            goFront={() => {
              setCurrentStep(currentStep + 1);
            }}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
      case 2:
        return (
          <SimpleIntro
            goFront={() => {
              setCurrentStep(currentStep + 1);
            }}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
      case 3:
        return (
          <SimpleThankyouPage
            simplePostPurchaseConfig={simplePostPurchaseConfig}
            setSimplePostPurchaseConfig={setSimplePostPurchaseConfig}
            rewardType={rewardType}
            simpleRewardConfig={simpleRewardConfig}
            simpleGiftConfig={giftStage}
            goFront={() => {
              setCurrentStep(currentStep + 1);
            }}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
      case 4:
        return (
          <SimpleGift
            giftStage={giftStage}
            setGiftStage={setGiftStage}
            rewardType={rewardType}
            maxDiscountValue={maxDiscountValue}
            simpleRewardConfig={simpleRewardConfig}
            goFront={() => {
              setCurrentStep(currentStep + 1);
            }}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
      case 5:
        return (
          <SimpleReward
            rewardType={rewardType}
            setRewardType={setRewardType}
            giftConfig={giftStage}
            maxDiscountValue={maxDiscountValue}
            setMaxDiscountValue={setMaxDiscountValue}
            simpleRewardConfig={simpleRewardConfig}
            setSimpleRewardConfig={setSimpleRewardConfig}
            goFront={() => {
              setCurrentStep(currentStep + 1);
            }}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
      case 6:
        return (
          <SimpleFinishing
            simpleRewardConfig={simpleRewardConfig}
            maxDiscountValue={maxDiscountValue}
            rewardType={rewardType}
            giftStage={giftStage}
            simplePostPurchaseConfig={simplePostPurchaseConfig}
            goBack={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        );
    }
  };

  return (
    <div className="flex overflow-y-auto h-screen w-screen justify-center items-center p-8">
      {renderOnboardingStage()}
    </div>
  );
}
