import React, { useState, useEffect } from "react";
import { RiAlertFill } from "react-icons/ri";
import { Radio } from "@atlaskit/radio";
import Popup from "@atlaskit/popup";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import {
  suportedCountries,
  suportedOsForTargeting,
} from "../defaultValues";

/**
 * This renders base level rules in the json Logic
 * @component
 * @param {object} rule - The rule that is needded to be rendered
 * @param {Boolean} firstItem - Flag to define if this is the first rule in the rule group (if yes there are certain style diferences)
 * @param {Function} onChange - What happens onChange
 * @param {Function} onDelete - What happens on Delete
 * @param {Object} variableDetails - Details for the variable type. There are different variable types and they are all defined in the variables folder in utilities
 */
const RuleItem = ({
  rule,
  firstItem,
  onChange,
  onDelete,
  variableDetails,
  key,
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const relation = Object.keys(rule)[0];
  const ruleVariable = (relation === "in" || relation === "not-in") ? rule[relation][1].var : rule[relation][0].var;
  const value = (relation === "in" || relation === "not-in") ? rule[relation][0] : rule[relation][1];
  const [ruleRelation, setRuleRelation] = useState(relation);
  const [ruleValue, setRuleValue] = useState(value);

  let isValid = ruleValue !== "";

  const relationToString = {
    "==": "is",
    "!=": "is not",
    ">": "greater than",
    "<": "less than",
    startsWith: "starts with",
    endsWith: "ends with",
    in: "contains",
    "not-in": "does not contain",
  };

  /**
   * Converts the values we have in hand (ruleValue, ruleRelation,ruleVariable) to a JSONLogic format which could be passed on to the onChange function
   */
  const handleRuleChange = () => {
    let updatedRule
    if (ruleRelation === "in" || ruleRelation === "not-in") { // incase of array we need to make sure the value comes before variable
      updatedRule = {
        [ruleRelation]: [
          ruleValue,
          {
            var: ruleVariable,
          },
        ],
      };
    }
    else {
      updatedRule = {
        [ruleRelation]: [
          {
            var: ruleVariable,
          },
          ruleValue,
        ],
      };
    }

    onChange(updatedRule);
  };

  /**
   * Different types of rules need different types of edit popups. This fucntion contents of edit popup based on the rule type
   */
  const renderRuleEditor = () => {
    const arrayOptions = [
      { value: "in", label: "contain" },
      { value: "not-in", label: "does not contain" },
    ];
    const stringOptions = [
      { value: "==", label: "is" },
      { value: "!=", label: "is not" },
      { value: "in", label: "contains" },
    ];
    const listOptions = [
      { value: "==", label: "is" },
      { value: "!=", label: "is not" },
    ];
    const numberOptions = [
      { value: "==", label: "is" },
      { value: "!=", label: "is not" },
      { value: ">", label: "greater than" },
      { value: "<", label: "less than" },
    ];
    const booleanOptions = [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ];

    const renderOption = () => {
      switch (variableDetails.type) {
        case "country": {
          return (
            <div>
              {listOptions.map((option, index) => {
                return (
                  <div key={`country-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleRelation === option.value}
                      onChange={(e) => {
                        setRuleRelation(e.target.value);
                      }}
                    />
                    {option.value === ruleRelation && (
                      <div className="flex justify-center items-center px-3">
                        <Select
                          onChange={(selectedCountry) => {
                            setRuleValue(selectedCountry.value);
                          }}
                          className="w-48 mr-2 grow"
                          options={suportedCountries}
                          placeholder="Select a country"
                          value={suportedCountries.find(
                            (option) => option.value === ruleValue
                          )}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
        case "os": {
          return (
            <div>
              {listOptions.map((option, index) => {
                return (
                  <div key={`os-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleRelation === option.value}
                      onChange={(e) => {
                        setRuleRelation(e.target.value);
                      }}
                    />
                    {option.value === ruleRelation && (
                      <div className="flex justify-center items-center px-3">
                        <Select
                          onChange={(selectedOS) => {
                            setRuleValue(selectedOS.value);
                          }}
                          className="w-48 mr-2 grow"
                          options={suportedOsForTargeting}
                          placeholder="Select an OS"
                          value={suportedOsForTargeting.find(
                            (option) => option.value === ruleValue
                          )}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
        case "array": {
          return (
            <div>
              {arrayOptions.map((option, index) => {
                return (
                  <div key={`array-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleRelation === option.value}
                      onChange={(e) => {
                        setRuleRelation(e.target.value);
                      }}
                    />
                    {option.value === ruleRelation && (
                      <Textfield
                        value={ruleValue}
                        onChange={(e) =>
                          setRuleValue(e.target.value.toLowerCase())
                        }
                        name="basic"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
        case "string": {
          return (
            <div>
              {stringOptions.map((option, index) => {
                return (
                  <div key={`string-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleRelation === option.value}
                      onChange={(e) => {
                        setRuleRelation(e.target.value);
                      }}
                    />
                    {option.value === ruleRelation && (
                      <Textfield
                        value={ruleValue}
                        onChange={(e) =>
                          setRuleValue(e.target.value.toLowerCase())
                        }
                        name="basic"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
        case "number": {
          return (
            <div>
              {numberOptions.map((option, index) => {
                return (
                  <div key={`number-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleRelation === option.value}
                      onChange={(e) => {
                        setRuleRelation(e.target.value);
                      }}
                    />
                    {option.value === ruleRelation && (
                      <Textfield
                        value={ruleValue}
                        type="number"
                        onChange={(e) => {
                          setRuleValue(parseFloat(e.target.value));
                        }}
                        name="basic"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
        case "boolean": {
          return (
            <div>
              {booleanOptions.map((option, index) => {
                return (
                  <div key={`boolean-options-${index}`}>
                    <Radio
                      value={option.value}
                      label={option.label}
                      isChecked={ruleValue == option.value}
                      onChange={({ target: { value, checked } }) => {
                        if (value === "false" && checked) setRuleValue(false);
                        if (value === "true" && checked) setRuleValue(true);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        }
      }
    };

    return (
      <div id="popup-editer" className="edit-popper overflow-visible">
        <div className="px-4 pt-4 p">{renderOption()}</div>
        <div className="flex justify-end mt-3 border-t">
          <button
            onClick={() => {
              onDelete();
              setIsEditPopupOpen(false);
            }}
            type="danger-link"
            className="w-1/2 flex text-tiny items-center justify-center px-5 py-2 hover:bg-red-100 text-red-700 font-bold border-r"
            noPadding
          >
            Remove{" "}
          </button>
          <button
            onClick={() => {
              handleRuleChange();
              setIsEditPopupOpen(false);
            }}
            type="danger-link"
            className="w-1/2 flex text-tiny items-center justify-center px-5 py-2 hover:bg-indigo-100 text-violet-700 font-bold"
            noPadding
          >
            Save{" "}
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // when you delete a rule just behind this one the rulevalue doesnt updatez and this takes care of it
    setRuleValue(relation === "in" || relation === "not-in" ? rule[relation][0] : rule[relation][1]);
  }, [rule]);

  return (
    <Popup
      isOpen={isEditPopupOpen}
      placement="top-start"
      boundary={() => (
        <div id="popup-editer" className="edit-popper overflow-visible"></div>
      )}
      onClose={() => {
        handleRuleChange();
        setIsEditPopupOpen(false);
      }}
      content={() => renderRuleEditor()}
      trigger={(triggerProps) => (
        <button
          {...triggerProps}
          onClick={() => setIsEditPopupOpen((prevState) => !prevState)}
          className={`shrink-0 my-1 transition-all cursor-pointer ${firstItem && "rounded-l-lg"
            }  ${isEditPopupOpen
              ? "bg-white border text-violet-700 border-violet-700 shadow-lg"
              : `bg-gray-100 border border-r-gray-100 ${firstItem ? "border-l-gray-200" : "border-l-gray-100"
              } hover:bg-white hover:text-violet-700  hover:border-violet-700 hover:shadow-lg`
            } ${!isValid && "text-red-700 animate-pulse"} `}
        >
          {ruleVariable && ruleValue !== null && ruleRelation && (
            <div className="flex items-center p-2 text-tiny ">
              <span className="mr-2 h-full flex items-center">
                {isValid ? (
                  variableDetails.icon
                ) : (
                  <RiAlertFill size={12} className="animate-bounce" />
                )}
              </span>
              <span>

                <span className="font-bold">{variableDetails.label}</span>
                {isValid ? (
                  <>
                    <i className="ml-1">{relationToString[ruleRelation]}</i>
                    <span key={`${key}-rule-value`} className="ml-1 ">
                      {ruleValue.length > 20
                        ? `${ruleValue.slice(0, 20)}...`
                        : `${ruleValue}`}
                    </span>
                  </>
                ) : (
                  <span className="ml-1">is missing value</span>
                )}
              </span>
            </div>
          )}
        </button>
      )}
    />
  );
};
export default RuleItem;
