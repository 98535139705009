import React, { Children } from "react";
import { FaChessKnight } from "react-icons/fa";
import { RiVipCrown2Fill } from "react-icons/ri";

const PaywallBadge = (props) => {
  return (
    <div
      className={`${
        props.absolute && "absolute -top-1 -right-3 "
      } flex felx-shrink-0 h-6 min-w-min items-center text-xs mx-1 text-white bg-violet-700  px-1 rounded-full`}
    >
      {" "}
      <span className="mr-1">
        {props.type == "lite" ? <FaChessKnight /> : <RiVipCrown2Fill />}
      </span>{" "}
      {props.type == "lite" ? "Standard" : "Pro"}
    </div>
  );
};

export default PaywallBadge;
