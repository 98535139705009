import {
  RiHandCoinFill,
  RiTimeFill,
  RiPauseCircleFill,
  RiPlayCircleFill,
  RiAlarmWarningFill,
} from "react-icons/ri";
import CartGoalExample from "../pages/cart/Cro/Campaigns/campaignExamples/CartGoalExample";
import OneClickExample from "../pages/cart/Cro/Campaigns/campaignExamples/OneClickExample";
import CartAnnouncementExample from "../pages/cart/Cro/Campaigns/campaignExamples/CartAnnouncementExample";
import DiscountCodeExample from "../pages/cart/Cro/Campaigns/campaignExamples/DiscountCodeExample";
import BxGyExample from "../pages/cart/Cro/Campaigns/campaignExamples/BxGyExample";
import CartTimerExample from "../pages/cart/Cro/Campaigns/campaignExamples/CartTimerExample";

// default value passed to table while creating a one click upsell
export const defaultOneClickUpsell = {
  upsellConfig: {
    isPreSelected: false,
    upsellDisplayText: "Add {{title}} for {{amount}}",
    isDisplayedinCart: false,
    isImageVisible: true,
    ctaType: "checkbox",
  },
  upsellProduct: {
    target: null,
    preferenceType: "userSelect",
    preferenceValue: "",
  },
};

/* default value passed to table while creating a one click upsell */
export const defaultCartAnnouncement = {
  message: [
    {
      language: "",
      content: "<p>Write Your Message Here</p>",
    },
  ],
  attributes: {
    textColor: "#000000",
    bgColor: "#efefef",
  },
  config: {},
};

/* default value passed to table while creating a cart goal */
export const defaultCartGoal = {
  goalType: "totalOrderValue",
  goalUnit: "",
  stackable: true,
  config: {
    featureBelowCartItems: false,
    featureInOffersPage: true,
    mandatoryRewardSelection: true,
    excludeExternalDiscounts: false,
  },
  accountid: "",
  milestones: [
    {
      id: Math.floor(Math.random() * 10000),
      goal: 400,
      content: {
        preGoalText: "",
        postGoalText: "",
        milestoneLabel: "",
        preGoalOfferPageTitle: "",
        postGoalOfferPageTitle: "",
        preGoalOfferPageSubTitle: "",
        postGoalOfferPageSubTitle: "",
      },
      rewards: {
        id: "",
        stackModifier: "all",
        stackSelectCount: 1,
        prerequisites: {
          ">=": [
            {
              var: "totalOrderValue",
            },
            400,
          ],
        },
        rewardStack: [],
      },
    },
  ],
};

export const defaultBXGY = {
  config: {
    type: "",
    usesPerOrderEnabled: false,
    usesPerOrderLimit: null,
    customerBuys: {
      items: {
        all: true,
        collections: {
          add: [],
          remove: [],
        },
        products: {
          productVariantsToAdd: [],
          productVariantsToRemove: [],
          productsToAdd: [],
          productsToRemove: [],
        },
      },
      value: {
        amount: "",
        quantity: "",
      },
    },
    rewards: {
      id: "",
      rewardStack: [],
      stackModifier: "any",
      stackSelectCount: 1,
      prerequisites: {},
    },
  },
};

export const defaultCartTimer = {
  config: {
    value: {
      type: "sessionTimer",
      datetimer: Date.now(),
      sessiontimer: {
        hours: 0,
        minutes: 5,
        seconds: 0,
      },
    },
    attributes: {
      preBgColor: "#efefef",
      preTextColor: "#000000",
      postBgColor: "#efefef",
      postTextColor: "#000000",
    },
    postTimerAction: "showMessage",
  },
  content: {
    lang: "en",
    value: {
      postCartTimerMessage: "<p>The Cart Timer Expired</p>",
      preCartTimerMessage: "<p>Your Cart will expire in {{timeRemaining}}</p>",
    },
  },
};

/** Array of Campaigns in the Campaign Create Section */
export const campaignCreateItemList = [
  {
    id: "cartGoal",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <CartGoalExample />
      </div>
    ),
    title: "Cart Goals",
    description:
      "Cart goal campaigns adds a progress bar at the top of the cart. When customers reach a milestone, for e.g order value reaches a minimum value, you can reward them with free shipping or gifts. You can have multiple milestones in the same campaign.",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 30%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">5-10 mins</span>
          </div>
        </div>
        <div className="border rounded-lg p-4 text-sm">
          <p className=" text-gray-700 font-bold">EXAMPLE USE-CASES</p>
          <ul className="list-disc ml-4 mt-4">
            <li className="mt-2">
              Add minimum 3 items in cart to get Free Shipping
            </li>
            <li className="mt-2">
              Offer a free gift when cart value reaches $150, but only if the
              customer is from USA or Canada
            </li>
            <li className="mt-2">
              Offer free shipping for cart value above $50 (1st milestone).
              Offer free shipping and a free gift if cart value is above $150
              (2nd milestone).
            </li>
          </ul>
        </div>
      </div>
    ),
    campaignSelectorId: "create-cart-goal-selector",
    ctaBtnId: "create-new-cart-goal",
    ctaText: "Create a Cart Goal",
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a0ef1eaa-d3bf-4081-093d-1940ceb18a00/public",
  },
  {
    title: "Buy X get Y",
    id: "buyXGetY",
    description:
      "Give free gifts when customers buy a specific product or any product from a specific collection.",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 25%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">5-10 mins</span>
          </div>
        </div>
        <div className="border rounded-lg px-4 py-2 text-sm">
          <p className=" text-gray-700 font-bold flex items-center">
            <span className="p-2 bg-red-100 rounded-full">
              <RiAlarmWarningFill color="red" size={12} />
            </span>
            <span className="ml-2 text-red-700">
              Current limitations to keep in mind
            </span>
          </p>
          <ul className="list-disc ml-4 ">
            <li className="mt-2">
              Multiple campaigns (For e.g 2 BXGY campaigns, or 1 BXGY and 1 Cart
              Goal campaign) giving the same product as free gift is currently
              not supported
            </li>
            <li className="mt-2">
              Running a BXGY campaign together with a Cart Goal campaign with
              order discount as a reward is not supported. We recommend offering
              free gifts or free shipping as Cart Goal rewards if you are
              running BXGY campaigns at the same time.
            </li>
            <li className="mt-2">
              Having 2 BXGY campaigns with overlapping conditions is not
              supported. For instance, if a campaign offers a free gift for
              buying any product from collection A and another requires
              purchasing product X, only one gift will be marked FREE by Shopify
              if product X belongs to collection A.
            </li>
          </ul>
        </div>
      </div>
    ),
    experimental: true,
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/a20afe40-8ea1-4a80-57e3-210ed523c700/public",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <BxGyExample />
      </div>
    ),
    campaignSelectorId: "",
    ctaBtnId: "create-new-buy-xget-y-campaign",
    ctaText: "Create a Buy X Get Y Campaign",
  },
  {
    id: "oneClickUpsell",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <OneClickExample />
      </div>
    ),
    title: "One Click Upsell",
    description:
      "Upsell add-on products, using a one click checkbox above checkout button.",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 15%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">2-3 mins</span>
          </div>
        </div>
        <div className="border rounded-lg p-4 text-sm">
          <p className=" text-gray-700 font-bold">Few one click upsell ideas</p>
          <ul className="list-disc ml-4 mt-4">
            <li className="mt-2">🎁 Gift Wrapping</li>
            <li className="mt-2">🚚 Priority Shipping</li>
            <li className="mt-2">🛡 Shipping Insurance</li>
            <li className="mt-2">🔋 Extra batteries</li>
            <li className="mt-2">📕 Ebook</li>
            <li className="mt-2">🗓 Extended Warranty</li>
          </ul>
        </div>
      </div>
    ),
    campaignSelectorId: "create-one-click-selector",
    ctaBtnId: "create-one-click-campaign-new",
    ctaText: "Create One Click Upsell",
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/4a504c23-2ded-4e39-9cd2-5cf7ee0ba400/public",
  },
  {
    id: "cartAnnouncement",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <CartAnnouncementExample />
      </div>
    ),
    title: "Cart Announcement Bar",
    description:
      "Add an anouncement bar at the top of your cart. This bar is ideal to showcasing shipping detials or currently running offers",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 5%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">1-2 mins</span>
          </div>
        </div>
        <div className="border rounded-lg p-4 text-sm">
          <p className=" text-gray-700 font-bold">Few Announcement Bar Ideas</p>
          <ul className="list-disc ml-4 mt-4">
            <li className="mt-2">🏷 Feature Discount Codes</li>
            <li className="mt-2">🚚 Shipping Related Messages</li>
            <li className="mt-2">🎄 Holiday Sales Messages </li>
            <li className="mt-2">⚠️ Product Warnings</li>
            <li className="mt-2">🎊 Site wide Discounts</li>
          </ul>
        </div>
      </div>
    ),
    campaignSelectorId: "create-cart-announcement-selector",
    ctaBtnId: "create-new-announcement-bar",
    ctaText: "Create Announcement Bar",
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/51031b76-5b7a-44dc-8bf2-bc1730279800/public",
  },
  {
    title: "Discount Code",
    id: "discountCode",
    description:
      "Feature discount codes in your cart, so your customers can easily apply them and save money.",
    content: "",
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/e1e5d495-5af2-409e-6464-428f62bc6b00/public",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <DiscountCodeExample />
      </div>
    ),
    campaignSelectorId: "",
    ctaBtnId: "create-new-discount-code-display-campaign",
    ctaText: "Create a Discount Code Campaign",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 15%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">2-5 mins</span>
          </div>
        </div>
        <div className="border rounded-lg p-4 text-sm">
          <p className=" text-gray-700 font-bold">Attention</p>
          <p>
            Before you run this campaign, you need to create the discount codes
            in the Shopify discount tab. Our app will not create the discont
            code in the shopify dashboard
          </p>
        </div>
      </div>
    ),
  },

  {
    id: "cartTimer",
    descriptionImage: (
      <div className="h-40 bg-gradient-to-b from-violet-100 flex items-center justify-center">
        <CartTimerExample />
      </div>
    ),
    title: "Cart Timer",
    description: "Show a countdown timer at the top of your cart",
    content: (
      <div>
        <div className="mb-4">
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiHandCoinFill />
            <span className="ml-3">Potential AOV boost :</span>{" "}
            <span className="ml-3 text-green-600">Upto 5%</span>
          </div>
          <div className="flex items-center text-sm font-bold text-gray-600">
            <RiTimeFill />
            <span className="ml-3">Time to set up :</span>{" "}
            <span className="ml-3 text-green-600">1-2 mins</span>
          </div>
        </div>
        <div className="border rounded-lg p-4 text-sm">
          <p className=" text-gray-700 font-bold">EXAMPLE USE-CASES</p>
          <ul className="list-disc ml-4 mt-4">
            <li className="mt-2">
              “Cart expires in” messages for creating a sense of urgency
            </li>
            <li className="mt-2">
              “Offer expires in” message to accompany a campaign.
            </li>
            <li className="mt-2">Countdown to the day of a product launch </li>
            <li className="mt-2">
              Countdown to the day of a product give away
            </li>
          </ul>
        </div>
      </div>
    ),
    campaignSelectorId: "create-cart-timer-selector",
    ctaBtnId: "create-new-timer",
    ctaText: "Create Cart Timer",
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/4406cd20-a2fc-4de0-cec7-58f2efb0dd00/public",
  },
  {
    title: "Volume Discount",
    id: "volume",
    description: "Lorem ipsum dolor amit vertas diago liera veritas",
    content: <div>Hello dear fireedn</div>,
    comingSoon: true,
    iconUrl:
      "https://imagedelivery.net/QI2wC6Vls23vJd9vFFaomg/d15ecc38-512a-4f44-a4ba-eb515ed97100/public",
    descriptionImage: <div>Hello dear fireedn</div>,
    campaignSelectorId: "",
    ctaBtnId: "create-new-cart-timer",
    ctaText: "Add a Timer to the cart",
  },
];

export const visibilityOptions = [
  {
    label: (
      <div className="flex items-center">
        <RiPlayCircleFill className="flex-none  mr-2 text-lg" /> Active{" "}
      </div>
    ),
    value: "active",
  },
  {
    label: (
      <div className="flex items-center">
        <RiPauseCircleFill className="flex-none mr-2 text-lg" /> Paused{" "}
      </div>
    ),
    value: "draft",
  },
];
