const langOptions = [
  { label: "Arabic", value: "ar" },
  { label: "German", value: "de" },
  { label: "Danish", value: "da" },
  { label: "English", value: "en" },
  { label: "Greek", value: "el" },
  { label: "Espanol", value: "es" },
  { label: "Persian", value: "fa" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Hebrew", value: "he" },
  { label: "Hungarian", value: "hu" },
  { label: "Indonesian", value: "id" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Korean", value: "ko" },
  { label: "Dutch", value: "nl" },
  { label: "Norwegian", value: "no" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese (Brazil)", value: "pt-BR" },
  { label: "Portuguese (Portugal)", value: "pt-PT" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Swedish", value: "sv" },
  { label: "Thai", value: "th" },
  { label: "Turkish", value: "tr" },
  { label: "Ukrainian", value: "uk" },
  { label: "Vietnamese", value: "vi" },
  { label: "Chinese", value: "zh-CN" },
  { label: "Taiwanese", value: "zh-TW" },
  { label: "Lithuanian", value: "lt" },
];
const suportedCurrencies = [
  {
    code: "AED",
    label: "United Arab Emirates Dirham (AED)",
    value: "AED {{amount}}",
  },
  {
    code: "AFN",
    label: "Afghan Afghani (AFN)",
    value: "؋ {{amount}}",
  },
  {
    code: "ALL",
    label: "Albanian Lek (ALL)",
    value: "Lek {{amount}}",
  },
  {
    code: "AMD",
    label: "Armenian Dram (AMD)",
    value: "դր. {{amount}}",
  },
  {
    code: "ANG",
    label: "Netherlands Antillean Guilder (ANG)",
    value: "ANG {{amount}}",
  },
  {
    code: "AOA",
    label: "Kwanza (AOA)",
    value: "AOA {{amount}}",
  },
  {
    code: "ARS",
    label: "Argentine Peso (ARS)",
    value: "$ {{amount}}",
  },
  {
    code: "AUD",
    label: "Australian Dollar (AUD)",
    value: "$ {{amount}}",
  },
  {
    code: "AWG",
    label: "Aruban Florin (AWG)",
    value: "AWG {{amount}}",
  },
  {
    code: "AZN",
    label: "Azerbaijani Manat (AZN)",
    value: "ман. {{amount}}",
  },
  {
    code: "BAM",
    label: "Bosnia-Herzegovina Convertible Mark (BAM)",
    value: "KM {{amount}}",
  },
  {
    code: "BBD",
    label: "Barbados Dollar (BBD)",
    value: "BBD {{amount}}",
  },
  {
    code: "BDT",
    label: "Bangladeshi Taka (BDT)",
    value: "৳ {{amount}}",
  },
  {
    code: "BGN",
    label: "Bulgarian Lev (BGN)",
    value: "лв. {{amount}}",
  },
  {
    code: "BIF",
    label: "Burundian Franc (BIF)",
    value: "FBu {{amount}}",
  },
  {
    code: "BMD",
    label: "Bermudian Dollar (BMD)",
    value: "BMD {{amount}}",
  },
  {
    code: "BND",
    label: "Brunei Dollar (BND)",
    value: "$ {{amount}}",
  },
  {
    code: "BOB",
    label: "Bolivian Boliviano (BOB)",
    value: "Bs {{amount}}",
  },
  {
    code: "BRL",
    label: "Brazilian Real (BRL)",
    value: "R$ {{amount}}",
  },
  {
    code: "BSD",
    label: "Bahamian Dollar (BSD)",
    value: "BSD {{amount}}",
  },
  {
    code: "BWP",
    label: "Botswanan Pula (BWP)",
    value: "P {{amount}}",
  },
  {
    code: "BZD",
    label: "Belize Dollar (BZD)",
    value: "$ {{amount}}",
  },
  {
    code: "CAD",
    label: "Canadian Dollar (CAD)",
    value: "$ {{amount}}",
  },
  {
    code: "CDF",
    label: "Congolese Franc (CDF)",
    value: "FrCD {{amount}}",
  },
  {
    code: "CHF",
    label: "Swiss Franc (CHF)",
    value: "CHF {{amount}}",
  },
  {
    code: "CLP",
    label: "Chilean Peso (CLP)",
    value: "$ {{amount}}",
  },
  {
    code: "CNY",
    label: "Chinese Yuan (CNY)",
    value: "CN¥ {{amount}}",
  },
  {
    code: "COP",
    label: "Colombian Peso (COP)",
    value: "$ {{amount}}",
  },
  {
    code: "CRC",
    label: "Costa Rican Colón (CRC)",
    value: "₡ {{amount}}",
  },
  {
    code: "CVE",
    label: "Cape Verdean Escudo (CVE)",
    value: "CV$ {{amount}}",
  },
  {
    code: "CZK",
    label: "Czech Republic Koruna (CZK)",
    value: "Kč {{amount}}",
  },
  {
    code: "DJF",
    label: "Djiboutian Franc (DJF)",
    value: "Fdj {{amount}}",
  },
  {
    code: "DKK",
    label: "Danish Krone (DKK)",
    value: "kr {{amount}}",
  },
  {
    code: "DOP",
    label: "Dominican Peso (DOP)",
    value: "RD$ {{amount}}",
  },
  {
    code: "DZD",
    label: "Algerian Dinar (DZD)",
    value: "DA {{amount}}",
  },
  {
    code: "EGP",
    label: "Egyptian Pound (EGP)",
    value: "EGP {{amount}}",
  },
  {
    code: "ETB",
    label: "Ethiopian Birr (ETB)",
    value: "Br {{amount}}",
  },
  {
    code: "EUR",
    label: "Euro (EUR)",
    value: "€ {{amount}}",
  },
  {
    code: "FJD",
    label: "Fiji Dollar (FJD)",
    value: "FJD {{amount}}",
  },
  {
    code: "FKP",
    label: "Falkland Islands Pound (FKP)",
    value: "FKP {{amount}}",
  },
  {
    code: "GBP",
    label: "British Pound Sterling (GBP)",
    value: "£ {{amount}}",
  },
  {
    code: "GEL",
    label: "Georgian Lari (GEL)",
    value: "GEL {{amount}}",
  },
  {
    code: "GIP",
    label: "GIP",
    value: "GIP {{amount}}",
  },
  {
    code: "GMD",
    label: "GMD",
    value: "GMD {{amount}}",
  },
  {
    code: "GNF",
    label: "Guinean Franc (GNF)",
    value: "FG {{amount}}",
  },
  {
    code: "GTQ",
    label: "Guatemalan Quetzal (GTQ)",
    value: "Q {{amount}}",
  },
  {
    code: "GYD",
    label: "GYD",
    value: "GYD {{amount}}",
  },
  {
    code: "HKD",
    label: "Hong Kong Dollar (HKD)",
    value: "$ {{amount}}",
  },
  {
    code: "HNL",
    label: "Honduran Lempira (HNL)",
    value: "L {{amount}}",
  },
  {
    code: "HRK",
    label: "Croatian Kuna (HRK)",
    value: "kn {{amount}}",
  },
  {
    code: "HTG",
    label: "Gourde (HTG)",
    value: "HTG {{amount}}",
  },
  {
    code: "HUF",
    label: "Hungarian Forint (HUF)",
    value: "Ft {{amount}}",
  },
  {
    code: "IDR",
    label: "Indonesian Rupiah (IDR)",
    value: "Rp {{amount}}",
  },
  {
    code: "ILS",
    label: "Israeli New Sheqel (ILS)",
    value: "₪ {{amount}}",
  },
  {
    code: "INR",
    label: "Indian Rupee (INR)",
    value: "₹ {{amount}}",
  },
  {
    code: "ISK",
    label: "Icelandic Króna (ISK)",
    value: "kr {{amount}}",
  },
  {
    code: "JMD",
    label: "Jamaican Dollar (JMD)",
    value: "$ {{amount}}",
  },
  {
    code: "JPY",
    label: "Japanese Yen (JPY)",
    value: "￥ {{amount}}",
  },
  {
    code: "KES",
    label: "Kenyan Shilling (KES)",
    value: "Ksh {{amount}}",
  },
  {
    code: "KGS",
    label: "Som (KGS)",
    value: "KGS {{amount}}",
  },
  {
    code: "KHR",
    label: "Cambodian Riel (KHR)",
    value: "៛ {{amount}}",
  },
  {
    code: "KMF",
    label: "Comorian Franc (KMF)",
    value: "FC {{amount}}",
  },
  {
    code: "KRW",
    label: "South Korean Won (KRW)",
    value: "₩ {{amount}}",
  },
  {
    code: "KYD",
    label: "KYD",
    value: "KYD {{amount}}",
  },
  {
    code: "KZT",
    label: "Kazakhstani Tenge (KZT)",
    value: "тңг. {{amount}}",
  },
  {
    code: "LAK",
    label: "Lao Kip (LAK)",
    value: "LAK {{amount}}",
  },
  {
    code: "LBP",
    label: "Lebanese Pound (LBP)",
    value: "L.L. {{amount}}",
  },
  {
    code: "LKR",
    label: "Sri Lankan Rupee (LKR)",
    value: "SL Re {{amount}}",
  },
  {
    code: "LRD",
    label: "Liberian Dollar (LRD)",
    value: "LRD {{amount}}",
  },
  {
    code: "LSL",
    label: "Loti (LSL)",
    value: "LSL {{amount}}",
  },
  {
    code: "MAD",
    label: "Moroccan Dirham (MAD)",
    value: "د.م.‏ {{amount}}",
  },
  {
    code: "MDL",
    label: "Moldovan Leu (MDL)",
    value: "MDL {{amount}}",
  },
  {
    code: "MGA",
    label: "Malagasy Ariary (MGA)",
    value: "MGA {{amount}}",
  },
  {
    code: "MKD",
    label: "Macedonian Denar (MKD)",
    value: "MKD {{amount}}",
  },
  {
    code: "MMK",
    label: "Myanma Kyat (MMK)",
    value: "K {{amount}}",
  },
  {
    code: "MNT",
    label: "Tugrik (MNT)",
    value: "MNT {{amount}}",
  },
  {
    code: "MOP",
    label: "Macanese Pataca (MOP)",
    value: "MOP$ {{amount}}",
  },
  {
    code: "MUR",
    label: "Mauritian Rupee (MUR)",
    value: "MURs {{amount}}",
  },
  {
    code: "MVR",
    label: "Rufiyaa (MVR)",
    value: "MVR {{amount}}",
  },
  {
    code: "MWK",
    label: "Malawi Kwacha (MWK)",
    value: "MWK {{amount}}",
  },
  {
    code: "MXN",
    label: "Mexican Peso (MXN)",
    value: "$ {{amount}}",
  },
  {
    code: "MYR",
    label: "Malaysian Ringgit (MYR)",
    value: "RM {{amount}}",
  },
  {
    code: "MZN",
    label: "Mozambican Metical (MZN)",
    value: "MTn {{amount}}",
  },
  {
    code: "NAD",
    label: "Namibian Dollar (NAD)",
    value: "N$ {{amount}}",
  },
  {
    code: "NGN",
    label: "Nigerian Naira (NGN)",
    value: "₦ {{amount}}",
  },
  {
    code: "NIO",
    label: "Nicaraguan Córdoba (NIO)",
    value: "C$ {{amount}}",
  },
  {
    code: "NOK",
    label: "Norwegian Krone (NOK)",
    value: "kr {{amount}}",
  },
  {
    code: "NPR",
    label: "Nepalese Rupee (NPR)",
    value: "नेरू {{amount}}",
  },
  {
    code: "NZD",
    label: "New Zealand Dollar (NZD)",
    value: "$ {{amount}}",
  },
  {
    code: "PAB",
    label: "Panamanian Balboa (PAB)",
    value: "B/. {{amount}}",
  },
  {
    code: "PEN",
    label: "Peruvian Nuevo Sol (PEN)",
    value: "S/. {{amount}}",
  },
  {
    code: "PGK",
    label: "Kina (PGK)",
    value: "PGK {{amount}}",
  },
  {
    code: "PHP",
    label: "Philippine Peso (PHP)",
    value: "₱ {{amount}}",
  },
  {
    code: "PKR",
    label: "Pakistani Rupee (PKR)",
    value: "₨ {{amount}}",
  },
  {
    code: "PLN",
    label: "Polish Zloty (PLN)",
    value: "zł {{amount}}",
  },
  {
    code: "PYG",
    label: "Paraguayan Guarani (PYG)",
    value: "₲ {{amount}}",
  },
  {
    code: "QAR",
    label: "Qatari Rial (QAR)",
    value: "QR {{amount}}",
  },
  {
    code: "RON",
    label: "Romanian Leu (RON)",
    value: "RON {{amount}}",
  },
  {
    code: "RSD",
    label: "Serbian Dinar (RSD)",
    value: "дин. {{amount}}",
  },
  {
    code: "RUB",
    label: "Russian Ruble (RUB)",
    value: "₽. {{amount}}",
  },
  {
    code: "RWF",
    label: "Rwandan Franc (RWF)",
    value: "FR {{amount}}",
  },
  {
    code: "SAR",
    label: "Saudi Riyal (SAR)",
    value: "SR ‏ {{amount}}",
  },
  {
    code: "SBD",
    label: "SBD",
    value: "SBD {{amount}}",
  },
  {
    code: "SCR",
    label: "SCR",
    value: "SCR {{amount}}",
  },
  {
    code: "SEK",
    label: "Swedish Krona (SEK)",
    value: "kr {{amount}}",
  },
  {
    code: "SGD",
    label: "Singapore Dollar (SGD)",
    value: "$ {{amount}}",
  },
  {
    code: "SHP",
    label: "Saint Helena Pound (SHP)",
    value: "SHP {{amount}}",
  },
  {
    code: "SLL",
    label: "Leone (SLL)",
    value: "SLL {{amount}}",
  },
  {
    code: "SRD",
    label: "Surinam Dollar (SRD)",
    value: "SRD {{amount}}",
  },
  {
    code: "STD",
    label: "Dobra (STD)",
    value: "STD {{amount}}",
  },
  {
    code: "SZL",
    label: "Lilangeni (SZL)",
    value: "SZL {{amount}}",
  },
  {
    code: "THB",
    label: "Thai Baht (THB)",
    value: "฿ {{amount}}",
  },
  {
    code: "TJS",
    label: "Somoni (TJS)",
    value: "TJS {{amount}}",
  },
  {
    code: "TOP",
    label: "Tongan Paʻanga (TOP)",
    value: "T$ {{amount}}",
  },
  {
    code: "TRY",
    label: "Turkish Lira (TRY)",
    value: "TL {{amount}}",
  },
  {
    code: "TTD",
    label: "Trinidad and Tobago Dollar (TTD)",
    value: "$ {{amount}}",
  },
  {
    code: "TWD",
    label: "New Taiwan Dollar (TWD)",
    value: "NT$ {{amount}}",
  },
  {
    code: "TZS",
    label: "Tanzanian Shilling (TZS)",
    value: "TSh {{amount}}",
  },
  {
    code: "UAH",
    label: "Ukrainian Hryvnia (UAH)",
    value: "₴ {{amount}}",
  },
  {
    code: "UGX",
    label: "Ugandan Shilling (UGX)",
    value: "USh {{amount}}",
  },
  {
    code: "USD",
    label: "US Dollar (USD)",
    value: "$ {{amount}}",
  },
  {
    code: "UYU",
    label: "Uruguayan Peso (UYU)",
    value: "$ {{amount}}",
  },
  {
    code: "UZS",
    label: "Uzbekistan Som (UZS)",
    value: "UZS {{amount}}",
  },
  {
    code: "VND",
    label: "Vietnamese Dong (VND)",
    value: "₫ {{amount}}",
  },
  {
    code: "VUV",
    label: "Vatu (VUV)",
    value: "VUV {{amount}}",
  },
  {
    code: "WST",
    label: "Tala (WST)",
    value: "WST {{amount}}",
  },
  {
    code: "XAF",
    label: "CFA Franc BEAC (XAF)",
    value: "FCFA {{amount}}",
  },
  {
    code: "XCD",
    label: "East Caribbean Dollar (XCD)",
    value: "XCD {{amount}}",
  },
  {
    code: "XOF",
    label: "CFA Franc BCEAO (XOF)",
    value: "CFA {{amount}}",
  },
  {
    code: "XPF",
    label: "CFP Franc (XPF)",
    value: "XPF {{amount}}",
  },
  {
    code: "YER",
    label: "Yemeni Rial (YER)",
    value: "YR {amount}}",
  },
  {
    code: "ZAR",
    label: "South African Rand (ZAR)",
    value: "R {{amount}}",
  },
  {
    code: "ZMW",
    label: "Zambian Kwacha (ZMW)",
    value: "ZMW {{amount}}",
  },
];

const defaultAddItemAction = {
  actionType: "add_item",
  actionConfig: {
    target: null,
    preferenceValue: null,
    preferenceType: null,
    quantity: 1,
    notification: {
      showNotification: false,
      title: "New Item added",
      description: "A new item has been added to the cart",
      autoClose: true,
      autoCloseDuration: 5,
    },
  },
};
const defaultAddCartAttributeAction = {
  actionType: "add_cart_attribute",
  actionConfig: {
    attributeName: "Name",
    attributeValue: "Value",
  },
};
const defaultGoToUrl = {
  actionType: "goto_url",
  actionConfig: {
    url: null,
    openInNewTab: true,
  },
};

export {
  langOptions,
  suportedCurrencies,
  defaultAddItemAction,
  defaultAddCartAttributeAction,
  defaultGoToUrl,
};
