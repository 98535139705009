import React from "react";
import { RiLoader5Fill } from "react-icons/ri";

const Button = ({
  btnId = "",
  type,
  onClick,
  icon,
  children,
  className,
  animate,
  disabled,
  loading,
  noPadding,
}) => {
  const getBtnStyle = () => {
    switch (type) {
      case "primary":
        return "bg-violet-700 hover:bg-violet-600 text-white font-bold ";
      case "ghost":
        return "bg-gray-300 border text-gray-700 bg-opacity-25 hover:bg-opacity-25 hover:bg-gray-500 font-bold ";
      case "white-ghost":
        return "shadow-md bg-white bg-opacity-50 text-gray-700  hover:bg-opacity-25 hover:bg-white hover:bg-opacity-100 font-bold ";
      case "link-thin":
        return "text-gray-700 hover:text-violet-700 ";
      case "link-primary":
        return "text-violet-700 hover:text-violet-800 font-bold";
      case "link":
        return "text-gray-700 hover:text-violet-700 font-bold";
      case "outline":
        return "border border-violet-700 text-violet-700 hover:text-gray-700 hover:border-gray-700 font-bold ";
      case "danger":
        return "bg-red-500 hover:bg-red-600 text-white font-bold ";
      case "danger-ghost":
        return "bg-red-200  text-red-600 bg-opacity-25 hover:bg-opacity-25 hover:bg-red-300 font-bold ";
      case "danger-link":
        return "text-red-600 hover:text-red-500 font-bold";
      case "danger-outline":
        return "border border-red-500 text-red-500 hover:text-red-600 hover:border-red-600 font-bold ";
      default:
        return "bg-violet-700  text-white font-bold";
    }
  };

  return (
    <button
      id={btnId}
      onClick={onClick}
      className={`${
        noPadding
          ? "p-0"
          : "py-2 desktop:py-3 desktop:px-4  laptop:px-3  mobile:px-2"
      } flex  items-center justify-center rounded-md transition duration-200 ease-in-out cursor-pointer focus:outline-none focus:shadow-none ${getBtnStyle()} ${className} ${
        animate && "animate-breathe"
      } ${disabled && "pointer-events-none opacity-50 cursor-not-allowed"} `}
    >
      {(icon || loading) && (
        <span
          className={`${
            children && "laptop:mr-2 mobile:mr-1"
          } text-lg desktop:text-xl ${loading === true && "animate-spin"}`}
        >
          {loading ? <RiLoader5Fill /> : icon}{" "}
        </span>
      )}
      <span className="shrink-0 text-sm desktop:text-base leading-none">
        {children}
      </span>
    </button>
  );
};

export default Button;
