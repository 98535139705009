import React, { useState, useEffect } from "react";
import Button from "./Button";
import {
  RiArrowDropRightFill,
  RiArrowRightFill,
  RiArrowLeftLine,
  RiArrowDownSLine,
  RiLoader4Line,
  RiMicroscopeFill
} from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useWorkspace } from "../contexts/WorkspaceContext";

const SelectionItem = ({
  id,
  title,
  isSelected,
  onClick,
  iconImg,
  comingSoon,
  selectorId,
  experimental
}) => (
  <button
    onClick={onClick}
    id={selectorId}
    key={id}
    className={`px-4 laptop:py-3 desktop:py-5 mb-2 text-base w-full flex font-bold items-center border cursor-pointer hover:shadow-lg transition-all rounded-lg ${isSelected ? "border-violet-700 " : "border-gray-300"
      }`}
  >
    <div className="w-9 mr-3">
      <img className="w-full" alt="" src={iconImg} />
    </div>
    <div
      className={`grow text-left ${isSelected ? "text-violet-800 " : "text-gray-700"
        }`}
    >
      <div className="flex items-center">
        <span>
          {title}
        </span>
        {experimental && (

          <p className="font-bold text-xs flex items-center ml-2 bg-violet-100 text-violet-700 px-2 rounded-full">
            <RiMicroscopeFill size={12} />
            <span className="ml-1">
              Experimental
            </span>
          </p>
        )}
      </div>
      {comingSoon && <div className="text-xs text-gray-500">Coming Soon</div>}
    </div>
    <span className={`${isSelected ? "text-violet-800" : "text-gray-300"}`}>
      <RiArrowDropRightFill size={30} />
    </span>
  </button>
);

/**
 * This component renders a modal with option to create various campaigns
 * @param {Boolean} isModalVisible determinses the modal is open or not
 * @param {Function} closeModal function to close modal
 * @param {Number} campaignCount the total number of campaign user curently have
 * @returns modal component
 */
const ItemSelectorModal = ({
  initialSelectItem,
  campaignArray,
  onCreate,
  title,
  description,
  isLoading,
}) => {
  let history = useHistory();
  const workspace = useWorkspace();
  const [selectedItem, setSelectedItem] = useState(initialSelectItem);
  const [moreInfoExpand, setMoreInfoExpand] = useState(true);

  const selectedCampaignObj = campaignArray.find(
    (object) => object.id === selectedItem
  );

  return (
    <div className="flex items-stretch rounded-md h-full overflow-hidden">
      <div className="w-1/2 px-8 py-8 h-full overflow-y-auto">
        <div className="mb-3 ">
          <div className="flex items-center mb-3">
            <p className="font-bold text-xl text-violet-800">{title}</p>
          </div>
          <p className="text-base font-gray-700">{description}</p>
        </div>
        <div>
          {campaignArray.map((campaignObj) => {
            return (
              <SelectionItem
                key={campaignObj.id}
                title={campaignObj.title}
                iconImg={campaignObj.iconUrl}
                comingSoon={campaignObj.comingSoon}
                experimental={campaignObj.experimental}
                isSelected={selectedItem === campaignObj.id}
                selectorId={campaignObj.campaignSelectorId}
                onClick={() => {
                  setSelectedItem(campaignObj.id);
                }}
              />
            );
          })}
        </div>
        <div className="mt-4">
          <Button
            onClick={() => history.goBack()}
            type="link"
            icon={<RiArrowLeftLine />}
          >
            Go Back
          </Button>
        </div>
      </div>
      {selectedCampaignObj ? (
        selectedCampaignObj.comingSoon ? (
          <div className="w-1/2 relative flex border-l flex-col max-h-full overflow-y-auto">
            <div className="flex items-center justify-center bg-gradient-to-b from-violet-100 py-5 grow text-center text-4xl font-bold text-violet-200">
              Coming Soon
            </div>
          </div>
        ) : (
          <div className="w-1/2 relative flex border-l flex-col max-h-full overflow-y-auto">
            <div className="">{selectedCampaignObj.descriptionImage}</div>
            <div className="px-4 my-4">
              <div className=" px-3">
                <p className="flex items-center text-violet-700 text-2xl font-bold">
                  <span>
                    {selectedCampaignObj.title}
                  </span>
                  {selectedCampaignObj.experimental && (

                    <p className="font-bold text-xs flex items-center ml-2 bg-violet-100 text-violet-700 px-2 rounded-full">
                      <RiMicroscopeFill size={12} />
                      <span className="ml-1">
                        Experimental
                      </span>
                    </p>
                  )}
                </p>
                <p className="text-gray-600 mt-3">
                  {selectedCampaignObj.description}
                </p>
              </div>

              <div className="px-4 mt-4">
                {selectedCampaignObj.experimental &&
                  workspace.data.isExperimentalFeaturesTurnedOn === false ? (
                  <div className="border p-2 rounded-md bg-amber-100">
                    <p className="text-sm">
                      To use this feature, please turn on the experimental features in our app settings.
                    </p>
                    <button
                      onClick={() => history.push("/settings/account")}
                      id={selectedCampaignObj.ctaBtnId}
                      className="text-sm text-violet-700 hover:text-violet-800 p-2 shadow bg-white border rounded-md mt-2 font-bold transition-all"
                    >
                      Turn on Experimental Features
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => onCreate(selectedCampaignObj)}
                    id={selectedCampaignObj.ctaBtnId}
                    className="bg-violet-700 hover:bg-violet-800 transition-all px-4 py-3 grow flex items-center justify-center rounded-lg text-base font-bold text-white"
                  >
                    <span className="mr-2">{selectedCampaignObj.ctaText}</span>
                    {isLoading ? (
                      <span className="animate-spin">
                        <RiLoader4Line />
                      </span>
                    ) : (
                      <RiArrowRightFill />
                    )}
                  </button>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                setMoreInfoExpand(!moreInfoExpand);
              }}
              className="border-b border-t px-8 py-3 bg-gray-100 hover:bg-gray-200 flex justify-between"
            >
              <p className="font-bold text-gray-700 text-sm ">More Info</p>
              <div
                className={`${!moreInfoExpand && "rotate-180"} transition-all`}
              >
                <RiArrowDownSLine />
              </div>
            </button>
            {moreInfoExpand && (
              <div className="mt-3 px-8 ">{selectedCampaignObj.content}</div>
            )}
          </div>
        )
      ) : (
        <div className="w-1/2 relative flex border-l flex-col max-h-full overflow-y-auto">
          <div className="flex items-center justify-center bg-gradient-to-b from-violet-100 p-12 grow text-center text-4xl font-bold text-violet-200">
            Please select a campaign type from the left to continue
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemSelectorModal;
