import React from "react";

const FaqItem = (props) => {
  const title = props.faqItem.title;

  return (
    <div>
      <div className="coca-d-flex coca-align-items-center coca-cowi-card coca-cowi-hover-card coca-cowi-faq-card">
        <div className="coca-cowi-faq-card-image">
        <img src={props.faqItem.categoryIcon} alt="asd" />
        </div>
        <div className="coca-cowi-faq-card-details coca-d-col">
          <p
            
          >
            <b>

            {title}
            </b>
          </p>
          {props.faqItem.categoryTitle && <small>{props.faqItem.categoryTitle}</small>}
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
