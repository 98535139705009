import React, { useState } from "react";
import { RiVipCrown2Fill } from "react-icons/ri";

import Button from "./Button";
import { useHistory } from "react-router-dom";

/**
 * An UI element for paywalling
 * @component
 * @param {Number} paywallPlan - Which plan to show on the paywall
 * @param {String} title - The title to show under the paywall
 * @param {String} description - The mesage to show under the paywall
 * @param {"horizontal"||"vertical"} type - the layout style
 */
const PaywallElement = ({ title, description, type }) => {
  const history = useHistory();

  const redirectToBilling = () => {
    history.push("/settings/Billing");
  };

  return (
    <>
      <div className="flex justify-center">
        <div
          className={`${
            type === "horizontal" && "flex"
          } m-3 rounded w-92 rounded-lg overflow-hidden border `}
        >
          <div className=" flex justify-center items-center text-violet-700 mt-3 ">
            <RiVipCrown2Fill size={22} />
          </div>
          <div
            className={` ${
              type === "horizontal" ? "text-left" : "text-center"
            }  px-3 m-3`}
          >
            <p className="font-bold text-base ">{title ? title : " Upgrade"}</p>
            <p className="text-base text-gray-600 ">
              {description
                ? description
                : " Subscribe to a higher plan to unlock this feature"}
            </p>
            <div
              className={` ${
                type === "horizontal" ? "justify-start" : "justify-center"
              }  flex
            mt-3`}
            >
              <Button type="primary" onClick={() => redirectToBilling()}>
                Upgrade
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaywallElement;
