import React from "react";
import { RiCloseFill } from "react-icons/ri";

/**
 * cart annoucement example
 */
const CartAnnouncementExample = () => {
  return (
    <div className="flex relative items-center bg-white rounded-t-lg overflow-hidden w-4/5">
      <div className=" absolute top-0 bg-gradient-to-b from-violet-700 w-full h-full p-3 z-10"></div>

      <div className="px-3 pt-3 z-20 w-full">
        <div className=" flex justify-between items-center w-full">
          <p className="text-lg font-bold text-white">Cart</p>
          <p className="text-xs shadow-lg bg-white rounded-full text-gray-700 px-2 flex items-center">
            <RiCloseFill />
            <span> Close</span>
          </p>
        </div>
        <div className="bg-white pb-8 rounded-t-lg mt-2 shadow-lg">
          <div className="bg-amber-300 px-2 py-3 rounded-t-lg mt-2 flex justify-center text-sm font-bold">
            <span>Your message here 🎁</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartAnnouncementExample;
