import React, { useState } from "react";

import { RiGiftFill, RiTruckFill, RiPercentFill } from "react-icons/ri";
import Button from "../../../../../components/Button";
import generateNanoId from "../../../../../utilities/generateNanoId";

/**
 * A UI which lets user select a goal type and returns a default milestone based on that goal type
 * @param {Object} values Current values in the formik
 * @param {Function} onSelect What happens when user selects a goal type. the default milestone with that goal type will be a parameter of this callback function
 * @param {Function} onClose what happens on close
 * @param {String} currentCampaignKey reward campaignCount
 * @param {String} title Title shown above the element
 */
const AddGoalButton = ({
  values,
  onSelect,
  currentCampaignKey,
  onClose,
  title,
}) => {
  const handleAddNewGoal = async (selectedType) => {
    const nanoId = await generateNanoId();
    let defaultRewardStack;
    let defaultContent;
    switch (selectedType) {
      case "shippingDiscount": {
        defaultRewardStack = [
          {
            type: "shippingDiscount",
            target: "shipping",
            value: 100,
            unit: "percent",
            promoCode: `SHIP${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText: "Add {{goal}} more to get Free Shipping on this order",
          postGoalText: "Congratulations! You have unlocked Free Shipping!",
          milestoneLabel: "Free Shipping!",
          preGoalOfferPageTitle: "Free Shipping",
          postGoalOfferPageTitle: "Free Shipping",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get free shipping on this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked free shipping!",
        };
        break;
      }
      case "freeProduct": {
        defaultRewardStack = [
          {
            type: "freeProduct",
            target: "",
            value: 100,
            unit: "percent",
            promoCode: `GIFT${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText: "Add {{goal}} more to get Free Gift with this order",
          postGoalText: "Congratulations! You have unlocked Free Gift!",
          milestoneLabel: "Free Gift!",
          preGoalOfferPageTitle: "Free Gift",
          postGoalOfferPageTitle: "Free Gift",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get Free Gift with this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked free Gift!",
        };
        break;
      }
      case "orderDiscount": {
        defaultRewardStack = [
          {
            type: "orderDiscount",
            target: "cart",
            value: 20,
            unit: "percent",
            promoCode: `OFF${currentCampaignKey}${nanoId}`,
            limit: {
              quantity: 1,
            },
          },
        ];
        defaultContent = {
          preGoalText:
            "Add {{goal}} more to get a {{discount}} discount on this order",
          postGoalText:
            "Congratulations! You have unlocked the {{discount}} discount!",
          milestoneLabel: "{{discount}} Off",
          preGoalOfferPageTitle: "{{discount}} Discount",
          postGoalOfferPageTitle: "{{discount}} Discount",
          preGoalOfferPageSubTitle:
            "Add {{goal}} more to get a {{discount}} discount on this order",
          postGoalOfferPageSubTitle:
            "Congratulations! You have unlocked the {{discount}} discount!",
        };

        break;
      }
    }

    const currentMilestones = values.campaign_cart_goals[0].milestones;

    const defaultMilestone = {
      id: Math.floor(Math.random() * 10000),
      goal: currentMilestones[currentMilestones.length - 1].goal + 50,
      rewards: {
        id: "",
        stackModifier: selectedType === "freeProduct" ? "any" : "all",
        stackSelectCount: 1,
        prerequisites: {},
        rewardStack: defaultRewardStack,
      },
      content: defaultContent,
    };
    onClose();
    onSelect(defaultMilestone);
  };

  const GoalTypeButton = ({ icon, title, onClick, disabled, subTitle }) => {
    return (
      <button
        onClick={onClick}
        className={`${
          disabled && "pointer-events-none opacity-50"
        } flex justify-center flex-col items-center p-3 mx-2 rounded-lg border bg-white text-gray-700  hover:text-violet-700 transition-all hover:border-violet-50 hover:bg-violet-50`}
      >
        {icon}
        <span className="mt-2 font-bold">{title}</span>
        {subTitle && <span className="text-xs">{subTitle}</span>}
      </button>
    );
  };

  const getExistingFreeShippingGoalIndex = () => {
    if (
      values.campaign_cart_goals[0].milestones[0].rewards.rewardStack.length > 0
    ) {
      const existingFreeShippingGoalIndex =
        values.campaign_cart_goals[0].milestones.findIndex(
          (milestone) =>
            milestone.rewards.rewardStack[0].type === "shippingDiscount"
        );
      return existingFreeShippingGoalIndex;
    } else {
      // this specific case is only for when the intial milestone reward is not selected
      return -1;
    }
  };

  return (
    <div className="w-full  text-center py-6 ">
      <p className="font-bold">
        <span className="ml-1">{title}</span>
      </p>
      <div className="mt-4 flex items-stretch justify-center">
        <GoalTypeButton
          icon={<RiGiftFill size={27} />}
          title="Give Free Products"
          onClick={() => {
            handleAddNewGoal("freeProduct");
          }}
        />

        <GoalTypeButton
          icon={<RiTruckFill size={27} />}
          title="Give Free Shipping"
          // disabled={getExistingFreeShippingGoalIndex() !== -1}
          subTitle={
            getExistingFreeShippingGoalIndex() !== -1 &&
            `(Warning : Free shipping is already given in Goal ${
              getExistingFreeShippingGoalIndex() + 1
            } )`
          }
          onClick={() => {
            handleAddNewGoal("shippingDiscount");
          }}
        />

        <GoalTypeButton
          icon={<RiPercentFill size={27} />}
          title="Give Cart Discount"
          onClick={() => {
            handleAddNewGoal("orderDiscount");
          }}
        />
      </div>
      <div className="mt-4 flex items-center justify-center">
        <Button
          type="ghost"
          noPadding
          className="px-3 py-1"
          onClick={() => onClose()}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default AddGoalButton;
