/* --------------- Queries ------------------- */
export const queryAllAssistantArticles = `
  query queryAllAssistantArticles{
    assistant_articles {
      id
      title
      statistics
      is_published
      is_featured
      lang
      body
      categoryid
      position_rank
      content

    }
    assistant_categories{
      id
      name
      icon_url
      meta
      position_rank
      content
    }
      accounts{
    id
  }
  }
`;

export const queryAssistantArticle = `
query queryAssistantArticle($article_id: bigint!) {
  assistant_articles(
    where: { id: { _eq: $article_id } }
  ) {
      id
      content
      metadata
      statistics
      is_published
      is_featured
      categoryid
      updated_at
      position_rank
      visibility_rules

  }
  assistant_categories{
      id
      content
      icon_url
      meta
      position_rank
    }
}
`;
export const queryArticleCategories = `
query queryArticleCategories($categoryid: bigint!) {
  assistant_articles(
    where: { categoryid: { _eq: $categoryid } }
  ) {
      id
      statistics
      is_published
      is_featured
      content
      categoryid
      updated_at
      position_rank
  }
   assistant_categories (
    where: { id: { _eq: $categoryid } }
  ){
      id
      icon_url
      content
      meta
      position_rank
      show_in_widget
      show_in_page
    }
}
`;

export const queryOneAccordion = `
query queryOneAccordion($accordionsid: bigint!) {
  accordions(
    where: { id: { _eq: $accordionsid } }
  ) {
    id
    is_active
    name
    sourceid
    type
    config
    custom_css
  }
  accordion_articles  (
    where: { accordionsid: { _eq: $accordionsid } }
  ){
    articlesid
    }
}
`;
export const queryProductAccordion = `
query queryProductAccordion {
  accordions(where: {type: {_eq: "dynamic"}}) {
    id
    is_active
    name
    sourceid
    type
    config
    custom_css
  }
}

`;

export const queryAllStaticAccordions = `
query queryAllStaticAccordions{
  accordions ( where: {type: {_eq: "static"}}) {
    id
    is_active
    name
    sourceid
    type
  }
}
`;

export const queryAccordionArticles = `
query queryAccordionArticles($accordionsid: bigint!) {
  accordion_articles(where: {accordionsid: {_eq: $accordionsid}}) {
    accordionsid
    position_rank
    id
    assistant_article {
      id
      title
    }
  }
}
`;

/* --------------- Mutations ------------------- */

export const insertAssistantArticle = `
  mutation insertAssistantArticle ($articleInput:assistant_articles_insert_input!){
    insert_assistant_articles_one(object: $articleInput) {
      id
    }
  }
`;

export const updateAssistantArticle = `
  mutation updateAssistantArticle ($articleId:assistant_articles_pk_columns_input!, $articleInput:assistant_articles_set_input){
    update_assistant_articles_by_pk(pk_columns: $articleId, _set:$articleInput) {
      id
      is_published
      categoryid
      title
      body
    }
  }
`;

export const bulkUpdateAssistantArticle = `
mutation bulkCategoryUpdateAssistantArticle($idArray: [bigint!]!,$newCategory:assistant_articles_set_input) {
  update_assistant_articles(_set:$newCategory, where:{_and:{id:{_in:$idArray}}}) {
     affected_rows
    }
}
`;

export const deleteAssistantArticle = `
  mutation deleteAssistantArticle ($articleId:bigint!){
    delete_assistant_articles_by_pk(id: $articleId) {
      id
    }
  }
`;

export const bulkDeleteAssistantArticle = `
mutation bulkDeleteAssistantArticle($idArray: [bigint!]!) {
  delete_assistant_articles(where:{_and:{id:{_in:$idArray}}})
  {
    affected_rows
  }
}
`;

export const insertAccordion = `
mutation insertAccordion ($accordionInput: accordions_insert_input!){
  insert_accordions_one(object: $accordionInput) {
    id
  }
}
`;
export const updateAccordion = `
mutation updateAccordion ($accordionsId: accordions_pk_columns_input!, $accordionsInput:accordions_set_input){
  update_accordions_by_pk(pk_columns: $accordionsId, _set:$accordionsInput) {
    id
  }
}
`;

export const deleteAccordion = `
  mutation deleteAccordion ($accordionsId:bigint!){
    delete_accordions_by_pk(id: $accordionsId) {
      id
    }
  }
`;

export const updateAccordionArticle = `
mutation updateAccordionArticle ($accordionsArticleId: accordion_articles_pk_columns_input!, $accordionsArticleInput:accordion_articles_set_input){
  update_accordion_articles_by_pk(pk_columns: $accordionsArticleId, _set:$accordionsArticleInput) {
    id
  }
}
`;

export const deleteAccordionArticle = `
  mutation deleteAssistantArticle ($accordionArticleId:bigint!){
    delete_accordion_articles_by_pk(id: $accordionArticleId) {
      id
    }
  }
`;
export const insertAccordionArticles = `
mutation insertAccordionArticles ($accordionArticlesInput: [accordion_articles_insert_input!]!){
  insert_accordion_articles(objects: $accordionArticlesInput) {
    affected_rows
  }
}
`;

export const insertAssistantCategory = `
mutation insertAssistantCategory ($categoryInput: assistant_categories_insert_input!){
  insert_assistant_categories_one(object: $categoryInput) {
    id
  }
}
`;
export const updateAssistantCategory = `
mutation updateAssistantCategory ($categoryId: assistant_categories_pk_columns_input!, $categoryInput:assistant_categories_set_input){
  update_assistant_categories_by_pk(pk_columns: $categoryId, _set:$categoryInput) {
    id
  }
}
`;

export const deleteAssistantCategory = `
  mutation deleteAssistantCategory ($categoryId:bigint!){
    delete_assistant_categories_by_pk(id: $categoryId) {
      id
    }
  }
`;
