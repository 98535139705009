import React from "react";
import { useQuery } from "react-query";
import { useGraphQl } from "../contexts/GraphqlClient";
import { Link } from "react-router-dom";
import { queryBillingInfo, queryUsageStats } from "../api/billing";
import { useWorkspace } from "../contexts/WorkspaceContext";

const OfcoBillingWarning = () => {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();

  const { data: usageData } = useQuery(
    "queryUsageStats",
    () =>
      graphQL(queryUsageStats, {
        appId: `${2}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
        accountId: `${workspace.data && workspace.data.accountid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 2) }
  );
  const { data: ofcoData } = useQuery(
    "queryofcoBillingInfo",
    () =>
      graphQL(queryBillingInfo, {
        appId: `${2}`,
        sourceId: `${workspace.data && workspace.data.sourceid}`,
      }),
    { enabled: workspace.data.installedApps.find((item) => item.appid === 2) }
  );

  if (
    ofcoData &&
    usageData &&
    ofcoData.billingInfo.planId === 10 &&
    usageData.getUsageStats.currentReferralSales > 100
  )
    return (
      <div className="bg-red-100 px-5 py-5 t text-red-500 flex justify-center items-center">
        <span className="max-w-4xl">
          <b>Campaign Paused (Limit Exceeded)</b>: In the Free plan the
          campaigns will be automatically paused if the referral revenue
          generated exceed $100.
        </span>
        <Link to={`/settings/account/billing/`}>
          <button className="bg-red-600 text-white font-bold p-3 rounded-lg mx-3 focus:outline-none">
            Upgrade to Pro
          </button>
        </Link>
      </div>
    );
  else return <div />;
};

export default OfcoBillingWarning;
