import { React, useState, useCallback, useEffect } from "react";
import CustomiserUnit from "../../../../../components/CustomiserUnit";
import DateTimePicker from "../../../../../components/DateTimePicker";
import TabRadioButton from "../../../../../components/TabRadioButton";
import { RiInformationFill } from "react-icons/ri";
/**
 * open settings
 *
 * @component
 * @param {Object} values - current values inside the form
 * @param {Function} setFieldValue - Function to set values in each field
 */
export default function Settings({ values, setFieldValue }) {
  const timerOptions = [
    { label: "Timer for a specified duration", value: "sessionTimer" },
    { label: "Count down to a specific date & time", value: "dateTimer" },
  ];

  const onEndDateChange = useCallback(
    (selectedDate) => {
      setFieldValue(
        `campaign_campaign_cart_timers[0].config.value.datetimer`,
        selectedDate
      );
    },
    [values]
  );
  return (
    <div className="h-full  overflow-y-auto px-8 py-4">
      <CustomiserUnit
        title="Timer Types"
        description="Select the type of Timer"
      >
        <div className="flex flex-col gap-5">
          <div className="flex gap-4">
            <TabRadioButton
              defaultValue={
                values.campaign_campaign_cart_timers[0].config.value.type
              }
              options={timerOptions}
              onChange={(selectedValue) => {
                setFieldValue(
                  `campaign_campaign_cart_timers[0].config.value.type`,
                  selectedValue
                );
              }}
            />
          </div>
          {values.campaign_campaign_cart_timers[0].config.value.type ===
          "sessionTimer" ? (
            <div className="flex flex-col flex-grow">
              <div className=" py-2 px-2 my-3 rounded-lg flex text-sm items-center bg-gray-100 w-fit">
                <RiInformationFill size={12} className="grow-0 mr-3" />
                <p>
                  The countdown starts when the first product is added to a cart
                </p>
              </div>
              <p className="text-xs font-bold text-gray-600 mb-2">
                SESSION DURATION
              </p>
              <div className="flex">
                <div className="flex items-end">
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-xs font-bold text-gray-600 mb-2 ">
                      Hours
                    </p>
                    <input
                      type="number"
                      placeholder="hr"
                      value={
                        values.campaign_campaign_cart_timers[0].config.value
                          .sessiontimer.hours
                      }
                      min="0"
                      max="999"
                      onChange={(e) => {
                        setFieldValue(
                          `campaign_campaign_cart_timers[0].config.value.sessiontimer.hours`,
                          parseInt(e.target.value)
                        );
                      }}
                      className="border border-solid border-gray-200 rounded-md py-2 mx-2 px-2 text-left w-full"
                    />
                  </div>
                  <p className="text-2xl m-2 items-end">:</p>
                </div>

                <div className="flex items-end">
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-xs font-bold text-gray-600 mb-2 ">
                      Minutes
                    </p>
                    <input
                      type="number"
                      placeholder="min"
                      value={
                        values.campaign_campaign_cart_timers[0].config.value
                          .sessiontimer.minutes
                      }
                      min="0"
                      max="60"
                      onChange={(e) => {
                        setFieldValue(
                          `campaign_campaign_cart_timers[0].config.value.sessiontimer.minutes`,
                          parseInt(e.target.value)
                        );
                      }}
                      className="border border-solid border-gray-200 rounded-md py-2 mx-2 px-2 text-left w-full"
                    />
                  </div>
                  <p className="text-2xl m-2 items-end">:</p>
                </div>
                <div className="flex items-end">
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-xs font-bold text-gray-600 mb-2 ">
                      Seconds
                    </p>
                    <input
                      type="number"
                      placeholder="sec"
                      value={
                        values.campaign_campaign_cart_timers[0].config.value
                          .sessiontimer.seconds
                      }
                      min="0"
                      max="60"
                      onChange={(e) => {
                        setFieldValue(
                          `campaign_campaign_cart_timers[0].config.value.sessiontimer.seconds`,
                          parseInt(e.target.value)
                        );
                      }}
                      className="border border-solid border-gray-200 rounded-md py-2 mx-2 px-2 text-left w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-fit">
              <p className="text-xs font-bold text-gray-600 mb-2">END DATE</p>
              <DateTimePicker
                value={
                  values.campaign_campaign_cart_timers[0].config.value.datetimer
                }
                onChange={(selectedDate) =>
                  onEndDateChange(Date.parse(selectedDate))
                }
              />
            </div>
          )}
        </div>
      </CustomiserUnit>
    </div>
  );
}
